import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  CircularProgress,
  LinearProgress,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Modal
} from '@cuda-networks/bds-core'
import { Delete, Close, Launch, MenuVertical, Block } from '@cuda-networks/bds-core/dist/Icons/Core'
import { Reply, ReplyAll, Forward, Report, Send } from '@cuda-networks/bds-core/dist/Icons/Email'

import RedeliveryDialog from 'components/libs/dialog/redeliveryDialog/RedeliveryDialog'
import EmailComposer from 'components/libs/dialog/emailComposer/EmailComposer'
import { useMessageDetailsToolbarLogic } from 'components/libs/message/messageDetails/messageDetailsToolbar/useMessageDetailsToolbarLogic'
import styles from 'components/libs/message/messageDetails/messageDetailsToolbar/messageDetailsToolbarStyles'
import ReportingActionModal from 'components/libs/dialog/reportingActionModal/ReportingActionModal'
import { useFormatMessage } from 'lib/localization'

const BASE_18N_KEY = 'ess.message_log.message_detail_toolbar'

const MessageDetailToolbar = () => {
  const formatMessage = useFormatMessage(BASE_18N_KEY)
  const [
    {
      deleteButton: { isDeleteButtonVisible },
      dotsMenu: { isDotsMenuEnabled, isDotsMenuOpen, dotsMenuAnchorElement },
      download: { isDownloadMessagePending, downloadMessageProgressPercent },
      emailComposer: { isComposerOpen, composerEmailType },
      historyButton: { messageHistoryButtonTextId },
      messageDetails: { isMessageDetailLinkVisible, messageDetailLinkHref, globalMid },
      modal: { isModalOpen },
      permissions: { canViewDeliver, isDeliverDisabled, isUserBlocklistDisabled, isEmergencyInboxEnabled },
      redeliveryDialog: { isRedeliveryDialogOpen, redeliveryDialogData, isReportButtonVisible },
      reportingModal: {
        isReportingActionModalDisabled,
        isReportingActionModalOpen,
        reportingActionModalTextId,
        messageAction
      },
      styleProps,
      viewConfig
    },
    {
      deleteButton: { onDeleteMessage },
      reportingModal: { onOpenReportingActionModal, onCloseReportingActionModal },
      historyButton: { onToggleMessageHistory },
      dotsMenu: { onOpenDotsMenu, onCloseDotsMenu, onBlockSenderEmail, onBlockSenderDomain },
      download: { onDownloadMessage },
      emailComposer: { onComposerReply, onComposerReplyAll, onComposerForward, onCloseComposer },
      modal: { onCloseModal },
      messageDetails: { onCloseMessageDetails },
      redeliveryDialog: { onOpenRedeliveryDialog, onCloseRedeliveryDialog },
      rejectButton: { onRejectMessage }
    }
  ] = useMessageDetailsToolbarLogic()
  const classes = styles(styleProps)

  return useMemo(
    () => (
      <>
        {isRedeliveryDialogOpen && redeliveryDialogData && (
          <RedeliveryDialog onClose={onCloseRedeliveryDialog} showReportButton={isReportButtonVisible} />
        )}
        <Modal className={classes.modal} open={isModalOpen} onClose={onCloseModal} data-testid="modal">
          <>
            {isComposerOpen && composerEmailType && (
              <EmailComposer handleClose={onCloseComposer} emailType={composerEmailType} />
            )}
            {isReportingActionModalOpen && messageAction && (
              <ReportingActionModal checkAction={messageAction} handleClose={onCloseReportingActionModal} />
            )}
          </>
        </Modal>
        <Grid container className={classes.drawerHeader} data-testid="drawer-header">
          <Grid item>
            {canViewDeliver && (
              <>
                <Tooltip disableFocusListener title="Deliver" placement="top">
                  <Grid className={classes.sendButton}>
                    <IconButton
                      className={classes.toolbarIconButton}
                      size="small"
                      color="secondary"
                      onClick={onOpenRedeliveryDialog}
                      disabled={isDeliverDisabled}
                      data-testid="send-button"
                    >
                      <Send />
                    </IconButton>
                  </Grid>
                </Tooltip>
                {viewConfig.detailsToolbar.showReport && (
                  <div className={classes.checkButtonWrapper} data-testid="checkbutton-wrapper">
                    <Button
                      className={classes.toolbarButton}
                      color="secondary"
                      disabled={isReportingActionModalDisabled}
                      onClick={onOpenReportingActionModal}
                      size="medium"
                      startIcon={<Report />}
                      variant="text"
                      data-testid="report-button"
                    >
                      {formatMessage(reportingActionModalTextId)}
                    </Button>
                  </div>
                )}
              </>
            )}
            {viewConfig.detailsToolbar.showReject && (
              <Tooltip disableFocusListener title={formatMessage('menu.reject_message')} placement="top">
                <IconButton
                  className={classes.toolbarIconButton}
                  color="secondary"
                  onClick={onRejectMessage}
                  size="small"
                  data-testid="reject-button"
                >
                  <Block />
                </IconButton>
              </Tooltip>
            )}
            {isDeleteButtonVisible && (
              <Tooltip disableFocusListener title={formatMessage('menu.delete_message')} placement="top">
                <IconButton
                  className={classes.toolbarIconButton}
                  color="secondary"
                  onClick={onDeleteMessage}
                  size="small"
                  data-testid="delete-button"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
            {viewConfig.detailsToolbar.showHistoryToggle &&
              globalMid &&
              isMessageDetailLinkVisible &&
              canViewDeliver && (
                <Button
                  className={classes.toolbarButton}
                  variant="text"
                  color="secondary"
                  onClick={onToggleMessageHistory}
                  data-testid="history-button"
                >
                  {formatMessage(messageHistoryButtonTextId)}
                </Button>
              )}
            {isDotsMenuEnabled && (
              <>
                <Tooltip disableFocusListener title={formatMessage('menu.actions')} placement="top">
                  <IconButton
                    ref={dotsMenuAnchorElement}
                    className={classes.toolbarIconButton}
                    size="small"
                    onClick={onOpenDotsMenu}
                    data-testid="dots-menu-button"
                  >
                    <MenuVertical />
                  </IconButton>
                </Tooltip>
                <Menu
                  keepMounted
                  anchorEl={dotsMenuAnchorElement.current}
                  open={isDotsMenuOpen}
                  onClose={onCloseDotsMenu}
                  data-testid="dots-menu-items"
                >
                  {canViewDeliver && !(isDeliverDisabled || isDownloadMessagePending) && (
                    <MenuItem component="li" onClick={onDownloadMessage} data-testid="dots-menu-item-download">
                      {formatMessage('menu.download')}
                    </MenuItem>
                  )}
                  {viewConfig.detailsToolbar.showBlockEmail && !isUserBlocklistDisabled && (
                    <MenuItem component="li" onClick={onBlockSenderEmail} data-testid="dots-menu-item-block-email">
                      {formatMessage('menu.add_email')}
                    </MenuItem>
                  )}
                  {viewConfig.detailsToolbar.showBlockDomain && !isUserBlocklistDisabled && (
                    <MenuItem component="li" onClick={onBlockSenderDomain} data-testid="dots-menu-item-block-domain">
                      {formatMessage('menu.add_domain')}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </Grid>
          <Grid item>
            {isDownloadMessagePending && (
              <div className={classes.downloadingWrapper}>
                <CircularProgress size={20} variant="indeterminate" data-testid="download-progress-indeterminate" />
                <span className={classes.downloadingText}>{formatMessage('downloading')}</span>
                {downloadMessageProgressPercent > 0 && (
                  <LinearProgress
                    variant="determinate"
                    value={downloadMessageProgressPercent}
                    data-testid="download-progress-determinate"
                  />
                )}
              </div>
            )}
            {isEmergencyInboxEnabled && (
              <>
                <Tooltip disableFocusListener title={formatMessage('menu.reply')} placement="top">
                  <IconButton
                    className={classes.toolbarIconButton}
                    size="small"
                    color="secondary"
                    onClick={onComposerReply}
                    data-testid="email-composer-reply-button"
                  >
                    <Reply />
                  </IconButton>
                </Tooltip>
                <Tooltip disableFocusListener title={formatMessage('menu.reply_all')} placement="top">
                  <IconButton
                    className={classes.toolbarIconButton}
                    size="small"
                    color="secondary"
                    onClick={onComposerReplyAll}
                    data-testid="email-composer-reply-all-button"
                  >
                    <ReplyAll />
                  </IconButton>
                </Tooltip>
                <Tooltip disableFocusListener title={formatMessage('menu.forward')} placement="top">
                  <IconButton
                    className={classes.toolbarIconButton}
                    size="small"
                    color="secondary"
                    onClick={onComposerForward}
                    data-testid="email-composer-forward-button"
                  >
                    <Forward />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {isMessageDetailLinkVisible && (
              <>
                <Link target="_blank" rel="noopener noreferrer" to={messageDetailLinkHref}>
                  <IconButton className={classes.toolbarIconButton} size="small" data-testid="message-detail-open">
                    <Launch />
                  </IconButton>
                </Link>
                <IconButton
                  className={classes.toolbarIconButton}
                  size="small"
                  onClick={onCloseMessageDetails}
                  data-testid="message-detail-close"
                >
                  <Close />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>
      </>
    ),
    [
      isRedeliveryDialogOpen,
      redeliveryDialogData,
      onCloseRedeliveryDialog,
      isReportButtonVisible,
      classes,
      isModalOpen,
      onCloseModal,
      isComposerOpen,
      composerEmailType,
      onCloseComposer,
      isReportingActionModalOpen,
      messageAction,
      onCloseReportingActionModal,
      canViewDeliver,
      onOpenRedeliveryDialog,
      isDeliverDisabled,
      isReportingActionModalDisabled,
      onOpenReportingActionModal,
      formatMessage,
      reportingActionModalTextId,
      isDeleteButtonVisible,
      onDeleteMessage,
      globalMid,
      isMessageDetailLinkVisible,
      onToggleMessageHistory,
      messageHistoryButtonTextId,
      isDotsMenuEnabled,
      dotsMenuAnchorElement,
      onOpenDotsMenu,
      isDotsMenuOpen,
      onCloseDotsMenu,
      isDownloadMessagePending,
      onDownloadMessage,
      isUserBlocklistDisabled,
      onBlockSenderEmail,
      onBlockSenderDomain,
      downloadMessageProgressPercent,
      isEmergencyInboxEnabled,
      onComposerReply,
      onComposerReplyAll,
      onComposerForward,
      messageDetailLinkHref,
      onCloseMessageDetails,
      viewConfig,
      onRejectMessage
    ]
  )
}

export default MessageDetailToolbar
