import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import routesConfig, { UiRoute } from 'lib/routesConfig'

interface SideMenuItem {
  id: UiRoute['id']
  text: string
  onClick: () => void
}

interface SideMenuLogic {
  closeSideMenu: () => void
  isSideMenuOpen: boolean
  sideMenuItems: SideMenuItem[]
  openSideMenu: () => void
  selectedSideMenuItem: SideMenuItem
  toggleSideMenu: () => void
}

const BASE_ESS_I18N_KEY = 'ess.sidemenu'

export const useSideMenuLogic = (): SideMenuLogic => {
  const intl = useIntl()

  // TODO: replace the logic below, get selectedItemId from redux state (activePath)
  const selectedItemId = useMemo(() => {
    const key = window.location.pathname.split('/')?.[2]
    if (!key) {
      return routesConfig.MESSAGE_LOG.id
    }
    const [activeRouteId] = Object.entries(routesConfig).find(([, route]) => route.path.split('/')?.[2] === key) || [
      routesConfig.MESSAGE_LOG.id
    ]
    return activeRouteId
  }, [])

  const listItems = useMemo(
    () => [
      {
        id: routesConfig.MESSAGE_LOG.id,
        text: intl.formatMessage({ id: `${BASE_ESS_I18N_KEY}.message_log` }),
        onClick: () => routesConfig.MESSAGE_LOG.goto()
      },
      {
        id: routesConfig.SETTINGS.id,
        text: intl.formatMessage({ id: `${BASE_ESS_I18N_KEY}.settings` }),
        onClick: () => routesConfig.SETTINGS.goto()
      },
      {
        id: routesConfig.SUPPORT.id,
        text: intl.formatMessage({ id: `${BASE_ESS_I18N_KEY}.support` }),
        onClick: () => routesConfig.SUPPORT.goto()
      }
    ],
    [intl]
  )

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const [selectedSideMenuItem, setSelectedSideMenuItem] = useState(
    listItems.find(listItem => listItem.id === selectedItemId) || listItems[0]
  )

  return useMemo(() => {
    const closeSideMenu = () => setIsSideMenuOpen(false)

    const openSideMenu = () => setIsSideMenuOpen(true)

    const toggleSideMenu = () => setIsSideMenuOpen(!isSideMenuOpen)

    const sideMenuItems: SideMenuItem[] = listItems.map(listItem => ({
      ...listItem,
      onClick: () => {
        setSelectedSideMenuItem(listItem)
        listItem.onClick()
        toggleSideMenu()
      }
    }))

    return {
      closeSideMenu,
      isSideMenuOpen,
      sideMenuItems,
      openSideMenu,
      selectedSideMenuItem,
      toggleSideMenu
    }
  }, [isSideMenuOpen, listItems, selectedSideMenuItem])
}
