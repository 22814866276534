import { ChangeEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { process, SortDescriptor } from '@progress/kendo-data-query'

import { CSVLink } from 'react-csv'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { formatDate } from 'lib/datetime'
import config from 'config/appConfig'

import { ColumnsConfig } from 'types/redux/dataTables/dataTables'
import {
  getAuditLogSearch,
  resetSearch,
  setSearchTerm,
  setSearchStartDate,
  setSearchEndDate
} from 'redux/features/auditLog/auditLogSlice'
import {
  reset as resetAuditLogTable,
  update as updateAuditLogTable
} from 'redux/features/dataTables/auditLog/auditLogSlice'
import { isPending } from 'redux/toolkit/api'
import { useFormatMessage } from 'lib/localization'

import { AuditLog, ModifiedAuditLog } from 'types/AuditLog'
import { formatDescription, formatEventType } from 'lib/auditLog'
import { AuditLogPayload } from 'redux/features/auditLog/auditLogApiThunks'
import { getStartDate, getEndDate } from 'lib/mstore'
import { EventTypes } from 'components/pages/auditLog/auditLogFilter/useAuditLogFilterLogic'

const BASE_I18N_ACTION_KEY = 'ess.audit_log.action'
const BASE_I18N_AFFECTED_KEY = 'ess.audit_log.affected'

export interface UseAuditLogLogic {
  inProgress: boolean
  exportConfig: {
    exportRef: MutableRefObject<(CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null>
    fileName: () => string
    headers: {
      label: string
      key: string
    }[]
    csvExport: () => void
  }
  searchConfig: {
    search: string
    sort: SortDescriptor[]
    eventTypes: EventTypes
    startDate: Date
    endDate: Date
    handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handlePageChange: (payload: AuditLogPayload) => void
    handleSearch: () => void
    handleSortChange: (e: GridSortChangeEvent) => void
    handleFilterChange: (eventTypes: EventTypes) => void
    handleUpdateEventTypes: (eventTypes: EventTypes, performSearch: boolean) => void
    handleRemoveEventType: (eventName: string) => void
  }
  tableConfig: {
    tableData: {
      skip: number
      take: number
      total: number
      data: ModifiedAuditLog[]
    }
    columns: { [key: string]: string }
    columnsConfig: ColumnsConfig
  }
  datePickerConfig: {
    min: Date
    max: Date
    format: string
    onChange: (event: DatePickerChangeEvent) => void
  }
}

export const useAuditLogLogic = (): UseAuditLogLogic => {
  const dispatch = useAppDispatch()
  const formatAction = useFormatMessage(BASE_I18N_ACTION_KEY)
  const formatAffected = useFormatMessage(BASE_I18N_AFFECTED_KEY)

  const { auditTable, auditLog, searchTerm, auditLogPending } = useAppSelector(_store => ({
    auditTable: _store.dataTables.auditLog,
    auditLog: _store.auditLog.search,
    searchTerm: _store.auditLog.searchTerm,
    auditLogPending: isPending(_store.auditLog.getAuditLogStatus)
  }))

  const exportRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAuditLogTable())
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(getAuditLogSearch())
    // eslint-disable-next-line
  }, [])

  const handlePageChange = useCallback(
    (payload: AuditLogPayload) => {
      dispatch(resetSearch(true))
      dispatch(resetAuditLogTable())
      dispatch(getAuditLogSearch(payload))
      dispatch(updateAuditLogTable(payload))
    },
    [dispatch]
  )

  const handleSearch = useCallback(() => {
    dispatch(resetSearch(true))
    dispatch(getAuditLogSearch(undefined))
  }, [dispatch])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setSearchTerm({ searchStr: e.target.value }))
    },
    [dispatch]
  )

  const handleSortChange = useCallback(
    (e: GridSortChangeEvent) => {
      dispatch(setSearchTerm({ sort: e.sort }))
      dispatch(getAuditLogSearch())
    },
    [dispatch]
  )

  const handleDateChange = useCallback(
    (event: DatePickerChangeEvent) => {
      const { name, value } = event.target
      const startLimit = getStartDate(30) * 1000
      const endLimit = Date.now()

      // Note: DateTimePicker min={} and max={} are not being checked
      // when datetime is changed using the keyboard, so we need to check it here
      if (!value || value.getTime() < startLimit || value.getTime() > endLimit) {
        return
      }
      const roundedTimestamp = Math.round(value.getTime() / 1000)
      if (name === 'start') {
        dispatch(setSearchStartDate(roundedTimestamp))
      } else {
        dispatch(setSearchEndDate(roundedTimestamp))
      }
      dispatch(getAuditLogSearch())
    },
    [dispatch]
  )

  const tableData = useMemo(() => {
    const { skip, take } = auditTable
    const { data } = process(
      (auditLog.results || []).map((audit: AuditLog, formatter: any) => ({
        ...(audit && {
          ...audit,
          formattedDate: formatDate(audit.timestamp, config.DATETIME.AUDIT_LOG_DATE_WITH_TIME_FORMAT),
          eventType: formatEventType(audit, formatAction),
          description: formatDescription(audit, formatAffected),
          userName: audit.actor,
          ipAddress: audit.ip
        })
      })),
      { skip, take }
    )

    return {
      data,
      total: auditLog.total || 0,
      skip: auditTable.skip || 0,
      take: auditTable.take || 0
    }
  }, [auditLog, auditTable, formatAction, formatAffected])

  const handleFilterChange = useCallback(
    (eventTypes: EventTypes) => {
      dispatch(setSearchTerm({ eventTypes }))
      dispatch(getAuditLogSearch())
    },
    [dispatch]
  )

  /* eslint-disable prettier/prettier */
  const handleUpdateEventTypes = useCallback(
    (eventTypes: EventTypes, performSearch: boolean) => {
      dispatch(setSearchTerm({ eventTypes }))
      if (performSearch) {
        dispatch(getAuditLogSearch())
      }
    },
    [dispatch]
  )
  /* eslint-enable prettier/prettier */

  const handleRemoveEventType = useCallback(
    (eventName: string) => {
      const eventTypes = { ...searchTerm.eventTypes, [eventName]: false }

      dispatch(setSearchTerm({ eventTypes }))
      dispatch(getAuditLogSearch())
    },
    [dispatch, searchTerm]
  )

  const exportHeaders = useMemo(
    () => [
      { label: 'formattedDate', key: 'formattedDate' },
      { label: 'eventType', key: 'eventType' },
      { label: 'description', key: 'description' },
      { label: 'userName', key: 'userName' },
      { label: 'ipAddress', key: 'ipAddress' }
    ],
    []
  )

  const exportFileName = useCallback(() => {
    const currentDate = formatDate(new Date(), config.DATETIME.EXPORT_FILE_NAME_DATE_FORMAT)
    return `atd_log_${currentDate}.csv`
  }, [])

  const csvExport = () => {
    if (exportRef?.current) {
      exportRef.current.link?.click()
    }
  }

  return useMemo(
    () => ({
      inProgress: auditLogPending,
      exportConfig: {
        exportRef,
        fileName: exportFileName,
        headers: exportHeaders,
        csvExport
      },
      searchConfig: {
        search: searchTerm.searchStr,
        sort: searchTerm.sort,
        eventTypes: searchTerm.eventTypes,
        startDate: new Date(searchTerm.startDate * 1000),
        endDate: new Date(searchTerm.endDate * 1000),
        handleInputChange,
        handlePageChange,
        handleSearch,
        handleSortChange,
        handleFilterChange,
        handleUpdateEventTypes,
        handleRemoveEventType
      },
      tableConfig: {
        tableData,
        columns: auditTable.GRID_COLUMNS,
        columnsConfig: auditTable.columnsConfig
      },
      datePickerConfig: {
        min: new Date(getStartDate(30) * 1000),
        max: new Date(getEndDate() * 1000),
        format: config.DATETIME.DATE_PICKER_FORMAT,
        onChange: handleDateChange
      }
    }),
    [
      handleInputChange,
      handlePageChange,
      handleSearch,
      handleSortChange,
      handleDateChange,
      handleFilterChange,
      handleUpdateEventTypes,
      handleRemoveEventType,
      tableData,
      auditTable,
      auditLogPending,
      searchTerm,
      exportFileName,
      exportHeaders
    ]
  )
}
