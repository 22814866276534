import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles({
  container: {
    flexGrow: 1,
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'unset'
  },
  row: {
    flexBasis: 0,
    width: '100%'
  },
  bottomRow: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'auto'
  }
})
