import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch } from 'redux/toolkit/hooks'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import {
  EventHandlers as SearchLogicEventHandlers,
  State as SearchLogicState,
  useSearchLogic
} from 'components/pages/support/useSearchLogic'
import { SupportTopicConfig } from 'types/Support'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'
import { getSupportTopics } from './config'

export interface State {
  searchResults: SearchLogicState['searchResults']
  searchQuery: string
  topics: SupportTopicConfig[]
}

export interface EventHandlers {
  onLinkClick: SearchLogicEventHandlers['onLinkClick']
  onSubmitSearch: () => void
  onReset: SearchLogicEventHandlers['onReset']
  onSearchQueryChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export type SupportLogic = [State, EventHandlers]

export const useSupportLogic = (): SupportLogic => {
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [{ searchResults }, { onSearch, onReset, onLinkClick }] = useSearchLogic()
  const { userHasRight } = useUserRights()

  const onSearchQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const onSubmitSearch = useCallback(() => onSearch(searchQuery), [onSearch, searchQuery])

  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.SUPPORT_PAGE_VIEW)
  }, [dispatch])

  return useMemo(
    () => [
      {
        searchResults,
        searchQuery,
        topics: getSupportTopics(userHasRight(UserRights.GET_ADMIN_SUPPORT_TOPICS))
      },
      {
        onLinkClick,
        onSearchQueryChange,
        onReset,
        onSubmitSearch
      }
    ],
    [onLinkClick, onReset, onSubmitSearch, searchQuery, searchResults, userHasRight]
  )
}
