import { makeStyles, colors } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  editCell: {
    '& .k-textbox': {
      backgroundColor: `${theme.palette.common.white} !important`,
      border: `1px solid ${colors.barracudaBlack30} !important`,
      boxSizing: 'border-box',
      borderRadius: '2px',
      height: '32px'
    },
    '& fieldset': {
      border: 'none !important'
    }
  },
  dropDownList: {
    '& .k-dropdown-wrap': {
      backgroundColor: `${theme.palette.common.white} !important`,
      border: `1px solid ${colors.barracudaBlack30} !important`,
      boxSizing: 'border-box',
      borderRadius: '2px',
      height: '32px'
    }
  },
  disableCss: {
    opacity: 0.5
  },
  iconWarning: {
    marginRight: '5px',
    marginTop: '6px',
    float: 'left',
    height: '20px'
  },
  whitelistNotification: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    backgroundColor: colors.yellow050
  },
  whitelistNotificationMessage: {
    paddingLeft: '5px',
    fontSize: '14px',
    color: theme.palette.common.black
  },
  table: {
    '& .k-i-sort-desc-sm:before': {
      content: '"\\25BE" !important',
      paddingBottom: 6
    },
    '& .k-i-sort-asc-sm:before': {
      content: '"\\25BE" !important',
      transform: 'rotate(180deg) !important',
      paddingBottom: 2
    },
    '& th': {
      textTransform: 'uppercase',
      color: colors.statusInformational,
      lineHeight: '16px',
      padding: 10,
      fontSize: '12px',
      borderColor: colors.uiElement,
      borderLeft: '1 solid transparent',
      borderRight: '1 solid transparent'
    },
    '& td': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 24
    },
    '& tr': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      '&.k-state-selected': {
        backgroundColor: colors.blue050
      },
      '& button': {
        fontSize: '12px',
        boxShadow: 'none',
        height: 32,
        marginBottom: 1
      }
    }
  },
  actionButton: {
    width: 80
  },
  progress: {
    height: 3,
    marginTop: 0
  },
  itemName: {
    marginLeft: 26
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  },
  subTitle: {
    color: colors.barracudaBlack50
  },
  actions: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16
  }
}))
