import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundAntiPhishing(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
        unless you change these settings for a specific domain. In that case, the domain-level settings override the
        global system settings.
        <br />
        <br />
        Phishing scams are typically fraudulent email messages appearing to come from legitimate senders (for example, a
        university, an Internet service provider, a financial institution). These messages usually direct you to a
        spoofed website or otherwise get you to reveal private information such as logins, passwords, or other sensitive
        data. This information is then used to commit identity and/or monetary theft.
        <br />
        <br />
        The settings on this page enable the powerful {props.productName} anti-phishing features to rewrite or block
        malicious URLs that a user might click on in an email, thereby protecting from these kinds of fraud.
        <br />
        <br />
        <b>Anti-Fraud Intelligence</b>
        <br />
        This feature uses a special Bayesian database that is constantly learning for the detection of Phishing scams.
        Use this setting to create policy for messages that
        {props.productName} determines may contain phishing. Actions you can take include:
        <ul>
          <li>
            <b>Block</b>
            <br />
            Blocks the message so that it is never delivered to users.
          </li>
          {!props.hasCpl && (
            <li>
              <b>Quarantine</b>
              <br />
              Quarantines the message so that either the admin decides whether or not to deliver the message or, if
              per-user quarantine is enabled, the user sees the message in their quarantine inbox.
            </li>
          )}
          <li>
            <b>Off</b>
            <br />
            Bypass this type of analysis.
          </li>
        </ul>
        <b>External Sender Warning</b>
        <br />
        When set to <b>On</b>, adds a banner to the top of all inbound emails that originate from outside your
        organization, cautioning your users about opening attachments and clicking links.
        <br />
        <br />
        <h3>Intent</h3>
        Intent analysis is an effective method for catching phishing attacks. It involves researching email addresses,
        web links, and phone numbers embedded in email messages and email attachments, to determine whether they are
        associated with legitimate entities.
        <br />
        <br />
        <b>Intent Analysis</b>
        <br />
        <br />
        Set to <b>On</b> for {props.productName} to apply various forms of Intent Analysis to inbound mail, including
        real-time and multi-level intent (content) analysis.
        <br />
        <br />
        <ul>
          <li>
            <b>Content Intent</b>
            <br />
            This type of intent checking is a subset of Intent Analysis; Content Intent identifies messages that include
            URLs in the message body for which the website contains suspicious content. If <b>Intent Analysis</b> is set
            to <b>On</b>, use this setting to create policy for such messages. Actions you can take with messages that
            match for content intent include:
          </li>
          <ul>
            <li>
              <b>Block</b> the message so that it is never delivered to the user.
            </li>
            {!props.hasCpl && (
              <li>
                <b>Quarantine</b> the message so that either the admin decides whether or not to deliver the message or,
                if per-user quarantine is enabled, the user sees the message in their quarantine inbox.
              </li>
            )}
            <li>
              {' '}
              <b>Defer</b> the message. When a message is deferred due to intent, if the sender retries the message it
              will be allowed and delivered to the recipient. This means that if a hacker adds malware to a legitimate
              website and that site is added to the Barracuda Networks intent list, then legitimate mail including that
              URL that now includes embedded malware will be deferred. As it is legitimate mail, it will be retried by
              the sending server, allowing the message, which contains a link to a website with embedded malware, to be
              delivered.
            </li>
            <li>
              Set to <b>Off</b> to bypass this type of analysis.
            </li>
          </ul>
        </ul>
        <b>Link Protection</b>
        <br />
        <br />
        When set to <b>On</b>, this feature automatically rewrites any URL in an email message to a Barracuda Networks
        safe URL, and then delivers the message. If the user clicks the URL in the message, the service evaluates the
        URL for validity and, if determined as a valid, non-fraudulent website, redirects the user to that website.
        Otherwise the website is blocked. This feature must be enabled to use <b>Typosquatting Protection</b>.
        <br />
        <br />
        <ul>
          <li>
            <b>Typosquatting Protection</b>
            <br />
            Typosquatting, also known as URL hijacking, is a common trick used by hackers to fool users into thinking
            they are visiting a valid domain such as bankofamerica.com, but there is an incorrect letter in the domain
            name which leads the user to a different domain that may be &apos;spoofing&apos; the domain they wanted.
            When set to <b>On</b>, the Typosquatting Protection feature checks for common typos in the domain name of
            the URL and, if found, rewrites the URL to the correct domain name so that the user visits the intended
            website.
            <br />
            <br />
          </li>
        </ul>
        <b>Notes on Link Protection</b>:
        <ul>
          <li>
            In order to minimize false positives and page load delays, Barracuda Networks continuously maintains a list
            of domains that are considered to be safe. Because of this, some links detected in email messages by this
            feature are &quot;wrapped&quot;, while others are not. For example, Barracuda Networks does not currently
            wrap &quot;google.com&quot;, but does wrap &quot;googlegroups.com&quot; because it provides user-generated
            content.
          </li>
          <li>
            The following are exempt from Link Protection:
            <ul>
              <li>
                Sender email addresses added under <b>Inbound Settings &gt; Sender Policies</b>
              </li>
              <li>
                URLs/domains under <b>Intent Domain Policies</b> set as <b>Ignore</b>
              </li>
            </ul>
          </li>
          <li>
            URLs which are ignored or exempt are not rewritten. Barracuda Networks typosquatting works with tools such
            as{' '}
            <a href="http://www.desvio.com" target="_new">
              Desvio
            </a>
            to determine misspelled domain names. To protect your brand and misspelled domains, contact providers such
            as Desvio to add your misspelled domain name variations to their list.
          </li>
        </ul>
        <b>Intent Domain Policies</b>
        <br />
        <br />
        Use the table to either exempt (Ignore) trusted domains from Intent Analysis and/or add domains which, if found
        in an email message body, should result in blocking {!props.hasCpl && <>or quarantining</>} the message based on
        intent.
        <br /> <br />
        To exempt domains found in the message body from Intent Analysis:
        <ol>
          <li>Enter a domain name.</li>
          <li>
            Select <b>Ignore</b> for the <b>Policy</b>.
          </li>
          <li>
            Click <b>Add</b>.
          </li>
        </ol>
        Any messages containing the exempt domains still undergo spam scanning and Intent Analysis on other domains.
        Specify either a domain or subdomain if you want a wildcard match. For example, enter <code>abc.com</code> to
        exempt messages containing <code>www.abc.com</code> and
        <code>secure.abc.com</code>.
        <br />
        <br />
        <b className={classes.redText}>Important:</b> Any domain listed in this table with <b>Policy</b> set to{' '}
        <b>Ignore</b> is also exempt from <b>URL Click Protection</b>
        settings. Any URL in the list is not rewritten by the service.
        <br />
        <br />
        To add domains which, when found in an email message body, result in the message being blocked{' '}
        {!props.hasCpl && <>or quarantined</>}:
        <ol>
          <li>Enter a domain name.</li>
          <li>
            Select <b>Block</b>
            {!props.hasCpl && (
              <>
                or <b>Quarantine</b>
              </>
            )}{' '}
            as the <b>Policy</b>.Blocked messages never reach the user.{' '}
            {!props.hasCpl && (
              <>
                Quarantined messages reach the user&apos;s inbox if per-user quarantine is enabled. Otherwise the
                administrator can determine which, if any, quarantined messages to deliver.
              </>
            )}
          </li>
          <li>
            {' '}
            Click <b> Add</b>.
          </li>
        </ol>
        You can enter top-level domains here. The domain should not contain <code>http://</code>. For example, per
        company policy, you might want to add the domain <code>ebay.com</code> and select <b>Block</b> to block all eBay
        auction emails.
        <br />
        <br />
        To remove a domain from the table, click <b>Remove</b>.
        <br />
        <br />
        Click on one of the following table headings to sort the table:
        <ul>
          <li>Domain Name</li>
          <li>Policy</li>
          <li>Comment</li>
        </ul>
      </p>
    </div>
  )
}

export default InboundAntiPhishing
