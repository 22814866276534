import React, { useMemo } from 'react'

import { Grid, TextField, Button, Alert, AlertTitle, Tooltip } from '@cuda-networks/bds-core'

import { Typography } from '@material-ui/core'
import { useFormatMessage } from 'lib/localization'
import { onEnter } from 'lib/inputEventHandlers'
import StatusCell from 'components/pages/onboardWizard/serverAndMxSetup/sections/components/StatusCell'
import { SectionsProps } from 'components/pages/onboardWizard/onboardWizardTypes'

import { useSpecifyEmailServerLogic } from 'components/pages/onboardWizard/serverAndMxSetup/sections/useSpecifyEmailServerLogic'
import styles from 'components/pages/onboardWizard/serverAndMxSetup/sections/sectionsStyles'

const BASE_I18N_KEY = 'ess_wizard.server_and_mx_setup.content.specify_email_server'

const SpecifyEmailServer = (props: SectionsProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { setSectionToActive } = props

  const [state, eventHandlers] = useSpecifyEmailServerLogic(props)

  return useMemo(
    () => (
      <Grid container direction="column">
        {state.isMissedServerDetection && (
          <Grid data-testid="missed-email-server" className={classes.alertWrapper} item>
            <Alert severity="error">{formatMessage('missed_email_server')}</Alert>
          </Grid>
        )}

        <Grid container direction="row">
          <Grid className={classes.inputWrapper} item>
            <TextField
              data-testid="email-input"
              autoFocus
              error={state.isEmailInputError}
              fullWidth
              size="small"
              disabled={state.isDetectEmailServerInProgress}
              label={formatMessage('email_address')}
              value={state.emailAddress}
              onFocus={() => setSectionToActive()}
              onChange={eventHandlers.onChangeEmailAddress}
              onKeyDown={onEnter(eventHandlers.onDetectEmailServer)}
              helperText={state.isEmailInputError && formatMessage('invalid_email_address')}
            />
          </Grid>
          <Grid item>
            <Button
              data-testid="detect-email-server"
              isLoading={state.isDetectEmailServerInProgress}
              onClick={eventHandlers.onDetectEmailServer}
              color="primary"
            >
              {formatMessage('detect_email_server')}
            </Button>
          </Grid>
        </Grid>

        {state.emailServerConfig && !state.defaultEmailServerConfig?.server?.length && !state.isVerifiedServer && (
          <Grid className={classes.alertTopWrapper} item>
            <Alert data-testid="unable-to-detect-email-server" severity="error">
              <AlertTitle className={classes.alertTitle}>
                {formatMessage('unable_to_detect_email_server_title')}
              </AlertTitle>
              {`- ${formatMessage('unable_to_detect_email_server_subtitle_1')}`}
              <br />
              {`- ${formatMessage('unable_to_detect_email_server_subtitle_2')}`}
              <br />
              {`- ${formatMessage('unable_to_detect_email_server_subtitle_3')}`}
            </Alert>
          </Grid>
        )}

        {/* EMAIL SERVER DATA */}
        {state.isMissedServerVerify && (
          <Grid className={classes.alertTopWrapper} item>
            <Alert data-testid="verify-server-error" severity="error">
              {formatMessage('verify_server_error')}
            </Alert>
          </Grid>
        )}

        {state.emailServerConfig && (
          <Grid container direction="row">
            <Grid className={classes.tableWrapper} container direction="column">
              {/* TABLE HEADER */}
              <Grid className={classes.tableHeader} container direction="row" alignItems="center">
                <Grid item xs={5} md={6}>
                  <Typography variant="h5">{formatMessage('mail_server')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h5" align="center">
                    {formatMessage('port')}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h5" align="center">
                    {formatMessage('action')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={2}>
                  <Typography variant="h5">{formatMessage('status')}</Typography>
                </Grid>
              </Grid>

              {/* TABLE CONTENT */}
              {!state.isEditEmailServer && (
                <Grid
                  data-testid="email-server-info"
                  className={classes.tableRow}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={5} md={6}>
                    <Tooltip title={state.emailServerConfig?.server} placement="top-start">
                      <Typography data-testid="email-server-name" variant="body1" noWrap>
                        {state.emailServerConfig?.server}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography data-testid="email-server-port" variant="body1" align="center">
                      {state.emailServerConfig?.port}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent="center">
                      <Button
                        data-testid="edit-email-server-button"
                        disabled={state.isEditButtonDisabled}
                        size="small"
                        className={classes.linkButton}
                        color="primary"
                        onClick={() => {
                          eventHandlers.onEdit()
                        }}
                      >
                        {formatMessage('edit')}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <StatusCell
                      isVerified={state.isVerifiedServer}
                      isMissedToVerify={state.isMissedServerVerify || state.isVerifyEmailServerFailed}
                    />
                  </Grid>
                </Grid>
              )}

              {/* EDIT TABLE CONTENT */}
              {state.isEditEmailServer && (
                <Grid
                  data-testid="edit-email-server-row"
                  className={state.isEmailServerConfigError ? classes.erroredTableRow : classes.tableRow}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid className={classes.serverNameTextField} item xs={6}>
                    <TextField
                      data-testid="edit-email-server-name"
                      autoFocus
                      fullWidth
                      error={state.isEmailServerConfigError && !state.emailServerConfig.server.length}
                      size="small"
                      label={formatMessage('server_placeholder')}
                      value={state.emailServerConfig.server}
                      onChange={eventHandlers.onChangeEmailServer}
                      onKeyDown={onEnter(eventHandlers.onFinishEdit)}
                      helperText={
                        state.isEmailServerConfigError &&
                        ((!state.emailServerConfig.server.length && formatMessage('mail_server_name_error')) ||
                          (!String(state.emailServerConfig.port).length && ' '))
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      data-testid="edit-email-server-port"
                      fullWidth
                      size="small"
                      label={formatMessage('port_placeholder')}
                      error={state.isEmailServerConfigError && !String(state.emailServerConfig.port).length}
                      value={String(state.emailServerConfig.port)}
                      onChange={eventHandlers.onChangeServerPort}
                      onKeyDown={onEnter(eventHandlers.onFinishEdit)}
                      helperText={
                        state.isEmailServerConfigError &&
                        ((!String(state.emailServerConfig.port).length && formatMessage('mail_server_port_error')) ||
                          (!state.emailServerConfig.server.length && ' '))
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent="center">
                      <Button
                        data-testid="edit-mode-email-server-button"
                        disabled={state.isEditButtonDisabled}
                        size="small"
                        className={classes.linkButton}
                        color="primary"
                        onClick={eventHandlers.onFinishEdit}
                      >
                        {formatMessage(state.defaultEmailServerConfig?.server.length ? 'update' : 'add')}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <StatusCell
                      isVerified={state.isVerifiedServer}
                      isMissedToVerify={
                        state.isMissedServerVerify || state.isVerifyEmailServerFailed || state.isEmailServerConfigError
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs className={classes.verifyButtonWrapper}>
              <Button
                data-testid="verify-server-button"
                className={state.isEmailServerConfigError ? classes.erroredVerifyButton : classes.verifyButton}
                variant="outlined"
                isLoading={state.isVerifyEmailServerInProgress}
                onClick={eventHandlers.onVerifyServer}
                color="primary"
              >
                {formatMessage('verify_server')}
              </Button>
            </Grid>

            <Grid className={classes.infoWrapper} container direction="column">
              <Alert severity="info">{formatMessage('server_update_info')}</Alert>
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    [classes, formatMessage, state, eventHandlers, setSectionToActive]
  )
}

export default SpecifyEmailServer
