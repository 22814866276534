/* eslint-disable no-bitwise */
import { useCallback, useMemo } from 'react'

import reduxStore from 'lib/reduxStore'

import { useAppSelector } from 'redux/toolkit/hooks'
import { UserRights, UserRole } from 'components/libs/userRights/userRights'

export { UserRights }
export type UseUserRights = {
  userHasRight: (rightName: UserRights) => boolean
  isAdminUser: boolean
  isEndUser: boolean
}

export const hasRight = (rightName: UserRights, userRoleType: number | undefined = undefined): boolean => {
  const validatedUserRoleType: number | undefined =
    userRoleType || reduxStore.getState()?.auth?.accessTokenObject?.roleType

  if (!validatedUserRoleType) {
    return false
  }

  return !!(validatedUserRoleType & rightName)
}

const useUserRights = (): UseUserRights => {
  const { userRoleType } = useAppSelector(_store => ({
    userRoleType: _store.auth.accessTokenObject?.roleType
  }))

  const userHasRight = useCallback((rightName: number): boolean => hasRight(rightName, userRoleType), [userRoleType])
  const isUserType = useCallback((role: UserRole) => !!(userRoleType && userRoleType & role), [userRoleType])

  const isAdminUser = useMemo(() => isUserType(UserRole.ADMIN_USER), [isUserType])
  const isEndUser = useMemo(() => isUserType(UserRole.USER), [isUserType])

  return useMemo(() => ({ userHasRight, isAdminUser, isEndUser }), [userHasRight, isAdminUser, isEndUser])
}

export default useUserRights
