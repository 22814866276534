import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending } from 'redux/toolkit/api'

import { getLastBlockedAtpStats } from 'redux/features/stats/statsSlice'

import { Range, StatType, TopRecipientDomains } from 'types/stats'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'

export interface UseLastBlockedLogicProps {
  domainId?: string
  range: Range
}

interface State {
  lastBlockedAtpStats: TopRecipientDomains | undefined
  lastBlockedAtpStatsInProgress: boolean
  selectedFilter: string
}

interface EventHandlers {
  onSelectFilter: (value: unknown) => void
}

export type UseLastBlockedLogic = [State, EventHandlers]

export const useLastBlockedLogic = (props: UseLastBlockedLogicProps): UseLastBlockedLogic => {
  const dispatch = useAppDispatch()

  const { lastBlockedAtpStats, lastBlockedAtpStatsInProgress } = useAppSelector(_store => ({
    lastBlockedAtpStats: _store.stats.lastBlockedAtpStats,
    lastBlockedAtpStatsInProgress: isPending(_store.stats.api.getLastBlockedAtpStats)
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(
    getTableWithDropdownConfig(DisplayType.atp)?.dropdownItems[0].value || ''
  )

  // new props or selected new filter
  useEffect(() => {
    dispatch(
      getLastBlockedAtpStats({
        domainId: props.domainId,
        range: props.range
      })
    )
  }, [dispatch, props.range, props.domainId, selectedFilter])

  const onSelectFilter: EventHandlers['onSelectFilter'] = useCallback(value => {
    setSelectedFilter(value as StatType)
  }, [])

  return useMemo(
    () => [
      {
        lastBlockedAtpStats,
        lastBlockedAtpStatsInProgress,
        selectedFilter
      },
      {
        onSelectFilter
      }
    ],
    [lastBlockedAtpStats, lastBlockedAtpStatsInProgress, selectedFilter, onSelectFilter]
  )
}
