import OVERVIEW_TAB_EN from 'config/i18n/tabs/overview_en'
import SUPPORT_EN from 'config/i18n/support_en'

const EMAIL = 'Email'
const HEADERS = 'Headers'
const MESSAGE_LOG = 'Message Log'
const SETTINGS = 'Settings'
const SUPPORT = 'Support'
const SENDER = 'Sender'
const SUBJECT = 'Subject'

/* eslint-disable quotes */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ess: {
    overview: OVERVIEW_TAB_EN,
    nivo: {
      choropleth_tooltip_attacks: '{count, plural, =0 {Attacks} one {Attack} other {Attacks}}'
    },
    support_page: SUPPORT_EN,
    app: {
      error: {
        default: 'Oops! Something went wrong',
        failed_to_load_content: 'Failed to load content'
      }
    },
    loading: 'Loading...',
    expiration_banner: {
      free_trial_expires: 'Free trial expired'
    },
    react_experience_banner: {
      end_user: 'Our interface has a new look and feel!',
      detail_link: 'More details'
    },
    advanced_search: {
      keywords: 'Keywords',
      from: 'From',
      envelope_from: 'Envelope From',
      to: 'To',
      envelope_to: 'Envelope To',
      subject: 'Subject',
      attachment_name: 'Attachment Name'
    },
    save_search: {
      title: 'Save Search',
      name_exists: 'This name already exists. Saving will overwrite the existing view.',
      must_enter_name: 'You must enter a name',
      valid_name: ' ',
      name_your_search: 'Name your saved search...'
    },
    open_search: {
      title: 'Open Search',
      subtitle: 'Name',
      find_your_search: 'Find your saved search...'
    },
    login: {
      title: 'Sign in',
      signin_message: 'Please sign in to access your messages and settings in Email Gateway Defense',
      signin_message_email: 'Please enter the password for <b>%s</b>',
      invalid_email: 'Invalid email address and/or password',
      password: 'Password',
      email: 'Email address',
      reset_password_message: 'Password successfully reset',
      valid_email_message: 'Please enter a valid email address',
      signin: 'Sign in',
      next: 'Next',
      different_account_message: 'Sign in with a different account',
      forgot_password: 'Forgot password?',
      admin_signin: 'Administrator sign in',
      signing_in: 'Please wait...',
      expired_message: 'The link to login has expired',
      invalid_link: 'The link to login is invalid',
      login_failed: 'Login was not successful',
      new_link_sent: 'A new link has been sent to your email',
      message: {
        title: 'Check your mail',
        sent_message: 'An email with a link to reset your password has been sent to</br><b>%s</b>.',
        expiry_message: 'The link will expire after 1 hour.',
        resend: 'Resend link'
      }
    },
    bcc_login: {
      error_page: {
        title: 'Admin cannot be authorized',
        subtitle: 'please try again with valid credentials.'
      }
    },
    reset_password: {
      title: 'Reset password',
      reset_password_message: 'Please enter new password for <b>%s</b>',
      password1: 'New password',
      password2: 'Confirm new password',
      password_matches: 'Password matches',
      password_not_match: 'Password does not match',
      new_link_sent: 'A new link has been sent to your email',
      reset: 'Reset',
      expired_link: {
        title: 'Expired link',
        message: 'The link to reset password for <b>%s</b> has expired.',
        sign_in: 'Return to sign in',
        resend_link: 'Resend link'
      },
      check_email: {
        title: 'Check your email',
        message: 'An email with a link to reset your password has been sent to<br/><b>%s</b>.',
        link_will_expire: 'The link will be expired in 1 hour.'
      },
      successful_reset: {
        title: 'Your password has been successfully reset.',
        sign_in: 'Sign in'
      },
      error: {
        send_login_info_failed: 'Failed to send login info',
        resend_link_failed: 'Failed to resend link'
      }
    },
    dialog: {
      title: 'Dialog',
      confirm_title: 'Confirmation',
      info_title: 'Information',
      error_title: 'Error',
      cancel: 'Cancel',
      ok: 'OK',
      yes: 'Yes',
      no: 'No',
      submit: 'Submit',
      save: 'Save',
      open: 'Open',
      search: 'Search',
      okay: 'Okay'
    },
    settings: {
      title: 'Settings',
      tabs: {
        quarantine_notification: 'Quarantine Notification',
        sender_policies: 'Sender Policy',
        linked_accounts: 'Linked Accounts',
        change_password: 'Change Password'
      },
      bulk_edit: {
        buttons: {
          save: 'Save Changes',
          cancel: 'Cancel'
        },
        place_holder: 'Write or past policies here',
        title: 'Bulk Edit: Sender Policies',
        header: 'Sender (Email Address, Domain or User),Policy (block, exempt, quarantine),Comment (optional)',
        sub_title:
          'Bulk Edit allows you to perform multiple changes (additions, subtractions, modifications) to a list of configuration settings in one step.',
        content_header: 'Editing the CSV Data',
        content_sub_header:
          'Many columns can only accept specific forms of data. For instance, columns associated with email addresses must contain data that appears in the format of an email address. Some columns are associated with data that is optional or has associated default values, and may be left empty.',
        warning:
          'Note: Please make sure that the first line does not contain any valid data as it is not parsed. Use column titles on the first line if desired.',
        error_missing_policy: 'Error on row %d (Missing policy)',
        error_invalid_name: 'Error on row %d (Invalid name)',
        error_invalid_policy: 'Error on row %d (Invalid policy)'
      },
      change_password: {},
      quarantine_notification: {
        reset_dialog: {
          title: 'Reset Settings',
          message: 'Are you sure you want to reset the settings?'
        },
        buttons: {
          save: 'Save',
          reset: 'Reset',
          default_interval: {
            never: 'Never',
            scheduled: 'Scheduled'
          }
        },
        title: 'Quarantine Notification',
        disabled_warning: 'Changes to this setting have been disabled by your domain administrator.',
        default_interval: 'Default interval for user quarantine notifications',
        schedule_notification: 'Schedule notification intervals',
        schedule_notification_sub_title:
          'Notifications will be sent at any time within the hour block chosen. Click and drag to select.  Hold Shift while dragging to unselect.',
        allow_override: 'Changes to this setting have been disabled by your domain administrator'
      },
      sender_policies: {
        invalid_domain: 'Please enter a valid domain name',
        invalid_domain_title: 'Invalid domain',
        confirm_removal: 'Are you sure you want to delete the policy?',
        confirm_removal_title: 'Confirm removal',
        block: 'Block',
        exempt: 'Exempt',
        quarantine: 'Quarantine',
        warningHoverMessage: 'Your Organization has blocked this sender',
        buttons: {
          bulk_edit: 'Bulk Edit',
          add: 'Add',
          remove: 'Remove'
        },
        tableHeadings: {
          sender: 'Sender',
          policy: 'Policy',
          comment: 'Comment',
          modified: 'Modified',
          actions: 'Actions'
        },
        title: 'Sender Policy',
        sub_title: 'Specify whether to Block or Exempt messages coming from a specific email address or domain.',
        sender_policy_whitelist_notification_message:
          'Your organization has limited the ability to block and allow email addresses and domains. Sender policies with some conflict will have a warning symbol next to them.'
      },
      linked_accounts: {
        title: 'Linked Accounts',
        sub_title:
          'You can add additional email addresses you have in the same domain to forward quarantined email to this account.',
        verification_message:
          'An email was sent to {email} with instructions on how to verify this account. This account will not be linked until it is verified.',
        confirm_unlink: 'Are you sure you want to unlink the account {email}?',
        account: 'Account (Email Address)',
        add: 'Add',
        unlink: 'Unlink',
        actions: 'Actions',
        unlink_title: 'Confirm Unlink',
        invalid_params: 'Invalid parameters',
        email_sent: 'The email account {userId} is now linked with your account {email}.',
        verifying: 'Verifying linked account...',
        unable_to_link: 'Unable to link these accounts'
      }
    },
    message_log: {
      title: 'Message Log',
      outbound_quarantine_title: 'Outbound Quarantine',
      search_bar: {
        search: 'Search',
        find_hint: 'Find messages, recipients, senders, and more...'
      },
      columns: {
        status: 'STATUS',
        from: 'FROM',
        to: 'TO',
        subject: 'SUBJECT',
        date: 'DATE',
        size: 'SIZE',
        reason: 'REASON',
        score: 'SCORE',
        details: 'DETAILS'
      },
      toolbar: {
        refresh: 'Refresh',
        deliver: 'Deliver',
        export: 'Export Table to CSV',
        categorize: 'Categorize',
        delete: 'Delete Message(s)',
        reject: 'Reject Message(s)',
        corp_email: 'Corporate Email',
        trans_email: 'Transactional Email',
        market_materials: 'Marketing Materials and Newsletters',
        mailing_lists: 'Mailing Lists',
        social_media: 'Social Media',
        other: 'Other',
        message_queue: 'Message Queue',
        deliver_dialog: {
          title: 'Deliver Message',
          text: 'This message will be delivered to the recipient. The sender or domain can be added to your allow list to deliver future messages. Optionally, report the email to help the Barracuda team better identify threats. ',
          learn_more_link: 'Learn more',
          deliver_only: 'Only deliver the message(s)',
          allow_list_sender: 'Deliver and add the sender(s) to your allow list',
          allow_list_domain: 'Deliver and add the domain(s) to your allow list',
          deliver_report: 'Deliver & Report',
          deliver: 'Deliver'
        },
        categorize_other_box_title: 'Specify the category',
        limit_special_chars: 'Characters allowed are Aa-Zz, 0-9, -_ and spaces',
        character_limit: 'Only 30 characters allowed',
        new_email: 'New Message'
      },
      from_column_menu: {
        show_name: 'Show name',
        show_email: 'Show email address',
        show_both: 'Show both'
      },
      message_detail_toolbar: {
        downloading: 'Downloading',
        incorrectly_allowed: 'Report as Incorrectly Delivered',
        incorrectly_blocked: 'Report as Incorrectly Blocked',
        menu: {
          download: 'Download Message',
          allow_deliver: 'Allow Sender and Deliver Message',
          add_email: 'Add Sender Email to Block List',
          add_domain: 'Add Sender Domain to Block List',
          delete: 'Delete',
          delete_message: 'Delete Message',
          reject_message: 'Reject Message',
          show_history: 'Show History',
          hide_history: 'Hide History',
          reply: 'Reply',
          reply_all: 'Reply All',
          forward: 'Forward',
          actions: 'More Actions'
        }
      },
      header_detail: {
        long_reason: 'Message was %s due to %s',
        short_reason: 'Message was %s',
        remediated: 'Allowed: Remediated by Barracuda incident Response at %s',
        from: 'From:',
        to: 'To:',
        subject: 'Subject:',
        date: 'Date:',
        attachments: 'Attachments:',
        mid: 'Message ID:',
        ip: 'IP:',
        env_from: 'Envelope From:',
        recipients: 'Recipients:'
      },
      recipients_info: {
        recipients: 'Recipients',
        action: 'Action',
        reason: 'Reason',
        delivery_status: 'Delivery Status'
      },
      message_preview: {
        message_too_large: 'Message too large. Please download this message to view the source.',
        message_has_virus: 'Virus detected. This message cannot be displayed.',
        show_images_banner: 'Images in this message are not automatically shown.',
        show_images: 'Show Images',
        cant_view_blocked: 'Your administrator has disabled viewing and delivering of blocked messages.',
        cant_view_quarantined: 'Your administrator has disabled viewing and delivering of quarantined messages.',
        no_content: 'This message has no content, or content is not yet available.',
        atd_detected: 'This message has one or more attachments with advanced threats detected.'
      },
      redelivery_queue: {
        redelivering: 'Redelivering...',
        buttons: {
          retry: 'Retry',
          close: 'Close'
        },
        title: 'Message Queue',
        sub_title:
          'The Message Queue displays messages designated for Delivery from the Message Log. Messages are automatically removed from the Queue in 12 hours.'
      },
      email_composer: {
        reply: 'Re',
        forward: 'Fw',
        from: 'From',
        to: 'To',
        cc: 'Cc',
        attachments: 'Attachments',
        new_email_title: 'New Message',
        subject: 'Subject',
        buttons: {
          cancel: 'Cancel',
          send: 'Send'
        }
      },
      reporting_action_modal: {
        report_message: 'Reporting message',
        incorrectly_delivered: 'Report as Incorrectly delivered',
        incorrectly_blocked: 'Report as Incorrectly blocked',
        incorrectly_delivered_content:
          'This email has been reported for further review by the Barracuda team. Provide additional details to ensure messages like this are blocked in the future.',
        incorrectly_blocked_content:
          'This email has been reported for further review by the Barracuda team. Provide additional details to ensure messages like this are delivered in the future.',
        buttons: {
          close: 'Close',
          why_blocked: 'TELL US WHY IT SHOULD BE BLOCKED',
          why_allowed: 'TELL US WHY IT SHOULD BE ALLOWED'
        }
      },
      preset_menu: {
        label: 'Date Presets',
        one_day: 'Last 1 Day',
        two_days: 'Last 2 Days',
        one_week: 'Last 1 Week',
        two_weeks: 'Last 2 Weeks',
        one_month: 'Last 1 Month',
        custom: 'Custom'
      },
      domain_menu: {
        label: 'Domains'
      },
      action: {
        label: 'Action Taken',
        any: 'Any',
        allowed: 'Allowed',
        blocked: 'Blocked',
        quarantined: 'Quarantined',
        encrypted: 'Encrypted',
        deferred: 'Deferred',
        remediated: 'Remediated',
        email_continuity: 'Email Continuity'
      },
      action_for_some: {
        allowed: 'Allowed for some',
        blocked: 'Blocked for some',
        quarantined: 'Quarantined for some',
        encrypted: 'Encrypted for some',
        deferred: 'Deferred for some'
      },
      status: {
        label: 'Action Status',
        any: 'Any',
        delivered: 'Delivered',
        delivered_partial: 'Delivered for some',
        spooled: 'Spooled',
        not_delivered: 'Not Delivered',
        deferred: 'Deferred',
        rejected: 'Rejected'
      },
      encryption: {
        inbound_outbound_encrypted: 'Inbound Encryption + Outbound Encryption',
        inbound_encrypted: 'Inbound Encryption Only',
        outbound_encrypted: 'Outbound Encryption Only',
        encrypted_for_some: 'Inbound Encryption + Outbound Encryption for some recipients'
      },
      reason: {
        label: 'Reason',
        any: 'Any',
        spf: 'Sender Policy Framework (SPF)',
        bafi: 'Anti-Fraud',
        barracuda_email_blacklist: 'Sender Email Address',
        av: 'Antivirus',
        bbl: 'Barracuda Reputation',
        brts: 'Barracuda Real-Time System (BRTS)',
        content_url: 'Content URL',
        content_protected: 'Content Protected',
        intent: 'Intent Analysis',
        image_analysis: 'Image Analysis',
        rbl: 'Realtime Block List',
        content_subject: 'Subject Content',
        content_header: 'Header Content',
        content_body: 'Body Content',
        predefined_filter_exemption: 'Predefined Filter Exemptions',
        predefined_content_sender: 'Predefined From Address',
        content_attachment: 'Attachment Content',
        content_sender: 'From Address',
        content_recipient: 'To/CC Address',
        invalid_recipient: 'Invalid Recipient',
        dest_invalid_recipient: 'Invalid Recipient',
        dkim: 'DomainKeys (DKIM)',
        dmarc: 'DMARC',
        encryption_exemption: 'Encryption Exception',
        mark_spam: 'Sent to Spam Categorization',
        no_prt: 'No PRT Record',
        no_spf: 'No SPF Record',
        password_protected_pdf: 'Password Protected PDF Filtering',
        patd_scan_pending: 'Pending Scan',
        phishline: 'Security Awareness Training',
        predefined_content_subject: 'Predefined Subject Content',
        predefined_content_header: 'Predefined Header Content',
        predefined_content_body: 'Predefined Body Content',
        predefined_content_attachment: 'Predefined Attachment Content',
        predefined_content_recipient: 'Predefined To/CC Address',
        rate_control: 'Rate Control',
        sender: 'Sender Policies',
        ip: 'IP Address Policies',
        tls_required: 'TLS Required',
        inbound_tls_required: 'Inbound TLS Required',
        attachment_filter: 'Attachment Filter',
        message_loop: 'Possible Mail Loop',
        message_size: 'Message Too Large',
        bulk_email: 'Bulk Email',
        office_macros: 'Office Macros',
        predefined_filter_exception: 'Predefined Filter Exceptions',
        emailcat: 'Email Categorization',
        system_sender: 'System Sender Policies',
        recipient: 'Recipient',
        recipient_limit: 'Recipients List',
        remediated_by_sentinel: 'Remediated by Impersonation Protection',
        remediated_by_forensics: 'Remediated by Incident Response',
        atd_exemption: 'ATP Exempt',
        atd_full: 'Advanced Threat Protection',
        atd: 'Advanced Threat Protection',
        atd_subscribed: 'Advanced Threat Detection',
        atd_quarantine: 'ATP Quarantined',
        batd_scan_pending: 'Pending Scan',
        service_error_batd: 'ATP Scan Inconclusive',
        ui_delivered: 'UI Delivered',
        sender_disconnect: 'Message Delivery Interrupted',
        sender_quit: 'Message Incomplete',
        sender_rset: 'Message Incomplete',
        service_error_av: 'AV Service Unavailable',
        service_error_cs: 'Cloudscan Service Unavailable',
        sender_spoof: 'Sender Spoof Protection',
        score: 'Score',
        suspicious: 'Suspicious',
        suspended: 'Account Suspended',
        content_protected_msdoc: 'Office Protected',
        malformed: 'Malformed',
        language: 'Language Policies',
        geoip: 'GeoIP Policies',
        'system-sender': 'System Sender Policies',
        no_ptr: 'No PTR record'
      },
      export: {
        message_id: 'Message ID',
        from: 'From',
        to: 'To',
        subject: 'Subject',
        date: 'Date',
        size: 'Size',
        action: 'Action',
        reason: 'Reason',
        score: 'Score'
      },
      error: {
        no_email_domain: 'The message log is unavailable because you have no email domains'
      },
      no_records_available: 'No records available',
      message_direction_menu: {
        inbound: 'Inbound',
        outbound: 'Outbound',
        label: 'Direction'
      },
      find_similar_messages: 'Search for similar messages'
    },
    message_detail: {
      title: 'Message Detail',
      preview: 'Preview',
      source: 'Source'
    },
    splitter_orientation: {
      none: 'No Split',
      horizontal: 'Horizontal Split',
      vertical: 'Vertical Split'
    },
    navbar: {
      add: 'Add',
      changes_saved: 'Changes saved',
      noticeable_header: "What's new",
      noticeable_title: 'Email Gateway Defense',
      noticeable_footer: 'View more updates',
      legacy_ui: 'Classic UI'
    },
    logoutmenu: {
      serial_number: 'Serial number',
      expiration: 'Expiration',
      sign_out: 'Sign Out',
      language_selector: 'Language: ',
      language_title: 'Select preferred language',
      review_license: 'Review licenses'
    },
    email_details: {
      email_from: 'Email from {sender} on {date}',
      recipients: 'Recipients',
      sender: SENDER,
      date: 'Date',
      subject: SUBJECT,
      headers: HEADERS,
      value: 'Value',
      type: 'Type',
      copied: 'Copied',
      copy_to_clipboard: 'Copy to clipboard',
      tabs: {
        email: EMAIL,
        headers: HEADERS,
        threat_details: 'Threat Details'
      },
      threats: {
        dmarc: 'DMARC Compliance',
        spf: 'SPF IP Check',
        dkim: 'DKIM Alignment'
      }
    },
    sidemenu: {
      message_log: MESSAGE_LOG,
      settings: SETTINGS,
      support: SUPPORT
    },
    change_password_page: {
      change_password: 'Change Password',
      old_password: 'Current Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      change_password_title: 'Confirm Change Password',
      change_password_confirmation: 'Are you sure you want to change the password?'
    },
    scheduler: {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday'
    },
    snackbar: {
      download_attachment_failed: 'Failed to download attachment',
      download_message_failed: 'Failed to download message',
      bad_date_range: 'Start date/time must be earlier than End date/time',
      post_policy_success: 'Sender policy was successfully added',
      post_policy_failure: 'Failed to add sender policy',
      put_bulk_edit_success: 'Bulk edit was successfully saved',
      put_bulk_edit_failure: 'Failed to save bulk edit: %s',
      put_quarantine_notification_success: 'Quarantine notification settings were successfully saved',
      put_quarantine_notification_failure: 'Failed to save quarantine notification settings',
      delete_policy_success: 'Sender policy was successfully deleted',
      delete_policy_failure: 'Failed to delete sender policy',
      password_success: 'Password was successfully set',
      password_failure: 'Failed to set password',
      reset_password_success: 'Password was successfully reset',
      reset_password_failure: 'Failed to reset password',
      get_message_source_failed: 'Failed to get message source',
      local_storage_quota_exceeded: "Browser's local storage ran out of space",
      post_block_sender_success: 'Added "%s" to block list',
      post_block_sender_failed: 'Failed to add sender or domain to block list',
      post_allow_sender_success: '%d message(s) allow listed and sent to the delivery queue',
      post_allow_sender_failed: 'Failed to add sender or domain to allow list',
      post_allow_sender_admin_forbidden: 'Action forbidden by admin policy, please contact your admin',
      post_redeliver_message_success: '%d message(s) sent to the delivery queue',
      post_redeliver_message_failure: 'An error occurred. 0 messages sent to the delivery queue',
      post_redeliver_message_admin_forbidden: 'Action forbidden by admin policy, please contact your admin',
      post_new_email_success: 'The email was successfully sent',
      post_new_email_failure: 'Failed to send the email',
      post_recategorize_success: '%d message(s) recategorized',
      post_recategorize_failure: 'Failed to recategorize message(s)',
      post_add_linked_account_success: 'Linked account was successfully added',
      post_add_linked_account_failure: 'Failed to add linked account',
      post_add_linked_account_not_same_domain_failure: 'The linked account must be on the same domain as %s, etc',
      post_add_linked_account_existed_failure:
        'This user already exists. Please choose another email address to add as an alias.',
      post_add_linked_account_already_linked_failure: 'The email address %s is already linked to %s',
      post_delete_linked_account_success: 'Email address was successfully unlinked',
      post_delete_linked_account_failure: 'Failed to unlink email address',
      error_password_char_limit: 'Password must be less than 100 characters',
      error_new_password_not_same: 'New Password cannot be the same as Old Password',
      error_confirm_password_is_same: 'New Password and Confirm New Password must be the same',
      post_fpfn_report_success: 'Successfully reported the incident',
      post_fpfn_report_failure: 'Failed to report the incident',
      delete_message_success: 'Message(s) successfully deleted from Quarantine View',
      delete_message_failure: 'Message(s) could not be deleted',
      stop_delivery_reasons: '0 Messages delivered. Cannot deliver due to "%s"',
      stop_delivery_admin: 'Delivery of %s messages has been disabled by your administrator',
      message_detail_missing: 'Message detail is missing. 0 messages delivered',
      detect_email_server_failure: 'Failed to detect email server',
      verify_email_server_failure: 'Failed to verify email server',
      wizard_save_error: 'There was an error while saving, try again',
      download_message_success: 'Message successfully downloaded',
      download_message_failure: 'Message could not be downloaded',
      download_attachment_success: 'Attachment successfully downloaded',
      download_attachment_failure: 'Attachment could not be downloaded'
    },
    data_tables: {
      message_log: {
        select: 'Select',
        action: 'Action',
        delivery: 'Delivery',
        from: 'From',
        to: 'To',
        subject: 'Subject',
        date: 'Date',
        size: 'Size',
        encryption: 'Encryption',
        attachment: 'Attachment',
        reason: 'Reason',
        score: 'Score'
      },
      audit_log: {
        timestamp: 'Date',
        action: 'Event Type',
        description: 'Description',
        actor: 'User',
        ip: 'IP address'
      },
      atp_log: {
        status: 'Status',
        time: 'Time',
        from: 'From',
        to: 'To',
        subject: 'Subject',
        file_name: 'File Name',
        file_type: 'File Type',
        report: 'Report',
        view_report: 'View Report'
      }
    },
    atp_log: {
      title: 'ATP Log',
      infected_attachments: 'Infected attachments',
      suspicious_attachments: 'Suspicious attachments',
      last_30_days: 'In the last 30 days',
      status: 'Status',
      time: 'Time',
      file_type: 'File Type',
      export_button: 'Export List as CSV',
      search_placeholder: 'Search From, To, Subject, or File Name',
      all_domains: 'All domains',
      file_types: {
        all: 'All File Types',
        ms_word: 'MS Word (doc, docx)',
        docx: 'MS Word (doc, docx)',
        ms_excel: 'MS Excel (xls, xlsx)',
        ms_ppt: 'MS Powerpoint (ppt, pptx)',
        win_exe: 'Windows Executable (exe)',
        win_package: 'Windows Package (msi)',
        android_package: 'Android Package (apk)',
        pdf: 'Adobe PDF (pdf)',
        archives: 'Archives (zip, rar)'
      },
      filters: {
        all: 'All',
        clean: 'Clean',
        suspicious: 'Suspicious',
        infected: 'Infected',
        30: 'Last 30 Days',
        7: 'Last 7 Days',
        1: 'Last 24 Hours'
      },
      logStatuses: {
        all: 'All',
        infected: 'Infected',
        clean: 'Clean',
        error: 'Error',
        suspicious: 'Suspicious',
        future: 'Scanning...'
      },
      export: {
        status: 'Status',
        origin: 'Origin',
        fileName: 'File Name',
        fileType: 'File Type',
        messageId: 'Message ID',
        from: 'From',
        to: 'To',
        time: 'Time'
      }
    },
    audit_log: {
      title: 'Audit Log',
      status: 'Status',
      time: 'Time',
      file_type: 'File Type',
      exportButton: 'Export CSV',
      search_placeholder: 'Search',
      start_date: 'Start Date',
      end_date: 'End Date',
      filter: {
        title: 'Filter',
        filter: 'Filter',
        event_type: 'Event type',
        apply: 'Apply',
        clear: 'Clear'
      },
      export: {
        to: 'To',
        time: 'TIME',
        ipAddress: 'IP address',
        userName: 'User',
        description: 'Description',
        eventType: 'Event Type',
        formattedDate: 'Date'
      },
      action: {
        select_all: 'Select all',
        move: 'Move',
        delete: 'Delete',
        change: 'Classified',
        login: 'Sign in',
        logout: 'Sign out',
        log_action: 'Log action',
        create: 'Create',
        delete_all: 'Delete all',
        reset: 'Reset',
        enable: 'Enable',
        disable: 'Disable',
        suspend: 'Suspend',
        notify: 'Notify',
        migrate_cpl: 'Migrate CPL',
        restore: 'Restore',
        login_failure: 'Sign in failure'
      },
      affected: {
        unavailable: 'Unavailable',
        details_unavailable: 'Details Unavailable',
        require_password_for_quarantine_links: 'Required password for quarantine links',
        allow_quarantine_notification_interval_change: 'Allow users to specify interval',
        enable_quarantine: 'Quarantine Messages',
        sender_policies: 'Sender Policies',
        quarantine_notification_interval: 'Quarantine Notification Interval',
        created: 'User Creation Time',
        sub_expires: 'Subscription Expiry',
        sub_start_date: 'Subscription Start Date',
        visited: 'Visited',
        password: 'Password',
        smtp_servers: 'Mail Servers',
        message_content_filters: 'Message Content Filter',
        ip_policies: 'IP Address Policies',
        geoip_policies: 'GeoIP Policies',
        spooling: 'Spooling',
        spf_hardfail: 'Sender Policy Framework (SPF) Hard Fail',
        spf_softfail: 'Sender Policy Framework (SPF) Soft Fail',
        cname_token: 'CNAME token',
        directory_services: 'Directory Services',
        dkim: 'DomainKeys Identified Mail (DKIM) Action',
        azure_ad: 'Azure AD',
        sender_spoof: 'Sender Spoof Protection',
        user_auto_add: 'Automatically Add Users',
        fastspam_scores: 'Cloudscan Scoring',
        encryption_passtoken: 'Encryption Validation Passtoken',
        encryption_vtype: 'Encryption Validation Type',
        ldap_auth: 'LDAP authentication config',
        ldap_sync: 'LDAP sync config',
        force_tls: 'SMTP over TLS',
        srs: 'Sender Rewriting Scheme (SRS)',
        ptr: 'Block on No PTR Records',
        bafi: 'Anti-Fraud Intelligence',
        redirector_content_action: 'Content Intent',
        atd: 'Advanced Threat Protection',
        intent: 'Intent Analysis',
        brts: 'Barracuda Real-Time System (BRTS)',
        brbl: 'Barracuda Reputation Block List',
        fastspam_enabled: 'Cloudscan',
        encryption_verification: 'Encryption Verification',
        vscan: 'Virus Scanning',
        ext_sender_warning: 'External Sender Warning',
        linkprotect: 'Link Protection',
        default_user_exempt_enabled: 'Allow users to exempt senders',
        status: 'Subscription Status',
        typosquat: 'Typosquatting Protection',
        outbound_message_content_filters: 'Outbound Message Content Filter',
        linked_domain_policy: 'Intent Domain Policies',
        dmarc: 'Domain Based Message Authentication (DMARC)',
        dkim_exempt: 'DKIM Exempt',
        atd_notify: 'ATP - Notify Admin',
        user_policies: 'Recipient Policies',
        ldap: 'LDAP Sync Settings',
        encryption_confirmed: 'Encryption Validation Confirmed',
        outbound_predefined_message_content_filters: 'Outbound Predefined Filters',
        spf_exempt: 'SPF Exemptions',
        language_policies: 'Language Policies',
        atd_exempt_email: 'ATP - Exemptions by Email Address / Domains',
        default_timezone: 'Default Timezone',
        bulk_email_detection: 'Bulk Email Detection',
        attachment_filter_policy: 'Attachment Filter',
        default_unmanaged_scan_policy: 'Default policy for unmanaged users',
        emailcat_marketing: 'Marketing Materials and Newsletters',
        default_user_deliver_enabled: 'Allow end users to view and deliver blocked messages',
        default_user_quar_deliver_enabled: 'Allow end users to view and deliver quarantined messages',
        emailcat_listserver: 'Mailing Lists categorization',
        emailcat_socialmedia: 'Social Media categorization',
        emailcat_corporate: 'Corporate Email categorization',
        emailcat_transactional: 'Transactional Email categorization',
        emergency_inbox_autoenabled: 'Email Continuity',
        outbound_tls_domains: 'Outbound TLS Domains',
        default_managed_scan_policy: 'Default policy for managed users',
        trusted_forwarders: 'Trusted Forwarders',
        managed_domains: 'Domains Managed by User',
        default_user_block_enabled: 'Allow users to block senders',
        deleted: 'Domain Deleted',
        rate_control_exemptions: 'Rate Control Exemptions',
        saved_searches: 'Saved Searches',
        rbls: 'Reputation Black Lists (RBLs)',
        atd_exempt_ip: 'ATP - Exemptions by Sender IP Address',
        dmarc_exempt: 'DMARC Exempt',
        ptr_exempt: 'Missing PTR Exemptions',
        outbound_spam_notification_address: 'Outbound Spam Notification Address',
        reject_notification_address: 'Reject Notification Address',
        rate_control: 'Rate Control',
        alias_of: 'Domain Alias',
        helpdesk_domains: 'User Helpdesk Domains',
        outbound_tagline: 'Outbound Tagline/Footer text',
        outbound_tagline_enabled: 'Append Tagline',
        outbound_attachment_filter_policy: 'Outbound Attachment Filter',
        atd_admin_emails: 'ATP Notification Email',
        disable_account_rate_control: 'Internal Rate Control',
        sender_notification_address: 'Quarantine Notification Address',
        sender_notification_enable: 'Quarantine Sender Notification',
        reject_notification_subject: 'Reject Notification Subject',
        reject_notification_template: 'Reject Notification Template',
        password_msoffice_filtering: 'Password Protected Microsoft Office Documents',
        password_archive_filtering: 'Password Protected Archive Filtering',
        outbound_password_archive_filtering: 'Outbound Password Proteted Archive Filtering',
        password_pdf_filtering: 'Password Protected PDF',
        outbound_password_pdf_filtering: 'Outbound Password Protected PDF',
        outbound_predefined_message_content_filter_exceptions: 'Outbound Predefined Filter Exceptions',
        outbound_password_msoffice_filtering: 'Outbound Password Protected Microsoft Office Documents',
        sender_notification_subject: 'Quarantine Notification Subject',
        sender_notification_template: 'Quarantine Notification Template',
        support_access_enabled: 'Support Access',
        default_scan_policy: 'Default Recipient Policy',
        message_body_access_enabled: 'Partner Access to Messages',
        dismissed_notifications: 'Notification Dismissed',
        sub_override: 'Subscription Overridden by Barracuda Networks',
        brts_send_evidence: 'Send suspicious message content to Barracuda Central for analysis',
        outbound_quarantine_start: 'Notification Start Time',
        outbound_quarantine_email: 'Notification Address',
        outbound_quarantine_interval: 'Outbound Quarantine Notification Interval',
        custom_interval: 'Outbound Quarantine Notification Interval',
        search_logs_all_domains: '(Internal Setting) Enable search message logs across all domains',
        azure_ad_ui: '(Internal Setting) Enable Azure AD settings',
        domain_move: '(Internal Setting) Enable self-service domain move',
        trial_expiration_banner: '(Internal Setting) Enable trial expiration banner',
        greylist_exempt: '(Internal Setting) Disable greylist (suspicious) deferrals for account',
        use_primary_smarthost: '(Internal Setting) Use Primary Smarthost',
        auditlog: '(Internal Setting) Enable Audit Log',
        click_protection: '(Internal Setting) Enable click protection settings',
        config_copy: '(Internal Setting) Enable Account Settings Backup',
        dmarc_ui: '(Internal Setting) Enable DMARC settings',
        inbound_tagline: '(Internal Setting) Inbound Email Tagline',
        noblock_sender_response: '(Internal Setting) Enable Silent Block of Emails',
        retention: '(Internal Setting) Email retention period',
        outbound_use_email_domain: '(Internal Setting) Use email domain for outbound messages',
        outbound_ndr_policy: '(Internal Setting) Outbound NDR Policy',
        spoofing_enabled: '(Internal Setting) Spoofing Enabled',
        max_message_size: '(Internal Setting) Max Message Size',
        athena_stats: '(Internal Setting) Enable athena recipient count',
        disable_shared_ip_ranges: '(Internal Setting) Hosted Outbound Relay – EG. O365, Google Apps, ...',
        outbound_ip_partition_id: '(Internal Setting) Outbound Partition Type',
        skip_subdomains: '(Internal Setting) Reject mail to child domains',
        outbound_rate_control_block: '(Internal Setting) Outbound User Rate Control Block',
        outbound_rate_control: '(Internal Setting) Unmanaged User Rate Control Per Domain',
        outbound_per_domain_rate_limit: '(Internal Setting) Managed User Rate Control Per User',
        admins_msg_body_access: '(Internal Setting) Enable admins to be able to view email content and attachments',
        disable_outbound_multilevel_intent_content_check:
          '(Internal Setting) Enable admins to turn off outbound multilevel-intent checking of web page content',
        outbound_per_user_rate_limit: '(Internal Setting) Outbound User Rate Control Exemptions',
        type: '(Internal Setting) Domain Type (ESS/Hybrid)',
        end_user_react_ui: '(Internal Setting) End-User React UI',
        react_end_users: '(Internal Setting) End-User React UI',

        // NOTE: These Quarantine Notifier Settings need to be in camel-case
        allowOverride: 'Allow users to specify interval',
        inboundSchedule: 'Quarantine Notification Interval',
        customInboundSchedule: 'Schedule Notification Intervals',
        customOutboundSchedule: 'Schedule notification intervals',
        outboundEmailIds: 'Notification Address',
        outboundSchedule: 'Outbound Quarantine Notification Interval',
        allowWhitelist: 'Allow users to exempt senders',

        // Strings with params
        fmt_domain_moved: 'Domain %s moved to %s',
        fmt_domain_from: 'Domain %s moved to this account from %s',
        fmt_changed: '%s changed',
        fmt_changed_for_domain: '%s changed for domain %s',
        fmt_user_deleted: 'User %s deleted',
        fmt_outbound_ip_deleted: 'Outbound IP %s deleted',
        fmt_deleted_for_domain: '%s deleted for domain %s',
        fmt_domain_deleted: 'Domain %s deleted',
        fmt_deleted: '%s deleted',
        fmt_user_logged_in: 'User logged in successfully',
        fmt_logged_in_domain: 'User logged in to domain %s management successfully',
        fmt_support_logged_in: 'Barracuda Support logged in successfully',
        fmt_user_logged_out: 'User logged out successfully',
        fmt_user_logged_out_domain: 'User logged out of domain %s management successfully',
        fmt_support_logged_out: 'Barracuda Support logged out successfully',
        fmt_atd_exported: 'ATD log lines exported',
        fmt_message_log_exported: 'Message log lines exported',
        fmt_message_deleted: 'Message %s deleted',
        fmt_message_sent_to_emailreg: 'Message %s sent to EmailReg',
        fmt_message_blocked: 'Message %s blocked',
        fmt_message_whitelisted: 'Message %s whitelisted',
        fmt_message_recategorized: 'Message %s recategorized',
        fmt_message_delivered: 'Message %s delivered',
        fmt_message_viewed: 'Message %s viewed',
        fmt_message_marked_spam: 'Message %s marked as spam',
        fmt_message_marked_not_spam: 'Message %s marked as not spam',
        fmt_message_rejected: 'Message %s rejected',
        fmt_setting_created: '%s created',
        fmt_user_created: 'User %s created',
        fmt_outbound_ip_created: 'Outbound IP %s created',
        fmt_domain_created: 'Domain %s created',
        fmt_user_settings_deleted: 'All settings for user %s deleted',
        fmt_domain_users_deleted: 'All Users in domain %s deleted',
        fmt_account_outbound_ips_deleted: 'All Outbound IPs for domain %s in account %s deleted',
        fmt_list_outbound_ips_deleted: 'All Outbound IPs in list [%s] deleted',
        fmt_domain_settings_deleted: 'All settings for domain %s deleted',
        fmt_account_settings_deleted: 'All settings for account %s deleted',
        fmt_domain_settings_reset_account_level: 'Domain Settings for domain %s reset to account-level equivalents',
        fmt_login_info_sent: 'Login information has been sent to <strong>%s</strong>',
        fmt_account_ended: 'Account ended',
        fmt_account_disabled: 'Account disabled',
        fmt_account_suspended: 'Account suspended',
        fmt_account_migrated_to_cpl: 'Account migrated to CPL',
        fmt_user_notified_via_email: 'User(s) %s has been notified via email',
        fmt_domain_settings_restored: 'Domain Settings [%s] restored for domain %s',
        fmt_login_failure: 'Login Failure'
      }
    }
  }
}
/* eslint-enable quotes */
