import React, { useMemo } from 'react'

import { Close } from '@cuda-networks/bds-core/dist/Icons/Core'
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@cuda-networks/bds-core'

import { useFormatMessage } from 'lib/localization'

import styles from './recategorizeOtherDialogStyles'
import { RecategorizeOtherDialogProps, useRecategorizeOtherDialogLogic } from './useRecategorizeOtherDialogLogic'

const BASE_I18N_KEY = 'ess.message_log.toolbar'

const RecategorizeOtherDialog: React.FC<RecategorizeOtherDialogProps> = ({ onClose }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { customCategory, errorMsg, handleFormChange, submitForm } = useRecategorizeOtherDialogLogic({ onClose })

  return useMemo(
    () => (
      <Dialog open onClose={onClose} data-testid="root-dialog">
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant="h6" data-testid="title">
            {formatMessage('categorize_other_box_title')}
          </Typography>
          <IconButton className={classes.closeButton} onClick={onClose} data-testid="close-icon">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            error={!!errorMsg}
            fullWidth
            helperText={errorMsg && formatMessage(errorMsg)}
            name="name"
            onChange={handleFormChange}
            value={customCategory}
            data-testid="input-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={!customCategory.length || !!errorMsg}
            onClick={() => submitForm()}
            variant="contained"
            data-testid="submit-button"
          >
            {formatMessage('categorize')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, customCategory, errorMsg, handleFormChange, submitForm, onClose]
  )
}

export default RecategorizeOtherDialog
