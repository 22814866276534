import React from 'react'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import geoip from 'i18n-iso-countries'
import geoipEn from 'i18n-iso-countries/langs/en.json'
import deepmerge from 'deepmerge'

import * as bdsTheme from '@cuda-networks/bds-core/dist/styles/themes'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import { BDSProvider } from '@cuda-networks/bds-core'
import reduxStore from 'lib/reduxStore'
import Routes from 'components/libs/routes/Routes'
import { history } from 'lib/browserHistory'
import theme from 'theme'
import registerAxiosHooks from 'lib/api/registerAxiosHooks'
import { CustomBrowserRouter } from './components/libs/routes/CustomBrowserRouter'
import { IntlWrapper } from './components/libs/intlWrapper'

import '@progress/kendo-theme-material/dist/all.css'
import 'app.scss'

// register axios hooks
registerAxiosHooks()

// Register all of the supported language localization
geoip.registerLocale(geoipEn)

export function App() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!)

  return root.render(
    <ThemeProvider theme={createTheme(deepmerge(bdsTheme.default, theme))}>
      <Provider store={reduxStore}>
        <IntlWrapper>
          <BDSProvider useBdsTheme={false}>
            <CustomBrowserRouter history={history}>{Routes}</CustomBrowserRouter>
          </BDSProvider>
        </IntlWrapper>
      </Provider>
    </ThemeProvider>
  )
}
