import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { IconButton, ListItemIcon, MenuItem, Popover } from '@cuda-networks/bds-core/'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import RecentActorsIcon from '@material-ui/icons/RecentActors'

import { Earth as EarthIcon, MenuVertical as MoreIcon } from '@cuda-networks/bds-core/dist/Icons/Core'

import LanguageMenu from 'components/libs/dialog/languageMenu/LanguageMenuDialog'
import { useLogoutMenuLogic } from 'components/libs/layout/navbar/useLogoutMenuLogic'
import appFeatures from 'config/appFeatures'
import { useFormatMessage } from 'lib/localization'
import { FEATURES, isMyFeatureOn } from 'lib/splitio'
import NavbarLogic from './NavbarLogic'
import { NavbarProps } from './navbarProps'
import CommonAnchorElLogic, { CommonAnchorElProps } from './CommonAnchorElLogic'

import styles from './navbarStyles'

const BASE_I18N_KEY = 'ess.logoutmenu'
const BASE_I18N_COMMON = 'ess_language.language'

interface Props extends NavbarProps, CommonAnchorElProps {}

function LogoutMenu({ anchorEl, handleClick, handleClose, onLogout }: Props) {
  const classes = styles()
  const {
    isLicenseComplianceOn,
    isLanguageMenuDialogOpen,
    preferredLanguage,
    handleLogout,
    onCloseLanguageMenu,
    onOpenLanguageMenu,
    onOpenReviewLicense
  } = useLogoutMenuLogic({
    onLogout,
    handleClose
  })
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const commonFormatMessage = useFormatMessage(BASE_I18N_COMMON)

  return useMemo(
    () => (
      <>
        <div>
          <IconButton aria-label="Open menu" edge="end" onClick={handleClick}>
            <MoreIcon className={classes.vertButton} />
          </IconButton>
          <Popover
            className={classes.vertPopover}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {isLicenseComplianceOn && (
              <MenuItem onClick={onOpenReviewLicense} className={classes.logoutMenu}>
                <ListItemIcon className={classes.logoutIcon}>
                  <RecentActorsIcon id="review_license" />
                </ListItemIcon>
                {formatMessage('review_license')}
              </MenuItem>
            )}
            {(!appFeatures.DisabledLanguageSelector || isMyFeatureOn(FEATURES.EGD_React_Language_Selector_Rollout)) && (
              <MenuItem onClick={onOpenLanguageMenu} className={classes.logoutMenu}>
                <ListItemIcon className={classes.logoutIcon}>
                  <EarthIcon id="language_selector" />
                </ListItemIcon>
                {formatMessage('language_selector')} {commonFormatMessage(preferredLanguage)}
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout} className={classes.logoutMenu}>
              <ListItemIcon className={classes.logoutIcon}>
                <ExitToAppIcon id="logout_button" />
              </ListItemIcon>
              {formatMessage('sign_out')}
            </MenuItem>
          </Popover>
        </div>
        {isLanguageMenuDialogOpen && <LanguageMenu onClose={onCloseLanguageMenu} />}
      </>
    ),
    [
      classes,
      handleClick,
      handleClose,
      handleLogout,
      anchorEl,
      isLanguageMenuDialogOpen,
      preferredLanguage,
      onOpenLanguageMenu,
      onCloseLanguageMenu,
      isLicenseComplianceOn,
      onOpenReviewLicense,
      commonFormatMessage,
      formatMessage
    ]
  )
}

LogoutMenu.propTypes = {
  onLogout: PropTypes.func.isRequired
}

export default NavbarLogic(CommonAnchorElLogic(LogoutMenu))
