const API_ENDPOINTS = {
  authentication: {
    LOGIN: {
      method: 'POST',
      path: '/internal/login'
    },
    BCC_LOGIN: {
      method: 'POST',
      path: '/internal/bcc-login'
    },
    LOGOUT: {
      method: 'DELETE',
      path: '/internal/logout'
    },
    USERINFO: {
      method: 'GET',
      path: '/internal/userinfo'
    },
    AZURE_LOGIN_URL: {
      method: 'POST',
      path: '/internal/azure-login-url'
    },
    LOGIN_INFO: {
      method: 'POST',
      path: '/internal/login-info'
    },
    RESET_PASSWORD: {
      method: 'POST',
      path: '/internal/reset-password'
    }
  },
  fpfn: {
    FPFN_REPORT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages/:messageId/fpfn-redirect'
    }
  },
  auditLog: {
    AUDIT_LOG: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/audit-log'
    },
    AUDIT_LOG_EXPORT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/audit-export'
    }
  },
  atpLog: {
    ATP_LOG: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog'
    },
    ATP_OVERVIEW: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/overview'
    },
    ATP_FILETYPES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/filetypes'
    },
    ATP_LOG_DELETE: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/delete'
    },
    ATP_LOG_REPORT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/report'
    }
  },
  mstore: {
    USER_MESSAGE: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/:messageId'
    },
    DOMAIN_MESSAGE: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/messages/:messageId'
    },
    SEARCH_USER_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages'
    },
    SEARCH_DOMAIN_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/messages'
    },
    SEARCH_ACCOUNT_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/messages'
    },
    USER_ATTACHMENT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/:messageId/attachments/:attachmentId'
    },
    DOMAIN_ATTACHMENT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/messages/:messageId/attachments/:attachmentId'
    },
    DELIVER_NEW: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages/deliver-new'
    },
    REDELIVER_MESSAGE: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages/redeliver'
    },
    BLOCK_SENDER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages/block'
    },
    ALLOW_SENDER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages/allow'
    },
    DELETE_MESSAGE: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages'
    }
  },
  settings: {
    SENDER_POLICIES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies?page=0&size=0'
    },
    SAVE_SENDER_POLICY: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies'
    },
    DELETE_SENDER_POLICY: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies'
    },
    BULK_EDIT_POLICIES: {
      method: 'PUT',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies'
    },
    PASSWORD: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/password'
    },
    QUARANTINE_NOTIFICATION: {
      method: 'GET',
      path: 'internal/accounts/:bccAccountId/users/:userId/qns/settings'
    },
    EDIT_QUARANTINE_NOTIFICATION: {
      method: 'PUT',
      path: 'internal/accounts/:bccAccountId/users/:userId/qns/settings'
    },
    LINKED_ACCOUNTS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/linked-accounts'
    },
    SAVE_LINKED_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/linked-accounts'
    },
    DELETE_LINKED_ACCOUNT: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/users/:userId/linked-accounts'
    },
    VERIFY_LINKED_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/users/linked-accounts/verify'
    },
    ACCOUNT_PERMISSIONS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/account-permissions'
    }
  },
  emailServer: {
    DETECT_EMAIL_SERVER: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/email-server/detect'
    },
    VERIFY_EMAIL_SERVER: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/verify'
    },
    GET_PROVIDER: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/provider'
    },
    GET_MX_RECORDS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/mxrecord'
    },
    GET_OLD_MX_RECORDS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/old-mxrecord'
    },
    GET_EMAIL_SERVER_SETTINGS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/email-server/settings'
    },
    SET_EMAIL_SERVER_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/settings'
    },
    SET_REGION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/select-region'
    },
    MIGRATE_REGION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/migrate-region'
    }
  },
  sidebandMailer: {
    REDELIVERY_QUEUE_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/queued-messages'
    }
  },
  customerFeedback: {
    RECATEGORIZE: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages/recategorize'
    }
  },
  MIXPANEL: {
    method: 'POST',
    path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/mixpanel'
  },
  user: {
    GET_AVAILABLE_DOMAINS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/domains'
    }
  },
  stats: {
    GET_ACCOUNT_LEVEL_GEO_DATA: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/geodata'
    },
    GET_DOMAIN_LEVEL_GEO_DATA: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/geodata'
    },
    GET_ACCOUNT_LEVEL_TOP_DOMAINS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/top-domains'
    },
    GET_DOMAIN_LEVEL_TOP_DOMAINS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/top-domains'
    },
    GET_ACCOUNT_LEVEL_TOP_BLOCKED: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/top-blocked'
    },
    GET_DOMAIN_LEVEL_TOP_BLOCKED: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/top-blocked'
    },
    GET_ACCOUNT_LEVEL_LAST_BLOCKED_ATP: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/last-blocked-atp'
    },
    GET_DOMAIN_LEVEL_LAST_BLOCKED_ATP: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/last-blocked-atp'
    },
    GET_ACCOUNT_LEVEL_EMAIL_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/email-statistics'
    },
    GET_DOMAIN_LEVEL_EMAIL_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/email-statistics'
    },
    GET_ACCOUNT_LEVEL_TOTAL_THREATS_AND_VIRUSES_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/total-threats-and-viruses'
    },
    GET_DOMAIN_LEVEL_TOTAL_THREATS_AND_VIRUSES_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/total-threats-and-viruses'
    }
  }
}

export default API_ENDPOINTS
