import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import { Range } from 'types/stats'
import { getAvailableDomains } from 'redux/features/user/userSlice'
import { isoCountries } from 'lib/isoCountries'
import { getGeoDataStats } from 'redux/features/stats/statsSlice'

export enum TimeRanges {
  'last24Hours' = 'last_24_hours',
  'last30Days' = 'last_30_days'
}

export const ALL_DOMAINS = 'all'

export type GeoDataChartObject = {
  id: string
  value: number
}

export type GeoDataChartValues = {
  data: GeoDataChartObject[] | undefined
  minCount: number
  maxCount: number
}

export type Footer = {
  accountId: string
  essAccountId: string
  serialNumber: string
  version: string
}

export interface State {
  shouldShowAllDomainsInSelector: boolean
  selectedTimeRange: TimeRanges
  selectedDomain: DomainsInDB | undefined
  availableDomains: DomainsInDB[] | undefined
  isGetAvailableDomainsInProgress: boolean
  isGetAvailableDomainsFailed: boolean
  geoData: GeoDataChartValues | undefined
  isGetGeoDataStatsInProgress: boolean
  isGetGeoDataStatsFailed: boolean
  range: Range
  footerConfig: Footer
}

export interface EventHandlers {
  onOpenSupport: () => void
  onCheckWhatsNew: () => void
  onSelectTimeRange: (e: React.ChangeEvent<{ value: unknown }>) => void
  onSelectDomain: (e: React.ChangeEvent<{ value: unknown }>) => void
}

export type UseDashboardLogic = [State, EventHandlers]

export const useDashboardLogic = (): UseDashboardLogic => {
  const dispatch = useAppDispatch()

  const {
    accessTokenObject,
    availableDomains,
    isGetAvailableDomainsInProgress,
    isGetAvailableDomainsSuccess,
    isGetAvailableDomainsFailed,
    geoData,
    isGetGeoDataStatsInProgress,
    isGetGeoDataStatsFailed
  } = useAppSelector(_store => ({
    accessTokenObject: _store.auth.accessTokenObject,
    availableDomains: _store.user.availableDomains,
    isGetAvailableDomainsInProgress: isPending(_store.user.api.getAvailableDomainsApiStatus),
    isGetAvailableDomainsSuccess: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    isGetAvailableDomainsFailed: isFailed(_store.user.api.getAvailableDomainsApiStatus),
    geoData: _store.stats.geoData,
    isGetGeoDataStatsInProgress: isPending(_store.stats.api.getGeoDataStats),
    isGetGeoDataStatsFailed: isFailed(_store.stats.api.getGeoDataStats)
  }))
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRanges>(TimeRanges.last30Days)
  const [selectedDomain, setSelectedDomain] = useState<DomainsInDB | undefined>()

  // init
  useEffect(() => {
    dispatch(getAvailableDomains())
    // eslint-disable-next-line
  }, [])

  // initial domains list is arrived and pdDomain is set
  useEffect(() => {
    if (isGetAvailableDomainsSuccess && !!availableDomains?.length && !selectedDomain) {
      let initialDomain: DomainsInDB | undefined
      // pd-domain-id is set
      if (accessTokenObject?.pdDomainId) {
        initialDomain = availableDomains.find(domain => domain.domainId === accessTokenObject.pdDomainId)
        // account has only one domain
      } else if (availableDomains.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        initialDomain = availableDomains[0]
      }

      if (initialDomain) setSelectedDomain(initialDomain)
    }
  }, [isGetAvailableDomainsSuccess, availableDomains, selectedDomain, accessTokenObject])

  const getStats = useCallback(() => {
    const range = selectedTimeRange === TimeRanges.last24Hours ? Range.last24Hours : Range.last30Days
    dispatch(getGeoDataStats({ domainId: selectedDomain?.domainId, range }))
  }, [dispatch, selectedDomain, selectedTimeRange])

  // mew domain or timerange is selected
  useEffect(() => {
    if (isGetAvailableDomainsSuccess) {
      getStats()
    }
  }, [selectedDomain, selectedTimeRange, availableDomains, getStats, isGetAvailableDomainsSuccess])

  const onOpenSupport = useCallback(() => {
    // TODO: open support page
  }, [])

  const geoDataChartValues = useMemo(() => {
    const chartValues: GeoDataChartValues = {
      data: undefined,
      minCount: 0,
      maxCount: 0
    }
    if (!geoData) {
      return chartValues
    }

    return Object.entries(geoData.results).reduce(
      (all: GeoDataChartValues, [country, result]) => ({
        data: [...(all.data || []), { id: isoCountries.alpha2ToAlpha3(country), value: result.count, code2: country }],
        minCount: 0,
        maxCount: Math.max(all.maxCount, result.count)
      }),
      chartValues
    )
  }, [geoData])

  const footerConfig: State['footerConfig'] = useMemo(
    () => ({
      accountId: accessTokenObject?.bccAccountId || '',
      essAccountId: accessTokenObject?.accountId || '',
      serialNumber: accessTokenObject?.serial || '',
      // TODO: Add version information
      version: ''
    }),
    [accessTokenObject]
  )

  const onCheckWhatsNew = useCallback(() => {
    // TODO: open what's new widget
  }, [])

  const onSelectTimeRange: EventHandlers['onSelectTimeRange'] = useCallback(e => {
    setSelectedTimeRange(e.target.value as TimeRanges)
  }, [])

  const onSelectDomain: EventHandlers['onSelectDomain'] = useCallback(
    e => {
      const selectedDomainObject = availableDomains?.find(domainData => domainData.domainId === e.target.value)
      setSelectedDomain(selectedDomainObject)
    },
    [availableDomains]
  )

  return useMemo(
    () => [
      {
        shouldShowAllDomainsInSelector: !!availableDomains && availableDomains.length > 1,
        selectedTimeRange,
        selectedDomain,
        availableDomains,
        isGetAvailableDomainsInProgress,
        isGetAvailableDomainsFailed,
        geoData: geoDataChartValues,
        isGetGeoDataStatsInProgress,
        isGetGeoDataStatsFailed,
        range: selectedTimeRange === TimeRanges.last24Hours ? Range.last24Hours : Range.last30Days,
        footerConfig
      },
      { onOpenSupport, onCheckWhatsNew, onSelectTimeRange, onSelectDomain }
    ],
    [
      selectedDomain,
      selectedTimeRange,
      availableDomains,
      isGetAvailableDomainsInProgress,
      isGetAvailableDomainsFailed,
      geoDataChartValues,
      isGetGeoDataStatsInProgress,
      isGetGeoDataStatsFailed,
      onOpenSupport,
      onCheckWhatsNew,
      onSelectTimeRange,
      onSelectDomain,
      footerConfig
    ]
  )
}
