import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Color } from '@material-ui/lab/Alert'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { resetPassword, requestLoginInfo } from 'redux/features/auth/authSlice'
import { FEATURES, isMyFeatureOn } from 'lib/splitio'
import routesConfig from 'lib/routesConfig'
import appConfig from 'config/appConfig'
import { now } from 'lib/datetime'

export type CardType = 'ResetPassword' | 'LinkExpired' | 'CheckEmail' | 'SuccessfulReset'

export interface State {
  cardType: CardType
  confirmPassword: string
  isPasswordMatch: boolean
  isResetDisabled: boolean
  messageId: string
  newPassword: string
  snackbarMessageId: string
  snackbarSeverity: Color
  userId: string
}

export interface EventHandlers {
  onClickLogo: () => void
  onClickSignin: () => void
  onCloseSnackBar: (event?: React.SyntheticEvent, reason?: string) => void
  onConfirmPasswordChange: (e: ChangeEvent<HTMLInputElement>) => void
  onNewPasswordChange: (e: ChangeEvent<HTMLInputElement>) => void
  onLogin: () => void
  onResendLink: () => void
  onReset: () => void
  onSendLoginInfo: () => void
}

export type ResetPasswordLogic = [State, EventHandlers]

export const useResetPasswordLogic = (): ResetPasswordLogic => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const userId: any = params.get('user')
  const hash: any = params.get('sig')
  const expiration: any = params.get('expiration')
  const allParamsValid = userId && hash && expiration
  const {
    isResettingPassword,
    resettingPasswordError,
    isRequestLoginInfoSuccess,
    isRequestLoginInfoFailed,
    requestLoginInfoOrigin
  } = useAppSelector(_store => ({
    isResettingPassword: isPending(_store.auth.loginApiStatus),
    resettingPasswordError: getErrorMessage(_store.auth.loginApiStatus),
    isRequestLoginInfoSuccess: isSuccess(_store.auth.requestLoginInfoApiStatus),
    isRequestLoginInfoFailed: isFailed(_store.auth.requestLoginInfoApiStatus),
    requestLoginInfoOrigin: _store.auth.requestLoginInfoOrigin
  }))
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [cardType, setCardType] = useState<CardType>('ResetPassword')
  const [isPasswordMatch, setIsPasswordMatch] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [messageId, setMessageId] = useState('')
  const [snackbarMessageId, setSnackbarMessageId] = useState('')
  const [isResetDisabled, setIsResetDisabled] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState<Color>('info')

  const onClickLogo = useCallback(() => {
    window.location.href = appConfig.LINKS.BARRACUDA_ESSENTIALS
  }, [])

  const onClickSignin = useCallback(() => routesConfig.LOGIN.goto(), [])

  const onInputChange = useCallback(
    (setValue: (value: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    []
  )

  const onReset = useCallback(() => {
    setIsSubmitted(true)
    dispatch(
      resetPassword({
        userId,
        password: newPassword,
        hash,
        expiration
      })
    )
  }, [dispatch, expiration, hash, newPassword, userId])

  const onSendLoginInfo = useCallback(() => {
    dispatch(requestLoginInfo({ userId, origin: 'LinkExpired' }))
  }, [dispatch, userId])

  const onResendLink = useCallback(() => {
    dispatch(requestLoginInfo({ userId, origin: 'CheckEmail' }))
  }, [dispatch, userId])

  const onLogin = useCallback(() => {
    routesConfig.LOGIN.goto()
  }, [])

  const onCloseSnackBar = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarSeverity('info')
    setSnackbarMessageId('')
  }, [])

  useEffect(() => {
    // TODO: using mixpanel without user
    // trackMixpanelEvent(TRACKING_EVENTS.WEBUI.RESET_PASSWORD_PAGE_VIEW)
  }, [dispatch])

  useEffect(() => {
    if (!allParamsValid) {
      return
    }

    if (now() > expiration * 1000) {
      setCardType('LinkExpired')
    }
  }, [allParamsValid, expiration])

  useEffect(() => {
    if (isSubmitted && !isResettingPassword && !resettingPasswordError) {
      setCardType('SuccessfulReset')
    }
  }, [isSubmitted, isResettingPassword, resettingPasswordError])

  useEffect(() => {
    if (confirmPassword.length === 0) {
      setIsPasswordMatch(true)
      setMessageId('')
      return
    }
    const isMatch = newPassword === confirmPassword
    setIsPasswordMatch(isMatch)
    setMessageId(isMatch ? 'password_matches' : 'password_not_match')
    setIsResetDisabled(!isMatch)
  }, [confirmPassword, newPassword])

  useEffect(() => {
    if (!isRequestLoginInfoSuccess) {
      return
    }
    if (requestLoginInfoOrigin !== 'CheckEmail') {
      setCardType('CheckEmail')
      return
    }
    setSnackbarSeverity('info')
    setSnackbarMessageId('new_link_sent')
  }, [isRequestLoginInfoSuccess, requestLoginInfoOrigin])

  useEffect(() => {
    if (!isRequestLoginInfoFailed) {
      return
    }
    const textId =
      requestLoginInfoOrigin === 'CheckEmail' ? 'error.resend_link_failed' : 'error.request_login_info_failed'
    setSnackbarSeverity('error')
    setSnackbarMessageId(textId)
  }, [isRequestLoginInfoFailed, requestLoginInfoOrigin])

  return useMemo(
    () => [
      {
        cardType,
        confirmPassword,
        isPasswordMatch,
        isResetDisabled,
        messageId,
        newPassword,
        snackbarMessageId,
        snackbarSeverity,
        userId
      },
      {
        onClickLogo,
        onClickSignin,
        onCloseSnackBar,
        onConfirmPasswordChange: onInputChange(setConfirmPassword),
        onLogin,
        onNewPasswordChange: onInputChange(setNewPassword),
        onResendLink,
        onReset,
        onSendLoginInfo
      }
    ],
    [
      cardType,
      confirmPassword,
      isPasswordMatch,
      isResetDisabled,
      messageId,
      newPassword,
      onClickLogo,
      onClickSignin,
      onCloseSnackBar,
      onInputChange,
      onLogin,
      onResendLink,
      onReset,
      onSendLoginInfo,
      snackbarMessageId,
      snackbarSeverity,
      userId
    ]
  )
}
