import { useCallback, useEffect, useMemo, useState } from 'react'
import { Serie } from '@nivo/line'

import { colors } from '@cuda-networks/bds-core'
import { useFormatMessage } from 'lib/localization'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending } from 'redux/toolkit/api'
import { getTotalThreatsAndVirusesStats } from 'redux/features/stats/statsSlice'
import { PieChartWithTableProps } from 'components/pages/overview/dashboard/pieChartWithTable/PieChartWithTable'
import { FileType, StatComponentProps, TotalThreatsAndVirusesObject } from 'types/stats'

export enum SectionView {
  overview = 'overview',
  fileType = 'file_type'
}

export interface State {
  chartConfig: {
    data: Serie[]
    colors: string[]
    axisBottomValuesToShow: string[]
    totals: [string, number][]
    maxValueInRange: number
  }
  overviewData: PieChartWithTableProps
  totalThreatsAndVirusesInProgress: boolean
  totalThreatsAndVirusesFailed: boolean
  selectedView: SectionView
}

export interface EventHandlers {
  onSelectView: (value: unknown) => void
}

export type UseTotalThreatsVirusesChartLogic = [State, EventHandlers]

const CHART_AXIS_ALTERNATIVE_CATEGORY_NAMES: { [key in FileType]?: string } = {
  [FileType.office]: 'office',
  [FileType.blockedAv]: 'virus'
}

const BASE_I18N_KEY = 'ess.overview.dashboard.charts'

export const useTotalThreatsVirusesChartLogic = (props: StatComponentProps): UseTotalThreatsVirusesChartLogic => {
  const dispatch = useAppDispatch()
  const formatActionMessage = useFormatMessage(BASE_I18N_KEY)

  const { totalThreatsAndViruses, totalThreatsAndVirusesInProgress, totalThreatsAndVirusesFailed } = useAppSelector(
    _store => ({
      totalThreatsAndViruses: _store.stats.totalThreatsAndViruses,
      totalThreatsAndVirusesInProgress: isPending(_store.stats.api.getTotalThreatsAndVirusesApiStatus),
      totalThreatsAndVirusesFailed: isFailed(_store.stats.api.getTotalThreatsAndVirusesApiStatus)
    })
  )
  const [selectedView, setSelectedView] = useState<SectionView>(SectionView.overview)

  // new props or selected new filter
  useEffect(() => {
    dispatch(
      getTotalThreatsAndVirusesStats({
        domainId: props.domainId,
        range: props.range
      })
    )
  }, [dispatch, props.range, props.domainId])

  const chartConfig: State['chartConfig'] = useMemo(() => {
    if (!totalThreatsAndViruses || selectedView === SectionView.overview) {
      return {
        data: [],
        axisBottomValuesToShow: [],
        colors: [],
        totals: [],
        maxValueInRange: 0
      }
    }

    let maxValueInRange = 0
    const axisBottomValuesToShow: string[] = []
    const serieObject = totalThreatsAndViruses.fileTypeResults.reduce(
      (all: { [key: string]: Serie }, totalThreatsAndVirusesStat: TotalThreatsAndVirusesObject, idx) => {
        if ((idx + 1) % 2 !== 0) {
          axisBottomValuesToShow.push(totalThreatsAndVirusesStat.date)
        }
        const updatedAll = { ...all }

        Object.values(FileType).forEach(fileType => {
          const newSerieData = {
            x: totalThreatsAndVirusesStat.date,
            y: totalThreatsAndVirusesStat.fileTypes[fileType]
          }
          maxValueInRange = Math.max(maxValueInRange, newSerieData.y)

          if (!all[fileType]) {
            updatedAll[fileType] = {
              id: CHART_AXIS_ALTERNATIVE_CATEGORY_NAMES[fileType]
                ? formatActionMessage(CHART_AXIS_ALTERNATIVE_CATEGORY_NAMES[fileType] || '')
                : fileType.toUpperCase(),
              data: [newSerieData]
            }
          } else {
            updatedAll[fileType].data.push(newSerieData)
          }
        })

        return updatedAll
      },
      {}
    )

    const totals =
      Object.keys(totalThreatsAndViruses.fileTypeCounts.fileTypes).reduce(
        (all: State['chartConfig']['totals'], fileType) => {
          all.push([fileType, totalThreatsAndViruses.fileTypeCounts.fileTypes[fileType as FileType]])

          return all
        },
        []
      ) || []

    return {
      data: Object.values(serieObject).slice().reverse(),
      axisBottomValuesToShow,
      totals,
      colors: [
        colors.orange800,
        colors.orange700,
        colors.redOrange500,
        colors.redOrange200,
        colors.orange200,
        colors.orange100,
        colors.orange400
      ],
      maxValueInRange
    }
  }, [selectedView, formatActionMessage, totalThreatsAndViruses])

  const overviewData: State['overviewData'] = useMemo(() => {
    const virusTableConfig = {
      text: formatActionMessage('viruses'),
      color: colors.orange400
    }
    const advThreatsTableConfig = {
      text: formatActionMessage('advanced_threats'),
      color: colors.magenta700
    }

    if (!totalThreatsAndViruses) {
      // demo data
      return {
        chartConfig: [
          { id: formatActionMessage('advanced_threats'), value: 70 },
          { id: formatActionMessage('virus'), value: 30 }
        ],
        tableConfig: [
          {
            ...virusTableConfig,
            value: 30,
            percentage: 30
          },
          {
            ...advThreatsTableConfig,
            value: 70,
            percentage: 70
          }
        ],
        total: 100
      }
    }

    const totalCounts = totalThreatsAndViruses.overviewResults.atdTotal + totalThreatsAndViruses.overviewResults.avTotal
    const atdPercentage = Math.round((100 * totalThreatsAndViruses.overviewResults.atdTotal) / totalCounts) || 0

    return {
      chartConfig: [
        { id: formatActionMessage('advanced_threats'), value: totalThreatsAndViruses.overviewResults.atdTotal },
        { id: formatActionMessage('virus'), value: totalThreatsAndViruses.overviewResults.avTotal }
      ],
      tableConfig: [
        {
          ...virusTableConfig,
          value: totalThreatsAndViruses.overviewResults.atdTotal,
          percentage: atdPercentage
        },
        {
          ...advThreatsTableConfig,
          value: totalThreatsAndViruses.overviewResults.avTotal,
          percentage: atdPercentage ? 100 - atdPercentage : 0
        }
      ],
      total: totalThreatsAndViruses.overviewResults.atdTotal + totalThreatsAndViruses.overviewResults.avTotal
    }
  }, [totalThreatsAndViruses, formatActionMessage])

  const onSelectView: EventHandlers['onSelectView'] = useCallback(value => {
    setSelectedView(value as SectionView)
  }, [])

  return useMemo(
    () => [
      {
        chartConfig,
        overviewData,
        totalThreatsAndVirusesInProgress,
        totalThreatsAndVirusesFailed,
        selectedView
      },
      {
        onSelectView
      }
    ],
    [
      chartConfig,
      overviewData,
      totalThreatsAndVirusesInProgress,
      totalThreatsAndVirusesFailed,
      selectedView,
      onSelectView
    ]
  )
}
