import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { saveAs } from '@progress/kendo-file-saver'

import { Action, RedeliverMessage, Search } from 'types/Messages'
import { stopDeliverReasons } from 'config/filterMaps'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import {
  deleteMessage,
  downloadMessage,
  getSearch,
  MlogViewConfig,
  MlogViewType,
  postBlockSender,
  resetDownloadMessage,
  resetSearch,
  resetSelectedMessage,
  setIsMessageHistoryOpen,
  setRedeliveryQueueMessages
} from 'redux/features/mstore/mstoreSlice'
import { useMstore } from 'lib/useMstore'
import { StyleProps } from 'components/libs/message/messageDetails/messageDetailsToolbar/messageDetailsToolbarStyles'
import routesConfig from 'lib/routesConfig'
import { getAccountPermissions } from 'redux/features/settings/settingsSlice'
import { useRemediated } from 'components/libs/message/useRemediated'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

export interface State {
  deleteButton: {
    isDeleteButtonVisible: boolean
  }
  dotsMenu: {
    isDotsMenuEnabled: boolean
    isDotsMenuOpen: boolean
    dotsMenuAnchorElement: RefObject<HTMLButtonElement>
  }
  download: {
    isDownloadMessagePending: boolean
    downloadMessageProgressPercent: number
  }
  emailComposer: {
    isComposerOpen: boolean
    composerEmailType: ComposerEmailType | undefined
  }
  historyButton: {
    messageHistoryButtonTextId: string
  }
  messageDetails: {
    isMessageDetailLinkVisible: boolean
    messageDetailLinkHref: string
    globalMid: string | undefined
  }
  modal: {
    isModalOpen: boolean
  }
  permissions: {
    canViewDeliver: boolean
    isDeliverDisabled: boolean
    isUserBlocklistDisabled: boolean
    isEmergencyInboxEnabled: boolean
  }
  redeliveryDialog: {
    isRedeliveryDialogOpen: boolean
    redeliveryDialogData: any[] | undefined
    isReportButtonVisible: boolean
  }
  reportingModal: {
    isReportingActionModalDisabled: boolean
    isReportingActionModalOpen: boolean
    reportingActionModalTextId: string
    messageAction: Action | undefined
  }
  styleProps: StyleProps
  viewConfig: MlogViewConfig
}

export interface EventHandlers {
  deleteButton: {
    onDeleteMessage: () => void
  }
  reportingModal: {
    onOpenReportingActionModal: () => void
    onCloseReportingActionModal: () => void
  }
  historyButton: {
    onToggleMessageHistory: () => void
  }
  dotsMenu: {
    onOpenDotsMenu: () => void
    onCloseDotsMenu: () => void
    onBlockSenderEmail: () => void
    onBlockSenderDomain: () => void
  }
  download: {
    onDownloadMessage: () => void
  }
  emailComposer: {
    onComposerReply: () => void
    onComposerReplyAll: () => void
    onComposerForward: () => void
    onCloseComposer: () => void
  }
  modal: {
    onCloseModal: () => void
  }
  messageDetails: {
    onCloseMessageDetails: () => void
  }
  redeliveryDialog: {
    onOpenRedeliveryDialog: () => void
    onCloseRedeliveryDialog: () => void
  }
  rejectButton: {
    onRejectMessage: () => void
  }
}

export type MessageDetailsToolbarLogic = [State, EventHandlers]

export type ComposerEmailType = 'reply' | 'replyAll' | 'forward'

export const useMessageDetailsToolbarLogic = (): MessageDetailsToolbarLogic => {
  const { contentViewFlags, getEndDate, getStartDate } = useMstore()
  const dispatch = useAppDispatch()
  const {
    activePath,
    isGetMessagePending,
    isGetMessageSourcePending,
    message,
    accountPermissions,
    searchFilter,
    userId,
    isDownloadMessagePending,
    isDownloadMessageSuccess,
    downloadedMessage,
    downloadMessageProgressPercent,
    isUserBlocklistDisabled,
    isEmergencyInboxEnabled,
    isMessageHistoryOpen,
    selectedMessageMid,
    searchTerm,
    viewConfig
  } = useAppSelector(_store => ({
    activePath: _store.app.activePath,
    isGetMessagePending: isPending(_store.mstore.getMessageApiStatus),
    isGetMessageSourcePending: isPending(_store.mstore.getMessageSourceApiStatus),
    message: _store.mstore.message,
    accountPermissions: _store.settings.accountPermissions,
    searchFilter: _store.mstore.searchFilter,
    userId: _store.auth.accessTokenObject?.userId,
    isDownloadMessagePending: isPending(_store.mstore.downloadMessageApiStatus),
    isDownloadMessageSuccess: isSuccess(_store.mstore.downloadMessageApiStatus),
    downloadedMessage: _store.mstore.downloadedMessage,
    downloadMessageProgressPercent: _store.mstore.downloadMessageProgressPercent,
    isUserBlocklistDisabled: _store.settings.accountPermissions?.defaultUserBlockEnabled !== 1,
    isEmergencyInboxEnabled: _store.settings.accountPermissions?.emergencyInboxEnabled === 1,
    isMessageHistoryOpen: _store.mstore.isMessageHistoryOpen,
    selectedMessageMid: _store.mstore.selectedMessageMid,
    searchTerm: _store.mstore.searchTerm,
    viewConfig: _store.mstore.viewConfig
  }))
  const { userHasRight } = useUserRights()
  const isMessageDetailLinkVisible = activePath.id !== routesConfig.MESSAGE_DETAIL.id
  const barracudaResponsible = !!message?.barracudaResponsible
  const globalMid = message?.fields.globalMessageId
  const hasVirus = !!(message && Array.isArray(message.virus) && message.virus.length > 0)
  const remediated = useRemediated()
  const [isComposerOpen, setIsComposerOpen] = useState(false)
  const [composerEmailType, setComposerEmailType] = useState<ComposerEmailType>()
  const [isReportingActionModalOpen, setIsReportingActionModalOpen] = useState(false)
  const [isDotsMenuVisible, setIsDotsMenuVisible] = useState(false)
  const [isRedeliveryDialogOpen, setIsRedeliveryDialogOpen] = useState(false)
  const messageDetailLinkHref = useMemo(() => {
    if (!message) {
      return ''
    }
    const remediatedParam = remediated ? 'true' : 'false'
    const search = typeof remediated === 'undefined' ? '' : `?remediated=${remediatedParam}`
    return `${routesConfig.MESSAGE_DETAIL.url({ messageId: message.fields.mid, domainId: message.did })}${search}`
  }, [message, remediated])

  const messageHistoryButtonTextId = useMemo(
    () => (isMessageHistoryOpen ? 'menu.hide_history' : 'menu.show_history'),
    [isMessageHistoryOpen]
  )

  const dotsMenuAnchorElement = useRef<HTMLButtonElement>(null)

  const canViewDeliver = useMemo(() => {
    if (!message || !accountPermissions) {
      return false
    }
    const flags = contentViewFlags(accountPermissions, message.fields)
    return !(flags.cantViewQuarantined || flags.cantViewBlocked)
  }, [message, accountPermissions, contentViewFlags])

  const isDeliverDisabled = useMemo(() => {
    if (!message) {
      return false
    }
    // Check if message has a virus or is empty
    if (hasVirus || Number(message.fields.size) === 0) {
      return true
    }
    // check other reasons message cannot be redelivered for logged-in user
    return message.fields.recipientInfo.some(
      recipient => recipient.envelopeTo === userId && stopDeliverReasons.indexOf(recipient.reason) !== -1
    )
  }, [message, hasVirus, userId])

  const messageAction = useMemo(() => {
    if (!message) {
      return undefined
    }
    return message.fields.recipientInfo?.[0].action
  }, [message])

  const isReportingActionModalDisabled = useMemo(() => !messageAction, [messageAction])

  const onOpenReportingActionModal = useCallback(() => setIsReportingActionModalOpen(true), [])

  const onCloseReportingActionModal = useCallback(() => setIsReportingActionModalOpen(false), [])

  const reportingActionModalTextId = useMemo(
    () => (messageAction === 'allowed' ? 'incorrectly_allowed' : 'incorrectly_blocked'),
    [messageAction]
  )

  const isQuarantinedSearch = useMemo(() => searchFilter.action.toLowerCase() === 'quarantined', [searchFilter])

  const isQuarantinedForARecipient = useMemo(
    () => !!(message && message.fields.recipientInfo.some((recipient: any) => recipient.action === 'quarantined')),
    [message]
  )

  const onDeleteMessage = useCallback(() => {
    if (!message) {
      return
    }
    dispatch(deleteMessage({ mids: [message.fields.mid] }))
  }, [dispatch, message])

  const isDotsMenuEnabled = useMemo(
    () => !isUserBlocklistDisabled || canViewDeliver,
    [canViewDeliver, isUserBlocklistDisabled]
  )

  const isDotsMenuOpen = useMemo(() => isDotsMenuEnabled && isDotsMenuVisible, [isDotsMenuEnabled, isDotsMenuVisible])

  const onOpenDotsMenu = useCallback(() => setIsDotsMenuVisible(true), [])

  const onCloseDotsMenu = useCallback(() => setIsDotsMenuVisible(false), [])

  const onBlockSender = useCallback(
    (emailOrDomain: string, messageId: string) => {
      const blockData = [
        {
          messageId,
          emailOrDomain
        }
      ]
      dispatch(postBlockSender(blockData))
      onCloseDotsMenu()
    },
    [dispatch, onCloseDotsMenu]
  )

  const onBlockSenderEmail = useCallback(() => {
    if (!message) {
      return
    }
    onBlockSender(message.fields.envelopeFrom, message.fields.mid)
  }, [message, onBlockSender])

  const onBlockSenderDomain = useCallback(() => {
    if (!message) {
      return
    }
    const sender = message.fields.envelopeFrom.split('@').pop()
    if (!sender) {
      return
    }
    onBlockSender(sender, message.fields.mid)
  }, [message, onBlockSender])

  const onCloseRedeliveryDialog = useCallback(() => setIsRedeliveryDialogOpen(false), [])

  const onCloseMessageDetails = useCallback(() => {
    dispatch(resetSelectedMessage())
  }, [dispatch])

  const blockedOrQuarantined = useMemo(() => {
    if (!message) {
      return false
    }
    return message.fields.recipientInfo.some(
      recipient => recipient.action === 'quarantined' || recipient.action === 'blocked'
    )
  }, [message])

  const isReportButtonVisible = useMemo(
    () => blockedOrQuarantined && barracudaResponsible,
    [barracudaResponsible, blockedOrQuarantined]
  )

  const redeliveryDialogData = useMemo((): RedeliverMessage[] | undefined => {
    if (!message || !canViewDeliver) {
      return undefined
    }
    return [
      {
        mid: message.fields.mid,
        status: messageAction as any
      }
    ]
  }, [canViewDeliver, message, messageAction])

  const onOpenRedeliveryDialog = useCallback(() => {
    if (!redeliveryDialogData) {
      return
    }
    dispatch(setRedeliveryQueueMessages(redeliveryDialogData))
    setIsRedeliveryDialogOpen(true)
  }, [dispatch, redeliveryDialogData])

  const isModalOpen = useMemo(() => {
    const isComposerVisible = isComposerOpen && composerEmailType
    const isReportingActionModalVisible = isReportingActionModalOpen && messageAction
    return !!(isComposerVisible || isReportingActionModalVisible)
  }, [composerEmailType, isComposerOpen, isReportingActionModalOpen, messageAction])

  const onDownloadMessage = useCallback(() => {
    if (!message) {
      return
    }
    onCloseDotsMenu()
    dispatch(
      downloadMessage({
        messageId: message.fields.mid,
        domainId: message.did,
        userId: userHasRight(UserRights.SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS) ? undefined : userId
      })
    )
  }, [dispatch, message, onCloseDotsMenu, userHasRight, userId])

  const onCloseComposer = useCallback(() => setIsComposerOpen(false), [])

  const onOpenComposer = (type: ComposerEmailType) => () => {
    setComposerEmailType(type)
    setIsComposerOpen(true)
  }

  const onCloseModal = useCallback(() => {
    if (isComposerOpen) {
      onCloseComposer()
    }
    if (isReportingActionModalOpen) {
      onCloseReportingActionModal()
    }
  }, [isComposerOpen, isReportingActionModalOpen, onCloseComposer, onCloseReportingActionModal])

  const restoreSearch = useCallback(() => {
    dispatch(resetSearch({ hardReset: true }))
    dispatch(getSearch())
  }, [dispatch])

  const dispatchHistorySearch = useCallback(() => {
    if (!message) {
      return
    }
    const historySearch: Search = {
      start: getStartDate(30),
      end: getEndDate(),
      offset: 0,
      newest: '0',
      oldest: '0',
      limit: 0,
      search_str: `global_message_id:${message.fields.globalMessageId}`,
      user_id: searchTerm.user_id,
      domain_id: searchTerm.domain_id,
      outbound: searchTerm.outbound
    }
    dispatch(resetSearch({ hardReset: true }))
    dispatch(getSearch(historySearch))
  }, [dispatch, message, searchTerm, getEndDate, getStartDate])

  const onToggleMessageHistory = useCallback(() => {
    onCloseDotsMenu()
    dispatch(setIsMessageHistoryOpen(!isMessageHistoryOpen))
    if (isMessageHistoryOpen) {
      restoreSearch()
      return
    }
    dispatchHistorySearch()
  }, [dispatch, dispatchHistorySearch, isMessageHistoryOpen, onCloseDotsMenu, restoreSearch])

  const isDeleteButtonVisible = useMemo(
    () => viewConfig.type === MlogViewType.OUTBOUND_QUARANTINE || (isQuarantinedSearch && isQuarantinedForARecipient),
    [isQuarantinedForARecipient, isQuarantinedSearch, viewConfig.type]
  )

  const onRejectMessage = useCallback(() => {
    // TODO: Implement rejecting messages [BNESS-22803]
  }, [])

  useEffect(() => {
    if (!selectedMessageMid || !message) {
      return
    }
    dispatch(getAccountPermissions())
  }, [dispatch, selectedMessageMid, message])

  useEffect(() => {
    if (!message || !isDownloadMessageSuccess || !downloadedMessage) {
      return
    }
    const url = window.URL.createObjectURL(new Blob([downloadedMessage.message], { type: 'text/plain;base64' }))
    saveAs(url, `${message.mid}.eml`)
    dispatch(resetDownloadMessage())
  }, [dispatch, downloadedMessage, isDownloadMessageSuccess, message])

  return useMemo(
    () => [
      {
        deleteButton: {
          isDeleteButtonVisible
        },
        dotsMenu: {
          isDotsMenuEnabled,
          isDotsMenuOpen,
          dotsMenuAnchorElement
        },
        download: {
          isDownloadMessagePending,
          downloadMessageProgressPercent
        },
        emailComposer: {
          isComposerOpen,
          composerEmailType
        },
        historyButton: {
          messageHistoryButtonTextId
        },
        messageDetails: {
          isMessageDetailLinkVisible,
          messageDetailLinkHref,
          globalMid
        },
        modal: {
          isModalOpen
        },
        permissions: {
          canViewDeliver,
          isDeliverDisabled,
          isUserBlocklistDisabled,
          isEmergencyInboxEnabled
        },
        redeliveryDialog: {
          isRedeliveryDialogOpen,
          redeliveryDialogData,
          isReportButtonVisible
        },
        reportingModal: {
          isReportingActionModalDisabled,
          isReportingActionModalOpen,
          reportingActionModalTextId,
          messageAction
        },
        styleProps: {
          barracudaResponsible,
          isGetMessagePending,
          isGetMessageSourcePending
        },
        viewConfig
      },
      {
        deleteButton: {
          onDeleteMessage
        },
        reportingModal: {
          onOpenReportingActionModal,
          onCloseReportingActionModal
        },
        historyButton: {
          onToggleMessageHistory
        },
        dotsMenu: {
          onOpenDotsMenu,
          onCloseDotsMenu,
          onBlockSenderEmail,
          onBlockSenderDomain
        },
        download: {
          onDownloadMessage
        },
        emailComposer: {
          onComposerReply: onOpenComposer('reply'),
          onComposerReplyAll: onOpenComposer('replyAll'),
          onComposerForward: onOpenComposer('forward'),
          onCloseComposer
        },
        modal: {
          onCloseModal
        },
        messageDetails: {
          onCloseMessageDetails
        },
        redeliveryDialog: {
          onOpenRedeliveryDialog,
          onCloseRedeliveryDialog
        },
        rejectButton: {
          onRejectMessage
        }
      }
    ],
    [
      barracudaResponsible,
      canViewDeliver,
      composerEmailType,
      downloadMessageProgressPercent,
      globalMid,
      isComposerOpen,
      isDeleteButtonVisible,
      isDeliverDisabled,
      isDotsMenuEnabled,
      isDotsMenuOpen,
      isDownloadMessagePending,
      isEmergencyInboxEnabled,
      isGetMessagePending,
      isGetMessageSourcePending,
      isMessageDetailLinkVisible,
      isModalOpen,
      isRedeliveryDialogOpen,
      isReportButtonVisible,
      isReportingActionModalDisabled,
      isReportingActionModalOpen,
      isUserBlocklistDisabled,
      messageAction,
      messageDetailLinkHref,
      messageHistoryButtonTextId,
      onBlockSenderDomain,
      onBlockSenderEmail,
      onCloseComposer,
      onCloseDotsMenu,
      onCloseMessageDetails,
      onCloseModal,
      onCloseRedeliveryDialog,
      onCloseReportingActionModal,
      onDeleteMessage,
      onDownloadMessage,
      onOpenDotsMenu,
      onOpenRedeliveryDialog,
      onOpenReportingActionModal,
      onToggleMessageHistory,
      redeliveryDialogData,
      reportingActionModalTextId,
      viewConfig,
      onRejectMessage
    ]
  )
}
