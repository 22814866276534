import { history } from 'lib/browserHistory'
import appConfig, { AppNames } from 'config/appConfig'

export type RouteMetaData = Record<string, unknown>

export interface RouteConfig {
  path: string
  legacyPath: string
  appAccess: AppNames[]
  metadata?: RouteMetaData
}

export interface UiRoute extends RouteConfig {
  id: string
  metadata?: Record<string, unknown>
  url(params?: RouteParams): string
  isActiveUrl(params?: RouteParams): boolean
  softGoto(params?: RouteParams, withUrlParams?: boolean): void
  goto(params?: RouteParams, withUrlParams?: boolean): void
  forceGoto(params?: RouteParams, withUrlParams?: boolean): void
  gotoWithSearch(search: string, params?: RouteParams): void
  refresh(params?: RouteParams): void
}

export interface UiRoutes {
  [index: string]: UiRoute
}

export interface RouteParams {
  [key: string]: string
}

interface Paths {
  [key: string]: any
}

export function createUrlGenerator(path: string) {
  return (params: RouteParams = {}) =>
    Object.keys(params).reduce((updatedPath, param) => updatedPath.replace(`:${param}`, params[param]), path)
}

export default function routesConfig(PATHS: Paths, appName?: string, isDisableTestFilterCheck?: boolean): UiRoutes {
  function filterPaths(pathKey: string) {
    return (
      PATHS[pathKey]?.appAccess?.includes(appName || appConfig.APP.NAME) ||
      (appConfig.ENVIRONMENT.IS_TEST && !isDisableTestFilterCheck)
    )
  }

  return Object.keys(PATHS)
    .filter(filterPaths)
    .reduce((builtRoutes, route) => {
      const modifiedBuiltRoutes: UiRoutes = { ...builtRoutes }
      const basePath = appConfig.BASE_PATHS[appConfig.APP.NAME]

      const routeConfig = PATHS[route]
      const path = `/${basePath}/${routeConfig.path}`
      const { legacyPath, appAccess } = routeConfig

      const createUrl = createUrlGenerator(path)

      modifiedBuiltRoutes[route] = {
        id: route,
        path,
        legacyPath,
        appAccess,
        url: createUrl,
        isActiveUrl: (params: RouteParams = {}) => createUrl(params) === window.location.pathname,
        // goto to the route without alert the react-router
        softGoto: (params: RouteParams = {}, withUrlParams = false) => {
          const newPathName = `${createUrl(params)}${withUrlParams ? window.location.search : ''}`
          window.history.replaceState(null, '', newPathName)
        },
        goto: (params: RouteParams = {}, withUrlParams = false) => {
          const currentPath = `${createUrl(params)}${withUrlParams ? window.location.search : ''}`
          history.push(currentPath)
        },
        gotoWithSearch: (search: string, params: RouteParams = {}) => {
          const currentPath = `${createUrl(params)}${search}`
          history.push(currentPath)
        },
        forceGoto: (params: RouteParams = {}, withUrlParams = false) => {
          const currentPath = `${createUrl(params)}${withUrlParams ? window.location.search : ''}`
          window.location.href = `${window.location.origin}${currentPath}`
        },
        refresh: (params: RouteParams = {}) => {
          window.location.href = createUrl(params)
        },
        metadata: routeConfig.metadata
      }

      return modifiedBuiltRoutes
    }, {})
}
