export const isEmailValid = (email: string) => {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g

  return re.test(email.toLowerCase())
}

export const isDomainValid = (domain: string) => {
  const re = /^[a-z0-9\-._]{1,255}$/i

  return re.test(domain.toLowerCase())
}

export const isPolicyNameValid = (domainOrEmail: string) => {
  if (domainOrEmail.indexOf('@') !== -1) {
    return isEmailValid(domainOrEmail)
  }
  return isDomainValid(domainOrEmail)
}

export const isPolicyValid = (policy: string) => {
  const pol = policy.toLowerCase()
  return pol === 'block' || pol === 'exempt' || pol === 'quarantine'
}
