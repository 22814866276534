import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending } from 'redux/toolkit/api'

import { getTopDomainsStats } from 'redux/features/stats/statsSlice'

import { Range, StatType, TopDomainsActions, TopRecipientDomains } from 'types/stats'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'
import { Direction } from 'types/Messages'

export interface UseTopDomainsLogicProps {
  domainId?: string
  range: Range
}

interface State {
  topDomains: TopRecipientDomains | undefined
  topDomainsInProgress: boolean
  selectedFilter: string
}

interface EventHandlers {
  onSelectFilter: (value: unknown) => void
}

export type UseTopDomainsLogic = [State, EventHandlers]

export const useTopDomainsLogic = (props: UseTopDomainsLogicProps): UseTopDomainsLogic => {
  const dispatch = useAppDispatch()

  const { topDomains, topDomainsInProgress } = useAppSelector(_store => ({
    topDomains: _store.stats.topDomains,
    topDomainsInProgress: isPending(_store.stats.api.getTopDomainsStats)
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(
    getTableWithDropdownConfig(DisplayType.recipientDomains)?.dropdownItems[0].value || ''
  )

  // new props or selected new filter
  useEffect(() => {
    dispatch(
      getTopDomainsStats({
        domainId: props.domainId,
        range: props.range,
        action: selectedFilter as TopDomainsActions,
        direction: Direction.INBOUND,
        statType: props.domainId ? StatType.SENDERS : StatType.RECIPIENTS
      })
    )
  }, [dispatch, props.range, props.domainId, selectedFilter])

  const onSelectFilter: EventHandlers['onSelectFilter'] = useCallback(value => {
    setSelectedFilter(value as TopDomainsActions)
  }, [])

  return useMemo(
    () => [
      {
        topDomains,
        topDomainsInProgress,
        selectedFilter
      },
      {
        onSelectFilter
      }
    ],
    [topDomains, topDomainsInProgress, selectedFilter, onSelectFilter]
  )
}
