import React, { useMemo } from 'react'

import { Grid } from '@cuda-networks/bds-core'

import { useFormatMessage } from 'lib/localization'
import ErrorPage from 'components/libs/errorPage/ErrorPage'
import styles from 'components/libs/contentLoader/contentLoaderStyles'

const BASE_I18N_KEY = 'ess.app.error'

export interface ContentLoaderErrorPageProps {
  errorMessage?: string
}

function ContentLoaderErrorPage({ errorMessage }: ContentLoaderErrorPageProps) {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Grid className={classes.errorPage} container justifyContent="center" alignContent="center" direction="column">
        <ErrorPage title={errorMessage || formatMessage('failed_to_load_content')} />
      </Grid>
    ),
    [classes, formatMessage, errorMessage]
  )
}

export default ContentLoaderErrorPage
