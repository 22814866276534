import routesGenerator, { UiRoute as GlobalUiRoute } from 'lib/routes'
import appConfig, { AppNames } from 'config/appConfig'
import appFeatures from 'config/appFeatures'
import UI_PATHS from 'config/uiPaths'

export type UiRoute = GlobalUiRoute
export type UiRoutes = {
  [key in keyof typeof UI_PATHS]: UiRoute
}

// disable the general login on stacks where the BCC login is enabled
const updatedUiPaths = {
  ...UI_PATHS,
  LOGIN: appFeatures.EnabledWizardBccLogin ? undefined : UI_PATHS.LOGIN
}
const routesConfig = routesGenerator(updatedUiPaths) as UiRoutes

// entry paths
const ENTRY_PATHS = {
  [AppNames.enduser]: routesConfig.MESSAGE_LOG,
  [AppNames.admin]: routesConfig.OVERVIEW,
  [AppNames.wizard]: routesConfig.ONBOARD_WIZARD
} as { [key: string]: UiRoute }

const LOGIN_ENTRY_PATHS = {
  [AppNames.enduser]: routesConfig.LOGIN,
  [AppNames.admin]: routesConfig.BCC_LOGIN,
  [AppNames.wizard]: appFeatures.EnabledWizardBccLogin ? routesConfig.BCC_LOGIN : routesConfig.LOGIN
} as { [key: string]: UiRoute }

export const appEntryPath = ENTRY_PATHS[appConfig.APP.NAME]
export const appLoginEntryPath = LOGIN_ENTRY_PATHS[appConfig.APP.NAME]

export function gotoAdminEntryPathFromWizard() {
  if (appFeatures.EnabledNewAdminApp) {
    window.location.href = `${window.location.origin}/${appConfig.APP_NAMES.ADMIN}/${UI_PATHS.MESSAGE_LOG.path}`
  } else {
    window.location.href = appConfig.LEGACY_UI_URL as string
  }
}

export function gotoEnduserEntryPathFromWizard() {
  window.location.href = `${window.location.origin}/${appConfig.APP_NAMES.ENDUSER}/${UI_PATHS.MESSAGE_LOG.path}`
}

export function gotoLegacyLogin() {
  window.location.href = `${appConfig.LEGACY_UI_URL}/${UI_PATHS.LOGIN.legacyPath}`
}

export default routesConfig
