import { UserRole } from 'config/userRole'

export { UserRole }

// features (can use multiple roles) like UserRole.SUPPORT_USER.value + UserRole.DOMAIN_USER.value
export enum UserRights {
  VIEW_ADMIN_MESSAGES_DETAILS = UserRole.ADMIN_USER,
  VIEW_BLOCKED_AND_QUARANTINED_MESSAGES = UserRole.ADMIN_USER,
  SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS = UserRole.ADMIN_USER,
  GET_ADMIN_SUPPORT_TOPICS = UserRole.ADMIN_USER,
  FILTER_ABOUT_DOMAINS = UserRole.ADMIN_USER,
  SEARCH_IN_ALL_DOMAINS = UserRole.ADMIN_USER,
  USE_ADMIN_VERSION_OF_MESSAGE_LOG = UserRole.ADMIN_USER
}
