import { createAsyncThunk } from '@reduxjs/toolkit'

import { objectToCamel, objectToSnake } from 'ts-case-convert'
import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

import { AuditLog, AuditLogSearch } from 'types/AuditLog'
import { RootState } from 'redux/toolkit/store'

export interface AuditLogPayload {
  skip: number
  take: number
}

export interface AuditLogResult {
  total: number
  results: AuditLog[]
}

export interface AuditLogDeletePayload {
  idList: string[]
}

export const getAuditLogSearch = createAsyncThunk<AuditLogResult, AuditLogPayload | undefined, ApiRejectResponse>(
  'AUDIT_LOG/auditSearch',
  async (payload: AuditLogPayload | undefined, { rejectWithValue, getState }) => {
    try {
      const query: AuditLogSearch = (getState() as RootState).auditLog.searchTerm
      const { skip, take } = payload || (getState() as RootState).dataTables.auditLog

      let eventTypes = ''
      Object.entries(query.eventTypes).map(([key, value]) => {
        if (value) {
          eventTypes += eventTypes.length ? `,${key}` : key
        }
        return key
      })

      // TODO: Correct the way we send page and limit to the backend as sending `take + skip` can be confusing
      const resp = await restClient(apiRoutes.AUDIT_LOG, {
        params: {
          ...query,
          page: skip,
          limit: take + skip,
          sort: query.sort[0].field,
          order: query.sort[0].dir,
          eventTypes
        }
      })

      return {
        total: resp.data.result.total,
        results: resp.data.result.results.map((r: any) => objectToCamel<AuditLog>(r))
      }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const exportAuditLog = createAsyncThunk<string, AuditLogDeletePayload, ApiRejectResponse>(
  'AUDIT_LOG/auditLogExport',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.AUDIT_LOG_EXPORT, {
        data: objectToSnake(payload)
      })

      return resp.data.result.result
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
