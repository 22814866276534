import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  root: {
    // TODO: rely on spacing only; keeping 20px for now with a spacing of 16
    margin: theme.spacing(2) + 4
  },
  progressWrapper: {
    marginTop: 0
  }
}))
