import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import { splitReducer } from '@splitsoftware/splitio-redux'

import { RootState } from 'redux/toolkit/store'

// slices
import app, { INITIAL_STATE as appInitialState } from 'redux/features/app/appSlice'
import atp, { INITIAL_STATE as atpInitialState } from 'redux/features/atp/atpSlice'
import auth, { INITIAL_STATE as authInitialState, logout, resetAll } from 'redux/features/auth/authSlice'
import dataTables, { INITIAL_STATE as dataTablesInitialState } from 'redux/features/dataTables/dataTablesSlice'
import auditLog, { INITIAL_STATE as auditLogInitialState } from 'redux/features/auditLog/auditLogSlice'
import emailServer, { INITIAL_STATE as emailServerInitialState } from 'redux/features/emailServer/emailServerSlice'
import fpfn, { INITIAL_STATE as fpfnInitialState } from 'redux/features/fpfn/fpfnSlice'
import mstore, { INITIAL_STATE as mstoreInitialState } from 'redux/features/mstore/mstoreSlice'
import settings, { INITIAL_STATE as settingsInitialState } from 'redux/features/settings/settingsSlice'
import stats, { INITIAL_STATE as statsInitialState } from 'redux/features/stats/statsSlice'
import user, { INITIAL_STATE as userInitialState } from 'redux/features/user/userSlice'

export const reducers = {
  app,
  auditLog,
  atp,
  auth,
  dataTables,
  emailServer,
  fpfn,
  mstore,
  settings,
  stats,
  user,
  splitio: splitReducer
}

const combinedReducers = combineReducers({ ...reducers })

export default function reducersGenerator(state: RootState, action: AnyAction) {
  let appState = { ...state }

  // We should reset the store again after the logout is finished
  if (resetAll.match(action) || logout.fulfilled.match(action)) {
    appState = {
      app: {
        ...appInitialState,
        activePath: appState.app.activePath
      },
      auditLog: auditLogInitialState,
      atp: atpInitialState,
      auth: authInitialState,
      dataTables: dataTablesInitialState,
      emailServer: emailServerInitialState,
      fpfn: fpfnInitialState,
      mstore: mstoreInitialState,
      settings: settingsInitialState,
      stats: statsInitialState,
      user: userInitialState,
      splitio: {
        ...appState.splitio,
        treatments: {}
      }
    }
  }

  return combinedReducers(appState, action)
}
