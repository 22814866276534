import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import {
  detectEmailServer,
  getEmailServerSettings,
  getMxRecords,
  getOldMxRecords,
  getProvider,
  setEmailServerSettings,
  setRegion,
  verifyEmailServer
} from 'redux/features/emailServer/emailServerApiThunks'
import {
  EmailServerConfig,
  EmailServerResult,
  EmailServerSettings,
  MxRecords,
  OldMxRecords,
  Provider
} from 'types/emailServer'

export interface EmailServerState {
  emailAddress: string
  detectEmailServerApiStatus: ApiStatus
  emailServer: EmailServerResult | undefined
  updatedEmailServer: EmailServerResult | undefined
  verifyEmailServerApiStatus: ApiStatus
  getProviderApiStatus: ApiStatus
  provider: Provider[] | undefined
  getMxRecordsApiStatus: ApiStatus
  mxRecords: MxRecords | undefined
  getOldMxRecordsApiStatus: ApiStatus
  oldMxRecords: OldMxRecords | undefined
  getEmailServerSettingsApiStatus: ApiStatus
  emailServerSettings: EmailServerSettings | undefined
  setEmailServerSettingsApiStatus: ApiStatus
  setRegionApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: EmailServerState = {
  emailAddress: '',
  detectEmailServerApiStatus: inIdle,
  emailServer: undefined,
  updatedEmailServer: undefined,
  verifyEmailServerApiStatus: inIdle,
  getProviderApiStatus: inIdle,
  provider: undefined,
  getMxRecordsApiStatus: inIdle,
  mxRecords: undefined,
  getOldMxRecordsApiStatus: inIdle,
  oldMxRecords: undefined,
  getEmailServerSettingsApiStatus: inIdle,
  emailServerSettings: undefined,
  setEmailServerSettingsApiStatus: inIdle,
  setRegionApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export function splitEmailSettings(state: EmailServerState, newSettings: EmailServerSettings) {
  state.emailServerSettings = newSettings
  state.emailAddress = newSettings.email || INITIAL_STATE.emailAddress
  if (newSettings.primaryMx && newSettings.backupMx) {
    state.mxRecords = {
      primary: newSettings.primaryMx,
      backup: newSettings.backupMx
    }
  }
  state.emailServer = newSettings.emailServers?.[0] || INITIAL_STATE.emailServer
  state.provider = newSettings.provider || INITIAL_STATE.provider
  state.oldMxRecords = newSettings.oldMxRecords || INITIAL_STATE.oldMxRecords
}

export const emailServerSlice = createSlice({
  name: 'EMAIL_SERVER',
  initialState: INITIAL_STATE,
  reducers: {
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload
      if (state.emailServer) state.emailServer.verified = false
      if (state.updatedEmailServer) state.updatedEmailServer.verified = false
    },
    setUpdatedEmailServer: (state, action: PayloadAction<EmailServerConfig | undefined>) => {
      state.updatedEmailServer = action.payload
    },
    resetEmailServer: state => {
      state.detectEmailServerApiStatus = INITIAL_STATE.detectEmailServerApiStatus
      state.emailServer = INITIAL_STATE.emailServer
      state.updatedEmailServer = INITIAL_STATE.updatedEmailServer
    },
    resetVerifyEmailServer: (state, action: PayloadAction<boolean | undefined>) => {
      state.verifyEmailServerApiStatus = INITIAL_STATE.verifyEmailServerApiStatus
      if (state.emailServer && action.payload) state.emailServer.verified = false
    },
    resetProvider: state => {
      state.getProviderApiStatus = INITIAL_STATE.getProviderApiStatus
      state.provider = INITIAL_STATE.provider
    },
    resetMxRecords: state => {
      state.getMxRecordsApiStatus = INITIAL_STATE.getMxRecordsApiStatus
      state.mxRecords = INITIAL_STATE.mxRecords
    },
    resetOldMxRecords: state => {
      state.getOldMxRecordsApiStatus = INITIAL_STATE.getOldMxRecordsApiStatus
      state.oldMxRecords = INITIAL_STATE.oldMxRecords
    },
    resetEmailServerSettings: state => {
      state.getEmailServerSettingsApiStatus = INITIAL_STATE.getEmailServerSettingsApiStatus
      state.setEmailServerSettingsApiStatus = INITIAL_STATE.setEmailServerSettingsApiStatus
      state.emailServerSettings = INITIAL_STATE.emailServerSettings
    },
    resetRegion: state => {
      state.setRegionApiStatus = INITIAL_STATE.setRegionApiStatus
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // detectEmailServer
      .addCase(detectEmailServer.pending, state => {
        state.detectEmailServerApiStatus = inProgress
        state.emailServer = INITIAL_STATE.emailServer
        state.updatedEmailServer = INITIAL_STATE.updatedEmailServer
      })
      .addCase(detectEmailServer.fulfilled, (state, action) => {
        state.detectEmailServerApiStatus = successResponse
        state.emailServer = action.payload
      })
      .addCase(detectEmailServer.rejected, (state, action) => {
        state.detectEmailServerApiStatus = failedResponse(action.payload)
      })

      // verifyEmailServer
      .addCase(verifyEmailServer.pending, state => {
        state.verifyEmailServerApiStatus = inProgress
      })
      .addCase(verifyEmailServer.fulfilled, (state, action) => {
        state.verifyEmailServerApiStatus = successResponse
        if (action.payload) {
          state.emailServer = { ...action.payload, verified: true }
        }
      })
      .addCase(verifyEmailServer.rejected, (state, action) => {
        state.verifyEmailServerApiStatus = failedResponse(action.payload)
      })

      // getProvider
      .addCase(getProvider.pending, state => {
        state.getProviderApiStatus = inProgress
        state.provider = INITIAL_STATE.provider
      })
      .addCase(getProvider.fulfilled, (state, action) => {
        state.getProviderApiStatus = successResponse
        state.provider = action.payload
      })
      .addCase(getProvider.rejected, (state, action) => {
        state.getProviderApiStatus = failedResponse(action.payload)
      })

      // getMxRecords
      .addCase(getMxRecords.pending, state => {
        state.getMxRecordsApiStatus = inProgress
        state.mxRecords = INITIAL_STATE.mxRecords
      })
      .addCase(getMxRecords.fulfilled, (state, action) => {
        state.getMxRecordsApiStatus = successResponse
        state.mxRecords = action.payload
      })
      .addCase(getMxRecords.rejected, (state, action) => {
        state.getMxRecordsApiStatus = failedResponse(action.payload)
      })

      // getOldMxRecords
      .addCase(getOldMxRecords.pending, state => {
        state.getOldMxRecordsApiStatus = inProgress
        state.oldMxRecords = INITIAL_STATE.oldMxRecords
      })
      .addCase(getOldMxRecords.fulfilled, (state, action) => {
        state.getOldMxRecordsApiStatus = successResponse
        state.oldMxRecords = action.payload
      })
      .addCase(getOldMxRecords.rejected, (state, action) => {
        state.getOldMxRecordsApiStatus = failedResponse(action.payload)
      })

      // getEmailServerSettings
      .addCase(getEmailServerSettings.pending, state => {
        state.getEmailServerSettingsApiStatus = inProgress
      })
      .addCase(getEmailServerSettings.fulfilled, (state, action) => {
        state.getEmailServerSettingsApiStatus = successResponse
        splitEmailSettings(state, action.payload)
      })
      .addCase(getEmailServerSettings.rejected, (state, action) => {
        state.getEmailServerSettingsApiStatus = failedResponse(action.payload)
      })

      // setEmailServerSettings
      .addCase(setEmailServerSettings.pending, state => {
        state.setEmailServerSettingsApiStatus = inProgress
      })
      .addCase(setEmailServerSettings.fulfilled, state => {
        state.setEmailServerSettingsApiStatus = successResponse
      })
      .addCase(setEmailServerSettings.rejected, (state, action) => {
        state.setEmailServerSettingsApiStatus = failedResponse(action.payload)
      })

      // setRegion
      .addCase(setRegion.pending, state => {
        state.setRegionApiStatus = inProgress
      })
      .addCase(setRegion.fulfilled, (state, action) => {
        state.setRegionApiStatus = successResponse
        splitEmailSettings(state, action.payload)
      })
      .addCase(setRegion.rejected, (state, action) => {
        state.setRegionApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setEmailAddress,
  setUpdatedEmailServer,
  resetEmailServer,
  resetVerifyEmailServer,
  resetProvider,
  resetMxRecords,
  resetOldMxRecords,
  resetEmailServerSettings,
  resetRegion,
  reset
} = emailServerSlice.actions

export {
  detectEmailServer,
  verifyEmailServer,
  getProvider,
  getMxRecords,
  getOldMxRecords,
  getEmailServerSettings,
  setEmailServerSettings,
  setRegion
}

export default emailServerSlice.reducer
