/* eslint-disable quotes */
export default {
  support: 'Ayuda',
  pervious_page: 'Volver a la página anterior',
  print: 'Vista imprimible',
  search_box_title: 'Buscar temas de apoyo',
  enter_keywords: 'Introducir palabras clave',
  search_box_example: 'Ejemplo: añadir servidor de correo, configuración de entrada',
  no_search_results: 'Su búsqueda no ha dado ningún resultado.',
  documentation: 'Documentación',
  documentation_content:
    'Toda la documentación del administrador para Barracuda Email Gateway Defense, incluida la guía de introducción, se puede encontrar en Barracuda TechLibrary con enlace aquí:',
  documentation_link1: 'Campus Barracuda',
  documentation_link2: 'Notas de publicación',
  linked_accounts: 'Cuentas vinculadas',
  sender_policy: 'Política de remitentes',
  quarantine_notification: 'Notificación de cuarentena',
  message_log: 'Mensaje registro',
  search_result: 'Resultado de la búsqueda',
  cpl_product_name: 'Barracuda Cloud Protection Layer',
  no_cpl_product_name: 'Email Gateway Defense',
  topics: {
    domains: 'Dominios',
    inbound_settings: 'Ajustes de entrada',
    outbound_settings: 'Ajustes de salida',
    user_topics: 'Usuarios',
    end_user: 'Usuario final',
    self_service_portal_topics: 'Portal de autoservicio',
    general_topics: 'General',
    report_topics: 'Informes',
    account_management_topics: 'Gestión de cuentas',

    seen_recipients: 'Destinatarios vistos',
    dashboard: 'Cuadro de mandos',
    staying_in_compliance: 'Cumplir la normativa',
    message_log: 'Mensaje registro',
    atp_log: 'ATP registro',
    audit_log: 'Registro de auditoría',
    domain_verification: 'Verificación de dominios',
    mail_server_test: 'Prueba del servidor de correo',
    domain_manager: 'Administrador de dominios',
    domain_settings: 'Configuración del dominio',
    recipients_list: 'Lista de destinatarios',
    anti_spam_antivirus: 'Anti-Spam/Antivirus',
    custom_rbls: 'RBL personalizados',
    ip_address_policies: 'Políticas de direcciones IP',
    regional_policies: 'Políticas regionales',
    recipient_policies: 'Políticas de los beneficiarios',
    sender_policies: 'Políticas del remitente',
    sender_authentication: 'Autenticación del remitente',
    content_policies: 'Políticas de contenidos',
    rate_control: 'Control de tarifas',
    anti_phishing: 'Anti Phishing',
    atp_settings: 'ATP Ajustes',
    sender_ip_address_ranges: 'Rangos de direcciones IP del remitente',
    anti_virus: 'Anti-Virus',
    tagline_footer: 'Lema/Pie de página',
    dlp_encryption: 'DLP/Encriptación',
    notifications: 'Notificaciones',
    outbound_quarantine: 'Cuarentena de salida',
    default_policy: 'Política por defecto',
    add_update_users: 'Añadir/actualizar usuarios',
    email_continuity: 'Continuidad del correo electrónico',
    quarantine_notification: 'Notificación de cuarentena',
    users_list: 'Lista de usuarios',
    linked_accounts: 'Cuentas vinculadas',
    sender_policy: 'Política de remitentes',
    specify_primary_email_domain: 'Paso 1 - Especifique el dominio de correo electrónico principal',
    specify_email_servers: 'Paso 2 - Especifique los servidores de correo electrónico',
    configure_settings: 'Paso 3 - Configurar los ajustes',
    configure_outbound_settings_for_office_365: 'Paso 4 - Configurar los ajustes de salida para Office365',
    route_email_through_barracuda_networks: 'Paso 5 - Enrutar el correo electrónico a través de la red Barracudas',
    regular_expressions: 'Expresiones regulares',
    generate_report_this_domain: 'Generar informe - Este dominio',
    generate_report_all_domains: 'Generar informe - Todos los dominios',
    syslog_integration: 'Integración de Syslog'
  }
}
/* eslint-enable quotes */
