import { makeStyles, colors } from '@cuda-networks/bds-core'

export const PROGRESS_WIDTH = 24
export const PROGRESS_HEIGHT = 23

export default makeStyles(theme => ({
  headerWrapper: {
    padding: theme.spacing(1, 2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    display: 'flex',
    alignItem: 'center'
  },
  helpIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    width: theme.spacing(2.5),
    marginTop: theme.spacing(0.25),
    color: colors.blue600
  },
  cardsRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0)
  },
  countWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(6)
  },
  count: {
    marginRight: theme.spacing(1),
    fontSize: 45,
    '&.red': {
      color: colors.red600
    },
    '&.orange': {
      color: colors.redOrange400
    }
  },
  countSubtitle: {
    color: colors.scaleGray500
  },
  container: {
    padding: theme.spacing(0, 2)
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: theme.spacing(3)
    }
  },
  filterLabel: {
    marginBottom: theme.spacing(0.65)
  },
  statusButton: {
    fontSize: 12,
    borderRadius: 0,
    background: colors.white,
    '&.status': {
      width: '92px'
    },
    '&.time': {
      width: '118px'
    }
  },
  buttonSelected: {
    background: colors.barracudaBlue,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.barracudaBlue
    }
  },
  fileTypeInputWrapper: {
    width: '142px'
  },
  domainSelectWrapper: {
    width: '180px'
  },
  selectInput: {
    borderRadius: 0,
    '& > div:first-child': {
      padding: theme.spacing(0.65, 1),
      fontSize: 12,
      backgroundColor: colors.white
    }
  },
  exportButton: {
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    fontSize: 12
  },
  searchTextField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white
  },
  logTableProgress: {
    height: '3px'
  },
  progressIcon: {
    width: theme.spacing(1),
    height: theme.spacing(1)
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  statusIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&.clean': {
      color: colors.green300
    },
    '&.infected': {
      color: colors.red500
    },
    '&.suspicious': {
      color: colors.orange300
    },
    '&.error': {
      color: colors.orange400
    }
  },
  statusText: {
    marginLeft: theme.spacing(2)
  },
  linkText: {
    color: colors.blue600
  }
}))
