/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import { Card, CardContent, CardHeader, Grid, IconButton, Link, Modal, Typography } from '@cuda-networks/bds-core'
import { Close } from '@cuda-networks/bds-core/dist/Icons/Core'

import { Navigate } from 'react-router'
import routesConfig from 'lib/routesConfig'
import { useFormatMessage } from 'lib/localization'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentInfo, useHelpLogic } from './useHelpLogic'

export interface HelpPropsComponent extends HelpComponentInfo {
  onClose: () => void
}
interface HelpProps {
  modalComponentInfo?: HelpPropsComponent
}

const BASE_I18N_KEY = 'ess.support_page'
const BASE_I18N_TOPICS_KEY = 'ess.support_page.topics'

const ModalComponent: React.FC<{ componentInfo: HelpPropsComponent }> = ({ componentInfo }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatTopicsMessage = useFormatMessage(BASE_I18N_TOPICS_KEY)
  const { brtsScan, productName, isCplAccount } = useHelpLogic()

  return useMemo(
    () => (
      <Modal open className={classes.modal} data-testid="root-modal">
        <Card elevation={1} className={classes.modalCard}>
          <CardHeader
            data-testid="title"
            className={classes.modalTitle}
            title={formatTopicsMessage(componentInfo.title)}
            action={
              <IconButton className={classes.closeIcon} onClick={componentInfo.onClose} data-testid="close-icon">
                <Close />
              </IconButton>
            }
          />
          <CardContent data-testid="content" className={classes.modalContent}>
            <componentInfo.content productName={formatMessage(productName)} hasCpl={isCplAccount} brtsScan={brtsScan} />
          </CardContent>
        </Card>
      </Modal>
    ),
    [classes, formatMessage, formatTopicsMessage, brtsScan, productName, isCplAccount, componentInfo]
  )
}

const PageComponent: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatTopicsMessage = useFormatMessage(BASE_I18N_TOPICS_KEY)
  const { brtsScan, productName, isCplAccount, componentInfo, onSupportClick, onGoBackClick } = useHelpLogic()
  return useMemo(
    () => (
      <>
        {!componentInfo && <Navigate to={routesConfig.SUPPORT.path} />}
        {componentInfo && (
          <Grid container direction="row" data-testid="root-grid">
            <Grid item xs={12}>
              <Card elevation={1}>
                <CardHeader
                  title={
                    <Typography className={classes.title} data-testid="title">
                      {formatTopicsMessage(componentInfo.title)}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} className={classes.subtitle} data-testid="subtitle">
              <Grid>
                <Link className={classes.link} onClick={onSupportClick}>
                  {formatMessage('support')}{' '}
                </Link>
                &gt; {formatTopicsMessage(componentInfo.groupTitle)} &gt;{' '}
                <b>{formatTopicsMessage(componentInfo.title)}</b>
              </Grid>
              <Grid>
                <Link className={classes.link} onClick={onGoBackClick}>
                  <b>{formatMessage('pervious_page')}</b>
                </Link>{' '}
                | <Link className={classes.link}>{formatMessage('print')}</Link>
              </Grid>
            </Grid>
            <Grid item xs={12} data-testid="content">
              <componentInfo.content
                productName={formatMessage(productName)}
                hasCpl={isCplAccount}
                brtsScan={brtsScan}
              />
            </Grid>
          </Grid>
        )}
      </>
    ),
    [
      classes,
      formatMessage,
      formatTopicsMessage,
      brtsScan,
      productName,
      isCplAccount,
      componentInfo,
      onGoBackClick,
      onSupportClick
    ]
  )
}

const Help: React.FC<HelpProps> = ({ modalComponentInfo }) => (
  <>
    {modalComponentInfo && <ModalComponent componentInfo={modalComponentInfo} />}
    {!modalComponentInfo && <PageComponent />}
  </>
)

export default Help
/* eslint-enable jsx-a11y/anchor-is-valid */
