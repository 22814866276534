// TODO: fix components defined during render
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react'
import Draggable from 'react-draggable'

import {
  Checkbox,
  DataTable,
  DataTableColumn,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Box,
  IconButton
} from '@cuda-networks/bds-core'
import { Close } from '@cuda-networks/bds-core/dist/Icons/Core'
import { useFormatMessage } from 'lib/localization'

import { Cell } from 'components/libs/grid/cell'
import styles from 'components/libs/dialog/redeliveryQueue/redeliveryQueueStyles'
import {
  ModifiedResultConfig,
  useRedeliveryQueueLogic
} from 'components/libs/dialog/redeliveryQueue/useRedeliveryQueueLogic'

const BASE_I18N_KEY = 'ess.message_log.redelivery_queue'

export interface RedeliveryQueueProps {
  onClose: () => void
}

const RedeliveryQueue: React.FC<RedeliveryQueueProps> = React.forwardRef(({ onClose }, _) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const {
    isGetRedeliveryQueueLoading,
    shouldDisableButton,
    gridState,
    sort,
    handleSortChange,
    handlePageChange,
    retryStarted,
    onRetry,
    numChecked,
    checkedAll,
    handleCheck,
    handleCheckAll
  } = useRedeliveryQueueLogic()

  return useMemo(
    () => (
      <Dialog
        open
        fullWidth
        maxWidth="xl"
        onClose={onClose}
        PaperComponent={props => (
          <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
          </Draggable>
        )}
        data-testid="root-dialog"
      >
        <div className={classes.dialogHeadingWrapper}>
          <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title" data-testid="title">
            {formatMessage('title')}
          </DialogTitle>
          <IconButton className={classes.closeButton} onClick={onClose} data-testid="close-button">
            <Close />
          </IconButton>
        </div>
        <DialogContent>
          <DialogContentText data-testid="subtitle">{formatMessage('sub_title')}</DialogContentText>
          <div className={classes.progressBar}>
            {isGetRedeliveryQueueLoading && <LinearProgress data-testid="linear-progress" />}
          </div>
          <DataTable
            className={classes.table}
            data={gridState.dataResult.data}
            resizable
            sort={sort}
            onSortChange={handleSortChange}
            onDataStateChange={handlePageChange}
            pageConfig={{
              pageable: { pageSizes: [10, 25, 50] },
              skip: gridState.dataState.skip || 0,
              take: gridState.dataState.take || 10,
              total: gridState.dataResult.total
            }}
          >
            <DataTableColumn
              width={50}
              cell={({ dataItem }: { dataItem: ModifiedResultConfig }) => (
                <Cell data-testid="select-cell">
                  <Box component="span" display="flex" justifyContent="center" alignItems="center">
                    <Checkbox
                      onChange={(cbEvent: React.ChangeEvent<HTMLInputElement>) => handleCheck(cbEvent, dataItem.id)}
                      checked={dataItem.isChecked}
                      data-testid={`checkbox-${dataItem.id}`}
                    />
                  </Box>
                </Cell>
              )}
              headerCell={() => (
                <Box component="span" display="flex" justifyContent="center" alignItems="center">
                  <Checkbox
                    onChange={handleCheckAll}
                    checked={checkedAll}
                    indeterminate={!!numChecked && numChecked !== gridState.dataResult.data.length}
                    data-testid="check-all-button"
                  />
                </Box>
              )}
            />
            <DataTableColumn field="from" title="From" data-testid="from-cell" />
            <DataTableColumn field="to" title="To" data-testid="to-cell" />
            <DataTableColumn field="subject" title="Subject" data-testid="subject-cell" />
            <DataTableColumn field="formattedQueueDate" title="Time" data-testid="date-cell" />
            <DataTableColumn field="status" title="Status" width={100} data-testid="status-cell" />
            <DataTableColumn field="description" title="Reason" data-testid="description-cell" />
          </DataTable>
        </DialogContent>
        <DialogActions className={classes.buttonsWrapper}>
          {retryStarted && (
            <span className={classes.progressBarContainer}>
              <span className={classes.progressBarStatus}>{formatMessage('redelivering')}</span>
              <LinearProgress data-testid="retry-linear-progress" />
            </span>
          )}
          <Button
            className={classes.buttons}
            color="secondary"
            variant="text"
            onClick={onRetry}
            disabled={shouldDisableButton}
            data-testid="retry-button"
          >
            {formatMessage('buttons.retry')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      classes,
      formatMessage,
      isGetRedeliveryQueueLoading,
      shouldDisableButton,
      gridState,
      sort,
      handleSortChange,
      handlePageChange,
      retryStarted,
      onRetry,
      numChecked,
      checkedAll,
      handleCheck,
      handleCheckAll,
      onClose
    ]
  )
})

export default RedeliveryQueue
