import React, { useMemo } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextareaAutosize,
  LinearProgress
} from '@cuda-networks/bds-core'
import styles from 'components/pages/settings/bulkEdit/bulkEditStyles'
import { useFormatMessage } from 'lib/localization'
import { useBulkEditLogic } from './useBulkEditLogic'

const BASE_I18N_KEY = 'ess.settings.bulk_edit'

const BulkEdit: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { policiesAreLoading, saveEnabled, rawBulkPolicies, onCancel, onSave, onChange } = useBulkEditLogic()

  return useMemo(
    () => (
      <Card elevation={0} data-testid="root-card">
        <CardHeader
          title={
            <Typography className={classes.title} data-testid="title">
              {formatMessage('title')}
            </Typography>
          }
          subheader={
            <Typography className={classes.subTitle} variant="body2" data-testid="subtitle">
              {formatMessage('sub_title')}
            </Typography>
          }
        />
        <CardActions className={classes.actions}>
          <Button color="secondary" onClick={onCancel} variant="contained" data-testid="cancel-button">
            {formatMessage('buttons.cancel')}
          </Button>
          <Button
            color="primary"
            onClick={onSave}
            disabled={!saveEnabled}
            variant="contained"
            data-testid="save-button"
          >
            {formatMessage('buttons.save')}
          </Button>
        </CardActions>
        <CardContent className={classes.cardContent}>
          <div className={classes.progress}>{policiesAreLoading && <LinearProgress data-testid="loader" />}</div>
          {!policiesAreLoading && (
            <>
              <Typography variant="h6" gutterBottom data-testid="content-header">
                {formatMessage('content_header')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom data-testid="content-subheader">
                {formatMessage('content_sub_header')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom data-testid="content-subtitle">
                {formatMessage('warning')}
              </Typography>
              <TextareaAutosize
                minRows={16}
                maxRows={16}
                className={classes.textArea}
                placeholder={formatMessage('place_holder')}
                defaultValue={rawBulkPolicies}
                onChange={onChange}
                data-testid="textarea"
              />
            </>
          )}
        </CardContent>
      </Card>
    ),
    [classes, formatMessage, policiesAreLoading, saveEnabled, rawBulkPolicies, onCancel, onSave, onChange]
  )
}

export default BulkEdit
