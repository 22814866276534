import React, { useMemo } from 'react'
import { CSVLink } from 'react-csv'

import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  DataTable,
  DataTableColumn,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Select,
  SelectMenuItem,
  SelectWrapper,
  TextField,
  Typography
} from '@cuda-networks/bds-core'
import { Search, HelpOutline } from '@cuda-networks/bds-core/dist/Icons/Core'

import { useFormatMessage } from 'lib/localization'
import { onKeyDown } from 'lib/keyEvents'

import { Cell } from 'components/libs/grid/cell'

import styles, { PROGRESS_HEIGHT, PROGRESS_WIDTH } from 'components/pages/atpLog/atpLogStyles'
import { useAtpLogLogic } from 'components/pages/atpLog/useAtpLogLogic'
import { ModifiedAtpLog } from 'types/Atp'
import Help from 'components/pages/support/help/Help'
import AtpLogSupportPage from 'components/pages/support/help/atpLog/AtpLog'
import { GROUP_TITLE, TOPICS } from '../support/config'

const BASE_I18N_KEY = 'ess.atp_log'
const BASE_I18N_EXPORT_KEY = 'ess.atp_log.export'
const BASE_I18N_TABLE_KEY = 'ess.data_tables.atp_log'

const AtpLog = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatExportMessage = useFormatMessage(BASE_I18N_EXPORT_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)

  const {
    inProgress,
    domainConfig,
    infectedCount,
    suspiciousCount,
    exportConfig,
    searchConfig,
    filterConfig,
    tableConfig,
    handleRowClick,
    reportConfig,
    helpConfig
  } = useAtpLogLogic()

  return useMemo(() => {
    const CardCount = ({
      value,
      title,
      subtitle,
      color
    }: {
      value: number
      title: string
      subtitle: string
      color: string
    }) => (
      <Grid className={classes.countWrapper} item data-testid={`${title}-card`}>
        <Typography className={`${classes.count} ${color}`} align="center" data-testid={`${title}-value`}>
          {value}
        </Typography>
        <Grid>
          <Typography variant="subtitle1" data-testid={`${title}-title`}>
            {formatMessage(title)}
          </Typography>
          <Typography className={classes.countSubtitle} variant="body2" data-testid={`${title}-subtitle`}>
            {formatMessage(subtitle)}
          </Typography>
        </Grid>
      </Grid>
    )

    const FilterButtons = ({
      values,
      selected,
      title,
      onChange
    }: {
      values: string[] | number[]
      selected: string | number
      title: string
      onChange: (event: React.MouseEvent<HTMLButtonElement>) => void
    }) => (
      <Grid data-testid={`${title}-filter`}>
        <Typography className={classes.filterLabel} variant="body2" align="center">
          {formatMessage(title)}
        </Typography>
        {values.map(value => (
          <Button
            size="small"
            variant="outlined"
            name={title}
            key={value}
            className={`${classes.statusButton} ${value === selected ? classes.buttonSelected : ''} ${title}`}
            onClick={onChange}
            value={value}
            data-testid={`${title}-filter-action`}
          >
            {formatMessage(`filters.${value}`)}
          </Button>
        ))}
      </Grid>
    )
    return (
      <>
        <CSVLink
          ref={exportConfig.exportRef}
          filename={exportConfig.fileName()}
          headers={exportConfig.headers.map(header => ({ ...header, label: formatExportMessage(header.label) }))}
          data={tableConfig.tableData.data}
          data-testid="export-link"
        />

        {helpConfig.isOpen && (
          <Help
            modalComponentInfo={{
              content: AtpLogSupportPage,
              onClose: helpConfig.onCloseHelp,
              title: TOPICS.atpLog,
              groupTitle: GROUP_TITLE.messageLog
            }}
          />
        )}

        <Grid>
          <Grid item xs={12}>
            <Card elevation={1}>
              <CardHeader
                className={classes.headerWrapper}
                title={
                  <Grid className={classes.header}>
                    <Grid className={classes.headerTitle}>
                      <Typography variant="h2" data-testid="title">
                        {formatMessage('title')}
                      </Typography>
                      {/* TODO: Support page */}
                      <HelpOutline className={classes.helpIcon} onClick={helpConfig.onHelpClick} />
                    </Grid>
                    <SelectWrapper className={classes.domainSelectWrapper} size="small">
                      <Select
                        className={classes.selectInput}
                        onChange={domainConfig.onSelect}
                        value={domainConfig.selectedItem}
                        data-testid="select-domain-menu"
                      >
                        {domainConfig.items.map(({ value, label }) => (
                          <SelectMenuItem key={label} value={value} data-testid={`${value}-domain-action`}>
                            {label === 'all_domains' ? formatMessage(label) : label}
                          </SelectMenuItem>
                        ))}
                      </Select>
                    </SelectWrapper>
                  </Grid>
                }
                data-testid="card-header"
              />
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <Grid className={classes.cardsRow}>
              <CardCount
                value={infectedCount}
                title="infected_attachments"
                subtitle="last_30_days"
                color="red"
                data-testid="infected-card"
              />
              <CardCount
                value={suspiciousCount}
                title="suspicious_attachments"
                subtitle="last_30_days"
                color="orange"
                data-testid="suspicious-card"
              />
            </Grid>
            <Grid className={classes.filterRow}>
              <FilterButtons
                values={filterConfig.statusFilters}
                selected={filterConfig.selectedStatusFilter}
                onChange={e => filterConfig.handleFilterChange('status', e.currentTarget.value)}
                title="status"
                data-testid="status-filter"
              />
              <FilterButtons
                values={filterConfig.timeFilters}
                selected={filterConfig.selectedTimeFilter}
                onChange={e => filterConfig.handleFilterChange('time', e.currentTarget.value)}
                title="time"
                data-testid="time-filter"
              />
              <SelectWrapper className={classes.fileTypeInputWrapper} size="small">
                <Typography className={classes.filterLabel} variant="body2" align="center">
                  {formatMessage('file_type')}
                </Typography>
                <Select
                  className={classes.selectInput}
                  onChange={e => filterConfig.handleFilterChange('filetype', e.currentTarget.value)}
                  value={filterConfig.selectedFiletype}
                  data-testid="select-filetype-menu"
                >
                  {filterConfig.filetypes.map(type => (
                    <SelectMenuItem key={type} value={type}>
                      {formatMessage(`file_types.${type}`)}
                    </SelectMenuItem>
                  ))}
                </Select>
              </SelectWrapper>
              <Button
                size="small"
                variant="outlined"
                className={classes.exportButton}
                onClick={exportConfig.csvExport}
                data-testid="export-button"
              >
                {formatMessage('export_button')}
              </Button>
            </Grid>
            <Grid>
              <TextField
                className={classes.searchTextField}
                placeholder={formatMessage('search_placeholder')}
                variant="outlined"
                fullWidth
                size="small"
                value={searchConfig.search}
                onChange={searchConfig.handleInputChange}
                onKeyDown={onKeyDown(['Enter'], () => searchConfig.handleSearch())}
                InputProps={{
                  startAdornment: (
                    <IconButton size="small" edge="start" data-id="search-button" data-testid="search-button">
                      <Search />
                    </IconButton>
                  )
                }}
                data-testid="search-input"
              />
            </Grid>
            <Grid>
              <Box className={classes.logTableProgress}>
                {inProgress && <LinearProgress data-testid="linear-loader" />}
              </Box>
              <DataTable data={tableConfig.tableData} selectedField="selected" resizable>
                <DataTableColumn
                  field={tableConfig.columns.STATUS}
                  {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                  title={tableFormatMessage(`${tableConfig.columns.STATUS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="status-cell" onClick={() => handleRowClick(dataItem)}>
                      {dataItem.isFuture && (
                        <Grid className={classes.statusWrapper}>
                          <CircularProgress
                            style={{ height: PROGRESS_HEIGHT, width: PROGRESS_WIDTH }}
                            className={classes.progressIcon}
                            color="secondary"
                          />
                          <Typography className={classes.statusText} variant="body2">
                            {dataItem.statusText}
                          </Typography>
                        </Grid>
                      )}
                      {!dataItem.isFuture && (
                        <Grid className={classes.statusWrapper}>
                          {dataItem.statusIcon && (
                            <dataItem.statusIcon className={`${classes.statusIcon} ${dataItem.statusText}`} />
                          )}
                          {!dataItem.statusIcon && <Grid className={classes.statusIcon} />}
                          <Typography className={classes.statusText} variant="body2">
                            {dataItem.statusText}
                          </Typography>
                        </Grid>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.TIME}
                  {...tableConfig.columnsConfig[tableConfig.columns.TIME]}
                  title={tableFormatMessage(`${tableConfig.columns.TIME}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="time-cell" onClick={() => handleRowClick(dataItem)}>
                      <Typography variant="body2">{dataItem.formattedDate}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.FROM}
                  {...tableConfig.columnsConfig[tableConfig.columns.FROM]}
                  title={tableFormatMessage(`${tableConfig.columns.FROM}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="from-cell" onClick={() => handleRowClick(dataItem)}>
                      <Typography variant="body2">{dataItem.headerFrom}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.TO}
                  {...tableConfig.columnsConfig[tableConfig.columns.TO]}
                  title={tableFormatMessage(`${tableConfig.columns.TO}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="to-cell" onClick={() => handleRowClick(dataItem)}>
                      <Typography variant="body2">{dataItem.headerTo}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.SUBJECT}
                  {...tableConfig.columnsConfig[tableConfig.columns.SUBJECT]}
                  title={tableFormatMessage(`${tableConfig.columns.SUBJECT}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="subject-cell" onClick={() => handleRowClick(dataItem)}>
                      <Typography variant="body2">{dataItem.headerSubject}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.FILE_NAME}
                  {...tableConfig.columnsConfig[tableConfig.columns.FILE_NAME]}
                  title={tableFormatMessage(`${tableConfig.columns.FILE_NAME}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="file-name-cell" onClick={() => handleRowClick(dataItem)}>
                      <Typography variant="body2">{dataItem.filename}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.FILE_TYPE}
                  {...tableConfig.columnsConfig[tableConfig.columns.FILE_TYPE]}
                  title={tableFormatMessage(`${tableConfig.columns.FILE_TYPE}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="file-type-cell" onClick={() => handleRowClick(dataItem)}>
                      <Typography variant="body2">{dataItem.fileType}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.REPORT}
                  {...tableConfig.columnsConfig[tableConfig.columns.REPORT]}
                  title={tableFormatMessage(`${tableConfig.columns.REPORT}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="report-cell">
                      <Button
                        size="small"
                        variant="text"
                        color="primary"
                        disabled={reportConfig.isLoading}
                        onClick={() => reportConfig.onViewReport(dataItem)}
                        data-testid="report-button"
                      >
                        <Typography variant="body2">{tableFormatMessage('view_report')}</Typography>
                      </Button>
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }, [
    classes,
    formatMessage,
    formatExportMessage,
    tableFormatMessage,
    domainConfig,
    infectedCount,
    suspiciousCount,
    exportConfig,
    searchConfig,
    filterConfig,
    tableConfig,
    handleRowClick,
    reportConfig,
    inProgress,
    helpConfig
  ])
}

export default AtpLog
