import React, { useCallback, useMemo, useState } from 'react'
import { Alert, Link } from '@cuda-networks/bds-core'

import appConfig from 'config/appConfig'
import { useFormatMessage } from 'lib/localization'
import { getExpBannerCookie, setExpBannerCookie } from 'lib/cookies'

import styles from 'components/libs/banner/interfaceExpBannerStyles'

const BASE_I18N_KEY = 'ess'

const InterfaceExpBanner = () => {
  const classes = styles()
  const isExpBannerHidden = getExpBannerCookie()
  const [open, setOpen] = useState(!isExpBannerHidden)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const onCloseBanner = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setExpBannerCookie(true)
    setOpen(false)
  }, [])

  return useMemo(
    () => (
      <div>
        {open && (
          <Alert
            className={classes.alert}
            severity="info"
            data-testid="react_experience_banner"
            onClose={onCloseBanner}
          >
            {formatMessage('react_experience_banner.end_user')}
            <Link className={classes.interfaceBannerLink} href={appConfig.LINKS.REACT_INTERFACE_URL}>
              <b>{formatMessage('react_experience_banner.detail_link')}</b>
            </Link>
          </Alert>
        )}
      </div>
    ),
    [classes, formatMessage, onCloseBanner, open]
  )
}

export default InterfaceExpBanner
