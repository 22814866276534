import { useCallback, useEffect, useMemo } from 'react'
import { saveAs } from '@progress/kendo-file-saver'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { downloadAttachment, resetDownloadAttachment } from 'redux/features/mstore/mstoreSlice'

import IconAlertSuccess from 'assets/images/icons/icon_alert_success.svg'
import IconAlertInfo from 'assets/images/icons/icon_alert_info.svg'
import IconAlertWarning from 'assets/images/icons/icon_alert_warning.svg'
import {
  RecipientTable,
  useMessage
} from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentHeader/useMessage'
import { Attachment, Message } from 'types/Messages'
import appConfig from 'config/appConfig'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

export interface State {
  message: Message | undefined
  isGetMessageSuccess: boolean
  isDownloadAttachmentPending: boolean
  reason: string
  determinationColor: string
  determinationIcon: typeof IconAlertSuccess | typeof IconAlertInfo | typeof IconAlertWarning
  subject: string
  from: string
  to: string
  date: string
  id: string
  ip: string
  recipientTable: RecipientTable
  cantViewBlocked: boolean
  cantViewQuarantined: boolean
  isDownloadAttachmentEnabled: boolean | undefined
  forensicsLink: string | undefined
}

export interface EventHandlers {
  onDownloadAttachment: (attachment: Attachment) => void
}

export type MessageDetailsContentHeaderLogic = [State, EventHandlers]

export interface MessageDetailsContentHeaderConfig {
  text: (id: string) => string
}

export const useMessageDetailsContentHeaderLogic = ({
  text
}: MessageDetailsContentHeaderConfig): MessageDetailsContentHeaderLogic => {
  const dispatch = useAppDispatch()
  const {
    isDownloadAttachmentPending,
    isDownloadAttachmentSuccess,
    downloadedAttachment,
    message,
    isGetMessageSuccess,
    hasForensicsAccess,
    bccAccountId,
    userId
  } = useAppSelector(_store => ({
    isDownloadAttachmentPending: isPending(_store.mstore.downloadAttachmentApiStatus),
    isDownloadAttachmentSuccess: isSuccess(_store.mstore.downloadAttachmentApiStatus),
    downloadedAttachment: _store.mstore.downloadedAttachment,
    message: _store.mstore.message,
    isGetMessageSuccess: isSuccess(_store.mstore.getMessageApiStatus),
    hasForensicsAccess: !!_store.settings.accountPermissions?.hasForensicsAccess,
    bccAccountId: _store.auth.accessTokenObject?.bccAccountId,
    userId: _store.auth.accessTokenObject?.userId
  }))
  const [
    {
      reason,
      determinationColor,
      determinationIcon,
      subject,
      from,
      to,
      date,
      id,
      ip,
      recipientTable,
      cantViewBlocked,
      cantViewQuarantined,
      isDownloadAttachmentEnabled
    }
  ] = useMessage({
    message,
    text
  })
  const { userHasRight } = useUserRights()

  const forensicsLink = useMemo(() => {
    if (!hasForensicsAccess || !message || !bccAccountId) {
      return undefined
    }
    const searchParams = new URLSearchParams({
      email: message.fields.headerFrom || message.fields.envelopeFrom,
      subject: message.fields.subject,
      bccId: bccAccountId
    })
    return `https://${appConfig.LINKS.FORENSICS_HOSTNAME}/new-incident?${searchParams.toString()}`
  }, [bccAccountId, hasForensicsAccess, message])

  const onDownloadAttachment = useCallback(
    (attachment: Attachment) => {
      if (!isDownloadAttachmentEnabled || !message) {
        return
      }
      dispatch(
        downloadAttachment({
          messageId: message.mid,
          attachmentId: attachment.id,
          domainId: message.did,
          userId: userHasRight(UserRights.SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS) ? undefined : userId
        })
      )
    },
    [dispatch, isDownloadAttachmentEnabled, message, userHasRight, userId]
  )

  useEffect(() => {
    if (!isDownloadAttachmentSuccess || !downloadedAttachment) {
      return
    }
    const url = window.URL.createObjectURL(
      new Blob([Buffer.from(downloadedAttachment.attachment.content, 'base64')], {
        type: downloadedAttachment.attachment.contentType
      })
    )
    saveAs(url, downloadedAttachment.attachment.filename)
    dispatch(resetDownloadAttachment())
  }, [dispatch, downloadedAttachment, isDownloadAttachmentSuccess])

  return useMemo(
    () => [
      {
        message,
        isGetMessageSuccess,
        isDownloadAttachmentPending,
        reason,
        determinationColor,
        determinationIcon,
        subject,
        from,
        to,
        date,
        id,
        ip,
        recipientTable,
        cantViewBlocked,
        cantViewQuarantined,
        isDownloadAttachmentEnabled,
        forensicsLink
      },
      {
        onDownloadAttachment
      }
    ],
    [
      message,
      isGetMessageSuccess,
      isDownloadAttachmentPending,
      reason,
      determinationColor,
      determinationIcon,
      subject,
      from,
      to,
      date,
      id,
      ip,
      recipientTable,
      cantViewBlocked,
      cantViewQuarantined,
      isDownloadAttachmentEnabled,
      forensicsLink,
      onDownloadAttachment
    ]
  )
}
