import { makeStyles, colors } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  fullSizeContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  container: {
    height: '100%'
  },
  icon: {
    width: 30,
    height: 30,
    color: colors.scaleGray600,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(1.5)
  },
  text: {
    color: colors.scaleGray900,
    lineHeight: '120%'
  }
}))
