import { makeStyles, colors } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  headerWrapper: {
    padding: theme.spacing(1, 2)
  },
  cardHeader: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    display: 'flex',
    alignItem: 'center'
  },
  helpIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    width: theme.spacing(2.5),
    marginTop: theme.spacing(0.25),
    color: colors.blue600
  },
  cardsRow: {
    maxHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0)
  },
  container: {
    padding: theme.spacing(0, 2)
  },
  dateRange: {
    display: 'flex',
    alignItems: 'center'
  },
  filterChips: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  chip: {
    margin: 0
  },
  filter: {
    marginTop: theme.spacing(1),
    display: 'flex-end',
    alignItems: 'center'
  },
  filterRow: {
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    '& > div': {
      marginRight: theme.spacing(3)
    }
  },
  filterLabel: {
    marginBottom: theme.spacing(0.65)
  },
  statusButton: {
    fontSize: 12,
    borderRadius: 0,
    '&.status': {
      width: '92px'
    },
    '&.time': {
      width: '118px'
    }
  },
  buttonSelected: {
    background: colors.barracudaBlue,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.barracudaBlue
    }
  },
  fileTypeInputWrapper: {
    width: '142px'
  },
  selectInput: {
    borderRadius: 0,
    '& > div:first-child': {
      padding: theme.spacing(0.65, 1),
      fontSize: 12,
      backgroundColor: colors.white
    }
  },
  exportButton: {
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    fontSize: 12
  },
  searchText: {
    display: 'flex',
    flexGrow: 1
  },
  searchTextField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white
  },
  logTableProgress: {
    height: '3px'
  },
  datePickerWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
    whiteSpace: 'nowrap',
    '& > :first-child': {
      marginRight: 16
    }
  },
  datePicker: {
    backgroundColor: theme.palette.common.white,
    width: 130,
    padding: theme.spacing(0, 1),
    '& .k-picker-wrap.k-state-default': {
      borderStyle: 'none !important'
    },
    '& .k-picker-wrap input': {
      fontSize: '14px'
    }
  }
}))
