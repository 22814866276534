import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Loading from 'components/libs/loading/Loading'
import { FEATURES, initSplitio, isMyFeatureOn } from 'lib/splitio'
import { useAppSelector } from 'redux/toolkit/hooks'
import { gotoLegacyLogin } from 'lib/routesConfig'
import appConfig from 'config/appConfig'
import appFeatures from 'config/appFeatures'

export default function validateSplitioKeys<P>(WrappedComponent: React.ComponentType<P>) {
  const ValidateSplitioKeys: React.FC<any> = props => {
    const { accountId, isSplitioInitialized, isSplitioError } = useAppSelector(_store => ({
      accountId: _store.auth.accessTokenObject?.accountId,
      isSplitioInitialized: Object.keys(_store.splitio.treatments).length === Object.keys(FEATURES).length,
      isSplitioError: _store.splitio.isTimedout
    }))
    const [shouldRenderTheApp, setShouldRenderTheApp] = useState<boolean>(false)

    useMemo(() => {
      if (accountId) {
        initSplitio({ accountId })
      }
    }, [accountId])

    // admin related splitio checks
    const adminValidations = useCallback(() => {
      if (appFeatures.EnabledNewAdminApp || isMyFeatureOn(FEATURES.EGD_React_Overview_Rollout)) {
        setShouldRenderTheApp(true)
        return
      }

      gotoLegacyLogin()
    }, [])

    // enduser related splitio checks
    const enduserValidations = useCallback(() => {
      // temporary disable the enduser check
      // TODO: remove when TDF supports only the splitio rollout
      setShouldRenderTheApp(true)
      return

      const isRolledOutEnduser = isMyFeatureOn(FEATURES.EGD_React_End_User_Rollout)

      if (isRolledOutEnduser) {
        setShouldRenderTheApp(true)
      } else {
        gotoLegacyLogin()
      }
    }, [])

    useEffect(() => {
      if (isSplitioInitialized) {
        // disable the splitio validations in GT3
        if (appConfig.ENVIRONMENT.IS_GT3) {
          setShouldRenderTheApp(true)
        } else if (appConfig.APP.IS_ADMIN) {
          adminValidations()
        } else if (appConfig.APP.IS_ENDUSER) {
          enduserValidations()
          // every other cases keep the app alive
        } else {
          setShouldRenderTheApp(true)
        }
      }
    }, [isSplitioInitialized, adminValidations, enduserValidations])

    // send back the user to TDF if the splitio service has issues
    useMemo(() => {
      if (isSplitioError) {
        gotoLegacyLogin()
      }
    }, [isSplitioError])

    return useMemo(() => {
      if (!shouldRenderTheApp) {
        return <Loading />
      }

      return <WrappedComponent {...(props as any)} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRenderTheApp])
  }

  return ValidateSplitioKeys
}
