import { cloneElement, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { process } from '@progress/kendo-data-query'
import { GridColumnResizeEvent, GridRowProps } from '@progress/kendo-react-grid'

import { encryptionInfo, mapAction, mapDeliveryColor, mapDeliveryInfo, mapFrom, mapReasons, mapSize } from 'lib/mstore'
import { formatDate, hasSameDay } from 'lib/datetime'
import { useFormatMessage } from 'lib/localization'
import { isLargeMessage } from 'lib/email'
import { ModifiedReportMessage, ReportMessage } from 'types/Messages'
import { ColumnsConfig } from 'types/redux/dataTables/dataTables'
import config from 'config/appConfig'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import {
  checkSearchMessage,
  getMessage,
  MlogViewConfig,
  selectSearchMessage,
  setShowImages
} from 'redux/features/mstore/mstoreSlice'
import { reset as resetMessagesTable } from 'redux/features/dataTables/messages/messagesSlice'
import { LocalStorageKeys } from 'lib/types/localStorageTypes'
import routesConfig from 'lib/routesConfig'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

export interface UseMessageLogListTableLogic {
  tableData: {
    total: number
    data: ModifiedReportMessage[]
  }
  columns: { [key: string]: string }
  columnsConfig: ColumnsConfig
  fromColumnMenu: {
    display: string
    handleFromColumnMenu: (value: string) => void
    menuItems: { [key: string]: { label: string } }
  }
  handleRowClick: (event: any, message: ModifiedReportMessage) => void
  handleCheck: (message: ModifiedReportMessage) => void
  handleColumnResize: (e: GridColumnResizeEvent) => void
  checkedMessages: ReportMessage[]
  rowRender: (row: ReactElement<HTMLTableRowElement>, props: GridRowProps) => ReactElement<HTMLTableRowElement>
  loadedAndNoRecord: boolean
  viewConfig: MlogViewConfig
}

const BASE_I18N_KEY = 'ess.message_log'

export const useMessageLogListTableLogic = (): UseMessageLogListTableLogic => {
  const dispatch = useAppDispatch()
  const {
    messagesTable,
    mstoreSearch,
    checkedMessages,
    selectedMessageMid,
    isMessageHistoryOpen,
    isGetSearchLoading,
    splitterOrientation,
    isGetSearchSuccess,
    userId,
    viewConfig
  } = useAppSelector(_store => ({
    messagesTable: _store.dataTables.messages,
    mstoreSearch: _store.mstore.search,
    checkedMessages: _store.mstore.checkedMessages,
    selectedMessageMid: _store.mstore.selectedMessageMid,
    isMessageHistoryOpen: _store.mstore.isMessageHistoryOpen,
    isGetSearchLoading: isPending(_store.mstore.getSearchApiStatus),
    splitterOrientation: _store.app.splitter.orientation,
    isGetSearchSuccess: isSuccess(_store.mstore.getSearchApiStatus),
    userId: _store.auth.accessTokenObject?.userId,
    viewConfig: _store.mstore.viewConfig
  }))
  const { userHasRight } = useUserRights()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [fromColumnDisplay, setFromColumnDisplay] = useState(
    localStorage.getItem(LocalStorageKeys.essFromColumnDisplay) || 'both'
  )
  const [historyMid, setHistoryMid] = useState<string>('')

  // unmount
  useEffect(
    () => () => {
      dispatch(resetMessagesTable())
    },
    [dispatch]
  )

  const menuItems = useMemo(
    () => ({
      name: { label: formatMessage('from_column_menu.show_name') },
      email: { label: formatMessage('from_column_menu.show_email') },
      both: { label: formatMessage('from_column_menu.show_both') }
    }),
    [formatMessage]
  )

  const handleFromColumnMenu = useCallback((value: string) => {
    setFromColumnDisplay(value)
    localStorage.setItem(LocalStorageKeys.essFromColumnDisplay, value)
  }, [])

  const tableData = useMemo(() => {
    const { take } = messagesTable

    const { data } = process(
      (mstoreSearch?.messages || []).map((report: ReportMessage) => ({
        ...(report && {
          ...report,
          from: mapFrom(report, fromColumnDisplay),
          action: report.remediated ? mapAction('remediated') : mapAction(report.status),
          actionColor: mapDeliveryColor(report.status, report.remediated),
          isChecked: report.isChecked || false,
          selected: selectedMessageMid === report.mid,
          exportDate: formatDate(new Date(report.date), config.DATETIME.EXPORT_DATE_FORMAT),
          formattedDate: formatDate(
            new Date(report.date),
            hasSameDay(report.date) ? config.DATETIME.TIME_FORMAT : config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT
          ),
          formattedSize: mapSize(report.size),
          encryption: encryptionInfo(report),
          reason: mapReasons(report.reasons, formatMessage),
          score: report.score || '',
          statusMapped: mapDeliveryInfo(report.deliveryStatus, report.status)
        })
      })),
      { skip: 0, take }
    )

    return {
      data,
      total: mstoreSearch?.messageCount || 0
    }
  }, [messagesTable, mstoreSearch, formatMessage, fromColumnDisplay, selectedMessageMid])

  const handleRowClick = useCallback(
    (event: MouseEvent, message: ModifiedReportMessage) => {
      if (event.detail === 1) {
        dispatch(selectSearchMessage(message.mid))
        dispatch(setShowImages(false))
        dispatch(
          getMessage({
            headersOnly: isLargeMessage(message.size) ? 1 : 0,
            messageId: message.mid,
            showImages: 0,
            domainId: message.did,
            userId: userHasRight(UserRights.SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS) ? undefined : userId
          })
        )
        if (splitterOrientation !== 'none') {
          if (!isMessageHistoryOpen) {
            setHistoryMid(message.mid)
          }
        }
      }
      if (event.detail === 2) {
        const remediated = message.remediated ? 'true' : 'false'
        const search = `?remediated=${remediated}`
        const path = `${routesConfig.MESSAGE_DETAIL.url({ messageId: message.mid, domainId: message.did })}${search}`
        window.open(`${window.location.origin}${path}`, '_blank')
      }
    },
    [dispatch, isMessageHistoryOpen, splitterOrientation, userHasRight, userId]
  )

  const handleCheck = useCallback(
    (message: ModifiedReportMessage) => {
      // eslint-disable-next-line no-param-reassign
      message.isChecked = !message.isChecked
      dispatch(checkSearchMessage(message))
    },
    [dispatch]
  )

  const handleColumnResize = useCallback(
    (e: GridColumnResizeEvent) => {
      const widths: { [key: string]: number } = {}
      Object.values(messagesTable.GRID_COLUMNS).forEach((key: string, i) => {
        widths[key] = e.columns[i].width as number
      })

      localStorage.setItem(LocalStorageKeys.columnWidths, JSON.stringify(widths))
    },
    [messagesTable.GRID_COLUMNS]
  )

  const rowRender = useCallback(
    (row: ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
      const historyRow = !isGetSearchLoading && isMessageHistoryOpen && historyMid === props.dataItem.mid
      return cloneElement(
        row,
        { className: `${row.props.className} ${historyRow ? 'history-row' : ''}` },
        row.props.children as any
      )
    },
    [isMessageHistoryOpen, isGetSearchLoading, historyMid]
  )

  return useMemo(
    () => ({
      tableData,
      columns: messagesTable.GRID_COLUMNS,
      columnsConfig: messagesTable.columnsConfig,
      fromColumnMenu: {
        display: fromColumnDisplay,
        menuItems,
        handleFromColumnMenu
      },
      handleRowClick,
      handleCheck,
      handleColumnResize,
      checkedMessages,
      rowRender,
      loadedAndNoRecord: isGetSearchSuccess && !tableData.data.length,
      viewConfig
    }),
    [
      tableData,
      messagesTable,
      fromColumnDisplay,
      menuItems,
      handleFromColumnMenu,
      handleRowClick,
      handleCheck,
      handleColumnResize,
      checkedMessages,
      rowRender,
      isGetSearchSuccess,
      viewConfig
    ]
  )
}
