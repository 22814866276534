import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { EmailServerResult, EmailServerSettings, MxRecords, OldMxRecords, Provider } from 'types/emailServer'
import { RootState } from 'redux/toolkit/store'

export type DetectEmailServerPayload = string
export type GetProviderPayload = string
export type GetMxRecordsPayload = string
export type GetOldMxRecordsPayload = string

export interface VerifyEmailServerPayload {
  email: string
  server: string
  port: number
}

export type SetEmailServerSettingsPayload = boolean
export type SetRegionPayload = string

export const detectEmailServer = createAsyncThunk<EmailServerResult, DetectEmailServerPayload, ApiRejectResponse>(
  'EMAIL_SERVER/detect',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DETECT_EMAIL_SERVER, {
        params: { email: payload }
      })

      return resp.data?.results?.[0]
    } catch (e) {
      // not found
      if (e?.status === 404) {
        return { server: '', port: '' }
      }

      // server error
      dispatch(
        setErrorSnackBar({
          message: 'detect_email_server_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const verifyEmailServer = createAsyncThunk<EmailServerResult, VerifyEmailServerPayload, ApiRejectResponse>(
  'EMAIL_SERVER/verify',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.VERIFY_EMAIL_SERVER, {
        params: { email: payload.email, server: payload.server, port: payload.port }
      })

      return resp.data?.results?.[0]?.[0]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getProvider = createAsyncThunk<Provider[], GetProviderPayload, ApiRejectResponse>(
  'EMAIL_SERVER/getProvider',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_PROVIDER, {
        params: { domain: payload }
      })

      return resp.data?.results
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getMxRecords = createAsyncThunk<MxRecords, GetMxRecordsPayload, ApiRejectResponse>(
  'EMAIL_SERVER/getMxRecords',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_MX_RECORDS, {
        params: { domain: payload }
      })

      return resp.data?.results?.[0]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getOldMxRecords = createAsyncThunk<OldMxRecords, GetOldMxRecordsPayload, ApiRejectResponse>(
  'EMAIL_SERVER/getOldMxRecords',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_OLD_MX_RECORDS, {
        params: { domain: payload }
      })

      return resp.data?.results?.[0]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getEmailServerSettings = createAsyncThunk<EmailServerSettings, undefined, ApiRejectResponse>(
  'EMAIL_SERVER/getEmailServerSettings',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_EMAIL_SERVER_SETTINGS, {})

      return resp.data?.results?.[0]?.settings
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const setEmailServerSettings = createAsyncThunk<
  EmailServerSettings,
  SetEmailServerSettingsPayload,
  ApiRejectResponse
>('EMAIL_SERVER/setEmailServerSettings', async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const emailServerStore = (getState() as RootState).emailServer
    const requestBody: Omit<EmailServerSettings, 'region' | 'url' | 'migrateToken'> = {
      email: emailServerStore.emailAddress,
      primaryMx: emailServerStore.mxRecords?.primary || null,
      backupMx: emailServerStore.mxRecords?.backup || null,
      emailServers: emailServerStore.updatedEmailServer
        ? [
            {
              ...emailServerStore.updatedEmailServer,
              port: Number(emailServerStore.updatedEmailServer.port),
              verified: !!emailServerStore.emailServer?.verified,
              domainId: emailServerStore.emailServer?.domainId,
              domainName: emailServerStore.emailServer?.domainName
            }
          ]
        : null,
      provider: emailServerStore.provider || null,
      oldMxRecords: emailServerStore.oldMxRecords || null,
      wizardCompleted: payload
    }

    const resp = await restClient(apiRoutes.SET_EMAIL_SERVER_SETTINGS, { data: requestBody })

    return resp.data
  } catch (e) {
    dispatch(setErrorSnackBar({ message: 'wizard_save_error' }))

    return rejectWithValue(validateApiError(e))
  }
})

export const setRegion = createAsyncThunk<EmailServerSettings, SetRegionPayload, ApiRejectResponse>(
  'EMAIL_SERVER/setRegion',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.SET_REGION, { data: { region: payload } })

      return resp.data?.results?.[0]?.settings
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'wizard_save_error' }))

      return rejectWithValue(validateApiError(e))
    }
  }
)
