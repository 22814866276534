import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import config from 'config/appConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isPending } from 'redux/toolkit/api'
import { now } from 'lib/datetime'
import routesConfig from 'lib/routesConfig'
import { autoLogin, logout } from 'redux/features/auth/authSlice'
import { postAllowSender, postBlockSender, postRedeliverMessage } from 'redux/features/mstore/mstoreSlice'

export interface AutoLoginInfoDialogLogic {
  isVisible: boolean
  message: 'expired_message' | 'invalid_link' | ''
  onClose: () => void
}

interface AutoLoginProgressLogic {
  isVisible: boolean
}

export type AutoLoginLogic = [AutoLoginInfoDialogLogic, AutoLoginProgressLogic]

export const qNKey = `${config.LEGACY_UI_URL}/user/settings/quarantine_notification`

const redirectUrlsMap: { [key: string]: (body: any) => any } = {
  deliver: postRedeliverMessage,
  block: postBlockSender,
  whitelist: postAllowSender
}

export const useAutoLoginLogic = (): AutoLoginLogic => {
  const dispatch = useAppDispatch()
  const loginHasBeenDispatched = useRef(false)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const expiration = params.get('expiration') || undefined
  const redirectUrl = params.get('return_url') || undefined
  const sid = params.get('sid') || undefined
  const hash = params.get('sig') || undefined
  const source = params.get('source') || undefined
  const url = params.get('url') || undefined
  const userId = params.get('user') || undefined
  const { loginError, accessToken, isLoggingIn, enforceManualLogin } = useAppSelector(_store => ({
    loginError: getErrorMessage(_store.auth.loginApiStatus),
    isLoggingIn: isPending(_store.auth.loginApiStatus),
    accessToken: _store.auth.accessToken,
    enforceManualLogin: _store.auth.enforceManualLogin
  }))
  const allParamsValid = !!(userId && hash && expiration)
  const isExpired = !Number.isNaN(Number(expiration)) && now() > Number(expiration) * 1000
  const shouldDispatchLogin = !accessToken && allParamsValid && !isExpired
  const infoDialogMessage = useMemo(() => {
    if (allParamsValid && isExpired) {
      return 'expired_message'
    }
    if (loginError && !enforceManualLogin) {
      return 'invalid_link'
    }
    return ''
  }, [allParamsValid, enforceManualLogin, isExpired, loginError])
  const shouldDisplayInfoDialog = !!infoDialogMessage
  const shouldDisplayProgress = isLoggingIn
  const isQuarantineNotificationAction = !!(redirectUrl && redirectUrl.includes('quarantine_notification'))

  const onCloseInfoDialog = useCallback(() => {
    routesConfig.LOGIN.goto()
  }, [])

  //
  // onMount
  //
  useEffect(() => {
    // TODO: using mixpanel without user
    // trackMixpanelEvent(TRACKING_EVENTS.WEBUI.AUTOLOGIN_ACTION_PAGE_VIEW)
    if (!allParamsValid) {
      dispatch(logout())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  //
  // Dispatch login
  //
  useEffect(() => {
    if (!shouldDispatchLogin || loginHasBeenDispatched.current) {
      return
    }
    loginHasBeenDispatched.current = true
    dispatch(
      autoLogin({
        user_id: userId,
        session_id: sid,
        hash,
        expiration,
        source
      })
    )
  }, [dispatch, expiration, hash, shouldDispatchLogin, sid, source, userId])

  //
  // Handle login success
  //
  useEffect(() => {
    if (!accessToken) {
      return
    }
    if (isQuarantineNotificationAction) {
      const redirectPage = redirectUrl === qNKey ? 'SETTINGS_QUARANTINE_NOTIFICATION' : 'MESSAGE_LOG'
      routesConfig[redirectPage].goto()
      return
    }
    if (url && url.includes('log')) {
      const [, , action, , messageId] = url.split('/')
      dispatch(
        redirectUrlsMap[action]?.([
          {
            messageId
          }
        ])
      )
      routesConfig.MESSAGE_LOG.goto()
      return
    }
    routesConfig.MESSAGE_LOG.goto()
  }, [accessToken, dispatch, isQuarantineNotificationAction, redirectUrl, url])

  //
  // Handle login failure
  //
  useEffect(() => {
    if (!loginError || !enforceManualLogin) {
      return
    }

    if (isQuarantineNotificationAction) {
      routesConfig.LOGIN.gotoWithSearch('?actionType=quarantineNotification')
      return
    }
    if (url && url.includes('log')) {
      const [, , action, , messageId] = url.split('/')

      routesConfig.LOGIN.gotoWithSearch(`?actionType=${action}&messageId=${messageId}`)
      return
    }
    routesConfig.LOGIN.goto()
  }, [enforceManualLogin, isQuarantineNotificationAction, loginError, url])

  return useMemo(
    () => [
      {
        isVisible: shouldDisplayInfoDialog,
        message: infoDialogMessage,
        onClose: onCloseInfoDialog
      },
      { isVisible: shouldDisplayProgress }
    ],
    [infoDialogMessage, onCloseInfoDialog, shouldDisplayInfoDialog, shouldDisplayProgress]
  )
}
