import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import {
  EmailStatistics,
  GeoData,
  LastBlockedAtp,
  StatsPayloadBase,
  StatType,
  TopBlocked,
  TopDomainsActions,
  TopRecipientDomains,
  TotalThreatsAndViruses
} from 'types/stats'
import { Action, Direction } from 'types/Messages'

export type GetGeoDataStatsPayload = StatsPayloadBase
export type GetTopBlockedAtpPayload = StatsPayloadBase

export interface GetTopDomainsPayload extends StatsPayloadBase {
  direction: Direction
  action: TopDomainsActions
  statType: StatType
}

export interface GetInboundEmailStatisticsPayload extends StatsPayloadBase {
  action: Action
}

export type GetOutboundEmailStatisticsPayload = GetInboundEmailStatisticsPayload
export type GetTotalThreatsAndVirusesStatsPayload = StatsPayloadBase

export interface GetTopBlockedPayload extends StatsPayloadBase {
  statType: StatType
}

export const getGeoDataStats = createAsyncThunk<GeoData, GetGeoDataStatsPayload, ApiRejectResponse>(
  'STATS/getGeoDataStats',
  async (payload, { rejectWithValue }) => {
    try {
      const apiEndpointLevel = payload.domainId
        ? apiRoutes.GET_DOMAIN_LEVEL_GEO_DATA
        : apiRoutes.GET_ACCOUNT_LEVEL_GEO_DATA
      const resp = await restClient(apiEndpointLevel, {
        params: { range: payload.range },
        urlParams: { domainId: payload.domainId }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getTopDomainsStats = createAsyncThunk<TopRecipientDomains, GetTopDomainsPayload, ApiRejectResponse>(
  'STATS/getTopDomainsStats',
  async (payload, { rejectWithValue }) => {
    try {
      const apiEndpointLevel = payload.domainId
        ? apiRoutes.GET_DOMAIN_LEVEL_TOP_DOMAINS
        : apiRoutes.GET_ACCOUNT_LEVEL_TOP_DOMAINS
      const resp = await restClient(apiEndpointLevel, {
        params: {
          range: payload.range,
          action: payload.action,
          direction: payload.direction,
          stat_type: payload.statType
        },
        urlParams: { domainId: payload.domainId }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getInboundTopBlockedStats = createAsyncThunk<TopBlocked, GetTopBlockedPayload, ApiRejectResponse>(
  'STATS/getInboundTopBlockedStats',
  async (payload, { rejectWithValue }) => {
    try {
      const apiEndpointLevel = payload.domainId
        ? apiRoutes.GET_DOMAIN_LEVEL_TOP_BLOCKED
        : apiRoutes.GET_ACCOUNT_LEVEL_TOP_BLOCKED
      const resp = await restClient(apiEndpointLevel, {
        params: { range: payload.range, direction: Direction.INBOUND, stat_type: payload.statType },
        urlParams: { domainId: payload.domainId }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getOutboundTopBlockedStats = createAsyncThunk<TopBlocked, GetTopBlockedPayload, ApiRejectResponse>(
  'STATS/getOutboundTopBlockedStats',
  async (payload, { rejectWithValue }) => {
    try {
      const apiEndpointLevel = payload.domainId
        ? apiRoutes.GET_DOMAIN_LEVEL_TOP_BLOCKED
        : apiRoutes.GET_ACCOUNT_LEVEL_TOP_BLOCKED
      const resp = await restClient(apiEndpointLevel, {
        params: { range: payload.range, direction: Direction.OUTBOUND, stat_type: payload.statType },
        urlParams: { domainId: payload.domainId }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getLastBlockedAtpStats = createAsyncThunk<LastBlockedAtp, GetTopBlockedAtpPayload, ApiRejectResponse>(
  'STATS/getLastBlockedAtpStats',
  async (payload, { rejectWithValue }) => {
    try {
      const apiEndpointLevel = payload.domainId
        ? apiRoutes.GET_DOMAIN_LEVEL_LAST_BLOCKED_ATP
        : apiRoutes.GET_ACCOUNT_LEVEL_LAST_BLOCKED_ATP
      const resp = await restClient(apiEndpointLevel, {
        params: { range: payload.range },
        urlParams: { domainId: payload.domainId }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

async function getEmailStatisticsStats(
  payload: GetInboundEmailStatisticsPayload | GetOutboundEmailStatisticsPayload,
  direction: Direction
): Promise<EmailStatistics> {
  const apiEndpointLevel = payload.domainId
    ? apiRoutes.GET_DOMAIN_LEVEL_EMAIL_STATISTICS
    : apiRoutes.GET_ACCOUNT_LEVEL_EMAIL_STATISTICS
  const resp = await restClient(apiEndpointLevel, {
    params: { range: payload.range, direction, action: payload.action },
    urlParams: { domainId: payload.domainId }
  })

  return resp.data
}

export const getInboundEmailStatisticsStats = createAsyncThunk<
  EmailStatistics,
  GetInboundEmailStatisticsPayload,
  ApiRejectResponse
>('STATS/getEmailStatisticsStats', async (payload, { rejectWithValue }) => {
  try {
    return await getEmailStatisticsStats(payload, Direction.INBOUND)
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getOutboundEmailStatisticsStats = createAsyncThunk<
  EmailStatistics,
  GetOutboundEmailStatisticsPayload,
  ApiRejectResponse
>('STATS/getOutboundEmailStatisticsStats', async (payload, { rejectWithValue }) => {
  try {
    return await getEmailStatisticsStats(payload, Direction.OUTBOUND)
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getTotalThreatsAndVirusesStats = createAsyncThunk<
  TotalThreatsAndViruses,
  GetTotalThreatsAndVirusesStatsPayload,
  ApiRejectResponse
>('STATS/getTotalThreatsAndVirusesStats', async (payload, { rejectWithValue }) => {
  try {
    const apiEndpointLevel = payload.domainId
      ? apiRoutes.GET_DOMAIN_LEVEL_TOTAL_THREATS_AND_VIRUSES_STATISTICS
      : apiRoutes.GET_ACCOUNT_LEVEL_TOTAL_THREATS_AND_VIRUSES_STATISTICS
    const resp = await restClient(apiEndpointLevel, {
      params: { range: payload.range },
      urlParams: { domainId: payload.domainId }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
