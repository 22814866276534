import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosPromise } from 'axios'
import { objectToCamel } from 'ts-case-convert'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { setDownloadAttachmentProgress, setDownloadMessageProgress } from 'redux/features/mstore/mstoreSlice'
import {
  AttachmentDetail,
  Message,
  MessageMetadata,
  MessageReportResult,
  MessageSource,
  MessageUpdateReport,
  RedeliverMessageResult,
  Search
} from 'types/Messages'
import { RootState } from 'redux/toolkit/store'
import { generateFilterString } from 'lib/searchForm'
import { MetadataResponse } from 'types/general'

export interface GetMessagePayload {
  headersOnly: number
  showImages: number
  messageId: string
  domainId: string
  userId: string | undefined
}

export interface GetMessageResponse {
  message: Message
  metadata: MetadataResponse
}

export interface GetMessageSourcePayload extends GetMessagePayload {
  parse: number
}

export interface GetAttachmentPayload {
  messageId: string
  attachmentId: string
  domainId: string
  userId: string | undefined
}

export type GetSearchPayload = Search

export interface PostBlockSenderItem {
  emailOrDomain: string
  messageId: string
}

export type PostBlockSenderPayload = PostBlockSenderItem[]

export interface PostRedeliverMessageResponse {
  results: RedeliverMessageResult[]
  metadata: MessageMetadata
}

export interface MessageId {
  messageId: Message['mid']
}

export type PostAllowSenderPayload = MessageId[]
export type PostRedeliverMessagePayload = MessageId[]

export type PostRecategorizePayload = {
  messageIds: string[]
  category: string
  customCategory?: string
}

export interface DeleteMessagePayload {
  mids: string[]
}

export interface DownloadMessagePayload {
  messageId: string
  domainId: string
  userId: string | undefined
}

// TODO: fix API swagger doc for the GET message endpoint
// download feature uses only the message field from the response
export interface DownloadMessageResponse {
  message: string
}

export interface ComposerAttachment {
  id: string
  fileName: string
  binaryContent: ArrayBuffer | null
}

export type PostNewEmailPayload = {
  originalMessage?: Message
  from: string
  to: string
  cc?: string
  subject: string
  body: string
  attachments: ComposerAttachment[]
}

export interface GetRedeliveryQueueMessagesResponse {
  results: RedeliverMessageResult[]
  metadata: MessageMetadata
}

export interface DownloadAttachmentPayload {
  messageId: string
  attachmentId: string
  domainId: string
  userId: string | undefined
}

// TODO: fix API swagger doc for the GET attachment endpoint
// download feature uses only the listed attachment field from the response
export interface DownloadAttachmentResponse {
  attachment: {
    content: string
    contentType: string
    filename: string
  }
}

export const getMessage = createAsyncThunk<GetMessageResponse, GetMessagePayload, ApiRejectResponse>(
  'MSTORE/getMessage',
  async (payload, { rejectWithValue }) => {
    try {
      const { messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_MESSAGE : apiRoutes.DOMAIN_MESSAGE
      const resp = await restClient(apiEndpoint, {
        params: {
          headers_only: payload.headersOnly,
          show_images: payload.showImages
        },
        urlParams: { messageId, domainId, userId }
      })
      // UTF-8 body fix
      const bufferObj = Buffer.from(resp.data.message.body, 'base64')
      resp.data.message.body = bufferObj.toString('utf8')

      return objectToCamel(resp.data) as any
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getMessageSource = createAsyncThunk<MessageSource, GetMessageSourcePayload, ApiRejectResponse>(
  'MSTORE/getMessageSource',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_MESSAGE : apiRoutes.DOMAIN_MESSAGE
      const resp = await restClient(apiEndpoint, {
        params: {
          headers_only: payload.headersOnly,
          parse: payload.parse
        },
        urlParams: { messageId, domainId, userId }
      })

      if (!resp.data) {
        dispatch(
          setErrorSnackBar({
            message: 'get_message_source_failed'
          })
        )

        return rejectWithValue(String(resp.status))
      }

      return resp.data.message
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message:
            e.code === DOMException.QUOTA_EXCEEDED_ERR ? 'local_storage_quota_exceeded' : 'get_message_source_failed'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAttachment = createAsyncThunk<AttachmentDetail, GetAttachmentPayload, ApiRejectResponse>(
  'MSTORE/getAttachment',
  async (payload, { rejectWithValue }) => {
    try {
      const { attachmentId, messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_ATTACHMENT : apiRoutes.DOMAIN_ATTACHMENT
      const resp = await restClient(apiEndpoint, {
        urlParams: {
          messageId,
          attachmentId,
          domainId,
          userId
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getSearch = createAsyncThunk<MessageReportResult, Search | undefined, ApiRejectResponse>(
  'MSTORE/getSearch',
  async (payload, { rejectWithValue, getState }) => {
    const { searchFilter, search } = (getState() as RootState).mstore
    try {
      let params: { domain_id: string | undefined; user_id: string | undefined } & Record<string, unknown>
      let skip = 0
      let isSeparateSearch = false
      if (payload) {
        params = { ...payload }
        isSeparateSearch = true
      } else {
        const query: Search = (getState() as RootState).mstore.searchTerm
        skip = (getState() as RootState).dataTables.messages.skip

        const oldest = skip && search.messages?.[skip - 1].mid
        const filterString = generateFilterString(searchFilter)
        params = { ...query, oldest, search_str: `${query.search_str} ${filterString}` }
      }

      const getEndpoint = (domainId: string | undefined, userId: string | undefined) => {
        if (!domainId && userId) {
          throw new Error('mstore_search_with_user_id_only_supported_for_domains')
        }
        if (!domainId && !userId) {
          return apiRoutes.SEARCH_ACCOUNT_MESSAGES
        }
        if (domainId && userId) {
          return apiRoutes.SEARCH_USER_MESSAGES
        }
        return apiRoutes.SEARCH_DOMAIN_MESSAGES
      }

      const { domain_id: domainId, user_id: userId, ...queryParams } = params
      const resp = await restClient(getEndpoint(domainId, userId), {
        params: queryParams,
        urlParams: {
          userId,
          domainId
        }
      })

      return { ...objectToCamel(resp.data), offset: skip, isSeparateSearch } as any
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postBlockSender = createAsyncThunk<MessageUpdateReport, PostBlockSenderPayload, ApiRejectResponse>(
  'MSTORE/postBlockSender',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.BLOCK_SENDER, {
        data: payload
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_block_sender_success',
          // currently only 1 sender can be blocked at a time, so this works for now
          params: [resp.data.results[0].emailOrDomain]
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_block_sender_failed'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postAllowSender = createAsyncThunk<MessageUpdateReport, PostAllowSenderPayload, ApiRejectResponse>(
  'MSTORE/postAllowSender',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.ALLOW_SENDER, {
        data: payload
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_allow_sender_success',
          params: [payload.length]
        })
      )

      return resp.data
    } catch (e) {
      let errorMessage = 'post_allow_sender_failed'
      const response_message = e?.data?.detail
      if (response_message === 'Action forbidden by admin policy') {
        errorMessage = 'post_allow_sender_admin_forbidden'
      }

      dispatch(
        setErrorSnackBar({
          message: errorMessage
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postRedeliverMessage = createAsyncThunk<
  PostRedeliverMessageResponse,
  PostRedeliverMessagePayload,
  ApiRejectResponse
>('MSTORE/postRedeliverMessage', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const resp = await restClient(apiRoutes.REDELIVER_MESSAGE, {
      data: payload
    })

    dispatch(
      setSuccessSnackBar({
        message: 'post_redeliver_message_success',
        params: [payload.length || 1]
      })
    )

    return resp.data
  } catch (e) {
    let errorMessage = 'post_redeliver_message_failure'
    const response_message = e?.data?.detail
    if (response_message === 'Action forbidden by admin policy') {
      errorMessage = 'post_redeliver_message_admin_forbidden'
    }
    dispatch(
      setErrorSnackBar({
        message: errorMessage
      })
    )

    return rejectWithValue(validateApiError(e))
  }
})

export const getRedeliveryQueueMessages = createAsyncThunk<
  GetRedeliveryQueueMessagesResponse,
  undefined,
  ApiRejectResponse
>('MSTORE/getRedeliveryQueueMessages', async (_, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.REDELIVERY_QUEUE_MESSAGES, {})

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const postRecategorize = createAsyncThunk<MessageUpdateReport, PostRecategorizePayload, ApiRejectResponse>(
  'MSTORE/postRecategorize',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.RECATEGORIZE, {
        data: { ...payload }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_recategorize_success',
          params: [payload.messageIds.length || 1]
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_recategorize_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteMessage = createAsyncThunk<string[], DeleteMessagePayload, ApiRejectResponse>(
  'MSTORE/deleteMessage',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_MESSAGE, {
        data: { ...payload }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'delete_message_success'
        })
      )

      return payload.mids
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'delete_message_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const downloadMessage = createAsyncThunk<DownloadMessageResponse, DownloadMessagePayload, ApiRejectResponse>(
  'MSTORE/downloadMessage',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_MESSAGE : apiRoutes.DOMAIN_MESSAGE
      const response = await restClient(apiEndpoint, {
        params: {
          parse: 0,
          headers_only: 0
        },
        urlParams: { messageId, domainId, userId },
        callbacks: {
          onDownloadProgress: ({ loaded, total }: { loaded: number; total: number }) => {
            dispatch(setDownloadMessageProgress({ loaded, total }))
          }
        }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'download_message_success'
        })
      )

      return response.data.message
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'download_message_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postNewEmail = createAsyncThunk<undefined, PostNewEmailPayload, ApiRejectResponse>(
  'MSTORE/postNewEmail',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData()
      formData.append('from', payload.from)
      formData.append('to', payload.to)
      formData.append('cc', payload.cc || '')
      formData.append('subject', payload.subject)
      formData.append('body', payload.body)

      const fetchAttachments: AxiosPromise[] = []
      payload.attachments.forEach(attachment => {
        if (attachment.binaryContent !== null) {
          const file = new File([attachment.binaryContent], attachment.fileName)
          formData.append('attachments', file, attachment.fileName)
          return
        }
        if (!payload.originalMessage) {
          // This is a new email without an original message reference, can't fetch existing attachments
          return
        }
        fetchAttachments.push(
          restClient(apiRoutes.ATTACHMENT, {
            urlParams: {
              messageId: payload.originalMessage.mid,
              attachmentId: attachment.id
            }
          })
        )
      })
      const fetchedAttachments = await Promise.all(fetchAttachments)
      fetchedAttachments.forEach(({ data: { attachment } }) => {
        if (!attachment) {
          return
        }
        const file = new File([attachment.binaryContent], attachment.fileName)
        formData.append('attachments', file, attachment.fileName)
      })

      const resp = await restClient(apiRoutes.DELIVER_NEW, {
        data: formData as any,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_new_email_success'
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_new_email_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const downloadAttachment = createAsyncThunk<
  DownloadAttachmentResponse,
  DownloadAttachmentPayload,
  ApiRejectResponse
>('MSTORE/downloadAttachment', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { attachmentId, messageId, domainId, userId } = payload
    const apiEndpoint = userId ? apiRoutes.USER_ATTACHMENT : apiRoutes.DOMAIN_ATTACHMENT
    const response = await restClient(apiEndpoint, {
      params: {
        parse: 0,
        headers_only: 0
      },
      urlParams: {
        messageId,
        attachmentId,
        domainId,
        userId
      },
      callbacks: {
        onDownloadProgress: ({ loaded, total }: { loaded: number; total: number }) => {
          dispatch(setDownloadAttachmentProgress({ loaded, total }))
        }
      }
    })

    dispatch(
      setSuccessSnackBar({
        message: 'download_attachment_success'
      })
    )

    return response.data
  } catch (e) {
    dispatch(
      setErrorSnackBar({
        message: 'download_attachment_failure'
      })
    )

    return rejectWithValue(validateApiError(e))
  }
})
