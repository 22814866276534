import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    padding: theme.spacing(2),
    width: 550
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  listHeader: {
    color: theme.palette.secondary.dark,
    fontWeight: 300
  },
  scrollBox: {
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  menuTitle: {
    '&&&': {
      fontWeight: 500,
      fontSize: 20
    }
  }
}))
