import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@cuda-networks/bds-core'

import { useErrorDialogLogic } from 'components/libs/dialog/errorDialog/useErrorDialogLogic'
import styles from 'components/libs/dialog/errorDialog/errorDialogStyles'

const BASE_I18N_KEY = 'ess.dialog'

export interface ErrorDialogProps {
  title?: string
  text: string
  buttonText?: string
  onClose: () => void
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ title, text, buttonText, onClose }) => {
  const intl = useIntl()
  const classes = styles()
  const [dialog] = useErrorDialogLogic({ onClose })

  return useMemo(
    () => (
      <Dialog maxWidth="lg" open={dialog.isDialogOpened} onClose={dialog.onHandleClose}>
        <DialogTitle disableTypography id="form-dialog-title">
          <Typography variant="h6">{title || intl.formatMessage({ id: `${BASE_I18N_KEY}.error_title` })}</Typography>
        </DialogTitle>
        <DialogContent className={classes.errorDialog}>
          <DialogContentText className={classes.errorDialogText}>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={dialog.onHandleClose} color="primary">
            {buttonText || intl.formatMessage({ id: `${BASE_I18N_KEY}.ok` })}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      buttonText,
      classes.errorDialog,
      classes.errorDialogText,
      dialog.isDialogOpened,
      dialog.onHandleClose,
      intl,
      text,
      title
    ]
  )
}

export default ErrorDialog
