import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function SyslogIntegration(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        Syslog Integration enables you to export your message log data to a syslog server or a security information and
        events management (SIEM) system. With Syslog Integration, you can store your information beyond 30 days and use
        it for tracking, analysis, and troubleshooting.
      </p>
      <p>Syslog Integration supports syslog version 3 and is available with Email Protection Advanced Plan.</p>
      To configure Syslog Integration:
      <ol>
        <li>Open any firewall ports needed for communication with your syslog server/SIEM system. </li>
        <li>
          Enter the <b>IP Address/Hostname</b> and <b>Port</b> for your syslog server/SIEM system.{' '}
        </li>
        <li>
          Click <b>Test</b> to ensure that {props.productName} can connect with your syslog server/SIEM system.
        </li>
        <ul>
          <li>If the test works, your message log data will begin transferring to your syslog server/SIEM system.</li>
          <li>
            If the test fails, check the IP Address/Hostname and Port information and reenter it if needed. Then perform
            the test again.{' '}
          </li>
        </ul>
      </ol>
      To delete the syslog server, click <b>Delete</b>.
      <br /> <br />
      <b>Notes:</b>
      <ul>
        <li>
          You can only connect one syslog server/SIEM system at a time. You can delete an existing entry and replace it,
          but you cannot have multiple entries.{' '}
        </li>
        <li>
          This feature is available only for Transmission Control Protocol (TCP) with Transport Layer Security (TLS).
        </li>
        <li>
          If your syslog server/SIEM system stops responding, data will not spool until the communication is
          re-established.{' '}
        </li>
        <li>
          After you enable or disable syslog integration, it can take up to 10 minutes for message transmission to
          either start or stop.
        </li>
        <li>Data is transferred at the account level, not at the domain level.</li>
      </ul>
    </div>
  )
}

export default SyslogIntegration
