import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  alert: {
    margin: theme.spacing(1)
  },
  interfaceBannerLink: {
    margin: theme.spacing(1),
    color: '#000000'
  }
}))
