import { useEffect, useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isPending, isSuccess } from 'redux/toolkit/api'
import { trackMixpanelEvent, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export interface State {
  isGetMessagePending: boolean
  isGetMessageSuccess: boolean
  getMessageError: string | undefined
}

export type MessageDetailsLogic = [State]

export const useMessageDetailsLogic = (): MessageDetailsLogic => {
  const { isGetMessagePending, isGetMessageSuccess, getMessageError } = useAppSelector(_store => ({
    isGetMessagePending: isPending(_store.mstore.getMessageApiStatus),
    isGetMessageSuccess: isSuccess(_store.mstore.getMessageApiStatus),
    getMessageError: getErrorMessage(_store.mstore.getMessageApiStatus)
  }))

  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.MESSAGE_DETAIL_PAGE_VIEW)
  }, [])

  return useMemo(
    () => [{ isGetMessagePending, isGetMessageSuccess, getMessageError }],
    [isGetMessagePending, isGetMessageSuccess, getMessageError]
  )
}
