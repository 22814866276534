import React, { useMemo } from 'react'

import {
  Button,
  Badge,
  Checkbox,
  DataTableToolbar,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  Modal,
  Grid,
  Box
} from '@cuda-networks/bds-core'
import * as CoreIcons from '@cuda-networks/bds-core/dist/Icons/Core'
import * as EmailIcons from '@cuda-networks/bds-core/dist/Icons/Email'
import { Pager } from '@progress/kendo-react-data-tools'

import { CSVLink } from 'react-csv'
import { useFormatMessage } from 'lib/localization'
import { ActionTaken } from 'types/Messages'

import IconExport from 'assets/images/icons/icon_export.svg'
import RedeliveryDialog from 'components/libs/dialog/redeliveryDialog/RedeliveryDialog'
import RecategorizeOtherDialog from 'components/libs/dialog/recategorizeOtherDialog/RecategorizeOtherDialog'
import RedeliveryQueue from 'components/libs/dialog/redeliveryQueue/RedeliveryQueue'
import SplitterMenu from 'components/libs/splitter/SplitterMenu/SplitterMenu'
import EmailComposer from 'components/libs/dialog/emailComposer/EmailComposer'
import { useMessageLogListToolbarLogic } from 'components/pages/messageLog/messageLogList/messageLogListToolbar/useMessageLogListToolbarLogic'
import styles from 'components/pages/messageLog/messageLogList/messageLogListToolbar/messageLogListToolbarStyles'

const BASE_I18N_KEY = 'ess.message_log.toolbar'

const emailType = 'newEmail'
const MessageLogListToolbar = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const {
    isGetSearchLoading,
    disableRefresh,
    badgeCount,
    redeliveryOpen,
    setRedeliveryOpen,
    recategorizeOtherOpen,
    setRecategorizeOtherOpen,
    handleIfCanDeliver,
    anchorEl,
    setAnchorEl,
    handleRecategorizeClick,
    handleSetOpenCategorizeOther,
    eibEnabled,
    setComposerOpen,
    handleOpenRedeliveryQueueModal,
    pageConfig,
    composerOpen,
    redeliveryQueueOpen,
    setRedeliveryQueueOpen,
    onRefresh,
    checkConfig,
    exportConfig,
    checkedMessages,
    searchFilter,
    handleDelete,
    viewConfig,
    handleDirectDeliver,
    handleReject
  } = useMessageLogListToolbarLogic()
  const classes = styles()

  return useMemo(
    () => (
      <>
        <CSVLink
          ref={exportConfig.exportRef}
          filename={exportConfig.fileName()}
          headers={exportConfig.headers}
          data={checkedMessages}
          data-testid="export-link"
        />
        {redeliveryOpen && <RedeliveryDialog onClose={() => setRedeliveryOpen(false)} />}
        {recategorizeOtherOpen && <RecategorizeOtherDialog onClose={() => setRecategorizeOtherOpen(false)} />}
        {redeliveryQueueOpen && <RedeliveryQueue onClose={() => setRedeliveryQueueOpen(false)} />}
        <Box className={classes.toolbarWrapper} data-testid="box-wrapper">
          <DataTableToolbar>
            <Checkbox
              className={classes.toolbarCheckbox}
              onChange={checkConfig.onCheckAll}
              checked={checkConfig.isChecked}
              indeterminate={checkConfig.isIndeterminate}
              data-testid="checkbox"
            />
            <Tooltip disableFocusListener title={formatMessage('refresh')} placement="top">
              <Grid>
                <IconButton
                  className={classes.toolbarIconButton}
                  color="secondary"
                  size="small"
                  onClick={() => onRefresh()}
                  disabled={disableRefresh}
                  data-testid="refresh-button"
                >
                  <CoreIcons.Refresh />
                </IconButton>
              </Grid>
            </Tooltip>
            {(checkConfig.isChecked || checkConfig.isIndeterminate) && (
              <>
                {viewConfig.listToolbar.showOpenRedeliverDialog && (
                  <Tooltip disableFocusListener title={formatMessage('deliver')} placement="top">
                    <IconButton
                      className={classes.toolbarIconButton}
                      color="secondary"
                      size="small"
                      onClick={() => handleIfCanDeliver()}
                      data-testid="deliver-button"
                    >
                      <EmailIcons.Send />
                    </IconButton>
                  </Tooltip>
                )}
                {viewConfig.listToolbar.showDirectDeliver && (
                  <Tooltip disableFocusListener title={formatMessage('deliver')} placement="top">
                    <IconButton
                      className={classes.toolbarIconButton}
                      color="secondary"
                      size="small"
                      onClick={handleDirectDeliver}
                      data-testid="deliver-button"
                    >
                      <EmailIcons.Send />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip disableFocusListener title={formatMessage('export')} placement="top">
                  <IconButton
                    className={classes.toolbarIconButton}
                    color="secondary"
                    size="small"
                    onClick={() => exportConfig.csvExport()}
                    data-testid="export-button"
                  >
                    <img alt={formatMessage('export')} src={IconExport} className={classes.toolbarIcon} />
                  </IconButton>
                </Tooltip>
                {viewConfig.listToolbar.showRecategorize && (
                  <Tooltip disableFocusListener title={formatMessage('categorize')} placement="top">
                    <IconButton
                      className={classes.toolbarIconButton}
                      size="small"
                      onClick={e => setAnchorEl(e.currentTarget)}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      data-testid="categorize-button"
                    >
                      <CoreIcons.LabelOutline />
                    </IconButton>
                  </Tooltip>
                )}
                {viewConfig.listToolbar.showReject && (
                  <Tooltip disableFocusListener title={formatMessage('reject')} placement="top">
                    <IconButton
                      className={classes.toolbarIconButton}
                      color="secondary"
                      size="small"
                      onClick={handleReject}
                      data-testid="reject-button"
                    >
                      <CoreIcons.Block />
                    </IconButton>
                  </Tooltip>
                )}
                {searchFilter.action === ActionTaken.quarantined && (
                  <Tooltip disableFocusListener title={formatMessage('delete')} placement="top">
                    <IconButton
                      className={classes.toolbarIconButton}
                      color="secondary"
                      size="small"
                      onClick={() => handleDelete()}
                      data-testid="delete-button"
                    >
                      <CoreIcons.Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              data-testid="menu"
            >
              <MenuItem onClick={() => handleRecategorizeClick('corporate')} data-testid="corporate-item">
                {formatMessage('corp_email')}
              </MenuItem>
              <MenuItem onClick={() => handleRecategorizeClick('transactional')} data-testid="transactional-item">
                {formatMessage('trans_email')}
              </MenuItem>
              <MenuItem onClick={() => handleRecategorizeClick('marketing')} data-testid="marketing-item">
                {formatMessage('market_materials')}
              </MenuItem>
              <MenuItem onClick={() => handleRecategorizeClick('listserver')} data-testid="listserver-item">
                {formatMessage('mailing_lists')}
              </MenuItem>
              <MenuItem onClick={() => handleRecategorizeClick('socialmedia')} data-testid="socialmedia-item">
                {formatMessage('social_media')}
              </MenuItem>
              <MenuItem onClick={handleSetOpenCategorizeOther} data-testid="other-item">
                {formatMessage('other')}
              </MenuItem>
            </Menu>
            {eibEnabled && (
              <Tooltip disableFocusListener title={formatMessage('new_email')} placement="top">
                <IconButton
                  className={classes.toolbarIconButton}
                  color="secondary"
                  size="small"
                  onClick={() => setComposerOpen(true)}
                  data-testid="composer-button"
                >
                  <EmailIcons.Email />
                </IconButton>
              </Tooltip>
            )}
            {!!badgeCount && (
              <Button
                color="secondary"
                onClick={() => handleOpenRedeliveryQueueModal(true)}
                startIcon={<CoreIcons.Schedule />}
                variant="text"
                data-testid="badge-button"
              >
                <Badge badgeContent={badgeCount} color="primary" overlap="rectangular">
                  {formatMessage('message_queue')}
                </Badge>
              </Button>
            )}
            <Box className={classes.spacer} />
            <Pager
              className={`${classes.pager} ${isGetSearchLoading ? 'disabled' : ''}`}
              {...pageConfig}
              previousNext
              data-testid="pager"
            />
            <Box className={classes.splitterMenu}>
              <SplitterMenu />
            </Box>
          </DataTableToolbar>
        </Box>
        <Modal open={composerOpen} className={classes.modal}>
          <EmailComposer handleClose={() => setComposerOpen(false)} emailType={emailType} />
        </Modal>
      </>
    ),
    [
      classes,
      formatMessage,
      badgeCount,
      redeliveryOpen,
      setRedeliveryOpen,
      recategorizeOtherOpen,
      setRecategorizeOtherOpen,
      handleIfCanDeliver,
      isGetSearchLoading,
      anchorEl,
      setAnchorEl,
      handleRecategorizeClick,
      handleSetOpenCategorizeOther,
      eibEnabled,
      setComposerOpen,
      handleOpenRedeliveryQueueModal,
      pageConfig,
      composerOpen,
      redeliveryQueueOpen,
      setRedeliveryQueueOpen,
      onRefresh,
      checkConfig,
      exportConfig,
      checkedMessages,
      disableRefresh,
      searchFilter,
      handleDelete,
      handleDirectDeliver,
      handleReject,
      viewConfig
    ]
  )
}

export default MessageLogListToolbar
