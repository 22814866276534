import { AppNames } from 'config/appConfig'

const UI_PATHS = {
  ONBOARD_WIZARD: {
    path: 'setup',
    legacyPath: 'wizard',
    appAccess: [AppNames.wizard]
  },
  OVERVIEW: {
    path: 'overview',
    legacyPath: 'overview',
    appAccess: [AppNames.admin]
  },
  OVERVIEW_DASHBOARD: {
    path: 'overview/dashboard',
    legacyPath: 'overview',
    appAccess: [AppNames.admin]
  },
  OVERVIEW_MESSAGE_LOG: {
    path: 'overview/message-log',
    legacyPath: 'overview',
    appAccess: [AppNames.admin]
  },
  OVERVIEW_ATP_LOG: {
    path: 'overview/atp-log',
    legacyPath: 'overview',
    appAccess: [AppNames.admin]
  },
  OVERVIEW_OUTBOUND_QUARANTINE: {
    path: 'overview/outbound-quarantine',
    legacyPath: 'overview',
    appAccess: [AppNames.admin]
  },
  OVERVIEW_AUDIT_LOG: {
    path: 'overview/audit-log',
    legacyPath: 'overview',
    appAccess: [AppNames.admin]
  },
  OVERVIEW_LICENSE_COMPLIANCE: {
    path: 'overview/license',
    legacyPath: 'license',
    appAccess: [AppNames.admin]
  },
  MESSAGE_LOG: {
    path: 'message-log',
    legacyPath: 'log',
    appAccess: [AppNames.enduser]
  },
  MESSAGE_DETAIL: {
    path: 'domains/:domainId/message-detail/:messageId',
    legacyPath: 'message-detail',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  LOGIN: {
    path: 'login',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.enduser, AppNames.wizard]
  },
  BCC_LOGIN: {
    path: 'bcc-login',
    legacyPath: 'bcc-login',
    appAccess: [AppNames.wizard, AppNames.admin]
  },
  RESET_PASSWORD_LOGIN: {
    path: 'login?reset-password=1',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  FORGOT_PASSWORD_LOGIN: {
    path: 'login?forgot-password=:email',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  AUTO_LOGIN: {
    path: 'actions/auto-login',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  AZURE_SSO: {
    path: 'actions/sso/azure',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SUPPORT: {
    path: 'support',
    legacyPath: 'support',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SUPPORT_HELP_SEEN_RECIPIENTS: {
    path: 'support/help/seen-recipients',
    legacyPath: 'support/topic/status_seen_recipients',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_DASHBOARD: {
    path: 'support/help/dashboard',
    legacyPath: 'support/topic/status',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_COMPLIANCE: {
    path: 'support/help/compliance',
    legacyPath: 'support/topic/compliance',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_ADMIN_LOG: {
    path: 'support/help/log',
    legacyPath: 'support/topic/log',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_ATP_LOG: {
    path: 'support/help/atp-log',
    legacyPath: 'support/topic/atp_log',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_AUDIT_LOG: {
    path: 'support/help/audit-log',
    legacyPath: 'support/topic/audit_log',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_DOMAIN_VERIFICATION: {
    path: 'support/help/domain-verification',
    legacyPath: 'support/topic/domain_verification',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_MAIL_SERVER_TEST: {
    path: 'support/help/mail-server-test',
    legacyPath: 'support/topic/mail_server_test',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_DOMAIN_MANAGER: {
    path: 'support/help/domain-manager',
    legacyPath: 'support/topic/domain_manager',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_DOMAIN_SETTINGS: {
    path: 'support/help/domain-settings',
    legacyPath: 'support/topic/domain_settings',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_RECIPIENT_LIST: {
    path: 'support/help/recipient-list',
    legacyPath: 'support/topic/recipient_list',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_ANTI_VIRUS: {
    path: 'support/help/inbound-anti-virus',
    legacyPath: 'support/topic/inbound_anti_virus',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_CUSTOM_RBLS: {
    path: 'support/help/inbound-custom-rbls',
    legacyPath: 'support/topic/inbound_custom_rbls',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_IP_ADDRESS_POLICIES: {
    path: 'support/help/inbound-ip-address-policies',
    legacyPath: 'support/topic/inbound_ip_address_policies',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_REGIONAL_POLICIES: {
    path: 'support/help/inbound-regional-policies',
    legacyPath: 'support/topic/inbound_regional_policies',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_RECIPIENT_POLICIES: {
    path: 'support/help/inbound-recipient-policies',
    legacyPath: 'support/topic/inbound_recipient_policies',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_SENDER_POLICIES: {
    path: 'support/help/inbound-sender-policies',
    legacyPath: 'support/topic/inbound_sender_policies',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_SENDER_AUTHENTICATION: {
    path: 'support/help/inbound-sender-authentication',
    legacyPath: 'support/topic/inbound_sender_authentication',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_CONTENT_POLICIES: {
    path: 'support/help/inbound-content_policies',
    legacyPath: 'support/topic/inbound_content_policies',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_RATE_CONTROL: {
    path: 'support/help/inbound-rate-control',
    legacyPath: 'support/topic/inbound_rate_control',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_ANTI_PHISHING: {
    path: 'support/help/inbound-anti-phishing',
    legacyPath: 'support/topic/inbound_anti_phishing',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_INBOUND_ATP: {
    path: 'support/help/inbound-atp',
    legacyPath: 'support/topic/inbound_atd',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_OUTBOUND_SENDER_IP: {
    path: 'support/help/outbound-sender-ip',
    legacyPath: 'support/topic/outbound_sender_ip',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_OUTBOUND_ANTI_VIRUS: {
    path: 'support/help/outbound-anti-virus',
    legacyPath: 'support/topic/outbound_anti_virus',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_OUTBOUND_TAGLINE: {
    path: 'support/help/outbound-tagline',
    legacyPath: 'support/topic/outbound_tagline',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_OUTBOUND_DLP: {
    path: 'support/help/outbound-dlp',
    legacyPath: 'support/topic/outbound_dlp',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_OUTBOUND_CONTENT_POLICIES: {
    path: 'support/help/outbound-content-policies',
    legacyPath: 'support/topic/outbound_content_policies',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_SENDER_NOTIFICATION: {
    path: 'support/help/sender-notification',
    legacyPath: 'support/topic/sender_notification',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_OUTBOUND_QUARANTINE_INBOX: {
    path: 'support/help/outbound-quarantine-inbox',
    legacyPath: 'support/topic/outbound_quarantine_inbox',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_USERS_DEFAULT_POLICY: {
    path: 'support/help/users-default-policy',
    legacyPath: 'support/topic/users_default_policy',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_USERS_EDIT: {
    path: 'support/help/users-edit',
    legacyPath: 'support/topic/users_edit',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_USERS_EMAIL_CONTINUITY: {
    path: 'support/help/users-email-continuity',
    legacyPath: 'support/topic/users_email_continuity',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_USERS_QUARANTINE: {
    path: 'support/help/users-quarantine',
    legacyPath: 'support/topic/users_quarantine',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_USERS_LIST: {
    path: 'support/help/users-list',
    legacyPath: 'support/topic/users_list',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_LINKED_ACCOUNTS: {
    path: 'support/help/linked-accounts',
    legacyPath: 'support/topic/linked_accounts',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SUPPORT_HELP_ENDUSER_SENDER_POLICY: {
    path: 'support/help/enduser-sender-policy',
    legacyPath: 'support/topic/enduser_sender_policy',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SUPPORT_HELP_ENDUSER_QUARANTINE_NOTIFICATION: {
    path: 'support/help/enduser-quarantine-notification',
    legacyPath: 'support/topic/enduser_quarantine_notificatin',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SUPPORT_HELP_ENDUSER_LOG: {
    path: 'support/help/enduser-log',
    legacyPath: 'support/topic/enduser_log',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SUPPORT_HELP_SELF_SERVICE1: {
    path: 'support/help/self-service1',
    legacyPath: 'support/topic/self_service1',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_SELF_SERVICE2: {
    path: 'support/help/self-service2',
    legacyPath: 'support/topic/self_service2',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_SELF_SERVICE3: {
    path: 'support/help/self-service3',
    legacyPath: 'support/topic/self_service3',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_SELF_SERVICE4: {
    path: 'support/help/self-service4',
    legacyPath: 'support/topic/self_service4',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_SELF_SERVICE5: {
    path: 'support/help/self-service5',
    legacyPath: 'support/topic/self_service5',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_REGEX: {
    path: 'support/help/regex',
    legacyPath: 'support/topic/regex',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_REPORTS_DOMAIN: {
    path: 'support/help/reports-domain',
    legacyPath: 'support/topic/reports_domain',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_REPORTS_ALL_DOMAIN: {
    path: 'support/help/reports-all-domain',
    legacyPath: 'support/topic/reports_all_domains',
    appAccess: [AppNames.admin]
  },
  SUPPORT_HELP_SYSLOG_INTEGRATION: {
    path: 'support/help/syslog-integration',
    legacyPath: 'support/topic/syslog_integration',
    appAccess: [AppNames.admin]
  },

  SETTINGS: {
    path: 'settings',
    legacyPath: '/users/settings/quarantine_notification',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SETTINGS_QUARANTINE_NOTIFICATION: {
    path: 'settings?tab=quarantine_notification',
    legacyPath: '/users/settings/quarantine_notification',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SETTINGS_SENDER_POLICY: {
    path: 'settings?tab=sender_policy',
    legacyPath: '/users/settings/sender_policy',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SETTINGS_LINKED_ACCOUNTS: {
    path: 'settings?tab=linked_accounts',
    legacyPath: '/users/settings/linked_accounts',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SETTINGS_CHANGE_PASSWORD: {
    path: 'settings?tab=change_password',
    legacyPath: '/users/settings/change_password',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  SENDER_POLICIES_BULK_EDIT: {
    path: 'settings/sender-policies/bulk-edit',
    legacyPath: '',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  VERIFY_LINK_ACCOUNT: {
    path: 'actions/verify-link-account',
    legacyPath: 'user/settings/do_link_account',
    appAccess: [AppNames.admin, AppNames.enduser]
  },
  RESET_PASSWORD: {
    path: 'actions/reset-password',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser]
  }
} as const

export default UI_PATHS
