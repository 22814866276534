import { ChangeEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { process } from '@progress/kendo-data-query'

import { CSVLink } from 'react-csv'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { formatDate } from 'lib/datetime'
import config from 'config/appConfig'

import { ColumnsConfig } from 'types/redux/dataTables/dataTables'
import {
  getAtpFiletypes,
  getAtpOverview,
  getAtpReport,
  getAtpSearch,
  resetSearch,
  setSearchTerm
} from 'redux/features/atp/atpSlice'
import { getAvailableDomains } from 'redux/features/user/userSlice'
import { reset as resetMessagesTable } from 'redux/features/dataTables/atp/atpSlice'
import { isPending } from 'redux/toolkit/api'

import { AtpLog, FileTypes, ModifiedAtpLog } from 'types/Atp'
import { atpRisk, AtpStatus, atpStatus, atpStatusIcon } from 'lib/atp'
import routesConfig from 'lib/routesConfig'

interface SelectItem {
  value: string
  label: string
}
export interface UseAtpLogLogic {
  inProgress: boolean
  domainConfig: {
    items: SelectItem[]
    selectedItem: string | number
    onSelect: (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => void
  }
  infectedCount: number
  suspiciousCount: number
  exportConfig: {
    exportRef: MutableRefObject<(CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null>
    fileName: () => string
    headers: {
      label: string
      key: string
    }[]
    csvExport: () => void
  }
  searchConfig: {
    search: string
    handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleSearch: () => void
  }
  filterConfig: {
    filetypes: FileTypes[]
    selectedFiletype: FileTypes
    timeFilters: number[]
    selectedTimeFilter: number
    statusFilters: string[]
    selectedStatusFilter: string
    handleFilterChange: (name: string, value: unknown) => void
  }
  tableConfig: {
    tableData: {
      total: number
      data: ModifiedAtpLog[]
    }
    columns: { [key: string]: string }
    columnsConfig: ColumnsConfig
  }
  handleRowClick: (item: ModifiedAtpLog) => void
  reportConfig: {
    onViewReport: (item: ModifiedAtpLog) => void
    isLoading: boolean
  }
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
}

const STATUS_FILTERS = ['all', 'clean', 'suspicious', 'infected']
const TIME_FILTERS = [30, 7, 1]

export const useAtpLogLogic = (): UseAtpLogLogic => {
  const dispatch = useAppDispatch()

  const {
    atpTable,
    atpLog,
    atpOverview,
    atpFiletypes,
    searchTerm,
    atpLogPending,
    atpOverviewPending,
    atpFiletypesPending,
    availableDomains,
    availableDomainsPending,
    atpReportPending
  } = useAppSelector(_store => ({
    atpTable: _store.dataTables.atp,
    atpLog: _store.atp.search,
    atpOverview: _store.atp.overview,
    atpFiletypes: _store.atp.filetypes,
    searchTerm: _store.atp.searchTerm,
    availableDomains: _store.user.availableDomains,
    availableDomainsPending: isPending(_store.user.api.getAvailableDomainsApiStatus),
    atpLogPending: isPending(_store.atp.getAtpLogStatus),
    atpOverviewPending: isPending(_store.atp.getAtpOverviewStatus),
    atpFiletypesPending: isPending(_store.atp.getAtpFiletypesStatus),
    atpReportPending: isPending(_store.atp.getAtpReportStatus)
  }))

  const exportRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)
  const [selectedDomain, setSelectedDomain] = useState<string>('all')
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  // init
  useEffect(() => {
    dispatch(getAtpSearch())
    dispatch(getAtpOverview())
    dispatch(getAtpFiletypes())
    dispatch(getAvailableDomains())
    // eslint-disable-next-line
  }, [])

  const handleSearch = useCallback(
    (domainId?: string) => {
      dispatch(resetSearch(true))
      dispatch(resetMessagesTable())

      dispatch(getAtpSearch({ domainId }))
    },
    [dispatch]
  )

  const onSelectDomain = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      const domainId = event.target.value as string
      setSelectedDomain(domainId)
      dispatch(getAtpOverview({ domainId }))
      handleSearch(domainId)
    },
    [dispatch, handleSearch]
  )

  const handleFilterChange = useCallback(
    (name: string, value: unknown) => {
      // The button's value is always string we need to convert it to number for the time filter
      dispatch(setSearchTerm({ [name as string]: name === 'time' ? Number(value) : value }))
      handleSearch()
    },
    [dispatch, handleSearch]
  )

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setSearchTerm({ searchStr: e.target.value }))
    },
    [dispatch]
  )

  const domains = useMemo(
    () => [
      { value: 'all', label: 'all_domains' },
      ...(availableDomains || []).map(domain => ({ value: domain.domainId, label: domain.domainName }))
    ],
    [availableDomains]
  )

  const tableData = useMemo(() => {
    const { skip, take } = atpTable
    const { data } = process(
      (atpLog.result || []).map((report: AtpLog) => ({
        ...(report && {
          ...report,
          isFuture:
            atpStatus(report.status) === AtpStatus.future &&
            (report.determination || report.determination === AtpStatus.error),
          formattedDate: formatDate(new Date(report.created), config.DATETIME.ATP_LOG_DATE_WITH_TIME_FORMAT),
          statusText: atpStatus(report.status),
          originText: report.origin === 'MX' ? 'Automated' : 'Manual',
          riskText: atpRisk(report.risk),
          statusIcon: atpStatusIcon(atpStatus(report.status))
        })
      })),
      { skip, take }
    )

    return {
      data,
      total: atpLog.count || 0
    }
  }, [atpLog, atpTable])

  const exportHeaders = useMemo(
    () => [
      { label: 'status', key: 'statusText' },
      { label: 'origin', key: 'originText' },
      { label: 'fileName', key: 'filename' },
      { label: 'fileType', key: 'fileType' },
      { label: 'messageId', key: 'messageId' },
      { label: 'from', key: 'headerFrom' },
      { label: 'to', key: 'headerTo' },
      { label: 'time', key: 'formattedDate' }
    ],
    []
  )

  const exportFileName = useCallback(() => {
    const currentDate = formatDate(new Date(), config.DATETIME.EXPORT_FILE_NAME_DATE_FORMAT)
    return `atd_log_${currentDate}.csv`
  }, [])

  const csvExport = () => {
    if (exportRef?.current) {
      exportRef.current.link?.click()
    }
  }

  const handleRowClick = useCallback((message: ModifiedAtpLog) => {
    const path = `${routesConfig.MESSAGE_DETAIL.url({ messageId: message.mid, domainId: message.domainId.toString() })}`
    window.open(`${window.location.origin}${path}`, '_blank')
  }, [])

  const onViewReport = useCallback(
    (item: ModifiedAtpLog) => {
      dispatch(getAtpReport({ cuid: item.cuid }))
    },
    [dispatch]
  )

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  return useMemo(
    () => ({
      inProgress: atpLogPending || atpOverviewPending || atpFiletypesPending || availableDomainsPending,
      domainConfig: {
        items: domains,
        selectedItem: selectedDomain,
        onSelect: onSelectDomain
      },
      infectedCount: atpOverview.infectedCount,
      suspiciousCount: atpOverview.suspiciousCount,
      exportConfig: {
        exportRef,
        fileName: exportFileName,
        headers: exportHeaders,
        csvExport
      },
      searchConfig: {
        search: searchTerm.searchStr,
        handleInputChange,
        handleSearch
      },
      filterConfig: {
        filetypes: atpFiletypes,
        selectedFiletype: searchTerm.filetype,
        timeFilters: TIME_FILTERS,
        selectedTimeFilter: searchTerm.time,
        statusFilters: STATUS_FILTERS,
        selectedStatusFilter: searchTerm.status,
        handleFilterChange
      },
      tableConfig: {
        tableData,
        columns: atpTable.GRID_COLUMNS,
        columnsConfig: atpTable.columnsConfig
      },
      handleRowClick,
      reportConfig: {
        onViewReport,
        isLoading: atpReportPending
      },
      helpConfig: {
        isOpen: isHelpDialogOpened,
        onHelpClick,
        onCloseHelp
      }
    }),
    [
      selectedDomain,
      onSelectDomain,
      handleInputChange,
      handleSearch,
      tableData,
      atpTable,
      atpOverview,
      atpFiletypes,
      atpLogPending,
      atpOverviewPending,
      atpFiletypesPending,
      onViewReport,
      atpReportPending,
      searchTerm,
      exportFileName,
      exportHeaders,
      handleFilterChange,
      availableDomainsPending,
      domains,
      handleRowClick,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
