import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  textfield: {
    '&&&': {
      margin: theme.spacing(0, 2, 2, 0)
    },
    backgroundColor: theme.palette.common.white,
    '& label': {
      position: 'absolute !important'
    },
    '& > div': {
      height: theme.layout.outlineDivHeight
    }
  }
}))
