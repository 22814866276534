import camelizeKeys from 'lib/camelizeKeys'

import reduxStore from 'lib/reduxStore'
import {
  ejectRequestInterceptor,
  ejectResponseInterceptor,
  setRequestInterceptors,
  setResponseInterceptors
} from 'lib/api/restClient'
import { appLoginEntryPath } from 'lib/routesConfig'
import { logout } from 'redux/features/auth/authApiThunks'

export async function responseDataInterceptor(data: any) {
  if (data.config.headers.isToolkitCompatible) {
    return {
      ...data,
      data: {
        ...camelizeKeys(data.data)
      }
    }
  }

  return data
}

/* eslint-disable no-param-reassign */
async function requestInterceptor(requestConfig: any) {
  const authStore = reduxStore.getState().auth
  const accessToken = authStore?.accessToken

  if (accessToken?.length) {
    requestConfig.headers = {
      // add authentication header
      Authorization: `Bearer ${accessToken}`,
      ...(requestConfig.headers || {})
    }
  }

  return requestConfig
}

/* eslint-enable no-param-reassign */

// error handles
const REDIRECT_STATUS_CODES = [
  401 // Unauthorized
  // TODO: add back 403 check after this status code is used correctly on api-gateway side
  // 403 // Forbidden
]

const REDIRECT_URLS_BLOCKLIST: string[] = [appLoginEntryPath.path]
const SKIP_API_ENDPOINTS: string[] = ['/verify', '/login']

export function responseErrorInterceptor(error: any) {
  const isAllowedApiEndpoint = !SKIP_API_ENDPOINTS.some((endpoint: string) => error?.config?.url?.includes(endpoint))

  // logout the user if any requests failed with one of REDIRECT_STATUS_CODES and not whitelisted and blocked
  if (
    isAllowedApiEndpoint &&
    REDIRECT_STATUS_CODES.includes(error?.status) &&
    !REDIRECT_URLS_BLOCKLIST.includes(window.location.pathname)
  ) {
    reduxStore.dispatch(logout())
  }

  return Promise.reject(error)
}

export default function registerAxiosHooks() {
  ejectRequestInterceptor()
  setRequestInterceptors(requestInterceptor)

  ejectResponseInterceptor()
  setResponseInterceptors(responseDataInterceptor, responseErrorInterceptor)
}
