/* eslint-disable quotes */
export default {
  support: 'Support',
  pervious_page: 'Return to pervious page',
  print: 'Printable view',
  search_box_title: 'Search Support Topics',
  enter_keywords: 'Enter keywords',
  search_box_example: 'Example: adding mail server, inbound settings',
  no_search_results: 'Your search query returned no results.',
  documentation: 'Documentation',
  documentation_content:
    "All administrator's documentation for the Barracuda Email Gateway Defense, including the Getting Started guide, can be found in Barracuda TechLibrary linked here:",
  documentation_link1: 'Barracuda Campus',
  documentation_link2: 'Release Notes',
  linked_accounts: 'Linked Accounts',
  sender_policy: 'Sender Policy',
  quarantine_notification: 'Quarantine Notification',
  message_log: 'Message Log',
  search_result: 'Search Result',
  cpl_product_name: 'Barracuda Cloud Protection Layer',
  no_cpl_product_name: 'Email Gateway Defense',
  topics: {
    domains: 'Domains',
    inbound_settings: 'Inbound Settings',
    outbound_settings: 'Outbound Settings',
    user_topics: 'Users',
    end_user: 'EndUser',
    self_service_portal_topics: 'Self Service Portal',
    general_topics: 'General',
    report_topics: 'Reports',
    account_management_topics: 'Account Management',

    seen_recipients: 'Seen Recipients',
    dashboard: 'Dashboard',
    staying_in_compliance: 'Staying in Compliance',
    message_log: 'Message Log',
    atp_log: 'ATP Log',
    audit_log: 'Audit Log',
    domain_verification: 'Domain Verification',
    mail_server_test: 'Mail Server Test',
    domain_manager: 'Domain Manager',
    domain_settings: 'Domain Settings',
    recipients_list: 'Recipients List',
    anti_spam_antivirus: 'Anti-Spam/Antivirus',
    custom_rbls: 'Custom RBLs',
    ip_address_policies: 'IP Address Policies',
    regional_policies: 'Regional Policies',
    recipient_policies: 'Recipient Policies',
    sender_policies: 'Sender Policies',
    sender_authentication: 'Sender Authentication',
    content_policies: 'Content Policies',
    rate_control: 'Rate Control',
    anti_phishing: 'Anti Phishing',
    atp_settings: 'ATP Settings',
    sender_ip_address_ranges: 'Sender IP Address Ranges',
    anti_virus: 'Anti-Virus',
    tagline_footer: 'Tagline/Footer',
    dlp_encryption: 'DLP/Encryption',
    notifications: 'Notifications',
    outbound_quarantine: 'Outbound Quarantine',
    default_policy: 'Default Policy',
    add_update_users: 'Add/Update Users',
    email_continuity: 'Email Continuity',
    quarantine_notification: 'Quarantine Notification',
    users_list: 'Users List',
    linked_accounts: 'Linked Accounts',
    sender_policy: 'Sender Policy',
    specify_primary_email_domain: 'Step 1 - Specify Primary Email Domain',
    specify_email_servers: 'Step 2 - Specify Email Servers',
    configure_settings: 'Step 3 - Configure Settings',
    configure_outbound_settings_for_office_365: 'Step 4 - Configure Outbound Settings for Office365',
    route_email_through_barracuda_networks: 'Step 5 - Route Email Through Barracuda Networks',
    regular_expressions: 'Regular Expressions',
    generate_report_this_domain: 'Generate Report - This Domain',
    generate_report_all_domains: 'Generate Report - All Domains',
    syslog_integration: 'Syslog Integration'
  }
}
/* eslint-enable quotes */
