import React, { useState } from 'react'

export interface CommonAnchorElProps {
  anchorEl: HTMLButtonElement
  setAnchorEl: () => void
  handleClick: () => void
  handleClose: () => void
}
export default function commonAnchorElLogic<P extends CommonAnchorElProps>(WrappedComponent: React.ComponentType<P>) {
  function CommonAnchorElLogic(props: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <WrappedComponent {...(props as any)} anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} />
    )
  }
  return CommonAnchorElLogic
}
