const DASHBOARD = 'Dashboard'
const ALL = 'All'

/* eslint-disable quotes */
const overview = {
  tabs: {
    dashboard: DASHBOARD,
    message_log: 'Message Log',
    atp_log: 'ATP log',
    outbound_quarantine: 'Outbound Quarantine',
    audit_log: 'Audit log',
    review_license: 'Review licenses'
  },
  dashboard: {
    title: DASHBOARD,
    whats_new: "What's new",
    select_domain: 'Select domain',
    all: ALL,
    time: 'Time',
    last_24_hours: 'Last 24 Hours',
    last_30_days: 'Last 30 days',
    threat_origins: 'Threat origins',
    inbound_email_statistics: 'Inbound Email Statistics',
    outbound_email_statistics: 'Outbound Email Statistics',
    total_threat_viruses: 'Total Threats / Viruses',
    threats_viruses_detected: 'Threats / Viruses Detected',
    dropdown_table: {
      top_recipient_domains: 'Top Recipient Domains',
      top_sender_domains: 'Top Sender Domains',
      no_data_found: 'No data found',
      inbound: 'Inbound',
      outbound: 'Outbound',
      atp: 'ATP',
      none: 'Volume',
      volume: 'Volume',
      blocked: 'Blocked',
      allowed: 'Allowed',
      top_recipients_blocked: 'Top Recipients Blocked',
      top_senders_blocked: 'Top Senders Blocked',
      last_blocked: 'Last Blocked',
      selected_value: {
        none: 'Volume',
        blocked: 'Blocked',
        allowed: 'Allowed',
        recipients: 'Top Recipients Blocked',
        senders: 'Top Senders Blocked',
        atp: 'ATP'
      },
      table: {
        RANK: 'Rank',
        DOMAIN: 'Domain',
        VOLUME: 'Volume',
        BLOCKED: 'Blocked',
        RECIPIENTS: 'Recipients',
        SENDERS: 'Senders',
        FILE_NAME: 'Filename',
        FILE_TYPE: 'Filetype',
        LAST_SEEN: 'Last Seen'
      }
    },
    charts: {
      overview: 'Overview',
      allowed: 'Allowed',
      file_type: 'File Type',
      total_blocked: 'Total blocked',
      advanced_threats: 'Advanced Threats',
      sent: 'Sent',
      encrypted: 'Encrypted',
      total_sent: 'Total sent',
      total: 'Total',
      virus: 'Virus',
      viruses: 'Viruses',
      atp: 'ATP',
      brbl: 'BRBL',
      spam: 'Spam',
      brts: 'BRTS',
      quarantined: 'Quarantined',
      blocked: 'Blocked',
      blocked_bbl: 'BRBL',
      blocked_spam: 'Spam',
      blocked_brts: 'BRTS',
      blocked_av: 'Virus',
      office: 'Ms Office',
      category: 'Category',
      percentage: 'Percentage'
    },
    footer: {
      account_id: 'Account ID',
      serial_number: 'Serial number',
      version: 'Version'
    }
  }
}
/* eslint-enable quotes */

export default overview
