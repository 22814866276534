import util from 'util'
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Alert, AppBar, Snackbar, Toolbar, AlertProps } from '@cuda-networks/bds-core'
import { useIntl } from 'react-intl'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import appConfig from 'config/appConfig'

import { closeSnackBar } from 'redux/features/app/appSlice'
import inIframe from 'lib/inIframe'
import { NavbarProps } from './navbarProps'
import NavbarLogic from './NavbarLogic'
import CommonAnchorElLogic, { CommonAnchorElProps } from './CommonAnchorElLogic'

import LogoutMenu from './LogoutMenu'
import UserInfoWidget from './UserInfoWidget'
import SideMenu from './SideMenu'

import styles from './navbarStyles'

interface Props extends NavbarProps, CommonAnchorElProps {}

// eslint-disable-next-line react/function-component-definition
const Navbar: React.FC<Props> = ({ barracudaProductLogo, isNavbarVisible, showLogoutMenu }) => {
  const dispatch = useAppDispatch()
  const { snackBar } = useAppSelector(_store => ({ snackBar: _store.app.snackBar }))
  const classes = styles()
  const intl = useIntl()

  const text = useCallback((id: string) => intl.formatMessage({ id: `ess.snackbar.${id}` }), [intl])

  const snackBarMessage = useMemo(() => {
    if (!snackBar.message) {
      return ''
    }

    let message
    if (snackBar.message.startsWith('@literal:')) {
      message = snackBar.message.substring(9)
    } else {
      message = text(snackBar.message)
    }

    if (snackBar.params) {
      return util.format(message, ...snackBar.params)
    }
    return message

    // eslint-disable-next-line
  }, [snackBar])

  const handleCloseSnackBar = useCallback(
    (_: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return
      }

      dispatch(closeSnackBar())
    },
    [dispatch]
  )

  return useMemo(() => {
    if (!isNavbarVisible || inIframe()) {
      return null
    }

    return (
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Snackbar
            className={classes.snackBar}
            open={snackBar.open}
            onClose={handleCloseSnackBar}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            TransitionProps={{
              appear: false
            }}
          >
            <Alert severity={snackBar.severity as AlertProps['severity']} onClose={handleCloseSnackBar}>
              {snackBarMessage}
            </Alert>
          </Snackbar>
          <div className={classes.essViewWrapper}>
            {appConfig.APP.IS_ENDUSER && (
              <div className={classes.navbarEssNavigationHamburger}>
                <SideMenu />
              </div>
            )}
            <img
              alt="Barracuda Email Gateway Defense"
              className={classes.navbarProductLogo}
              src={barracudaProductLogo}
            />
            <div className={classes.flexGrow} />
            <UserInfoWidget />
            {showLogoutMenu && (
              <div className={classes.switcherVertWrapper}>
                <LogoutMenu />
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    )
  }, [
    barracudaProductLogo,
    classes,
    handleCloseSnackBar,
    isNavbarVisible,
    showLogoutMenu,
    snackBarMessage,
    snackBar.open,
    snackBar.severity
  ])
}

Navbar.propTypes = {
  barracudaProductLogo: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isNavbarVisible: PropTypes.bool.isRequired,
  showLogoutMenu: PropTypes.bool.isRequired
}

export default CommonAnchorElLogic(NavbarLogic(Navbar))
