import { makeStyles } from '@cuda-networks/bds-core'

export interface StyleProps {
  barracudaResponsible: boolean
  isGetMessagePending: boolean
  isGetMessageSourcePending: boolean
}

export default makeStyles(theme => ({
  drawerHeader: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    pointerEvents: ({ isGetMessagePending, isGetMessageSourcePending }: StyleProps) =>
      isGetMessagePending || isGetMessageSourcePending ? 'none' : 'auto'
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  downloadingWrapper: {
    display: 'inline-flex',
    margin: theme.spacing(0, 1),
    verticalAlign: 'middle'
  },
  downloadingText: {
    color: theme.palette.secondary.main,
    marginLeft: 4
  },
  toolbarIconButton: {
    margin: theme.spacing(0, 0.25),
    padding: theme.spacing(0.75)
  },
  toolbarButton: {
    height: 36,
    margin: theme.spacing(0, 0.25)
  },
  checkButtonWrapper: {
    display: ({ barracudaResponsible }: StyleProps) => (barracudaResponsible ? 'inline-block' : 'none')
  },
  sendButton: {
    display: 'inline-block'
  }
}))
