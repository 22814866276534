import { colors, createStyles, makeStyles } from '@cuda-networks/bds-core'

const LOADING_ICON_SIZE = 40

export default makeStyles(() => {
  const general = createStyles({
    fullSizeContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  })

  return createStyles({
    wrapper: {
      position: 'relative',
      width: '100%',
      height: '100%'
    },
    errorPage: {
      zIndex: 1
    },
    fullSizeContainer: {
      ...general.fullSizeContainer
    },
    fullSizeOpacityContainer: {
      ...general.fullSizeContainer,
      backgroundColor: colors.white,
      opacity: 0.7
    },
    loadingIcon: {
      position: 'absolute',
      width: LOADING_ICON_SIZE,
      height: LOADING_ICON_SIZE,
      top: `calc((100% - ${LOADING_ICON_SIZE}px) / 2)`,
      left: `calc((100% - ${LOADING_ICON_SIZE}px) / 2)`
    }
  })
})
