import React, { useMemo } from 'react'

import { Grid } from '@cuda-networks/bds-core'
import Tabs from 'components/libs/tabs/Tabs'
import { useOverviewLogic, OverviewTabs } from 'components/pages/overview/useOverviewLogic'
import styles from 'components/pages/overview/overviewStyles'

import Dashboard from 'components/pages/overview/dashboard/Dashboard'
import MessageLogPage from 'components/pages/messageLog/MessageLogPage'
import AuditLog from 'components/pages/auditLog/AuditLog'
import AtpLog from 'components/pages/atpLog/AtpLog'
import OutboundQuarantine from 'components/pages/outboundQuarantine/OutboundQuarantine'
import LicenseContent from '../license/LicenseContent'

const Overview: React.FC = () => {
  const classes = styles()
  const [tabsConfig] = useOverviewLogic()

  return useMemo(
    () => (
      <Grid container direction="column">
        {tabsConfig.activeTab && (
          <Tabs className={classes.tabs} {...tabsConfig} variant="fullWidth" data-testid="tabs" />
        )}
        {tabsConfig.activeTab === OverviewTabs.dashboard && <Dashboard />}
        {tabsConfig.activeTab === OverviewTabs.messageLog && <MessageLogPage />}
        {tabsConfig.activeTab === OverviewTabs.atpLog && <AtpLog />}
        {tabsConfig.activeTab === OverviewTabs.outboundQuarantine && <OutboundQuarantine />}
        {tabsConfig.activeTab === OverviewTabs.auditLog && <AuditLog />}
        {tabsConfig.activeTab === OverviewTabs.reviewLicense && <LicenseContent />}
      </Grid>
    ),
    [tabsConfig, classes]
  )
}

export default Overview
