import React, { useMemo } from 'react'

import { colors, Grid, Typography } from '@cuda-networks/bds-core'
import { isNumber } from 'lodash'
import { useFormatMessage } from 'lib/localization'
import PieChart from 'components/libs/nivo/pieChart/PieChart'
import styles from 'components/pages/overview/dashboard/dashboardStyles'

export type ChartConfig = { id: string; value: number }
export type TableConfig = { text: string; color?: string; value: number; percentage?: number }

export type PieChartWithTableProps = {
  chartConfig: ChartConfig[]
  tableConfig: TableConfig[]
  total: number
  tableTitle?: string
  isTooltip?: boolean
  customTotalTitle?: string

  pieColors?: string[]
}

const BASE_I18N_KEY = 'ess.overview.dashboard'
const PieChartWithTable: React.FC<PieChartWithTableProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { chartConfig, total, tableConfig, tableTitle, customTotalTitle, pieColors, isTooltip = false } = props

  return useMemo(
    () => (
      <Grid className={classes.pieChartWithTableContainer} container direction="row" data-testid="container">
        <Grid className={classes.overviewChart} item xs={5} data-testid="overview-chart">
          <Grid
            className={isTooltip ? classes.smallOverviewSection : classes.overviewSection}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className={isTooltip ? classes.smallThreatChartTotalCount : classes.threatChartTotalCount}
              data-testid="overview-total-value"
            >
              {total}
            </Typography>
            <Typography
              className={isTooltip ? classes.smallThreatChartTotalTitle : classes.threatChartTotalTitle}
              data-testid="overview-total-title"
            >
              {customTotalTitle || formatMessage('charts.total')}
            </Typography>
          </Grid>
          {!!total && (
            <PieChart
              config={{
                data: chartConfig,
                colors: pieColors || [colors.magenta700, colors.orange400],
                ...(isTooltip && {
                  margin: {
                    top: 10,
                    right: 5,
                    bottom: 10,
                    left: 5
                  }
                })
              }}
            />
          )}
        </Grid>
        <Grid className={isTooltip ? classes.smallOverviewContent : classes.overviewContent} item xs={7}>
          <Grid container direction="column">
            {tableTitle && (
              <Typography className={classes.overviewContentTitle} variant="h2" data-testid="table-title">
                {tableTitle}
              </Typography>
            )}
            <Grid className={classes.overviewContentTableHeader} container direction="row">
              <Grid item xs={5}>
                <Typography
                  data-testid="category"
                  className={
                    isTooltip ? classes.smallOverviewContentTableHeaderTitle : classes.overviewContentTableHeaderTitle
                  }
                >
                  {formatMessage('charts.category')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  data-testid="total"
                  className={
                    isTooltip ? classes.smallOverviewContentTableHeaderTitle : classes.overviewContentTableHeaderTitle
                  }
                  align="right"
                >
                  {formatMessage('charts.total')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  data-testid="percentage"
                  className={
                    isTooltip ? classes.smallOverviewContentTableHeaderTitle : classes.overviewContentTableHeaderTitle
                  }
                  align="right"
                >
                  {formatMessage('charts.percentage')}
                </Typography>
              </Grid>
            </Grid>
            {tableConfig.map(rowConfig => (
              <Grid key={rowConfig.text} container direction="row" data-testid="table">
                <Grid item xs={5}>
                  <Typography
                    variant={isTooltip ? 'body2' : 'body1'}
                    className={
                      isTooltip ? classes.smallOverviewContentTableContent : classes.overviewContentTableContent
                    }
                    data-testid="table-text"
                  >
                    {rowConfig.color && (
                      <data
                        data-testid="table-color"
                        className={classes.chartColorPrefix}
                        style={{ backgroundColor: rowConfig.color }}
                      />
                    )}
                    {rowConfig.text}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant={isTooltip ? 'body2' : 'body1'}
                    className={
                      isTooltip ? classes.smallOverviewContentTableContent : classes.overviewContentTableContent
                    }
                    align="right"
                    data-testid="table-value"
                  >
                    {rowConfig.value}
                  </Typography>
                </Grid>
                {isNumber(rowConfig.percentage) && (
                  <Grid item xs={4}>
                    <Typography
                      variant={isTooltip ? 'body2' : 'body1'}
                      className={
                        isTooltip ? classes.smallOverviewContentTableContent : classes.overviewContentTableContent
                      }
                      align="right"
                      data-testid="table-percentage"
                    >
                      {`${rowConfig.percentage} %`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    ),
    [chartConfig, total, tableConfig, tableTitle, isTooltip, customTotalTitle, pieColors, classes, formatMessage]
  )
}

export default PieChartWithTable
