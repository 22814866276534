import { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import routesConfig from 'lib/routesConfig'
import appConfig from 'config/appConfig'
import { FEATURES, isMyFeatureOn } from 'lib/splitio'
import appFeatures from 'config/appFeatures'

interface LogoutMenuLogicConfig {
  onLogout: () => void
  handleClose: () => void
}

interface LogoutMenuLogic {
  isLicenseComplianceOn: boolean
  isLanguageMenuDialogOpen: boolean
  preferredLanguage: string
  handleLogout: () => void
  onCloseLanguageMenu: () => void
  onOpenLanguageMenu: () => void
  onOpenReviewLicense: () => void
}

export const useLogoutMenuLogic = ({ onLogout, handleClose }: LogoutMenuLogicConfig): LogoutMenuLogic => {
  const isLicenseComplianceOn =
    appFeatures.EnabledLicenseCompliance || isMyFeatureOn(FEATURES.EGD_React_License_Compliance_Rollout)

  const { preferredLanguage, serial } = useAppSelector(_store => ({
    preferredLanguage: _store.app.language,
    serial: _store.auth.accessTokenObject?.serial
  }))
  const [isLanguageMenuDialogOpen, setIsLanguageMenuDialogOpen] = useState(false)

  const handleLogout = useCallback(() => {
    handleClose()
    onLogout()
  }, [handleClose, onLogout])

  const onCloseLanguageMenu = useCallback(() => {
    setIsLanguageMenuDialogOpen(false)
    handleClose()
  }, [handleClose])

  const onOpenLanguageMenu = useCallback(() => setIsLanguageMenuDialogOpen(true), [])

  const onOpenReviewLicense = useCallback(() => routesConfig.OVERVIEW_LICENSE_COMPLIANCE.goto(), [])

  return useMemo(
    () => ({
      isLicenseComplianceOn: appConfig.APP.IS_ADMIN && isLicenseComplianceOn && !!serial,
      isLanguageMenuDialogOpen,
      preferredLanguage,
      handleLogout,
      onCloseLanguageMenu,
      onOpenLanguageMenu,
      onOpenReviewLicense
    }),
    [
      isLanguageMenuDialogOpen,
      preferredLanguage,
      onOpenReviewLicense,
      isLicenseComplianceOn,
      serial,
      handleLogout,
      onCloseLanguageMenu,
      onOpenLanguageMenu
    ]
  )
}
