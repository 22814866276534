import { useCallback, useEffect, useMemo } from 'react'

import { ApiResponseError, getErrorMessage, isPending } from 'redux/toolkit/api'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { postFpfnReport, resetFpfnRedirectionUrl } from 'redux/features/fpfn/fpfnSlice'
import { Message } from 'types/Messages'

export interface ReportingActionModalProps {
  handleClose: () => void
  checkAction: string
}

export interface UseReportingActionModalLogic {
  postFpfnReportInProgress: boolean
  messageData: {
    messageIsLoading: boolean
    message: Message | undefined
    messageLoadingError: ApiResponseError
  }
  handleReport: () => void
}

export const useReportingActionModalLogic = (props: ReportingActionModalProps): UseReportingActionModalLogic => {
  const {
    getMessageInProgress,
    mstoreMessage,
    messageLoadingError,
    redirectionUrl,
    postFpfnReportInProgress,
    postFpfnReportErrorMessage
  } = useAppSelector(_store => ({
    getMessageInProgress: isPending(_store.mstore.getMessageApiStatus),
    messageLoadingError: getErrorMessage(_store.mstore.getMessageApiStatus),
    mstoreMessage: _store.mstore.message,
    redirectionUrl: _store.fpfn.redirectionURL,
    postFpfnReportInProgress: isPending(_store.fpfn.redirectionUrlApiStatus),
    postFpfnReportErrorMessage: getErrorMessage(_store.fpfn.redirectionUrlApiStatus)
  }))
  const dispatch = useAppDispatch()

  const messageData = useMemo(
    () => ({
      messageIsLoading: getMessageInProgress,
      message: mstoreMessage,
      messageLoadingError
    }),
    [getMessageInProgress, mstoreMessage, messageLoadingError]
  )

  useEffect(() => {
    const handleReportType = props.checkAction === 'allowed' ? 'fn' : 'fp'
    if (messageData.message) {
      dispatch(
        postFpfnReport({
          headerFrom: messageData.message.fields.headerFrom,
          envelopeFrom: messageData.message.fields.envelopeFrom,
          type: handleReportType,
          messageId: messageData.message.mid
        })
      )
    }
    return () => {
      dispatch(resetFpfnRedirectionUrl())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (postFpfnReportErrorMessage) {
      props.handleClose()
    }
  }, [postFpfnReportErrorMessage, props])

  const handleReport = useCallback(() => {
    if (redirectionUrl && !postFpfnReportErrorMessage) {
      window.open(redirectionUrl, '_blank')
    }
    props.handleClose()
  }, [redirectionUrl, postFpfnReportErrorMessage, props])

  return useMemo(
    () => ({
      postFpfnReportInProgress,
      messageData,
      handleReport
    }),
    [postFpfnReportInProgress, messageData, handleReport]
  )
}
