import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Typography, LinearProgress } from '@cuda-networks/bds-core'

import { useAutoLoginLogic } from 'components/pages/actions/autoLogin/useAutoLoginLogic'
import styles from 'components/pages/actions/autoLogin/AutoLoginStyles'
import InfoDialog from 'components/libs/dialog/infoDialog/InfoDialog'

const AutoLogin: React.FC = () => {
  const classes = styles()
  const intl = useIntl()
  const text = useCallback((id: string) => intl.formatMessage({ id: `ess.login.${id}` }), [intl])
  const [infoDialog, progress] = useAutoLoginLogic()

  return useMemo(
    () => (
      <div className={classes.root}>
        {infoDialog.isVisible && (
          <InfoDialog text={text(infoDialog.message)} onClose={infoDialog.onClose} data-testid="info-dialog" />
        )}
        {progress.isVisible && (
          <>
            <Typography variant="overline">{text('signing_in')}</Typography>
            <div className={classes.progressWrapper}>
              <LinearProgress data-testid="linear-progress" />
            </div>
          </>
        )}
      </div>
    ),
    [
      classes.progressWrapper,
      classes.root,
      infoDialog.isVisible,
      infoDialog.message,
      infoDialog.onClose,
      progress.isVisible,
      text
    ]
  )
}

export default AutoLogin
