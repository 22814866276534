import React, { useMemo } from 'react'

import {
  Typography,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Link
} from '@cuda-networks/bds-core'
import * as ControlIcons from '@cuda-networks/bds-core/dist/Icons/Controls'
import { Launch } from '@cuda-networks/bds-core/dist/Icons/Core'

import { mapSize } from 'lib/mstore'
import styles from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentHeader/messageDetailsContentHeaderStyles'
import { useMessageDetailsContentHeaderLogic } from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentHeader/useMessageDetailsContentHeaderLogic'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.message_log'

const MessageDetailsContentHeader = () => {
  const classes = styles()
  const text = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useMessageDetailsContentHeaderLogic({ text })
  const {
    message,
    isGetMessageSuccess,
    isDownloadAttachmentPending,
    date,
    to,
    from,
    determinationColor,
    determinationIcon,
    reason,
    subject,
    recipientTable,
    cantViewBlocked,
    cantViewQuarantined,
    id,
    ip,
    isDownloadAttachmentEnabled,
    forensicsLink
  } = state
  const { onDownloadAttachment } = eventHandlers

  const determination = useMemo(
    () => (
      <Box className={`${classes.determinationBox} ${determinationColor}`}>
        <Grid container direction="row" justifyContent="flex-start" wrap="nowrap">
          <Grid item>
            <img alt="" src={determinationIcon} className={classes.determinationIcon} />
          </Grid>
          <Grid item container alignContent="center">
            <Typography variant="subtitle2" className={classes.determinationText} data-testid="reason">
              {reason}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ),
    [
      classes.determinationBox,
      classes.determinationIcon,
      classes.determinationText,
      determinationColor,
      determinationIcon,
      reason
    ]
  )

  return useMemo(
    () => (
      <div>
        {isDownloadAttachmentPending && <LinearProgress data-testid="linear-progress" />}
        {isGetMessageSuccess && message && (
          <>
            {determination}
            {forensicsLink && (
              <Typography className={classes.findSimilar} data-testid="forensics-link">
                <Link href={forensicsLink} target="_blank">
                  {text('find_similar_messages')}
                  <Launch />
                </Link>
              </Typography>
            )}
            <Typography className={classes.headerSubject} data-testid="subject">
              {subject}
            </Typography>
            <Accordion className={classes.headerSummaryDetail}>
              <AccordionSummary
                className={classes.headerAccordionSummary}
                expandIcon={<ControlIcons.DropDownArrow />}
                aria-controls="email-detail-headers"
              >
                <div className={classes.headerFromTo}>
                  <table>
                    <tbody>
                      <tr>
                        <td>{text('header_detail.from')}</td>
                        <td data-testid="from">{from}</td>
                      </tr>
                      <tr>
                        <td>{text('header_detail.to')}</td>
                        <td data-testid="to">{to}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.headerDetails}>
                <table id="details">
                  <tbody>
                    <tr>
                      <td>{text('header_detail.date')}</td>
                      <td data-testid="date">{date}</td>
                    </tr>
                    {!cantViewBlocked && !!message.attachments?.length && (
                      <tr>
                        <td>{text('header_detail.attachments')}</td>
                        <td>
                          {message.attachments.map((att, index: number) => (
                            <React.Fragment key={att.id}>
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <Link
                                data-testid={`attachment-${att.id}`}
                                className={`${classes.attachmentLink} ${isDownloadAttachmentEnabled ? '' : 'disabled'}`}
                                onClick={() => onDownloadAttachment(att)}
                              >
                                {att.filename}
                              </Link>
                              &ensp;
                              <span>
                                ({mapSize(att.size)}){index < message.attachments.length - 1 && <>,&ensp;</>}
                              </span>
                            </React.Fragment>
                          ))}
                        </td>
                      </tr>
                    )}
                    {(cantViewBlocked || cantViewQuarantined) && message?.fields.attachment?.length && (
                      <tr>
                        <td>{text('header_detail.attachments')}</td>
                        <td data-testid="blocked-attachments">{message.fields.attachment.replace(/,/g, ', ')}</td>
                      </tr>
                    )}
                    <tr>
                      <td>{text('header_detail.mid')}</td>
                      <td data-testid="mid">{id}</td>
                    </tr>
                    <tr>
                      <td>{text('header_detail.ip')}</td>
                      <td data-testid="ip">{ip}</td>
                    </tr>
                    <tr>
                      <td>{text('header_detail.env_from')}</td>
                      <td data-testid="envelopeFrom">{message.fields.envelopeFrom}</td>
                    </tr>
                    <tr>
                      <td>{text('header_detail.recipients')}</td>
                      <td>
                        {/* Start of table of recipients */}
                        <div className={classes.recipients}>
                          <span>
                            <table>
                              <tbody>
                                <tr>
                                  <th>{text(`recipients_info.${recipientTable.header.recipients}`)}</th>
                                  <th>{text(`recipients_info.${recipientTable.header.action}`)}</th>
                                  <th>{text(`recipients_info.${recipientTable.header.reason}`)}</th>
                                  <th>{text(`recipients_info.${recipientTable.header.deliveryStatus}`)}</th>
                                </tr>
                                {recipientTable.data.map((row, idx) => (
                                  <tr key={row.recipients}>
                                    <td data-testid={`recipient-${idx}-recipients`}>{row.recipients}</td>
                                    <td data-testid={`recipient-${idx}-action`}>{row.action}</td>
                                    <td data-testid={`recipient-${idx}-reason`}>{row.reason}</td>
                                    <td data-testid={`recipient-${idx}-deliveryStatus`}>{row.deliveryStatus}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </span>
                        </div>
                        {/* End of table of recipients */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    ),
    [
      cantViewBlocked,
      cantViewQuarantined,
      classes,
      date,
      determination,
      from,
      id,
      ip,
      isDownloadAttachmentEnabled,
      isDownloadAttachmentPending,
      isGetMessageSuccess,
      message,
      onDownloadAttachment,
      recipientTable.data,
      recipientTable.header.action,
      recipientTable.header.deliveryStatus,
      recipientTable.header.reason,
      recipientTable.header.recipients,
      subject,
      text,
      to,
      forensicsLink
    ]
  )
}

export default MessageDetailsContentHeader
