/* eslint-disable jsx-a11y/anchor-is-valid */
import util from 'util'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  TextField,
  Link,
  AppBar,
  Snackbar,
  Alert
} from '@cuda-networks/bds-core'

import LogoURL from 'assets/images/barracuda/EP-EmailGatewayDefense_rev.svg'
import Footer from 'components/libs/layout/footer/Footer'
import { useResetPasswordLogic, CardType } from 'components/pages/actions/resetPassword/useResetPasswordLogic'
import styles from './resetPasswordStyles'

const ResetPassword: React.FC = () => {
  const intl = useIntl()
  const classes = styles()
  const [state, eventHandlers] = useResetPasswordLogic()

  const text = useCallback(
    (id: string) => {
      if (!id) {
        return ''
      }
      return intl.formatMessage({ id: `ess.reset_password.${id}` })
    },
    [intl]
  )

  const ResetPasswordCard = useMemo(
    () => (
      <Card elevation={3} className={classes.resetPasswordCard}>
        <CardHeader title={text('title')} />
        <CardContent>
          <div className={classes.cardContentMessageBox}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: util.format(text('reset_password_message'), state.userId)
              }}
            />
          </div>
          <div>
            <Typography variant="subtitle2">{text('password1')}</Typography>
            <TextField
              fullWidth
              autoComplete="off"
              name="password1"
              value={state.newPassword}
              onChange={eventHandlers.onNewPasswordChange}
              size="medium"
              placeholder="Password"
              type="password"
              data-testid="new-password"
            />
          </div>
          <div className={classes.passwordWrapper}>
            <Typography variant="subtitle2">{text('password2')}</Typography>
            <TextField
              fullWidth
              autoComplete="off"
              name="password2"
              error={!state.isPasswordMatch}
              value={state.confirmPassword}
              onChange={eventHandlers.onConfirmPasswordChange}
              size="medium"
              placeholder="Password"
              type="password"
              className={state.isPasswordMatch && state.messageId !== '' ? classes.password : ''}
              helperText={text(state.messageId)}
              FormHelperTextProps={{
                className: classes.passwordHelpText
              }}
              data-testid="confirm-password"
            />
          </div>
          <CardActions disableSpacing className={classes.cardActions}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              className={classes.cardActionGridContainer}
            >
              <Grid item>
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={eventHandlers.onReset}
                  disabled={state.cardType === 'LinkExpired' || state.isResetDisabled}
                  data-testid="reset-button"
                >
                  {text('reset')}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </CardContent>
      </Card>
    ),
    [
      classes.cardActionGridContainer,
      classes.cardActions,
      classes.cardContentMessageBox,
      classes.password,
      classes.passwordHelpText,
      classes.passwordWrapper,
      classes.resetPasswordCard,
      eventHandlers.onConfirmPasswordChange,
      eventHandlers.onNewPasswordChange,
      eventHandlers.onReset,
      state.cardType,
      state.confirmPassword,
      state.isPasswordMatch,
      state.isResetDisabled,
      state.messageId,
      state.newPassword,
      state.userId,
      text
    ]
  )

  const CheckEmailCard = useMemo(
    () => (
      <Card elevation={3} className={classes.checkEmailCard}>
        <CardHeader title={text('check_email.title')} />
        <CardContent>
          <Grid container direction="column">
            <Grid item>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: util.format(text('check_email.message'), state.userId)
                }}
              />
            </Grid>
            <Grid item className={classes.linkExpiredMessage}>
              <Typography variant="body1">{text('check_email.link_will_expire')}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Link href="#" data-name="resend" onClick={eventHandlers.onResendLink}>
              {text('expired_link.resend_link')}
            </Link>
          </Grid>
          <CardActions disableSpacing className={classes.cardActions}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item className={classes.linkedAccountsLink}>
                <Link href="#" data-name="signin" onClick={eventHandlers.onLogin}>
                  {text('expired_link.sign_in')}
                </Link>
              </Grid>
            </Grid>
          </CardActions>
        </CardContent>
      </Card>
    ),
    [
      classes.cardActions,
      classes.checkEmailCard,
      classes.linkExpiredMessage,
      classes.linkedAccountsLink,
      eventHandlers.onLogin,
      eventHandlers.onResendLink,
      state.userId,
      text
    ]
  )

  const SuccessfulResetCard = useMemo(
    () => (
      <Card elevation={3} className={classes.successfulResetCard}>
        <CardHeader title={text('successful_reset.title')} />
        <CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button color="primary" size="medium" variant="contained" onClick={eventHandlers.onClickSignin}>
                  {text('successful_reset.sign_in')}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </CardContent>
      </Card>
    ),
    [classes.cardActions, classes.successfulResetCard, eventHandlers.onClickSignin, text]
  )

  const LinkExpiredCard = useMemo(
    () => (
      <Card elevation={3} className={classes.linkExpiredCard}>
        <CardHeader title={text('expired_link.title')} />
        <CardContent>
          <Box className={classes.linkExpiredContent}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: util.format(text('expired_link.message'), state.userId)
              }}
            />
          </Box>
          <CardActions disableSpacing className={classes.cardActions}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item>
                <Link href="#" data-name="signin" onClick={eventHandlers.onLogin}>
                  {text('expired_link.sign_in')}
                </Link>
              </Grid>
              <Grid item>
                <Button color="primary" size="medium" variant="contained" onClick={eventHandlers.onSendLoginInfo}>
                  {text('expired_link.resend_link')}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </CardContent>
      </Card>
    ),
    [
      classes.cardActions,
      classes.linkExpiredCard,
      classes.linkExpiredContent,
      eventHandlers.onLogin,
      eventHandlers.onSendLoginInfo,
      state.userId,
      text
    ]
  )

  const CardMap: Record<CardType, ReactNode> = useMemo(
    () => ({
      ResetPassword: ResetPasswordCard,
      LinkExpired: LinkExpiredCard,
      CheckEmail: CheckEmailCard,
      SuccessfulReset: SuccessfulResetCard
    }),
    [ResetPasswordCard, LinkExpiredCard, CheckEmailCard, SuccessfulResetCard]
  )

  return useMemo(
    () => (
      <AppBar position="fixed" elevation={1} className={classes.login}>
        <div className={classes.logoBanner}>
          <img
            role="presentation"
            alt="Barracuda Email Gateway Defense"
            className={classes.logo}
            src={LogoURL}
            onClick={eventHandlers.onClickLogo}
          />
        </div>
        <Snackbar
          className={classes.snackBar}
          open={state.snackbarMessageId !== ''}
          transitionDuration={0}
          autoHideDuration={6000}
          onClose={eventHandlers.onCloseSnackBar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          TransitionProps={{
            appear: false
          }}
          data-testid="reset-password-snackbar"
        >
          <Alert severity={state.snackbarSeverity} onClose={eventHandlers.onCloseSnackBar}>
            {text(state.snackbarMessageId)}
          </Alert>
        </Snackbar>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.container}>{CardMap[state.cardType]}</div>
          </Grid>
        </Grid>
        <Footer />
      </AppBar>
    ),
    [
      CardMap,
      classes.container,
      classes.login,
      classes.logo,
      classes.logoBanner,
      classes.snackBar,
      eventHandlers.onClickLogo,
      eventHandlers.onCloseSnackBar,
      state.cardType,
      state.snackbarMessageId,
      state.snackbarSeverity,
      text
    ]
  )
}

export default ResetPassword
