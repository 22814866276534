import { useEffect, useMemo } from 'react'

import { useSearchParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isSuccess } from 'redux/toolkit/api'
import { bccLogin } from 'redux/features/auth/authSlice'
import { appEntryPath, gotoAdminEntryPathFromWizard } from 'lib/routesConfig'
import appConfig from 'config/appConfig'

export type IsFailedToLogin = boolean

export const useBccLoginLogic = (): [IsFailedToLogin] => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { isBccLoginSuccess, isBccLoginFailed } = useAppSelector(_store => ({
    isBccLoginSuccess: isSuccess(_store.auth.loginApiStatus),
    isBccLoginFailed: isFailed(_store.auth.loginApiStatus)
  }))

  // init
  useEffect(() => {
    const migrateToken = searchParams.get(appConfig.QUERY_PARAMS.MIGRATE_TOKEN)

    dispatch(bccLogin(migrateToken || undefined))
    // eslint-disable-next-line
  }, [])

  // successFully logged in
  useEffect(() => {
    if (isBccLoginSuccess) appEntryPath.goto()
  }, [isBccLoginSuccess])

  // failed to login
  // TODO: Handle admin failed authentication - forward back to BCC app
  useEffect(() => {
    if (isBccLoginFailed && appConfig.APP.IS_WIZARD) gotoAdminEntryPathFromWizard()
  }, [isBccLoginFailed])

  return useMemo(() => [isBccLoginFailed], [isBccLoginFailed])
}
