import React, { useMemo } from 'react'

import { Grid } from '@cuda-networks/bds-core'
import { CheckmarkCircle, HelpOutline } from '@cuda-networks/bds-core/dist/Icons/Core'

import { Typography } from '@material-ui/core'
import { useFormatMessage } from 'lib/localization'

import styles from 'components/pages/onboardWizard/serverAndMxSetup/sections/sectionsStyles'

const BASE_I18N_KEY = 'ess_wizard.server_and_mx_setup'

export type StatusCellProps = {
  isVerified: boolean
  isMissedToVerify: boolean
}

const StatusCell = ({ isVerified, isMissedToVerify }: StatusCellProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const textColor = useMemo(() => {
    let className: string = classes.unverifiedText
    if (isVerified) {
      className = classes.verifiedText
    } else if (isMissedToVerify) {
      className = classes.missedToVerifyText
    }

    return className
  }, [isVerified, isMissedToVerify, classes])

  return useMemo(
    () => (
      <Grid container direction="row">
        {isVerified ? (
          <CheckmarkCircle data-testid="verified" className={classes.verifiedIcon} />
        ) : (
          <HelpOutline
            data-testid={isMissedToVerify ? 'missed-to-verify' : 'unverified'}
            className={isMissedToVerify ? classes.missedToVerifyIcon : classes.unverifiedIcon}
          />
        )}
        <Typography data-testid="status-text" className={textColor} variant="body1">
          {formatMessage(isVerified ? 'verified' : 'unverified')}
        </Typography>
      </Grid>
    ),
    [classes, formatMessage, isVerified, textColor, isMissedToVerify]
  )
}

export default StatusCell
