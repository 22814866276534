import React, { useMemo } from 'react'
import { Card, CardHeader, TabPanel, Grid, Typography } from '@cuda-networks/bds-core'
import { useFormatMessage } from 'lib/localization'

import styles from 'components/pages/settings/settingsStyles'
import Tabs from 'components/libs/tabs/Tabs'
import {
  CHANGE_PASSWORD,
  LINKED_ACCOUNTS,
  QUARANTINE_NOTIFICATION,
  SENDER_POLICIES,
  useSettingsLogic
} from 'components/pages/settings/useSettingsLogic'
import QuarantineNotification from './quarantineNotification/QuarantineNotification'
import SenderPolicies from './senderPolicies/SenderPolicies'
import ChangePassword from './changePassword/ChangePassword'
import LinkedAccounts from './linkedAccounts/LinkedAccounts'

const BASE_I18N_KEY = 'ess.settings'

const Settings: React.FC = () => {
  const { tabsConfig } = useSettingsLogic()
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Grid container spacing={1} data-testid="root-grid">
        <Grid item xs={12}>
          <Card elevation={1}>
            <CardHeader
              title={
                <Typography className={classes.title} data-testid="title">
                  {formatMessage('title')}
                </Typography>
              }
            />
            <Tabs {...tabsConfig} variant="fullWidth" data-testid="tabs" />
          </Card>
          <TabPanel data-testid="tab-panel">
            {tabsConfig.activeTab === QUARANTINE_NOTIFICATION && (
              <QuarantineNotification data-testid="quarantine-tab" />
            )}
            {tabsConfig.activeTab === SENDER_POLICIES && <SenderPolicies data-testid="policies-tab" />}
            {tabsConfig.activeTab === LINKED_ACCOUNTS && <LinkedAccounts data-testid="accounts-tab" />}
            {tabsConfig.activeTab === CHANGE_PASSWORD && <ChangePassword data-testid="password-tab" />}
          </TabPanel>
        </Grid>
      </Grid>
    ),
    [tabsConfig, classes, formatMessage]
  )
}

export default Settings
