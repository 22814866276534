const API_VERSION = 'v1'

export enum AppNames {
  enduser = 'webui',
  admin = 'admin',
  wizard = 'wizard'
}

const isGT3 = window?.location?.host?.includes('gt3.ess')
const isStaging = window?.location?.host?.includes('staging1.ess')

const ENVIRONMENT = {
  IS_TEST: process.env.NODE_ENV === 'test',
  IS_DEV: process.env.NODE_ENV === 'development',
  IS_GT3: isGT3,
  IS_STAGING: isStaging,
  IS_PRODUCTION: process.env.NODE_ENV === 'production' && !isGT3 && !isStaging
}

const API_PATHNAME = process.env.REACT_APP_GATEWAY_URL
  ? new URL(process.env.REACT_APP_GATEWAY_URL).pathname.slice(1)
  : ''

export interface App {
  NAME: AppNames
  IS_ENDUSER: boolean
  IS_ADMIN: boolean
  IS_WIZARD: boolean
}

const APP_CONFIG = {
  API_VERSION,
  NAME: 'ESS',
  PRODUCT_PREFIX: 'EGD',
  LANGUAGE: 'en',
  APP_NAMES: {
    ENDUSER: AppNames.enduser,
    ADMIN: AppNames.admin,
    WIZARD: AppNames.wizard
  },
  APP: (
    [
      [AppNames.wizard, 'IS_WIZARD'],
      [AppNames.admin, 'IS_ADMIN']
    ] as any[]
  ).reduce(
    (all, appNameConfig) => {
      const [appName, keyName] = appNameConfig
      if (keyName && appName) {
        const isTheCurrentApp = window?.location?.pathname?.includes(appName) || false

        return {
          ...all,
          [keyName]: isTheCurrentApp,
          ...(isTheCurrentApp && { NAME: appName, IS_ENDUSER: false })
        }
      }

      return all
    },
    { NAME: AppNames.enduser, IS_ENDUSER: true, IS_ADMIN: false, IS_WIZARD: false } as App
  ),
  BASE_PATHS: {
    [AppNames.enduser]: 'webui',
    [AppNames.admin]: 'admin',
    [AppNames.wizard]: 'wizard'
  } as { [key: string]: string },
  ENVIRONMENT,
  GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL,
  LEGACY_UI_URL: process.env.REACT_APP_LEGACY_UI_URL,
  SPLITIO: {
    API_KEY: process.env.SPLITIO_API_KEY
  },
  INTERCOM: {
    APP_ID: process.env.INTERCOM_APP_ID,
    SECRET: process.env.INTERCOM_SECRET,
    IS_ENABLED: process.env.INTERCOM_IS_ENABLED === 'true' || false
  },
  MIXPANEL: {
    TOKEN: process.env.MIXPANEL_TOKEN,
    IS_ENABLED: process.env.MIXPANEL_IS_ENABLED === 'true' || false
  },
  DOMAIN: process.env.REACT_APP_DOMAIN,
  ONETRUST_ID: process.env.REACT_APP_ONETRUST_ID,
  FEATURES: {
    DARK_MODE: process.env.REACT_APP_DARK_MODE
  },
  LINKS: {
    PRIVACY_POLICY: 'https://www.barracuda.com/support/security',
    CAMPUS_SEARCHING_FOR_EMAILS: 'https://campus.barracuda.com/doc/91128973/',
    BARRACUDA_ESSENTIALS: 'https://www.barracuda.com/products/essentials',
    BARRACUDA_CONTENT_SHIELD: 'https://www.barracuda.com/fir-bcs',
    BARRACUDA_PHISHLINE_VIDEO: 'https://campus.barracuda.com/to/12US',
    BARRACUDA_SETUP_MX_RECORDS: 'https://campus.barracuda.com/doc/98213466/',
    BARRACUDA_ESS_LOG: {
      NON_US: 'https://ess.REGION.barracudanetworks.com/log',
      US: 'https://ess.barracudanetworks.com/log'
    },
    REACT_INTERFACE_URL:
      'https://esstimeline.barracudanetworks.com/publications/new-react-platform-launch-for-end-user-interface',
    FORENSICS_HOSTNAME: process.env.REACT_APP_FORENSICS_HOSTNAME || 'forensics.barracudanetworks.com'
  },
  DATETIME: {
    DEFAULT_DATE_FORMAT: 'MMM dd, yyyy',
    DEFAULT_DATE_WITH_TIME_FORMAT: 'MMM dd, yyyy h:mm a',
    DATE_TIME_PICKER_FORMAT: 'MMM dd, yyyy hh:mm:ss a',
    DATE_PICKER_FORMAT: 'MMM dd, yyyy',
    DEFAULT_ACCEPTED_DATE_FORMAT: 'MM/dd hh:mm:ss a',
    REDELIVERY_QUEUE_DATE_FORMAT: 'hh:mm a ZZZZ',
    EXPORT_FILE_NAME_DATE_FORMAT: 'yyyy-MM-dd',
    EXPORT_DATE_FORMAT: 'M/d/yy HH:mm',
    TIME_FORMAT: 'h:mm a',
    AUDIT_LOG_DATE_WITH_TIME_FORMAT: 'MMM dd, hh:mm a',
    AUDIT_API_DATE_WITH_TIME_FORMAT: 'yyyy-MM-dd HH:mm:ss.uuu',
    ATP_LOG_DATE_WITH_TIME_FORMAT: 'yyyy-MM-dd HH:mm:ss',
    ATP_API_DATE_WITH_TIME_FORMAT: 'yyyy-MM-dd HH:mm:ss.uuu'
  },
  DEFAULT_ERROR_MESSAGE: 'default',
  BCC_LOGIN_TEST_CREDENTIALS: {
    CLOUD_AT_TOKEN: process.env.BCC_CLOUD_AT_TOKEN,
    PORTAL_ID: process.env.BCC_PORTAL_ID
  },
  API_ERRORS: {
    UNSUPPORTED_MSTORE_SEARCH_WITH_USER_ID: 'Message log search for an email address is only supported for domains'
  },
  QUERY_PARAMS: {
    MIGRATE_TOKEN: 'migrate-token'
  },
  LICENSING_COMPLIANCE_PATH: `/${API_PATHNAME && `${API_PATHNAME}/`}internal/licenses`
} as const

export default APP_CONFIG
