import React, { useState, useMemo, useEffect, useCallback } from 'react'

import { useFormatMessage } from 'lib/localization'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { postAllowSender, postRedeliverMessage } from 'redux/features/mstore/mstoreSlice'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { MessageId } from 'redux/features/mstore/mstoreApiThunks'
import { Action } from 'types/Messages'

export interface UseRedeliveryDialogLogic {
  deliverDialogForm: string
  setDeliverDialogForm: (e: React.ChangeEvent<HTMLInputElement>) => void
  userAllowlistEnabled: boolean
  handleDeliverDialogClick: () => void
}

export interface UseRedeliveryDialogLogicProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.message_log.action'

export const useRedeliveryDialogLogic = (props: UseRedeliveryDialogLogicProps): UseRedeliveryDialogLogic => {
  const dispatch = useAppDispatch()
  const [deliverDialogForm, setDeliverDialogFormChange] = useState('deliverOnly')
  const { accountPermissions, redeliveryQueueMessages } = useAppSelector(_store => ({
    accountPermissions: _store.settings.accountPermissions,
    redeliveryQueueMessages: _store.mstore.redeliveryQueueMessages
  }))
  const [userAllowlistEnabled, setUserAllowlistEnabled] = useState(false)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const { onClose } = props

  useEffect(() => {
    if (accountPermissions) {
      setUserAllowlistEnabled([1, 2].includes(accountPermissions.defaultUserExemptEnabled))
    }
  }, [accountPermissions])

  const setDeliverDialogForm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDeliverDialogFormChange(e.target.value)
  }, [])

  const handleDeliverDialogClick = useCallback(() => {
    // collect the redelivery data of each of the checked messages
    const composedMessages: MessageId[] = []
    let blockedMessage = null
    redeliveryQueueMessages.forEach(message => {
      const isQuarantineDisabled =
        (message.status?.startsWith(Action.quarantined) || message.status?.startsWith(Action.allowedPartial)) &&
        accountPermissions?.defaultUserQuarDeliverEnabled !== 1
      const isBlockedDisabled =
        message.status?.startsWith(Action.blocked) && accountPermissions?.defaultUserDeliverEnabled !== 1

      const blockedState = isBlockedDisabled ? Action.blocked : null
      blockedMessage = isQuarantineDisabled ? Action.quarantined : blockedState

      if (blockedMessage) {
        return
      }

      composedMessages.push({ messageId: message.mid })
    })

    if (blockedMessage) {
      dispatch(
        setErrorSnackBar({
          message: 'stop_delivery_admin',
          params: [formatMessage(blockedMessage)]
        })
      )
      onClose()
      return
    }

    if (deliverDialogForm === 'deliverOnly') {
      dispatch(postRedeliverMessage(composedMessages))
    } else if (deliverDialogForm === 'deliverAllowSender') {
      dispatch(postAllowSender(composedMessages))
    }
    onClose()
  }, [accountPermissions, deliverDialogForm, dispatch, redeliveryQueueMessages, onClose, formatMessage])

  return useMemo(
    () => ({
      deliverDialogForm,
      setDeliverDialogForm,
      userAllowlistEnabled,
      handleDeliverDialogClick
    }),
    [deliverDialogForm, setDeliverDialogForm, userAllowlistEnabled, handleDeliverDialogClick]
  )
}
