import { useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'

import { useMstore } from 'lib/useMstore'
import { isLargeMessage as isLargeMessageFn } from 'lib/email'

export const useMessageDetailsContentLogic = () => {
  const { contentViewFlags, isAtpAffected: isAtpAffectedFn } = useMstore()
  const { message, accountPermissions } = useAppSelector(_store => ({
    message: _store.mstore.message,
    accountPermissions: _store.settings.accountPermissions
  }))

  const isLargeMessage = useMemo(() => message && isLargeMessageFn(message.fields.size), [message])

  const isAtpAffected = useMemo(() => {
    if (!message) {
      return undefined
    }
    return isAtpAffectedFn(message)
  }, [message, isAtpAffectedFn])

  const { cantViewBlocked, cantViewQuarantined } = useMemo(() => {
    if (!message || !accountPermissions) {
      return { cantViewBlocked: undefined, cantViewQuarantined: undefined }
    }
    return contentViewFlags(accountPermissions, message.fields)
  }, [message, accountPermissions, contentViewFlags])

  const hasContent = useMemo(() => !!message?.body && !!Number(message.fields.size), [message])

  const hasImages = useMemo(() => message?.hasImages, [message])

  const hasVirus = useMemo(() => !!message?.virus?.length || undefined, [message])

  return useMemo(
    () => [
      {
        cantViewBlocked,
        cantViewQuarantined,
        hasContent,
        hasImages,
        hasVirus,
        isAtpAffected,
        isLargeMessage
      }
    ],
    [cantViewBlocked, cantViewQuarantined, hasContent, hasImages, hasVirus, isAtpAffected, isLargeMessage]
  )
}
