import React, { useCallback, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import LogoURL from 'assets/images/barracuda/EP-EmailGatewayDefense_rev.svg'
import { logout } from 'redux/features/auth/authSlice'
import appConfig from 'config/appConfig'
import { getSessionCookie } from 'lib/cookies'
import routesConfig from 'lib/routesConfig'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

export default function navbarLogic<P>(WrappedComponent: React.ComponentType<P>) {
  const NavbarLogic: React.FC = (props: any) => {
    const { userEmail, activePathId } = useAppSelector(_store => ({
      userEmail: _store.auth.accessTokenObject?.userId,
      activePathId: _store.app.activePath.id
    }))
    const dispatch = useAppDispatch()
    const { userHasRight } = useUserRights()

    const onLogout = useCallback(() => {
      dispatch(logout())
    }, [dispatch])

    const showLogoutMenu = useMemo(() => {
      if (appConfig.APP.IS_WIZARD) {
        return false
      }

      const isAdminMessageDetails =
        userHasRight(UserRights.VIEW_ADMIN_MESSAGES_DETAILS) && activePathId === routesConfig.MESSAGE_DETAIL.id
      return !!getSessionCookie() && !isAdminMessageDetails
    }, [userHasRight, activePathId])

    return useMemo(
      () => (
        <WrappedComponent
          {...(props as any)}
          barracudaProductLogo={LogoURL}
          isNavbarVisible={!!userEmail}
          onLogout={onLogout}
          showLogoutMenu={showLogoutMenu}
          userEmail={userEmail || ''}
        />
      ),
      [userEmail, onLogout, props, showLogoutMenu]
    )
  }

  return NavbarLogic
}
