import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

import { setSuccessSnackBar, setErrorSnackBar } from 'redux/features/app/appSlice'

export interface PostFpfnReportPayload {
  messageId: string
  headerFrom: string
  envelopeFrom: string
  type: string
}
export type PostFpfnReport = { redirectUrl: string }[]

export const postFpfnReport = createAsyncThunk<PostFpfnReport, PostFpfnReportPayload, ApiRejectResponse>(
  'FPFN/postFpfnReport',
  async (payload, { rejectWithValue, dispatch }) => {
    const { messageId, ...request } = payload

    try {
      const resp = await restClient(apiRoutes.FPFN_REPORT, {
        data: { ...request },
        urlParams: { messageId }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_fpfn_report_success'
        })
      )

      return resp.data.results
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_fpfn_report_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)
