import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'redux/toolkit/hooks'

export const useRemediated = (): boolean | undefined => {
  const [searchParams] = useSearchParams()
  const [remediated, setRemediated] = useState<boolean>()
  const { message, search } = useAppSelector(_store => ({
    message: _store.mstore.message,
    search: _store.mstore.search
  }))

  // Get remediated flag from redux if available, from URL params otherwise
  // Leave remediated flag undefined is not available from any source
  useEffect(() => {
    if (!message) {
      return
    }
    const messageInSearch = search.messages?.find(item => item.mid === message.mid)
    if (messageInSearch) {
      setRemediated(messageInSearch.remediated)
      return
    }
    const remediatedParam = searchParams.get('remediated')
    if (remediatedParam === null) {
      return
    }
    setRemediated(remediatedParam === 'true')
  }, [message, search, searchParams])

  return remediated
}
