import { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from 'redux/toolkit/hooks'
import { history } from 'lib/browserHistory'
import { useFormatMessage } from 'lib/localization'
import { TabsComponentProps } from 'components/libs/tabs/Tabs'
import { UserType } from 'types/auth'

export interface UseSettingsLogic {
  tabsConfig: TabsComponentProps
}

export const QUARANTINE_NOTIFICATION = 'quarantine_notification'
export const SENDER_POLICIES = 'sender_policies'
export const LINKED_ACCOUNTS = 'linked_accounts'
export const CHANGE_PASSWORD = 'change_password'

const TABS = [
  {
    id: QUARANTINE_NOTIFICATION
  },
  {
    id: SENDER_POLICIES
  },
  {
    id: LINKED_ACCOUNTS
  },
  {
    id: CHANGE_PASSWORD
  }
]

const BASE_I18N_KEY = 'ess.settings.tabs'

export const useSettingsLogic = (): UseSettingsLogic => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const tabSearched: string | null = searchParams.get('tab')
  const [activeTab, setActiveTab] = useState(tabSearched || QUARANTINE_NOTIFICATION)
  const { accessTokenObject } = useAppSelector(_store => ({
    accessTokenObject: _store.auth.accessTokenObject
  }))

  const tabsConfig = useMemo(() => {
    function filterTabs(tab: { id: string }) {
      if (accessTokenObject?.type?.toLowerCase() !== UserType.manual && tab.id === CHANGE_PASSWORD) {
        return false
      }
      return true
    }
    return {
      activeTab,
      tabs: TABS.filter(filterTabs).map(tab => ({
        id: tab.id,
        onClick: () => {
          setActiveTab(tab.id)
          history.push({
            pathname: '/webui/settings',
            search: `tab=${tab.id}`
          })
        },
        label: formatMessage(tab.id)
      }))
    } as TabsComponentProps
  }, [activeTab, formatMessage, accessTokenObject])

  return useMemo(
    () => ({
      tabsConfig
    }),
    [tabsConfig]
  )
}
