import React, { useMemo } from 'react'

import {
  Button,
  Grid,
  LinearProgress,
  SelectLabel,
  SelectMenuItem,
  SelectWrapper,
  Typography
} from '@cuda-networks/bds-core'
import { HelpOutline } from '@cuda-networks/bds-core/dist/Icons/Core'

import Choropleth from 'components/libs/nivo/choropleth/Choropleth'
import Select from 'components/libs/bds/Select'
import overviewStyles from 'components/pages/overview/overviewStyles'
import styles from 'components/pages/overview/dashboard/dashboardStyles'
import { useFormatMessage } from 'lib/localization'
import ContentLoader from 'components/libs/contentLoader/ContentLoader'
import ContentLoaderErrorPage from 'components/libs/contentLoader/ContentLoaderErrorPage'

import { ALL_DOMAINS, TimeRanges, useDashboardLogic } from 'components/pages/overview/dashboard/useDashboardLogic'
import TopDomains from 'components/pages/overview/dashboard/topDomains/TopDomains'
import InboundTopBlocked from 'components/pages/overview/dashboard/inboundTopBlocked/InboundTopBlocked'
import OutboundTopBlocked from 'components/pages/overview/dashboard/outboundTopBlocked/OutboundTopBlocked'
import LastBlocked from 'components/pages/overview/dashboard/lastBlocked/LastBlocked'
import InboundEmailStatistics from 'components/pages/overview/dashboard/emailStatisticsCharts/InboundEmailStatistics'
import OutboundEmailStatistics from 'components/pages/overview/dashboard/emailStatisticsCharts/OutboundEmailStatistics'
import TotalThreatsAndViruses from 'components/pages/overview/dashboard/totalThreatsVirusesChart/TotalThreatsVirusesChart'

const BASE_I18N_KEY = 'ess.overview.dashboard'

const Dashboard: React.FC = () => {
  const overviewClasses = overviewStyles()
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useDashboardLogic()

  return useMemo(
    () => (
      <Grid container direction="column">
        <Grid className={overviewClasses.titleContainer} alignItems="center" container direction="row">
          <Grid xs item>
            <Grid container direction="row">
              <Typography variant="h2">{formatMessage('title')}</Typography>
              <HelpOutline
                data-testid="on-open-support"
                onClick={eventHandlers.onOpenSupport}
                className={overviewClasses.helpIcon}
              />
            </Grid>
          </Grid>
          <Grid className={classes.whatsNewContainer}>
            <Button data-testid="on-check-whats-new" onClick={eventHandlers.onCheckWhatsNew} variant="text">
              {formatMessage('whats_new')}
            </Button>
          </Grid>
        </Grid>
        {state.isGetAvailableDomainsInProgress && <LinearProgress data-testid="loading-state" />}
        {state.isGetAvailableDomainsFailed && <ContentLoaderErrorPage />}

        {state.availableDomains && (
          <Grid
            container
            className={classes.dashboardContentWrapper}
            data-testid="dashboard-content"
            direction="column"
          >
            <Grid className={classes.SelectorsWrapper} container direction="row">
              <Grid data-testid="domain-selector" item>
                <SelectWrapper className={classes.domainSelector} variant="outlined">
                  <SelectLabel>{formatMessage('select_domain')}</SelectLabel>
                  <Select
                    data-testid="domains-selector"
                    label={formatMessage('select_domain')}
                    onChange={eventHandlers.onSelectDomain}
                    value={state.selectedDomain?.domainId || (state.shouldShowAllDomainsInSelector ? ALL_DOMAINS : '')}
                  >
                    {state.shouldShowAllDomainsInSelector && (
                      <SelectMenuItem data-testid="select-all-domains" value={ALL_DOMAINS}>
                        {formatMessage('all')}
                      </SelectMenuItem>
                    )}
                    {state.availableDomains?.map(domain => (
                      <SelectMenuItem data-testid={domain.domainId} key={domain.domainId} value={domain.domainId}>
                        {domain.domainName}
                      </SelectMenuItem>
                    ))}
                  </Select>
                </SelectWrapper>
              </Grid>

              <Grid item>
                <SelectWrapper variant="outlined">
                  <SelectLabel>{formatMessage('time')}</SelectLabel>
                  <Select
                    data-testid="time-range-selector"
                    label={formatMessage('time')}
                    onChange={eventHandlers.onSelectTimeRange}
                    value={state.selectedTimeRange}
                  >
                    {Object.values(TimeRanges).map(range => (
                      <SelectMenuItem data-testid={range} key={range} value={range}>
                        {formatMessage(range)}
                      </SelectMenuItem>
                    ))}
                  </Select>
                </SelectWrapper>
              </Grid>
            </Grid>

            <Grid className={classes.contentSectionWrapper} container direction="row">
              <Grid data-testid="threat-origins-section" className={classes.threatOriginsWrapper} item>
                <Grid container direction="column">
                  <Typography className={classes.subtitle} variant="subtitle1">
                    {formatMessage('threat_origins')}
                  </Typography>
                  <ContentLoader isLoading={state.isGetGeoDataStatsInProgress} isFailed={state.isGetGeoDataStatsFailed}>
                    <Grid item className={classes.geomap}>
                      <Choropleth data={state.geoData?.data || []} />
                    </Grid>
                  </ContentLoader>
                </Grid>
              </Grid>
              <Grid className={classes.tableWrapper} item>
                <TopDomains domainId={state.selectedDomain?.domainId} range={state.range} />
              </Grid>
            </Grid>

            <Grid className={classes.contentSectionWrapper} container direction="row">
              <Grid data-testid="inbound-email-section" className={classes.chartWrapper} item>
                <InboundEmailStatistics range={state.range} domainId={state.selectedDomain?.domainId} />
              </Grid>
              <Grid className={classes.tableWrapper} item>
                <InboundTopBlocked domainId={state.selectedDomain?.domainId} range={state.range} />
              </Grid>
            </Grid>

            <Grid className={classes.contentSectionWrapper} container direction="row">
              <Grid data-testid="outbound-email-section" className={classes.chartWrapper} item>
                <OutboundEmailStatistics range={state.range} domainId={state.selectedDomain?.domainId} />
              </Grid>
              <Grid className={classes.tableWrapper} item>
                <OutboundTopBlocked domainId={state.selectedDomain?.domainId} range={state.range} />
              </Grid>
            </Grid>

            <Grid className={classes.contentSectionWrapper} container direction="row">
              <Grid data-testid="total-section" className={classes.chartWrapper} item>
                <TotalThreatsAndViruses range={state.range} domainId={state.selectedDomain?.domainId} />
              </Grid>
              <Grid className={classes.tableWrapper} item>
                <LastBlocked domainId={state.selectedDomain?.domainId} range={state.range} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {state.availableDomains && (
          <Grid className={classes.footer} container direction="column">
            <Typography className={classes.footerText} variant="body2">{`${formatMessage('footer.account_id')}: ${
              state.footerConfig.accountId
            } (${state.footerConfig.essAccountId})`}</Typography>
            <Typography className={classes.footerText} variant="body2">{`${formatMessage('footer.serial_number')}: ${
              state.footerConfig.serialNumber
            }`}</Typography>
            <Typography className={classes.footerText} variant="body2">{`${formatMessage('footer.version')}: ${
              state.footerConfig.version
            }`}</Typography>
          </Grid>
        )}
      </Grid>
    ),
    [classes, overviewClasses, formatMessage, state, eventHandlers]
  )
}

export default Dashboard
