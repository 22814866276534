import { useState, useMemo, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useFormatMessage } from 'lib/localization'
import { TabsComponentProps } from 'components/libs/tabs/Tabs'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import { useAppSelector } from 'redux/toolkit/hooks'
import { FEATURES, isMyFeatureOn } from 'lib/splitio'
import appFeatures from 'config/appFeatures'

export enum OverviewTabs {
  dashboard = 'dashboard',
  messageLog = 'message_log',
  atpLog = 'atp_log',
  outboundQuarantine = 'outbound_quarantine',
  auditLog = 'audit_log',
  reviewLicense = 'review_license'
}

const TABS: [OverviewTabs, UiRoute][] = [
  [OverviewTabs.dashboard, routesConfig.OVERVIEW_DASHBOARD],
  [OverviewTabs.messageLog, routesConfig.OVERVIEW_MESSAGE_LOG],
  [OverviewTabs.atpLog, routesConfig.OVERVIEW_ATP_LOG],
  [OverviewTabs.outboundQuarantine, routesConfig.OVERVIEW_OUTBOUND_QUARANTINE],
  [OverviewTabs.auditLog, routesConfig.OVERVIEW_AUDIT_LOG],
  [OverviewTabs.reviewLicense, routesConfig.OVERVIEW_LICENSE_COMPLIANCE]
]

const BASE_I18N_KEY = 'ess.overview.tabs'

export const useOverviewLogic = (): [TabsComponentProps] => {
  const location = useLocation()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const isLicenseComplianceOn =
    appFeatures.EnabledLicenseCompliance || isMyFeatureOn(FEATURES.EGD_React_License_Compliance_Rollout)

  const { serial } = useAppSelector(_stores => ({
    serial: _stores.auth.accessTokenObject?.serial
  }))

  // set the initial tab
  const activeInititalTab = useCallback(
    () =>
      TABS.find(tab => {
        if ((!serial || !isLicenseComplianceOn) && tab[0] === OverviewTabs.reviewLicense) {
          return false
        }
        const [, routeConfig] = tab
        return location.pathname.includes(routeConfig.path)
      }),
    [serial, isLicenseComplianceOn, location.pathname]
  )

  const [activeTab, setActiveTab] = useState<OverviewTabs | undefined>(activeInititalTab()?.[0])

  // init
  useEffect(() => {
    if (!activeInititalTab()) {
      routesConfig.OVERVIEW_DASHBOARD.goto()
    }
    // eslint-disable-next-line
  }, [])

  const tabsConfig: TabsComponentProps = useMemo(() => {
    function filterTabs(tab: [OverviewTabs, UiRoute]) {
      if ((!serial || !isLicenseComplianceOn) && tab[0] === OverviewTabs.reviewLicense) {
        return false
      }
      return true
    }
    return {
      activeTab,
      tabs: TABS.filter(filterTabs).map(tab => {
        const [tabId, routeConfig] = tab

        return {
          id: tabId,
          onClick: () => {
            setActiveTab(tabId)
            routeConfig?.softGoto()
          },
          label: formatMessage(tabId)
        }
      })
    }
  }, [activeTab, formatMessage, serial, isLicenseComplianceOn])

  return useMemo(() => [tabsConfig], [tabsConfig])
}
