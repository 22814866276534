import { MetadataResponse } from './general'

export interface Metadata {
  accountId: string
  bccAccountId: string
  domainId: number
  userId: string
}

export enum PolicyStatus {
  block = 'block',
  exempt = 'exempt',
  quarantine = 'quarantine'
}

export interface Policy {
  comment: string
  id: string
  modified: number
  name: string
  policy: PolicyStatus
  isDisabled?: boolean
  isConflict?: number
}

export const HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] as const
export type Hour = (typeof HOURS)[number]

export const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const
export type Day = (typeof DAYS)[number]

export type InboundScheduleValue = Hour[]
export type InboundSchedule = {
  [K in Day]: InboundScheduleValue
}

export type InboundScheduleUIValue = { [K in Hour]?: true }
export type InboundScheduleUI = {
  [K in Day]: InboundScheduleUIValue
}

export enum InboundScheduleType {
  never = 'never',
  custom = 'custom'
}

export interface InboundScheduleReport {
  allowWhitelist: number
  created: string | null
  customInboundSchedule: InboundSchedule
  inboundSchedule: InboundScheduleType
  modified: string | null
  timezone: string
}

export interface LinkedAccount {
  email: string
  id?: string
  verificationPending?: boolean
}

export interface AccountPermission {
  allowQuarantineNotificationIntervalChange: number
  defaultTimezone: string
  defaultUserBlockEnabled: number
  defaultUserDeliverEnabled: number
  defaultUserExemptEnabled: number
  defaultUserQuarDeliverEnabled: number
  emergencyInboxEnabled: number
  searchLogsAllDomainsLimit: number
  searchLogsAllDomainsLowLimit: number
  hasForensicsAccess: boolean
}

export interface QuarantineNotificationReport {
  metadata: Metadata
  results: InboundScheduleReport[]
}

export interface QuarantineNotificationUI {
  inboundSchedule: InboundScheduleType
  customInboundSchedule: InboundScheduleUI
}

export interface SenderPoliciesReport {
  itemsTotal: number
  metadata: Metadata
  pageNum: number
  pagesTotal: number
  results: Policy[]
  resultsCount: number
}

export interface SenderPoliciesResponse {
  data: SenderPoliciesReport
  metadata: MetadataResponse
}

export interface LinkedAccountsReport {
  metadata: Metadata
  results: LinkedAccount[]
}

export interface AccountPermissionsReport {
  metadata: Metadata
  results: AccountPermission[]
}
