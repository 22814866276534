import { useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import { getRedeliveryQueueMessages, MlogViewConfig, resetSelectedMessage } from 'redux/features/mstore/mstoreSlice'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

import { SplitterOrientation } from 'components/libs/splitter/splitterTypes'
import {
  MessageLogPreloadLogic,
  useMessageLogPreloadLogic
} from 'components/pages/messageLog/useMessageLogPreloadLogic'

export interface UseMessageLogLogic extends MessageLogPreloadLogic {
  inProgress: boolean
  showSplitter: boolean
  isHorizontal: boolean
  viewConfig: MlogViewConfig
  isAdmin: boolean
}

export const useMessageLogLogic = (): UseMessageLogLogic => {
  const { uiState, preloadErrors } = useMessageLogPreloadLogic()
  const dispatch = useAppDispatch()

  const { inProgress, splitter, selectedMessageMid, viewConfig, isDeleteMessageSuccess, deletedMessageIds } =
    useAppSelector(_store => ({
      inProgress: isPending(_store.mstore.getSearchApiStatus),
      splitter: _store.app.splitter,
      selectedMessageMid: _store.mstore.selectedMessageMid,
      viewConfig: _store.mstore.viewConfig,
      isDeleteMessageSuccess: isSuccess(_store.mstore.deleteMessageApiStatus),
      deletedMessageIds: _store.mstore.deletedMessageIds
    }))
  const { userHasRight } = useUserRights()

  // Init
  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.MESSAGE_LOG_PAGE_VIEW)
    const timer = setInterval(() => {
      dispatch(getRedeliveryQueueMessages())
    }, 30000)

    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line
  }, [])

  const showSplitter = useMemo(
    () => !!selectedMessageMid && splitter.orientation !== SplitterOrientation.none,
    [splitter, selectedMessageMid]
  )

  useEffect(() => {
    if (isDeleteMessageSuccess && deletedMessageIds && selectedMessageMid) {
      if (deletedMessageIds.includes(selectedMessageMid)) {
        dispatch(resetSelectedMessage())
      }
    }
  }, [isDeleteMessageSuccess, selectedMessageMid, deletedMessageIds, dispatch])

  return useMemo(
    () => ({
      inProgress,
      showSplitter,
      isHorizontal: splitter.orientation === SplitterOrientation.horizontal,
      uiState,
      preloadErrors,
      viewConfig,
      isAdmin: userHasRight(UserRights.USE_ADMIN_VERSION_OF_MESSAGE_LOG)
    }),
    [inProgress, preloadErrors, showSplitter, splitter.orientation, uiState, viewConfig, userHasRight]
  )
}
