import { colors, makeStyles } from '@cuda-networks/bds-core'

const TABLE_ROW_HEIGHT = 23
const TABLE_HEADER_HEIGHT = 60
const TABLE_HEIGHT = 10 * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT

export default makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& #last_blocked': {
      '& .k-grid-header .k-header:first-child': {
        paddingLeft: theme.spacing(1)
      },
      '& td:first-child': {
        paddingLeft: '8px !important'
      }
    }
  },
  tableWrapper: {
    flex: 1
  },
  table: {
    borderWidth: 0,
    height: TABLE_HEIGHT,
    '& th': {
      padding: theme.spacing(0.5)
    },
    '& tr': {
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    },
    '& th:last-child': {
      paddingRight: theme.spacing(1)
    },
    '& td': {
      cursor: 'default',
      borderWidth: 0,
      padding: theme.spacing(0.5),
      height: 16
    },
    '& td:last-child': {
      paddingRight: theme.spacing(1)
    },
    '& .k-grid-content': {
      marginTop: 4
    }
  },
  alignRight: {
    textAlign: 'right !important' as any
  },
  subtitle: {
    marginBottom: theme.spacing(0.6)
  },
  dropdown: {
    height: 20,
    '& > div:focus': {
      backgroundColor: 'inherit'
    },
    '&:before': {
      // normal
      borderBottom: 'none !important'
    },
    '&:after': {
      // focused
      borderBottom: 'none !important'
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: 'none !important'
    }
  },
  noDataFound: {
    marginTop: theme.spacing(10)
  },
  circleWrapper: {
    textAlign: 'center',
    padding: theme.spacing(0.7)
  },
  circle: {
    display: 'inline-block',
    backgroundColor: 'transparent',
    border: `1px solid ${colors.scaleGray500}`,
    width: 8,
    height: 8,
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: colors.scaleGray500,
      width: 9,
      height: 9
    }
  },
  logTableProgress: {
    height: '3px'
  }
}))
