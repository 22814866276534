import { makeStyles } from '@cuda-networks/bds-core'

export interface StyleProps {
  isAdmin: boolean
}

export default makeStyles(theme => ({
  logTableContainer: {
    marginBottom: `-${theme.layout.footerHeight}`,
    height: ({ isAdmin }: StyleProps) =>
      `calc(100vh - ${
        // Admin view has the sub-tabs too with min-height 48
        theme.layout.navbarHeight + theme.layout.footerHeight - theme.layout.splitterHeight + (isAdmin ? 48 : 0)
      }px)`,
    justifyContent: 'center',
    alignItems: 'center'
  },
  logTableCard: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  logTableCardContent: {
    height: '100%',
    padding: '12px 0 0 0 !important',
    display: 'flex',
    flexDirection: 'column'
  },
  logTableProgress: {
    height: '3px'
  },
  logTableContent: {
    flexGrow: 1,
    minHeight: 0
  },
  logTableTitle: {
    marginLeft: '25px',
    fontSize: '18px',
    height: `${theme.layout.logTableTitleHeight}px`
  },
  tableBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawerBox: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'flex-start'
  },
  contentGrid: {
    height: '100%'
  },
  alert: {
    margin: theme.spacing(1)
  }
}))
