import { makeStyles, colors } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  login: {
    backgroundColor: theme.palette.info.main,
    height: '100%'
  },
  logoBanner: {
    backgroundColor: theme.palette.text.primary,
    height: 60,
    width: '100%'
  },
  logo: {
    height: 40,
    margin: 10,
    cursor: 'pointer'
  },
  loginCard: {
    width: 600,
    height: 315
  },
  passwordCard: {
    width: 600,
    height: 273
  },
  messageCard: {
    width: 600,
    height: 294
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: `url("/login_background.jpg") center center no-repeat, linear-gradient(${theme.palette.primary.dark}, ${colors.skyBlue500}), ${colors.chartBlueDark}`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0'
  },
  snackBar: {
    marginBottom: 32,
    borderRadius: 32,
    boxShadow: `5px 10px 18px ${colors.scaleGray200}`
  },
  progress: {
    height: 3,
    marginTop: 2
  },
  loginCardTitle: {
    marginBottom: 10
  },
  loginCardForm: {
    height: 80
  },
  loginCardActionGridContainer: {
    marginTop: 30
  },
  passwordCardContentTitle: {
    marginBottom: 10
  },
  passwordCardInput: {
    height: 60
  },
  passwordCardActionGridContainer: {
    marginTop: 0
  },
  forgotPasswordLink: {
    marginTop: 30
  },
  passwordCardActionLinks: {
    marginTop: 20
  },
  flexLink: {
    display: 'flex'
  },
  messageCardContent: {
    marginBottom: 10
  },
  messageCardSubtitle: {
    marginBottom: 5
  },
  resendLinkBox: {
    marginTop: 13
  },
  differentUserLoginBox: {
    marginTop: 63
  },
  cardContent: {
    paddingBottom: '16px !important'
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  }
}))
