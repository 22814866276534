import React from 'react'
import { Routes, Route } from 'react-router-dom'

import routesConfig, { UiRoute } from 'lib/routesConfig'

import MessageLogPage from 'components/pages/messageLog/MessageLogPage'
import Overview from 'components/pages/overview/Overview'

import LicenseContent from 'components/pages/license/LicenseContent'
import Layout from '../layout/Layout'

import MessageDetail from '../../pages/messageDetail/MessageDetail'
import Settings from '../../pages/settings/Settings'
import Support from '../../pages/support/Support'
import Help from '../../pages/support/help/Help'
import Login from '../../pages/login/Login'
import AutoLogin from '../../pages/actions/autoLogin/AutoLogin'
import AzureSSO from '../../pages/azureSSO/AzureSSO'
import BccLogin from '../../pages/bccLogin/BccLogin'
import BulkEdit from '../../pages/settings/bulkEdit/BulkEdit'
import VerifyLinkAccount from '../../pages/actions/verifyLinkAccount/VerifyLinkAccount'
import ResetPassword from '../../pages/actions/resetPassword/ResetPassword'
import OnboardWizard from '../../pages/onboardWizard/OnboardWizard'
import UnregisteredPage from '../../pages/unregisteredPage/UnregisteredPage'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

export type RouteParams = [UiRoute, React.FC]

const publicRoutes: RouteParams[] = [
  // login
  [routesConfig.LOGIN, Login],
  [routesConfig.AUTO_LOGIN, AutoLogin],
  [routesConfig.AZURE_SSO, AzureSSO],
  [routesConfig.BCC_LOGIN, BccLogin],

  // verify link account
  [routesConfig.VERIFY_LINK_ACCOUNT, VerifyLinkAccount],
  [routesConfig.RESET_PASSWORD, ResetPassword]
]

const privateRoutes: RouteParams[] = [
  // Overview
  [routesConfig.OVERVIEW, Overview],
  [routesConfig.OVERVIEW_DASHBOARD, Overview],
  [routesConfig.OVERVIEW_MESSAGE_LOG, Overview],
  [routesConfig.OVERVIEW_ATP_LOG, Overview],
  [routesConfig.OVERVIEW_OUTBOUND_QUARANTINE, Overview],
  [routesConfig.OVERVIEW_AUDIT_LOG, Overview],
  [routesConfig.OVERVIEW_LICENSE_COMPLIANCE, Overview],

  // // MessageLog
  [routesConfig.MESSAGE_LOG, MessageLogPage],
  [routesConfig.MESSAGE_DETAIL, MessageDetail],

  // Settings
  [routesConfig.SETTINGS, Settings],
  [routesConfig.SENDER_POLICIES_BULK_EDIT, BulkEdit],

  // Support
  [routesConfig.SUPPORT, Support],
  [routesConfig.SUPPORT_HELP_SEEN_RECIPIENTS, Help],
  [routesConfig.SUPPORT_HELP_DASHBOARD, Help],
  [routesConfig.SUPPORT_HELP_COMPLIANCE, Help],
  [routesConfig.SUPPORT_HELP_ADMIN_LOG, Help],
  [routesConfig.SUPPORT_HELP_ATP_LOG, Help],
  [routesConfig.SUPPORT_HELP_AUDIT_LOG, Help],
  [routesConfig.SUPPORT_HELP_DOMAIN_VERIFICATION, Help],
  [routesConfig.SUPPORT_HELP_MAIL_SERVER_TEST, Help],
  [routesConfig.SUPPORT_HELP_DOMAIN_MANAGER, Help],
  [routesConfig.SUPPORT_HELP_DOMAIN_SETTINGS, Help],
  [routesConfig.SUPPORT_HELP_RECIPIENT_LIST, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_ANTI_VIRUS, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_CUSTOM_RBLS, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_IP_ADDRESS_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_REGIONAL_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_RECIPIENT_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_SENDER_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_SENDER_AUTHENTICATION, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_CONTENT_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_RATE_CONTROL, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_ANTI_PHISHING, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_ATP, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_SENDER_IP, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_ANTI_VIRUS, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_TAGLINE, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_DLP, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_CONTENT_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_SENDER_NOTIFICATION, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_QUARANTINE_INBOX, Help],
  [routesConfig.SUPPORT_HELP_USERS_DEFAULT_POLICY, Help],
  [routesConfig.SUPPORT_HELP_USERS_EDIT, Help],
  [routesConfig.SUPPORT_HELP_USERS_EMAIL_CONTINUITY, Help],
  [routesConfig.SUPPORT_HELP_USERS_QUARANTINE, Help],
  [routesConfig.SUPPORT_HELP_USERS_LIST, Help],
  [routesConfig.SUPPORT_HELP_LINKED_ACCOUNTS, Help],
  [routesConfig.SUPPORT_HELP_ENDUSER_SENDER_POLICY, Help],
  [routesConfig.SUPPORT_HELP_ENDUSER_QUARANTINE_NOTIFICATION, Help],
  [routesConfig.SUPPORT_HELP_ENDUSER_LOG, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE1, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE2, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE3, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE4, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE5, Help],
  [routesConfig.SUPPORT_HELP_REGEX, Help],
  [routesConfig.SUPPORT_HELP_REPORTS_DOMAIN, Help],
  [routesConfig.SUPPORT_HELP_REPORTS_ALL_DOMAIN, Help],
  [routesConfig.SUPPORT_HELP_SYSLOG_INTEGRATION, Help],

  // Onboard Wizard
  [routesConfig.ONBOARD_WIZARD, OnboardWizard]
]

const AppRoutes = (
  <Layout>
    <Routes>
      {/* Public routes */}
      {publicRoutes
        .filter(routeparams => routeparams[0])
        .map(routeParams => {
          const [routeConfig, Component] = routeParams

          return (
            <Route
              key={routeConfig.id}
              path={routeConfig.path}
              element={<PublicRoute route={routeConfig} Component={Component} />}
            />
          )
        })}

      {/* Private routes */}
      {privateRoutes
        .filter(routeparams => routeparams[0])
        .map(routeParams => {
          const [routeConfig, Component] = routeParams

          return (
            <Route
              key={routeConfig.id}
              path={routeConfig.path}
              element={<PrivateRoute route={routeConfig} Component={Component} />}
            />
          )
        })}

      {/* 404 route */}
      <Route path="*" element={<PrivateRoute Component={UnregisteredPage} />} />
    </Routes>
  </Layout>
)

export default AppRoutes
