import { useEffect, useMemo } from 'react'
import { CategoryTypes } from 'types/categoryTypes'

import { EventHandlers, State } from 'components/pages/overview/dashboard/emailStatisticsCharts/EmailStatisticsChart'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending } from 'redux/toolkit/api'
import { getInboundEmailStatisticsStats } from 'redux/features/stats/statsSlice'
import { Action } from 'types/Messages'
import { StatComponentProps } from 'types/stats'
import {
  ChartAxisAlternativeCategoryNames,
  ChartAxisBottomValues,
  ChartColorCodes,
  ChartColors,
  ChartTotalValueKeys,
  ChartTotalValues,
  useEmailStatisticsChartLogic
} from 'components/pages/overview/dashboard/emailStatisticsCharts/useEmailStatisticsChartLogic'
import inboundChartDemoData from 'components/pages/overview/dashboard/emailStatisticsCharts/inboundChartDemoData'

export type UseInboundEmailStatisticsLogic = [State, EventHandlers]

const CHART_AXIS_BOTTOM_VALUES: ChartAxisBottomValues = {
  [Action.none]: [CategoryTypes.allowed, CategoryTypes.blocked, CategoryTypes.quarantined],
  [Action.blocked]: [
    CategoryTypes.blockedBbl,
    CategoryTypes.blockedSpam,
    CategoryTypes.blockedBrts,
    CategoryTypes.blockedAv
  ],
  [Action.allowed]: [CategoryTypes.allowed],
  [Action.quarantined]: [CategoryTypes.quarantined]
}

const CHART_AXIS_ALTERNATIVE_CATEGORY_NAMES: ChartAxisAlternativeCategoryNames = {}

const CHART_TOTAL_VALUES: ChartTotalValues = {
  [Action.none]: [
    [ChartTotalValueKeys.allowed, [CategoryTypes.allowed]],
    [ChartTotalValueKeys.total_blocked, [CategoryTypes.blocked]],
    [ChartTotalValueKeys.virus, [CategoryTypes.blockedAtd]],
    [ChartTotalValueKeys.atp, [CategoryTypes.blockedAtdFull, CategoryTypes.blockedAtdFull]],
    [ChartTotalValueKeys.quarantined, [CategoryTypes.quarantined]]
  ],
  [Action.blocked]: [
    [
      ChartTotalValueKeys.total,
      [CategoryTypes.blockedBbl, CategoryTypes.blockedSpam, CategoryTypes.blockedBrts, CategoryTypes.blockedAv]
    ],
    [ChartTotalValueKeys.brbl, [CategoryTypes.blockedBbl]],
    [ChartTotalValueKeys.spam, [CategoryTypes.blockedSpam]],
    [ChartTotalValueKeys.brts, [CategoryTypes.blockedBrts]],
    [ChartTotalValueKeys.virus, [CategoryTypes.blockedAv]],
    [ChartTotalValueKeys.atp, [CategoryTypes.blockedAtdFull, CategoryTypes.blockedAtdFull]]
  ],
  [Action.allowed]: [[ChartTotalValueKeys.allowed, [CategoryTypes.allowed]]],
  [Action.quarantined]: [[ChartTotalValueKeys.quarantined, [CategoryTypes.quarantined]]]
}

const CHART_COLORS: ChartColors = {
  [Action.none]: [ChartColorCodes.allowed, ChartColorCodes.blocked, ChartColorCodes.quarantined],
  [Action.blocked]: [ChartColorCodes.bbl, ChartColorCodes.spam, ChartColorCodes.brts, ChartColorCodes.virus],
  [Action.allowed]: [ChartColorCodes.allowed],
  [Action.quarantined]: [ChartColorCodes.quarantined]
}

export const useInboundEmailStatisticsLogic = (props: StatComponentProps): UseInboundEmailStatisticsLogic => {
  const dispatch = useAppDispatch()

  const { inboundEmailStatistics, inboundEmailStatisticsInProgress, inboundEmailStatisticsFailed } = useAppSelector(
    _store => ({
      inboundEmailStatistics: _store.stats.inboundEmailStatistics,
      inboundEmailStatisticsInProgress: isPending(_store.stats.api.getInboundEmailStatisticsApiStatus),
      inboundEmailStatisticsFailed: isFailed(_store.stats.api.getInboundEmailStatisticsApiStatus)
    })
  )
  const [chartConfig, selectedFilter, onSelectFilter] = useEmailStatisticsChartLogic({
    emailStatisticsData: inboundEmailStatistics,
    chartAxisBottomValues: CHART_AXIS_BOTTOM_VALUES,
    chartAxisAlternativeCategoryNames: CHART_AXIS_ALTERNATIVE_CATEGORY_NAMES,
    chartTotalValues: CHART_TOTAL_VALUES,
    chartColors: CHART_COLORS,
    demoData: inboundChartDemoData
  })

  // new props or selected new filter
  useEffect(() => {
    dispatch(
      getInboundEmailStatisticsStats({
        domainId: props.domainId,
        range: props.range,
        action: selectedFilter
      })
    )
  }, [dispatch, props.range, props.domainId, selectedFilter])

  return useMemo(
    () => [
      {
        chartConfig,
        inProgress: inboundEmailStatisticsInProgress,
        isFailed: inboundEmailStatisticsFailed,
        selectedFilter
      },
      {
        onSelectFilter
      }
    ],
    [chartConfig, inboundEmailStatisticsInProgress, inboundEmailStatisticsFailed, selectedFilter, onSelectFilter]
  )
}
