import { destroySplitSdk, getTreatments, initSplitSdk, selectTreatmentValue } from '@splitsoftware/splitio-redux'

import config from 'config/appConfig'
import reduxStore from 'lib/reduxStore'

let savedAccountId: string | undefined

export const FEATURES = {
  EGD_React_Overview_Rollout: 'EGD_React_Overview_Rollout',
  EGD_React_End_User_Rollout: 'EGD_React_End_User_Rollout',
  EGD_React_License_Compliance_Rollout: 'EGD_React_License_Compliance_Rollout',
  EGD_React_Language_Selector_Rollout: 'EGD_React_Language_Selector_Rollout'
} as const
export type Features = (typeof FEATURES)[keyof typeof FEATURES]

export interface InitSplitio {
  accountId: string
}

export enum SplitioStatuses {
  on = 'on',
  off = 'off'
}

export function checkSplitioFeatures() {
  reduxStore.dispatch(getTreatments({ splitNames: [...Object.values(FEATURES)] }))
}

export function initSplitio({ accountId }: InitSplitio) {
  if (accountId && savedAccountId !== accountId) {
    savedAccountId = accountId

    const sdkConfig: any = {
      core: {
        authorizationKey: config.ENVIRONMENT.IS_DEV && !config.SPLITIO.API_KEY ? 'localhost' : config.SPLITIO.API_KEY,
        key: accountId,
        trafficType: 'account'
      },
      features: {
        // use this feature values for 'localhost'
        [FEATURES.EGD_React_Overview_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_End_User_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_License_Compliance_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_Language_Selector_Rollout]: SplitioStatuses.on
      }
    }

    reduxStore.dispatch(initSplitSdk({ config: sdkConfig, onReady: checkSplitioFeatures }))
  }
}

export function isMyFeatureOn(feature: string): boolean {
  const splitStore = reduxStore.getState().splitio
  const isFeatureOn = selectTreatmentValue(splitStore, feature) === SplitioStatuses.on

  return isFeatureOn
}

export function destroySplitio() {
  if (savedAccountId) {
    reduxStore.dispatch(destroySplitSdk())
    savedAccountId = undefined
  }
}
