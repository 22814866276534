import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending } from 'redux/toolkit/api'

import { getOutboundTopBlockedStats } from 'redux/features/stats/statsSlice'

import { Range, StatType, TopRecipientDomains } from 'types/stats'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'

export interface UseOutboundTopBlockedLogicProps {
  domainId?: string
  range: Range
}

interface State {
  outboundTopBlockedStats: TopRecipientDomains | undefined
  outboundTopBlockedStatsInProgress: boolean
  selectedFilter: string
}

interface EventHandlers {
  onSelectFilter: (value: unknown) => void
}

export type UseOutboundTopBlockedLogic = [State, EventHandlers]

export const useOutboundTopBlockedLogic = (props: UseOutboundTopBlockedLogicProps): UseOutboundTopBlockedLogic => {
  const dispatch = useAppDispatch()

  const { outboundTopBlockedStats, outboundTopBlockedStatsInProgress } = useAppSelector(_store => ({
    outboundTopBlockedStats: _store.stats.outboundTopBlockedStats,
    outboundTopBlockedStatsInProgress: isPending(_store.stats.api.getOutboundTopBlockedStats)
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(
    getTableWithDropdownConfig(DisplayType.outbound)?.dropdownItems[0].value || ''
  )

  // new props or selected new filter
  useEffect(() => {
    dispatch(
      getOutboundTopBlockedStats({
        domainId: props.domainId,
        range: props.range,
        statType: selectedFilter === StatType.SENDERS ? StatType.SENDERS : StatType.RECIPIENTS
      })
    )
    // eslint-disable-next-line
  }, [dispatch, props.range, props.domainId, selectedFilter])

  const onSelectFilter: EventHandlers['onSelectFilter'] = useCallback(value => {
    setSelectedFilter(value as StatType)
  }, [])

  return useMemo(
    () => [
      {
        outboundTopBlockedStats,
        outboundTopBlockedStatsInProgress,
        selectedFilter
      },
      {
        onSelectFilter
      }
    ],
    [outboundTopBlockedStats, outboundTopBlockedStatsInProgress, selectedFilter, onSelectFilter]
  )
}
