export enum TokenTypes {
  Bearer = 'bearer'
}

export enum UserType {
  admin = 'admin',
  manual = 'manual',
  azure = 'azure_ad'
}

export interface UserInfoFromAccessToken {
  portal_id: string
  scope: string
  user_type: UserType
}

export interface UserInfo {
  authToken: string
  userId: number
  portalId: string
  defaultPortalId: string
  timezone: string
  name: string
  username: string
  hasAdmin: boolean
  isMsp: boolean
  guestUser: boolean
}

export interface User {
  companyId: string
  companyName: string
  accountId: string
  bccAccountId: string
  domainId: number
  sessionId: string
  type: UserType
  userId: string
  portalId: number
  scope: string
  userInfo?: UserInfo
  isCplAccount: boolean
  pdDomainId: string | null
  serial: string
  serialNumber: string
  isAzureAdAccount: boolean
  roleType: number
}

export interface LoginResponse {
  accessToken: string
  accessTokenExpires: number
  sessionId: string
  tokenType: TokenTypes
}
