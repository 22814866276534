/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  FormHelperText,
  Typography,
  Button,
  Link,
  Box
} from '@cuda-networks/bds-core'

import styles from 'components/pages/support/supportStyles'
import { useSupportLogic } from 'components/pages/support/useSupportLogic'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.support_page'
const BASE_I18N_TOPICS_KEY = 'ess.support_page.topics'

const Support: React.FC = () => {
  const classes = styles()
  const [state, eventHandlers] = useSupportLogic()
  const { searchResults, searchQuery, topics } = state
  const { onSubmitSearch, onLinkClick, onReset, onSearchQueryChange } = eventHandlers

  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatTopicsMessage = useFormatMessage(BASE_I18N_TOPICS_KEY)

  const SearchPanel = useMemo(
    () => (
      <Card elevation={1} className={classes.searchPanel}>
        <CardContent>
          <Typography variant="subtitle1">{formatMessage('search_box_title')}</Typography>
          {/* Search Support Topics */}
          <br />
          <TextField
            className={classes.textField}
            name="supportSearch"
            value={searchQuery}
            onChange={onSearchQueryChange}
            size="small"
            placeholder={formatMessage('enter_keywords')} // Enter keywords
            inputProps={{ style: { padding: 6 } }}
          />
          <Button variant="contained" color="primary" size="small" onClick={onSubmitSearch}>
            Submit
          </Button>
          <br />
          <FormHelperText className={classes.helperText}>
            {formatMessage('search_box_example')} {/* Example: adding mail server, inbound settings */}
          </FormHelperText>
        </CardContent>
      </Card>
    ),
    [
      classes.helperText,
      classes.searchPanel,
      classes.textField,
      formatMessage,
      onSearchQueryChange,
      onSubmitSearch,
      searchQuery
    ]
  )

  const HelpLinksPanel = useMemo(
    () => (
      <Card elevation={1} className={classes.helpLinksPanel}>
        <CardContent className={classes.helpLinksContent}>
          {topics.map(topic => (
            <Box key={topic.title} className={classes.helpLinkGroup}>
              <Typography variant="subtitle1">{formatTopicsMessage(topic.title)}</Typography>
              <ul className={classes.helpLinkList}>
                {topic.topics.map(item => (
                  <li key={item.label}>
                    <Link onClick={onLinkClick(item.href)} data-name={item.label}>
                      <Typography variant="body1">{formatTopicsMessage(item.label)}</Typography>
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </CardContent>
      </Card>
    ),
    [classes, formatTopicsMessage, topics, onLinkClick]
  )

  const DocumentationPanel = useMemo(
    () => (
      <Card elevation={1} className={classes.documentationPanel}>
        <CardContent>
          <Typography variant="subtitle1">{formatMessage('documentation')}</Typography>
          {/* Documentation */}
          <br />
          <Typography variant="body1">{formatMessage('documentation_content')}</Typography>
          <ul>
            <li>
              <Link href="https://campus.barracuda.com/">
                <Typography variant="body1">{formatMessage('documentation_link1')}</Typography>
              </Link>
            </li>{' '}
            {/* Barracuda Campus */}
            <li>
              <Link href="https://campus.barracuda.com/doc/16679008/">
                <Typography variant="body1">{formatMessage('documentation_link2')}</Typography>
              </Link>
            </li>{' '}
            {/* Release Notes */}
          </ul>
        </CardContent>
      </Card>
    ),
    [classes.documentationPanel, formatMessage]
  )

  const StartingSupport = useMemo(
    () => (
      <Grid container direction="column" className={classes.container} data-testid="starting-support">
        <Grid item xs={12} className={classes.titleWrapper}>
          <Card elevation={1} className={classes.card}>
            <CardHeader title={<Typography className={classes.title}>{formatMessage('support')}</Typography>} />
          </Card>
        </Grid>

        <Grid container direction="row" className={classes.linksContainer}>
          <Grid item xs={9}>
            <Grid container direction="row">
              <Grid item xs={12}>
                {SearchPanel}
              </Grid>
              <Grid item xs={12} className={classes.panelWrapper}>
                {HelpLinksPanel}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3} className={classes.panelWrapper}>
            {DocumentationPanel}
          </Grid>
        </Grid>
      </Grid>
    ),
    [DocumentationPanel, HelpLinksPanel, SearchPanel, classes, formatMessage]
  )

  const SearchResults = useMemo(
    () => (
      <Grid container className={`${classes.container} ${classes.searchResultsContainer}`} data-testid="search-results">
        <Grid item xs={12} className={classes.searchResultsTitleWrapper}>
          <Card elevation={1} className={classes.card}>
            <CardHeader title={<Typography className={classes.title}>{formatMessage('support')}</Typography>} />{' '}
            {/* Support */}
          </Card>
        </Grid>

        <Grid item xs={12} className={classes.searchResultsWrapper}>
          <Card elevation={1} className={classes.searchResultsPanel} style={{ marginLeft: 20, marginRight: 20 }}>
            <CardContent>
              <Typography variant="subtitle1">{formatMessage('search_box_title')}</Typography>{' '}
              {/* Search Support Topics */}
              <br />
              <TextField
                className={classes.textField}
                name="supportSearch"
                value={searchQuery}
                onChange={onSearchQueryChange}
                size="medium"
                placeholder={formatMessage('enter_keywords')} // Enter keywords
                inputProps={{ style: { padding: 6 } }}
              />
              <Button color="primary" size="small" variant="contained" onClick={onSubmitSearch}>
                Submit
              </Button>
              <br />
              <FormHelperText className={classes.helperText}>
                {formatMessage('search_box_example')} {/* Example: adding mail server, inbound settings */}
              </FormHelperText>
              <br />
              <br />
              {searchResults}
              <br />
              <br />
              <Link onClick={onReset}>
                <Typography variant="body1">Reset Support page</Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    ),
    // eslint-disable-next-line prettier/prettier
    [classes, formatMessage, onSearchQueryChange, onSubmitSearch, onReset, searchQuery, searchResults]
  )

  return useMemo(
    () => (
      <Grid>
        {!searchResults && StartingSupport}
        {!!searchResults && SearchResults}
      </Grid>
    ),
    [SearchResults, StartingSupport, searchResults]
  )
}

export default Support
