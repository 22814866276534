import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

import { setSuccessSnackBar, setErrorSnackBar } from 'redux/features/app/appSlice'
import {
  Policy,
  QuarantineNotificationReport,
  InboundScheduleUI,
  InboundScheduleType,
  LinkedAccountsReport,
  LinkedAccount,
  AccountPermissionsReport,
  SenderPoliciesResponse
} from 'types/Settings'
import { convertScheduleToArr } from 'lib/convertSchedule'
import { AuthState } from '../auth/authSlice'

export type PostPolicyPayload = Policy
export type DeletePolicyPayload = Policy
export interface PutBulkEditPoliciesPayload {
  policies: Policy[]
}
export interface PutQuarantineNotificationPayload {
  customInboundSchedule: InboundScheduleUI
  inboundSchedule: InboundScheduleType
}
export interface ChangePasswordPayload {
  oldPassword: string
  newPassword: string
}
export type PostLinkedAccountPayload = LinkedAccount
export interface VerifyLinkedAccountPayload {
  accountId: string
  userId: string
  hash: string
  email: string
}
export type DeleteLinkedAccountPayload = LinkedAccount

export const getSenderPolicies = createAsyncThunk<SenderPoliciesResponse, undefined, ApiRejectResponse>(
  'SETTINGS/getSenderPolicies',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.SENDER_POLICIES, {})

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postPolicy = createAsyncThunk<Policy, PostPolicyPayload, ApiRejectResponse>(
  'SETTINGS/postPolicy',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.SAVE_SENDER_POLICY, {
        data: { ...payload }
      })

      dispatch(setSuccessSnackBar({ message: 'post_policy_success' }))

      return resp.data
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'post_policy_failure' }))

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deletePolicy = createAsyncThunk<Policy, DeletePolicyPayload, ApiRejectResponse>(
  'SETTINGS/deletePolicy',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_SENDER_POLICY, {
        data: { ...payload }
      })

      dispatch(setSuccessSnackBar({ message: 'delete_policy_success' }))

      return resp.data
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'delete_policy_failure' }))

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const putBulkEditPolicies = createAsyncThunk<Policy[], PutBulkEditPoliciesPayload, ApiRejectResponse>(
  'SETTINGS/putBulkEditPolicies',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.BULK_EDIT_POLICIES, {
        data: { ...payload }
      })

      dispatch(setSuccessSnackBar({ message: 'put_bulk_edit_success' }))

      return resp.data
    } catch (e) {
      const responseDataDetail = e?.data?.detail
      const message = Array.isArray(responseDataDetail) ? responseDataDetail[0]?.msg || '' : responseDataDetail || ''
      dispatch(setErrorSnackBar({ message: 'put_bulk_edit_failure', params: [message] }))

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getQuarantineNotification = createAsyncThunk<QuarantineNotificationReport, undefined, ApiRejectResponse>(
  'SETTINGS/getQuarantineNotification',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.QUARANTINE_NOTIFICATION, {})

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const putQuarantineNotification = createAsyncThunk<
  QuarantineNotificationReport,
  PutQuarantineNotificationPayload,
  ApiRejectResponse
>('SETTINGS/putQuarantineNotification', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const resp = await restClient(apiRoutes.EDIT_QUARANTINE_NOTIFICATION, {
      data: {
        ...payload,
        customInboundSchedule: convertScheduleToArr(payload.customInboundSchedule)
      }
    })

    dispatch(setSuccessSnackBar({ message: 'put_quarantine_notification_success' }))

    return resp.data
  } catch (e) {
    dispatch(setErrorSnackBar({ message: 'put_quarantine_notification_failure' }))

    return rejectWithValue(validateApiError(e))
  }
})

export const changePassword = createAsyncThunk<string, ChangePasswordPayload, ApiRejectResponse>(
  'SETTINGS/changePassword',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.PASSWORD, {
        data: { ...payload }
      })

      dispatch(setSuccessSnackBar({ message: 'password_success' }))

      return resp.data
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'password_failure' }))

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getLinkedAccounts = createAsyncThunk<LinkedAccountsReport, undefined, ApiRejectResponse>(
  'SETTINGS/getLinkedAccounts',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.LINKED_ACCOUNTS, {})

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postLinkedAccount = createAsyncThunk<LinkedAccountsReport, PostLinkedAccountPayload, ApiRejectResponse>(
  'SETTINGS/postLinkedAccount',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const resp = await restClient(apiRoutes.SAVE_LINKED_ACCOUNT, {
        data: { ...payload }
      })

      return resp.data
    } catch (e) {
      const detail = e?.data?.detail
      if (detail.message === 'Account must be on the same domain') {
        const { domain_list } = detail
        const params = domain_list || ''
        dispatch(setErrorSnackBar({ message: 'post_add_linked_account_not_same_domain_failure', params: [params] }))
      } else if (detail === 'Email already exists') {
        dispatch(setErrorSnackBar({ message: 'post_add_linked_account_existed_failure' }))
      } else if (detail.message === 'Email already linked to another user') {
        const { user } = detail
        const params = user || ''
        dispatch(
          setErrorSnackBar({
            message: 'post_add_linked_account_already_linked_failure',
            params: [payload.email, params]
          })
        )
      } else if (detail === 'Duplicate linked account') {
        const { auth } = getState() as { auth: AuthState }
        const userId = auth.accessTokenObject?.userId
        const params = userId || ''
        dispatch(
          setErrorSnackBar({
            message: 'post_add_linked_account_already_linked_failure',
            params: [payload.email, params]
          })
        )
      } else {
        dispatch(setErrorSnackBar({ message: 'post_add_linked_account_failure' }))
      }

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const verifyLinkedAccount = createAsyncThunk<number, VerifyLinkedAccountPayload, ApiRejectResponse>(
  'SETTINGS/verifyLinkedAccount',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.VERIFY_LINKED_ACCOUNT, {
        data: { ...payload }
      })

      return resp.data.results
    } catch (e) {
      return rejectWithValue(e.status)
    }
  }
)

export const deleteLinkedAccount = createAsyncThunk<LinkedAccount[], DeleteLinkedAccountPayload, ApiRejectResponse>(
  'SETTINGS/deleteLinkedAccount',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_LINKED_ACCOUNT, {
        data: { ...payload }
      })

      dispatch(setSuccessSnackBar({ message: 'post_delete_linked_account_success' }))

      return resp.data.results
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'post_delete_linked_account_failure' }))

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAccountPermissions = createAsyncThunk<AccountPermissionsReport, undefined, ApiRejectResponse>(
  'SETTINGS/getAccountPermissions',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.ACCOUNT_PERMISSIONS, {})

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
