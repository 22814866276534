import { makeStyles, colors } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  determinationBox: {
    borderRadius: 4,
    padding: '6px 10px',
    margin: '0 0 15px 16px',
    width: '40%',
    background: colors.red200
  },
  determinationIcon: {
    height: 20,
    marginRight: 10,
    verticalAlign: 'middle',
    width: 20
  },
  radioButtonWrapper: {
    paddingBottom: 32
  },
  schedulerWrapper: {
    paddingBottom: 32,
    maxWidth: '50%'
  },
  scheduleNotificationSubTitle: {
    marginTop: 20,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  },
  actions: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16
  },
  alert: {
    margin: theme.spacing(1)
  }
}))
