import { useState, useMemo, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { saveSearchForm } from 'redux/features/mstore/mstoreSlice'

export interface UseSaveSearchDialogLogic {
  saveName: string
  error: string | null
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  saveSearch: () => void
}

export const useSaveSearchDialogLogic = (onClose: () => void): UseSaveSearchDialogLogic => {
  const dispatch = useAppDispatch()

  const { savedSearchTerms } = useAppSelector(_store => ({
    savedSearchTerms: _store.mstore.savedSearchForms
  }))

  const [saveName, setSaveName] = useState('')
  const [error, setError] = useState<string | null>(null)

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const regex = /^([\sa-z0-9-_.]){1,32}$/i
      if (e.target.value === '' || regex.test(e.target.value)) {
        setSaveName(value)
      }

      if (savedSearchTerms.find(savedSearch => savedSearch.name.toLowerCase() === value.toLowerCase())) {
        setError('name_exists')
      } else {
        setError(null)
      }
    },
    [savedSearchTerms]
  )

  const saveSearch = useCallback(() => {
    dispatch(saveSearchForm(saveName))
    onClose()
  }, [dispatch, saveName, onClose])

  return useMemo(
    () => ({
      saveName,
      error,
      handleInputChange,
      saveSearch
    }),
    [saveName, error, handleInputChange, saveSearch]
  )
}
