import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getAccountPermissions, resetAccountPermissions } from 'redux/features/settings/settingsSlice'
import { getAvailableDomains, resetGetAvailableDomains } from 'redux/features/user/userSlice'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isSuccess } from 'redux/toolkit/api'
import {
  getSearch,
  loadSavedSearchForms,
  resetSearchDomainId,
  resetSearchMessageDirection,
  resetSearchUserId,
  setSearchDomainId,
  setSearchFilter,
  setSearchMessageDirection,
  setSearchUserId
} from 'redux/features/mstore/mstoreSlice'
import { ActionStatus, ActionTaken, MessageDirection, Reason } from 'types/Messages'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

export enum UiState {
  Error,
  Loading,
  Ready
}

export interface MessageLogPreloadLogic {
  uiState: UiState
  preloadErrors: {
    getAccountPermissionsError: string | undefined
    getAvailableDomainsError: string | undefined
    noEmailDomainError: boolean
  }
}

export const useMessageLogPreloadLogic = (): MessageLogPreloadLogic => {
  const dispatch = useAppDispatch()
  const [shouldStartPreload, setShouldStartPreload] = useState(false)
  const uiStateRef = useRef(UiState.Loading)
  const isUiStateLockedRef = useRef(false)
  const {
    isGetAccountPermissionsSuccess,
    getAccountPermissionsError,
    emergencyInboxEnabled,
    isGetAvailableDomainsSuccess,
    getAvailableDomainsError,
    userId,
    availableDomains,
    searchLogsAllDomainsLimit,
    searchLogsAllDomainsLowLimit,
    viewConfigType
  } = useAppSelector(_store => ({
    isGetAccountPermissionsSuccess: isSuccess(_store.settings.getAccountPermissionsApiStatus),
    getAccountPermissionsError: getErrorMessage(_store.settings.getAccountPermissionsApiStatus),
    emergencyInboxEnabled: _store.settings.accountPermissions?.emergencyInboxEnabled || 0,
    isGetAvailableDomainsSuccess: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    getAvailableDomainsError: getErrorMessage(_store.user.api.getAvailableDomainsApiStatus),
    userId: _store.auth.accessTokenObject?.userId || '',
    availableDomains: _store.user.availableDomains || [],
    searchLogsAllDomainsLimit: _store.settings.accountPermissions?.searchLogsAllDomainsLimit || 0,
    searchLogsAllDomainsLowLimit: _store.settings.accountPermissions?.searchLogsAllDomainsLowLimit || 0,
    viewConfigType: _store.mstore.viewConfig.type
  }))
  const { userHasRight } = useUserRights()

  const setDefaultSearch = useCallback(() => {
    // Default search actionTaken
    if (viewConfigType !== 'outbound-quarantine' && emergencyInboxEnabled === 1) {
      dispatch(
        setSearchFilter({
          action: ActionTaken.emailContinuity,
          delivery_status: ActionStatus.spooled,
          reason: Reason.any
        })
      )
    } else {
      dispatch(setSearchFilter({ action: ActionTaken.quarantined }))
    }

    // Default search userId
    dispatch(setSearchUserId(userHasRight(UserRights.SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS) ? undefined : userId))

    // Default search domainId
    if (!userHasRight(UserRights.FILTER_ABOUT_DOMAINS) || availableDomains.length > searchLogsAllDomainsLimit) {
      dispatch(setSearchDomainId(availableDomains[0]?.domainId))
    } else {
      const defaultDomainId =
        availableDomains.length > searchLogsAllDomainsLowLimit ? availableDomains[0].domainId : undefined
      dispatch(setSearchDomainId(defaultDomainId))
    }

    // Default message direction
    if (viewConfigType === 'message-log') {
      dispatch(setSearchMessageDirection(MessageDirection.INBOUND))
    } else {
      dispatch(setSearchMessageDirection(MessageDirection.OUTBOUND))
    }
  }, [
    availableDomains,
    dispatch,
    emergencyInboxEnabled,
    userHasRight,
    searchLogsAllDomainsLimit,
    searchLogsAllDomainsLowLimit,
    userId,
    viewConfigType
  ])

  useEffect(() => {
    dispatch(resetAccountPermissions())
    dispatch(resetGetAvailableDomains())
    dispatch(loadSavedSearchForms())
    setShouldStartPreload(true)
    isUiStateLockedRef.current = false
    uiStateRef.current = UiState.Loading
    return () => {
      dispatch(resetAccountPermissions())
      dispatch(resetGetAvailableDomains())
      dispatch(resetSearchUserId())
      dispatch(resetSearchDomainId())
      dispatch(resetSearchMessageDirection())
    }
  }, [dispatch])

  useEffect(() => {
    if (shouldStartPreload) {
      dispatch(getAccountPermissions())
      dispatch(getAvailableDomains())
    }
  }, [dispatch, shouldStartPreload])

  const noEmailDomainError = useMemo(() => !availableDomains.length, [availableDomains.length])

  const uiState = useMemo(() => {
    if (isUiStateLockedRef.current) {
      return uiStateRef.current
    }

    const isGetAvailableDomainsCompleted = isGetAvailableDomainsSuccess || !!getAvailableDomainsError
    const isGetAccountPermissionsCompleted = isGetAccountPermissionsSuccess || !!getAccountPermissionsError
    const isPreloadCompleted = isGetAccountPermissionsCompleted && isGetAvailableDomainsCompleted
    const hasErrors = !!getAccountPermissionsError || !!getAvailableDomainsError || noEmailDomainError

    switch (true) {
      case isPreloadCompleted && !hasErrors:
        uiStateRef.current = UiState.Ready
        isUiStateLockedRef.current = true
        break
      case isPreloadCompleted && hasErrors:
        isUiStateLockedRef.current = true
        uiStateRef.current = UiState.Error
        break
      default:
        uiStateRef.current = UiState.Loading
    }
    return uiStateRef.current
  }, [
    getAccountPermissionsError,
    getAvailableDomainsError,
    isGetAccountPermissionsSuccess,
    isGetAvailableDomainsSuccess,
    noEmailDomainError
  ])

  useEffect(() => {
    if (uiState !== UiState.Ready) {
      return
    }
    setDefaultSearch()
    dispatch(getSearch())

    // Effect should run only once, when uiState is set to UiState.Ready
    // isUiStateLockedRef = true prevents subsequent updates to uiState once Ready or Error is set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState])

  return useMemo(
    () => ({
      uiState,
      preloadErrors: {
        getAccountPermissionsError,
        getAvailableDomainsError,
        noEmailDomainError
      }
    }),
    [getAccountPermissionsError, getAvailableDomainsError, noEmailDomainError, uiState]
  )
}
