import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { history } from 'lib/browserHistory'
import { useAppSelector } from 'redux/toolkit/hooks'

import routesConfig from 'lib/routesConfig'
import { HelpComponentProps } from 'types/Help'
import { TOPICS, GROUP_TITLE, getSupportRouteConfig } from '../config'

export interface HelpComponentInfo {
  content: (props: HelpComponentProps) => JSX.Element
  title: TOPICS
  groupTitle: GROUP_TITLE
}
export interface UseHelpLogic {
  brtsScan: boolean
  productName: string
  isCplAccount?: boolean
  componentInfo?: HelpComponentInfo
  onSupportClick: () => void
  onGoBackClick: () => void
}

export const useHelpLogic = (): UseHelpLogic => {
  const { pathname, key } = useLocation()
  const { accessTokenObject } = useAppSelector(_store => ({
    accessTokenObject: _store.auth.accessTokenObject
  }))

  const onSupportClick = useCallback(() => routesConfig.SUPPORT.goto(), [])

  const onGoBackClick = useCallback(() => (key !== 'default' ? history.back() : routesConfig.SUPPORT.goto()), [key])

  const componentInfo = useMemo(() => getSupportRouteConfig()[pathname] || undefined, [pathname])

  return useMemo(
    () => ({
      brtsScan: !window.location.hostname.startsWith('us'),
      productName: accessTokenObject?.isCplAccount ? 'cpl_product_name' : 'no_cpl_product_name',
      isCplAccount: accessTokenObject?.isCplAccount,
      componentInfo,
      onSupportClick,
      onGoBackClick
    }),
    [accessTokenObject, componentInfo, onSupportClick, onGoBackClick]
  )
}
