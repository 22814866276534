import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import { LoginResponse, User } from 'types/auth'

import {
  autoLogin,
  azureSSOLogin,
  bccLogin,
  ENFORCE_MANUAL_LOGIN,
  login,
  logout,
  requestLoginInfo,
  RequestLoginInfoPayload,
  resetAll,
  resetPassword,
  validateAccessToken,
  validateSessionId
} from 'redux/features/auth/authApiThunks'

export interface AuthState {
  validateAccessTokenApiStatus: ApiStatus
  loginApiStatus: ApiStatus
  logoutApiStatus: ApiStatus
  resetPasswordApiStatus: ApiStatus
  requestLoginInfoApiStatus: ApiStatus

  accessToken: LoginResponse['accessToken'] | undefined
  session: LoginResponse | undefined
  accessTokenObject: User | undefined
  accessTokenExpires: number | undefined
  enforceManualLogin: boolean
  requestLoginInfoOrigin: RequestLoginInfoPayload['origin'] | undefined
}

// initialState
export const INITIAL_STATE: AuthState = {
  validateAccessTokenApiStatus: inIdle,
  loginApiStatus: inIdle,
  logoutApiStatus: inIdle,
  resetPasswordApiStatus: inIdle,
  requestLoginInfoApiStatus: inIdle,

  accessToken: undefined,
  session: undefined,
  accessTokenObject: undefined,
  accessTokenExpires: undefined,
  enforceManualLogin: false,
  requestLoginInfoOrigin: undefined
}

function isLoginPendingAction(action: AnyAction) {
  return [login, autoLogin, azureSSOLogin, bccLogin, validateSessionId].some(apiThunk => apiThunk.pending.match(action))
}

function isLoginFulfilledAction(action: AnyAction) {
  return [login, autoLogin, azureSSOLogin, bccLogin, validateSessionId].some(apiThunk =>
    apiThunk.fulfilled.match(action)
  )
}

function isLoginRejectedAction(action: AnyAction) {
  return [login, autoLogin, azureSSOLogin, bccLogin, validateSessionId].some(apiThunk =>
    apiThunk.rejected.match(action)
  )
}

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: 'AUTH',
  initialState: INITIAL_STATE,
  reducers: {
    setAccessToken: (state, action: PayloadAction<AuthState['accessToken']>) => {
      state.accessToken = action.payload
    },
    resetLogin: (state: AuthState) => {
      state.loginApiStatus = INITIAL_STATE.loginApiStatus
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // logout
      .addCase(logout.pending, state => {
        state.logoutApiStatus = inProgress
      })
      .addCase(logout.fulfilled, state => {
        state.logoutApiStatus = successResponse
      })
      .addCase(logout.rejected, (state, action) => {
        state.logoutApiStatus = failedResponse(action.payload)
      })

      // reset password
      .addCase(resetPassword.pending, state => {
        state.resetPasswordApiStatus = inProgress
      })
      .addCase(resetPassword.fulfilled, state => {
        state.resetPasswordApiStatus = successResponse
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordApiStatus = failedResponse(action.payload)
      })

      // request login info
      .addCase(requestLoginInfo.pending, state => {
        state.requestLoginInfoApiStatus = inProgress
        state.requestLoginInfoOrigin = undefined
      })
      .addCase(requestLoginInfo.fulfilled, (state, action) => {
        state.requestLoginInfoApiStatus = successResponse
        state.requestLoginInfoOrigin = action.meta.arg.origin
      })
      .addCase(requestLoginInfo.rejected, (state, action) => {
        state.requestLoginInfoApiStatus = failedResponse(action.payload)
        state.requestLoginInfoOrigin = action.meta.arg.origin
      })

      // validateAccessToken
      .addCase(validateAccessToken.pending, state => {
        state.validateAccessTokenApiStatus = inProgress
      })
      .addCase(validateAccessToken.fulfilled, (state, action) => {
        state.validateAccessTokenApiStatus = successResponse
        state.accessTokenObject = action.payload
      })
      .addCase(validateAccessToken.rejected, (state, action) => {
        state.validateAccessTokenApiStatus = failedResponse(action.payload)
      })

      // login
      .addMatcher(isLoginPendingAction, state => {
        state.loginApiStatus = inProgress
      })
      .addMatcher(isLoginFulfilledAction, (state, action) => {
        state.loginApiStatus = successResponse
        state.accessToken = action.payload.accessToken
        state.session = action.payload.session
        state.accessTokenObject = action.payload.accessTokenObject
        state.accessTokenExpires = action.payload.accessTokenExpires
      })
      .addMatcher(isLoginRejectedAction, (state, action) => {
        if (action.payload === ENFORCE_MANUAL_LOGIN) {
          state.enforceManualLogin = true
        }

        state.loginApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { setAccessToken, resetLogin, reset } = authSlice.actions

export {
  validateAccessToken,
  login,
  autoLogin,
  azureSSOLogin,
  bccLogin,
  validateSessionId,
  logout,
  resetPassword,
  requestLoginInfo,
  resetAll
}

export default authSlice.reducer
