import { colors, makeStyles } from '@cuda-networks/bds-core'

export default makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.values.sm]: {
      '& .MuiToolbar-gutters': {
        padding: theme.spacing(0, 2)
      },
      '& .MuiToolbar-regular': {
        minHeight: theme.layout.navbarHeight
      }
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: theme.palette.text.primary,
    height: theme.layout.navbarHeight,
    zIndex: 1200
  },
  toolBar: {
    padding: '0 16px 0 16px'
  },
  essViewWrapper: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  navbarEssNavigationHamburger: {
    marginRight: 16
  },
  navbarProductLogo: {
    display: 'flex',
    float: 'left',
    height: theme.layout.navbarProductLogoHeight
  },
  noticeableWrapper: {
    padding: '0px'
  },
  accountIcon: {
    verticalAlign: 'middle',
    marginRight: '5px'
  },
  accountEmail: {
    verticalAlign: 5
  },
  darkModeSwitch: {
    verticalAlign: 'middle'
  },
  switcherVertWrapper: {
    alignItems: 'center',
    display: 'flex'
  },
  vertButton: {
    color: theme.palette.common.white
  },
  vertPopover: {
    position: 'fixed'
  },
  // SideMenu
  list: {
    paddingTop: 70,
    width: theme.layout.sideMenuWidth
  },
  activeListItem: {
    boxShadow: `5px 0 0 0 ${theme.palette.primary.dark} inset`
  },
  hamburger: {
    height: '100%',
    width: '100%',
    verticalAlign: 'middle'
  },
  hamburgerWrapper: {
    height: '36px',
    width: '36px'
  },
  drawer: {
    zIndex: '1100 !important' as any,
    flexShrink: 0
  },
  sideMenuText: {
    fontSize: 16,
    lineHeight: '24px'
  },
  // Impersonation Mode Banner
  snackBar: {
    marginBottom: 32,
    borderRadius: 32,
    boxShadow: `5px 10px 18px ${colors.scaleGray200}`
  },
  // Logout menu
  logoutMenu: {
    fontSize: '1rem'
  },
  logoutIcon: {
    minWidth: 40
  }
}))
