import React, { useMemo } from 'react'
import { Tab, TabPanel, Tabs } from '@cuda-networks/bds-core'
import MessageDetailsContentTabMessagePreview from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessagePreview/MessageDetailsContentTabMessagePreview'
import MessageDetailsContentTabMessageSource from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessageSource/MessageDetailsContentTabMessageSource'
import styles, {
  colors
} from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabsStyles'
import { useMessageDetailsContentTabsLogic } from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/useMessageDetailsContentTabsLogic'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.message_detail'

const MessageDetailsContentTabs = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [{ selectedTabName }, { onTabChange }] = useMessageDetailsContentTabsLogic()

  return useMemo(
    () => (
      <>
        <Tabs
          value={selectedTabName}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="standard"
          TabIndicatorProps={{
            style: {
              padding: '2px',
              boxShadow: `inset 0px -4px 0px ${colors.barracudaBlue}`
            }
          }}
          className={classes.messageDetailTab}
        >
          <Tab label={formatMessage('preview')} value="preview" />
          <Tab label={formatMessage('source')} value="source" />
        </Tabs>
        <TabPanel className={classes.tabPanel}>
          {selectedTabName === 'preview' && <MessageDetailsContentTabMessagePreview />}
          {selectedTabName === 'source' && <MessageDetailsContentTabMessageSource />}
        </TabPanel>
      </>
    ),
    [classes.messageDetailTab, classes.tabPanel, formatMessage, onTabChange, selectedTabName]
  )
}

export default MessageDetailsContentTabs
