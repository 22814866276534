import OVERVIEW_TAB_ES from 'config/i18n/tabs/overview_es'
import SUPPORT_ES from 'config/i18n/support_es'

const EMAIL = 'Correo electrónico'
const HEADERS = 'Cabeceras'
const MESSAGE_LOG = 'Mensaje Registro'
const SETTINGS = 'Parámetros'
const SUPPORT = 'Apoyo'
const SENDER = 'Remitente'
const SUBJECT = 'Asunto'

/* eslint-disable quotes */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ess: {
    overview: OVERVIEW_TAB_ES,
    support_page: SUPPORT_ES,
    app: {
      error: {
        default: 'Oops! Algo salió mal',
        failed_to_load_content: 'Fallo al cargar el contenido'
      }
    },
    loading: 'Cargando...',
    expiration_banner: {
      free_trial_expires: 'Prueba gratuita caducada'
    },
    react_experience_banner: {
      end_user: 'Nuestra interfaz tiene un nuevo aspecto!',
      detail_link: 'Más detalles'
    },
    advanced_search: {
      keywords: 'Palabras clave',
      from: 'Desde',
      envelope_from: 'Sobre Desde',
      to: 'Para',
      envelope_to: 'Sobre Para',
      subject: 'Asunto',
      attachment_name: 'Adjunto Nombre'
    },
    save_search: {
      title: 'Salvar búsqueda',
      name_exists: 'Este nombre ya existe. Al guardar se sobrescribirá la vista existente.',
      must_enter_name: 'Debe introducir un nombre',
      valid_name: ' ',
      name_your_search: 'Nombra tu búsqueda guardada...'
    },
    open_search: {
      title: 'Abrir búsqueda',
      subtitle: 'Nombre',
      find_your_search: 'Encuentra tu búsqueda guardada...'
    },
    login: {
      title: 'Iniciar sesión',
      signin_message: 'Inicie sesión para acceder a sus mensajes y a la configuración de Email Gateway Defense',
      signin_message_email: 'Por favor, Introduzca la contraseña de <b>%s</b>',
      invalid_email: 'Dirección de correo electrónico y/o contraseña no válidas',
      password: 'Contraseña',
      email: 'Dirección de correo electrónico',
      reset_password_message: 'Contraseña restablecida correctamente',
      valid_email_message: 'Introduzca una dirección de correo electrónico válida',
      signin: 'Iniciar sesión',
      next: 'Próximo',
      different_account_message: 'Iniciar sesión con otra cuenta',
      forgot_password: 'Contraseña olvidada?',
      admin_signin: 'Inicio de sesión de administrador',
      signing_in: 'Espere, por favor...',
      expired_message: 'El enlace para iniciar sesión ha caducado',
      invalid_link: 'El enlace para iniciar sesión no es válido',
      login_failed: 'El inicio de sesión no se ha realizado correctamente',
      new_link_sent: 'Se ha enviado un nuevo enlace a su correo electrónico',
      message: {
        title: 'Compruebe su correo',
        sent_message:
          'Se ha enviado un correo electrónico con un enlace para restablecer su contraseña a</br><b>%s</b>.',
        expiry_message: 'El enlace caducará al cabo de 1 hora.',
        resend: 'Reenviar enlace'
      }
    },
    bcc_login: {
      error_page: {
        title: 'Admin no puede ser autorizado',
        subtitle: 'Por favor, inténtelo de nuevo con credenciales válidas.'
      }
    },
    reset_password: {
      title: 'Restaurar contraseña',
      reset_password_message: 'Introduzca una contraseña nueva para <b>%s</b>',
      password1: 'Nueva contraseña',
      password2: 'Confirmar nueva contraseña',
      password_matches: 'La contraseña coincide',
      password_not_match: 'La contraseña no coincide',
      new_link_sent: 'Se ha enviado un nuevo enlace a su correo electrónico',
      reset: 'Reiniciar',
      expired_link: {
        title: 'Enlace caducado',
        message: 'El enlace para restablecer la contraseña de <b>%s</b> ha expirado.',
        sign_in: 'Volver al inicio de sesión',
        resend_link: 'Reenviar enlace'
      },
      check_email: {
        title: 'Compruebe su correo electrónico',
        message: 'Se ha enviado un correo electrónico con un enlace para restablecer su contraseña a<br/><b>%s</b>.',
        link_will_expire: 'El enlace caducará en 1 hora.'
      },
      successful_reset: {
        title: 'Su contraseña se ha restablecido correctamente.',
        sign_in: 'Iniciar sesión'
      },
      error: {
        send_login_info_failed: 'Error al enviar la información de acceso',
        resend_link_failed: 'Error al reenviar el enlace'
      }
    },
    dialog: {
      title: 'Diálogo',
      confirm_title: 'Confirmación',
      info_title: 'Información',
      error_title: 'Error',
      cancel: 'Cancelar',
      ok: 'Vale',
      yes: 'Sí',
      no: 'No',
      submit: 'Envíe',
      save: 'Salvar',
      open: 'Abrir',
      search: 'Buscar',
      okay: 'Vale'
    },
    settings: {
      title: 'Ajustes',
      tabs: {
        quarantine_notification: 'Notificación de cuarentena',
        sender_policies: 'Política de remitentes',
        linked_accounts: 'Cuentas vinculadas',
        change_password: 'Cambiar contraseña'
      },
      bulk_edit: {
        buttons: {
          save: 'Guardar cambios',
          cancel: 'Cancelar'
        },
        place_holder: 'Escriba o políticas anteriores aquí',
        title: 'Edición masiva: Política de remitentes',
        header:
          'Remitente (Dirección de correo electrónico, dominio o usuario),Política (bloquear, eximir, cuarentena),Comentario (opcional)',
        sub_title:
          'La edición masiva le permite realizar múltiples cambios (adiciones, sustracciones, modificaciones) en una lista de parámetros de configuración en un solo paso..',
        content_header: 'Edición de los datos CSV',
        content_sub_header:
          'Muchas columnas sólo pueden aceptar formas específicas de datos. Por ejemplo, las columnas asociadas a direcciones de correo electrónico deben contener datos que aparezcan en el formato de una dirección de correo electrónico. Algunas columnas están asociadas a datos que son opcionales o tienen valores por defecto asociados, y pueden dejarse vacías.',
        warning:
          'Nota: Asegúrese de que la primera línea no contiene ningún dato válido, ya que no se analiza. Utilice títulos de columna en la primera línea si lo desea.',
        error_missing_policy: 'Error en la fila %d (Falta la política)',
        error_invalid_name: 'Error en la fila %d (Nombre no válido)',
        error_invalid_policy: 'Error en la fila %d (Política no válida)'
      },
      change_password: {},
      quarantine_notification: {
        reset_dialog: {
          title: 'Restablecer ajustes',
          message: '¿Estás seguro de que desea restablecer la configuración?'
        },
        buttons: {
          save: 'Guardar',
          reset: 'Reiniciar',
          default_interval: {
            never: 'Nunca',
            scheduled: 'Programada'
          }
        },
        title: 'Notificación de Cuarentena',
        disabled_warning: 'El administrador del dominio ha desactivado los cambios en esta configuración.',
        default_interval: 'Intervalo predeterminado para las notificaciones de cuarentena de usuarios',
        schedule_notification: 'Programar intervalos de notificación',
        schedule_notification_sub_title:
          'Las notificaciones se enviarán en cualquier momento dentro del bloque de horas elegido. Haga clic y arrastre para seleccionar.  Mantenga pulsada la tecla Mayús mientras arrastra para anular la selección.',
        allow_override: 'El administrador del dominio ha desactivado los cambios en esta configuración.'
      },
      sender_policies: {
        invalid_domain: 'Introduzca un nombre de dominio válido',
        invalid_domain_title: 'Dominio no válido',
        confirm_removal: '¿Estás seguro de que desea eliminar la política?',
        confirm_removal_title: 'Confirmar eliminación',
        block: 'Bloque',
        exempt: 'Exento',
        quarantine: 'Cuarentena',
        warningHoverMessage: 'Su organización ha bloqueado este remitente',
        buttons: {
          bulk_edit: 'Edición masiva',
          add: 'Añadir',
          remove: 'Eliminar'
        },
        tableHeadings: {
          sender: 'Remitente',
          policy: 'Política',
          comment: 'Comentario',
          modified: 'Modificado',
          actions: 'Acciones'
        },
        title: 'Política de remitentes',
        sub_title:
          'Especifique si desea Bloquear o Eximir los mensajes procedentes de una dirección de correo electrónico o dominio específicos.',
        sender_policy_whitelist_notification_message:
          'Su organización ha limitado la capacidad de bloquear y permitir direcciones de correo electrónico y dominios. Las políticas de remitente con algún conflicto tendrán un símbolo de advertencia junto a ellas.'
      },
      linked_accounts: {
        title: 'Cuentas Vinculadas',
        sub_title:
          'Puede añadir direcciones de correo electrónico adicionales que tenga en el mismo dominio para reenviar el correo en cuarentena a esta cuenta.',
        verification_message:
          'Se ha enviado un correo electrónico a {email} con instrucciones sobre cómo verificar esta cuenta. Esta cuenta no se vinculará hasta que se verifique.',
        confirm_unlink: '¿Estás seguro de que quieres desvincular la cuenta {email}?',
        account: 'Cuenta (Dirección de correo electrónico)',
        add: 'Añadir',
        unlink: 'Desvincular',
        actions: 'Acciones',
        unlink_title: 'Confirmar desvinculación',
        invalid_params: 'Parámetros no válidos',
        email_sent: 'La cuenta de correo {userId} está ahora vinculada a su cuenta {email}.',
        verifying: 'Verificación de la cuenta vinculada...',
        unable_to_link: 'No se pueden vincular estas cuentas'
      }
    },
    message_log: {
      title: 'Mensaje Registro',
      search_bar: {
        search: 'Búsqueda',
        find_hint: 'Buscar mensajes, destinatarios, remitentes y mucho más...'
      },
      columns: {
        status: 'ESTATUS',
        from: 'DESDE',
        to: 'A',
        subject: 'ASUNTO',
        date: 'FECHA',
        size: 'TAMAÑO',
        reason: 'RAZÓN',
        score: 'PUNTAJE',
        details: 'DETALLES'
      },
      toolbar: {
        refresh: 'Refrescar',
        deliver: 'Entregar',
        export: 'Exportar la tabla a CSV',
        categorize: 'Clasifique',
        delete: 'Borrar Mensaje(s)',
        corp_email: 'Correo electrónico corporativo',
        trans_email: 'Correo electrónico transaccional',
        market_materials: 'Materiales de marketing y boletines',
        mailing_lists: 'Listas de Correo',
        social_media: 'Redes Sociales',
        other: 'Otros',
        message_queue: 'Cola de Mensajes',
        deliver_dialog: {
          title: 'Entregar Mensaje',
          text: 'Este mensaje se entregará al destinatario. El remitente o dominio puede añadirse a su lista de permitidos para entregar futuros mensajes. Opcionalmente, informe del correo electrónico para ayudar al equipo de Barracuda a identificar mejor las amenazas. ',
          learn_more_link: 'Más información',
          deliver_only: 'Entregar sólo el mensaje(s)',
          allow_list_sender: 'Entrega y añade el remitente(s) a tu lista de permitidos',
          allow_list_domain: 'Entregue y añada el/los dominio(s) a su lista de permitidos',
          deliver_report: 'Entregar e informar',
          deliver: 'Entregar'
        },
        categorize_other_box_title: 'Especifique la categoría',
        limit_special_chars: 'Los caracteres permitidos son Aa-Zz, 0-9, -_ y espacios.',
        character_limit: 'Sólo se admiten 30 caracteres',
        new_email: 'Nuevo mensaje'
      },
      from_column_menu: {
        show_name: 'Mostrar nombre',
        show_email: 'Mostrar dirección de correo electrónico',
        show_both: 'Mostrar ambos'
      },
      message_detail_toolbar: {
        downloading: 'Descargar',
        incorrectly_allowed: 'Informar como Entregado Incorrectamente',
        incorrectly_blocked: 'Denunciar como Bloqueado Incorrectamente',
        menu: {
          download: 'Descargar Mensaje',
          allow_deliver: 'Permitir Remitente y Entregar Mensaje',
          add_email: 'Añadir Correo Electrónico del Remitente a la Lista de Bloqueados',
          add_domain: 'Añadir Dominio del Remitente a la Lista de Bloqueados',
          delete: 'Borrar',
          delete_message: 'Borrar Mensaje',
          show_history: 'Historia del Espectáculo',
          hide_history: 'Ocultar Historia',
          reply: 'Respuesta',
          reply_all: 'Responder a Todos',
          forward: 'Adelante',
          actions: 'Más Acciones'
        }
      },
      header_detail: {
        long_reason: 'El mensaje era %s debido a %s',
        short_reason: 'El mensaje era %s',
        remediated: 'Permitido: Remediado por Barracuda incident Response en %s',
        from: 'Desde:',
        to: 'A:',
        subject: 'Asunto:',
        date: 'Fecha:',
        attachments: 'Adjuntos:',
        mid: 'Mensaje ID:',
        ip: 'IP:',
        env_from: 'Sobre De:',
        recipients: 'Destinatarios:'
      },
      recipients_info: {
        recipients: 'Destinatarios',
        action: 'Acción',
        reason: 'Razón',
        delivery_status: 'Estado de Entrega'
      },
      message_preview: {
        message_too_large: 'Mensaje demasiado grande. Por favor, descargue este mensaje para ver la fuente.',
        message_has_virus: 'Virus detectado. Este mensaje no puede mostrarse.',
        show_images_banner: 'Las imágenes de este mensaje no se muestran automáticamente.',
        show_images: 'Mostrar Imágenes',
        cant_view_blocked: 'Su administrador ha desactivado la visualización y entrega de mensajes bloqueados.',
        cant_view_quarantined: 'Su administrador ha desactivado la visualización y entrega de mensajes en cuarentena.',
        no_content: 'Este mensaje no tiene contenido, o el contenido aún no está disponible.',
        atd_detected: 'Este mensaje tiene uno o más archivos adjuntos con amenazas avanzadas detectadas.'
      },
      redelivery_queue: {
        redelivering: 'Reenvío...',
        buttons: {
          retry: 'Reintentar',
          close: 'Cierra'
        },
        title: 'Cola de Mensajes',
        sub_title:
          'La cola de mensajes muestra los mensajes designados para su entrega desde el registro de mensajes. Los mensajes se eliminan automáticamente de la cola en 12 horas.'
      },
      email_composer: {
        reply: 'Re',
        forward: 'Fw',
        from: 'Desde',
        to: 'Para',
        cc: 'Cc',
        attachments: 'Adjuntos',
        new_email_title: 'Nuevo Mensaje',
        subject: 'Asunto',
        buttons: {
          cancel: 'Cancelar',
          send: 'Enviar'
        }
      },
      reporting_action_modal: {
        report_message: 'Mensaje de Información',
        incorrectly_delivered: 'Informar de Entrega Incorrecta',
        incorrectly_blocked: 'Informar de Bloqueo Incorrecto',
        incorrectly_delivered_content:
          'Este correo electrónico ha sido reportado para su revisión por el equipo de Barracuda. Proporcione detalles adicionales para asegurar que mensajes como este sean bloqueados en el futuro.',
        incorrectly_blocked_content:
          'Este correo electrónico ha sido reportado para su revisión por el equipo de Barracuda. Proporcione detalles adicionales para asegurar que mensajes como este sean entregados en el futuro.',
        buttons: {
          close: 'Cierra',
          why_blocked: 'DÍGANOS POR QUÉ DEBE BLOQUEARSE',
          why_allowed: 'DÍGANOS POR QUÉ DEBERÍA PERMITIRSE'
        }
      },
      preset_menu: {
        label: 'Date Presets',
        one_day: 'Último 1 día',
        two_days: 'Últimos 2 días',
        one_week: 'Última 1 semana',
        two_weeks: 'Últimas 2 semanas',
        one_month: 'Último 1 mes',
        custom: 'A medida'
      },
      domain_menu: {
        label: 'Dominios'
      },
      action: {
        label: 'Medidas adoptadas',
        any: 'Cualquier',
        allowed: 'Permitido',
        blocked: 'Bloqueado',
        quarantined: 'Cuarentena',
        encrypted: 'Encriptada',
        deferred: 'Aplazada',
        remediated: 'Remediado',
        email_continuity: 'Continuidad del correo electrónico'
      },
      action_for_some: {
        allowed: 'Permitido para algunos',
        blocked: 'Bloqueado para algunos',
        quarantined: 'En cuarentena para algunos',
        encrypted: 'Encriptado para algunos',
        deferred: 'Aplazado para algunos'
      },
      status: {
        label: 'Estado de la acción',
        any: 'Cualquier',
        delivered: 'Entregado',
        delivered_partial: 'Entregado para algunos',
        spooled: 'Carrete',
        not_delivered: 'No entregado',
        deferred: 'Aplazado',
        rejected: 'Rechazado'
      },
      encryption: {
        inbound_outbound_encrypted: 'Cifrado de Entrada + Cifrado de Salida',
        inbound_encrypted: 'Sólo Cifrado Entrante',
        outbound_encrypted: 'Sólo Cifrado Saliente',
        encrypted_for_some: 'Cifrado de Entrada + Cifrado de Salida Para Algunos Destinatarios'
      },
      reason: {
        label: 'Razón',
        any: 'Cualquier',
        spf: 'Marco de la Política de Remitentes',
        bafi: 'Antifraude',
        barracuda_email_blacklist: 'Dirección de Correo Electrónico del Remitente',
        av: 'Antivirus',
        bbl: 'Barracuda Reputación',
        brts: 'Barracuda Sistema en Tiempo Real',
        content_url: 'Contenido URL',
        content_protected: 'Contenido Protegido',
        intent: 'Análisis de Intenciones',
        image_analysis: 'Análisis de Imágenes',
        rbl: 'Lista de Bloqueos en Tiempo Real',
        content_subject: 'Contenido de la Asignatura',
        content_header: 'Contenido de la Cabecera',
        content_body: 'Contenido del Cuerpo',
        predefined_filter_exemption: 'Exenciones de Filtro Predefinidas',
        predefined_content_sender: 'Dirección del Remitente Predefinida',
        content_attachment: 'Contenido Adjunto',
        content_sender: 'De la Dirección',
        content_recipient: 'Para/CC Dirección',
        invalid_recipient: 'Destinatario no Válido',
        dest_invalid_recipient: 'Destinatario no Válido',
        dkim: 'Claves de Dominio',
        dmarc: 'DMARC',
        encryption_exemption: 'Excepción de Cifrado',
        mark_spam: 'Categorización Enviado a Spam',
        no_prt: 'Sin Registro PRT',
        no_spf: 'Sin Registro SPF',
        password_protected_pdf: 'Filtrado de PDF Protegido por Contraseña',
        patd_scan_pending: 'Escaneado Pendiente',
        phishline: 'Formación Sobre Sensibilización en Materia de Seguridad',
        predefined_content_subject: 'Contenido Temático Predefinido',
        predefined_content_header: 'Contenido de cabecera predefinido',
        predefined_content_body: 'Contenido predefinido del cuerpo',
        predefined_content_attachment: 'Contenido predefinido de los archivos adjuntos',
        predefined_content_recipient: 'Dirección To/CC predefinida',
        rate_control: 'Control de tarifas',
        sender: 'Políticas del remitente',
        ip: 'Políticas de direcciones IP',
        tls_required: 'TLS Obligatorio',
        inbound_tls_required: 'TLS entrante Obligatorio',
        attachment_filter: 'Filtro de fijación',
        message_loop: 'Posible bucle postal',
        message_size: 'Mensaje demasiado grande',
        bulk_email: 'Correo masivo',
        office_macros: 'Macros de oficina',
        predefined_filter_exception: 'Excepciones de filtro predefinidas',
        emailcat: 'Categorización del correo electrónico',
        system_sender: 'Políticas de remitente del sistema',
        recipient: 'Destinatario',
        recipient_limit: 'Lista de destinatarios',
        remediated_by_sentinel: 'Remediado por Impersonation Protection',
        remediated_by_forensics: 'Corregido por Respuesta a Incidentes',
        atd_exemption: 'ATP Exento',
        atd_full: 'Protección avanzada contra amenazas',
        atd: 'Protección avanzada contra amenazas',
        atd_subscribed: 'Detección avanzada de amenazas',
        atd_quarantine: 'ATP en cuarentena',
        batd_scan_pending: 'Escaneado pendiente',
        service_error_batd: 'ATP Scan Inconcluso',
        ui_delivered: 'UI Entregada',
        sender_disconnect: 'Entrega de mensaje interrumpida',
        sender_quit: 'Mensaje incompleto',
        sender_rset: 'Mensaje incompleto',
        service_error_av: 'Servicio AV no disponible',
        service_error_cs: 'Servicio Cloudscan no disponible',
        sender_spoof: 'Protección contra la suplantación de identidad del remitente',
        score: 'Puntaje',
        suspicious: 'Sospechoso',
        suspended: 'Cuenta suspendida',
        content_protected_msdoc: 'Oficina protegida',
        malformed: 'Malformado',
        language: 'Políticas lingüísticas',
        geoip: 'Políticas de GeoIP',
        'system-sender': 'Políticas de remitente del sistema',
        no_ptr: 'No hay registro PTR'
      },
      export: {
        message_id: 'Mensaje ID',
        from: 'Desde',
        to: 'Para',
        subject: 'Asunto',
        date: 'Fecha',
        size: 'Tamaño',
        action: 'Acción',
        reason: 'Razón',
        score: 'Puntaje'
      },
      error: {
        no_email_domain: 'El registro de mensajes no está disponible porque no tiene dominios de correo electrónico'
      },
      no_records_available: 'No hay registros disponibles',
      message_direction_menu: {
        inbound: 'Entrada',
        outbound: 'Salida',
        label: 'Dirección'
      }
    },
    message_detail: {
      title: 'Detalle de Mensaje',
      preview: 'Vista Previa',
      source: 'Fuente'
    },
    splitter_orientation: {
      none: 'Sin División',
      horizontal: 'División Horizontal',
      vertical: 'División Vertical'
    },
    navbar: {
      add: 'Añadir',
      changes_saved: 'Cambios guardados',
      noticeable_header: 'Novedades',
      noticeable_title: 'Email Gateway Defense',
      noticeable_footer: 'Ver más actualizaciones',
      legacy_ui: 'Clásico UI'
    },
    logoutmenu: {
      serial_number: 'Número de Serie',
      expiration: 'Caducidad',
      sign_out: 'Cerrar sesión',
      language_selector: 'Idioma: ',
      language_title: 'Seleccione el idioma preferido'
    },
    email_details: {
      email_from: 'Correo electrónico de {remitente} el {fecha}',
      recipients: 'Destinatarios',
      sender: SENDER,
      date: 'Fecha',
      subject: SUBJECT,
      headers: HEADERS,
      value: 'Valor',
      type: 'Tipo',
      copied: 'Copiado',
      copy_to_clipboard: 'Copiar al portapapeles',
      tabs: {
        email: EMAIL,
        headers: HEADERS,
        threat_details: 'Detalles de la amenaza'
      },
      threats: {
        dmarc: 'Cumplimiento de DMARC',
        spf: 'SPF Comprobación IP',
        dkim: 'Alineación DKIM'
      }
    },
    sidemenu: {
      message_log: MESSAGE_LOG,
      settings: SETTINGS,
      support: SUPPORT
    },
    change_password_page: {
      change_password: 'Cambiar contraseña',
      old_password: 'Contraseña actual',
      new_password: 'Nueva contraseña',
      confirm_new_password: 'Confirmar nueva contraseña',
      change_password_title: 'Confirmar cambio de contraseña',
      change_password_confirmation: '¿Estás seguro de que quieres cambiar la contraseña?'
    },
    scheduler: {
      mon: 'Lunes',
      tue: 'Martes',
      wed: 'Miércoles',
      thu: 'Jueves',
      fri: 'Viernes',
      sat: 'Sábado',
      sun: 'Domingo'
    },
    snackbar: {
      download_attachment_failed: 'No se ha podido descargar el archivo adjunto',
      download_message_failed: 'Mensaje de error de descarga',
      bad_date_range: 'La fecha/hora de inicio debe ser anterior a la fecha/hora de finalización',
      post_policy_success: 'La política de remitente se ha añadido correctamente',
      post_policy_failure: 'Error al añadir política de remitente',
      put_bulk_edit_success: 'La edición masiva se ha guardado correctamente',
      put_bulk_edit_failure: 'Error al guardar edición masiva: %s',
      put_quarantine_notification_success:
        'La configuración de la notificación de cuarentena se ha guardado correctamente',
      put_quarantine_notification_failure: 'No se ha podido guardar la configuración de notificación de cuarentena',
      delete_policy_success: 'La política de remitente se ha eliminado correctamente',
      delete_policy_failure: 'Error al eliminar la política de remitentes',
      password_success: 'La contraseña se ha establecido correctamente',
      password_failure: 'Error al establecer la contraseña',
      reset_password_success: 'La contraseña se ha restablecido correctamente',
      reset_password_failure: 'No se ha podido restablecer la contraseña',
      get_message_source_failed: 'Error al obtener el origen del mensaje',
      local_storage_quota_exceeded: 'El almacenamiento local del navegador se ha quedado sin espacio',
      post_block_sender_success: 'Añadido "%s" a la lista de bloqueos',
      post_block_sender_failed: 'Error al añadir remitente o dominio a la lista de bloqueados',
      post_allow_sender_success: '%d mensaje(s) permitido(s) listado(s) y enviado(s) a la cola de entrega',
      post_allow_sender_failed: 'Error al añadir remitente o dominio a la lista de permitidos',
      post_allow_sender_admin_forbidden:
        'Acción prohibida por la política de administración, póngase en contacto con su administrador',
      post_redeliver_message_success: '%d mensaje(s) enviado(s) a la cola de entrega',
      post_redeliver_message_failure: 'Se ha producido un error. 0 mensajes enviados a la cola de entrega',
      post_redeliver_message_admin_forbidden:
        'Acción prohibida por la política de administración, póngase en contacto con su administrador',
      post_new_email_success: 'El correo electrónico se ha enviado correctamente',
      post_new_email_failure: 'No se ha podido enviar el correo electrónico',
      post_recategorize_success: '%d mensaje(s) recategorizado(s)',
      post_recategorize_failure: 'Fallo al recategorizar mensaje(s)',
      post_add_linked_account_success: 'La cuenta vinculada se ha añadido correctamente',
      post_add_linked_account_failure: 'No se ha podido añadir la cuenta vinculada',
      post_add_linked_account_not_same_domain_failure:
        'La cuenta vinculada debe estar en el mismo dominio que %s, etc.',
      post_add_linked_account_existed_failure:
        'Este usuario ya existe. Elija otra dirección de correo electrónico para añadirla como alias.',
      post_add_linked_account_already_linked_failure: 'La dirección de correo electrónico %s ya está vinculada a %s',
      post_delete_linked_account_success: 'La dirección de correo electrónico se ha desvinculado correctamente',
      post_delete_linked_account_failure: 'Error al desvincular la dirección de correo electrónico',
      error_password_char_limit: 'La contraseña debe tener menos de 100 caracteres',
      error_new_password_not_same: 'La nueva contraseña no puede ser la misma que la antigua',
      error_confirm_password_is_same: 'Nueva contraseña y Confirmar nueva contraseña deben ser iguales',
      post_fpfn_report_success: 'Denunciado con éxito el incidente',
      post_fpfn_report_failure: 'No informar del incidente',
      delete_message_success: 'Mensaje(s) eliminado(s) con éxito de la Vista de Cuarentena',
      delete_message_failure: 'No se han podido borrar los mensajes',
      stop_delivery_reasons: '0 Mensajes entregados. No se puede entregar debido a "%s"',
      stop_delivery_admin: 'La entrega de %s mensajes ha sido desactivada por su administrador',
      message_detail_missing: 'Falta el detalle del mensaje. 0 mensajes entregados',
      detect_email_server_failure: 'No se ha detectado el servidor de correo electrónico',
      verify_email_server_failure: 'No se ha podido verificar el servidor de correo electrónico',
      wizard_save_error: 'Se ha producido un error al guardar, inténtalo de nuevo',
      download_message_success: 'Mensaje descargado correctamente',
      download_message_failure: 'No se ha podido descargar el mensaje',
      download_attachment_success: 'Archivo adjunto descargado correctamente',
      download_attachment_failure: 'No se ha podido descargar el archivo adjunto'
    },
    data_tables: {
      message_log: {
        select: 'Seleccione',
        action: 'Acción',
        delivery: 'Entrega',
        from: 'Desde',
        to: 'Para',
        subject: 'Asunto',
        date: 'Fecha',
        size: 'Tamaño',
        encryption: 'Encriptación',
        attachment: 'Adjunto',
        reason: 'Razón',
        score: 'Puntaje'
      },
      audit_log: {
        timestamp: 'Fecha',
        action_type: 'Tipo de evento',
        affected: 'Descripción',
        actor: 'Usuario',
        ip: 'Dirección IP'
      },
      atp_log: {
        status: 'Estado',
        time: 'Tiempo',
        from: 'Desde',
        to: 'Para',
        subject: 'Asunto',
        file_name: 'Nombre del archivo',
        file_type: 'Tipo de archivo',
        report: 'Informe',
        view_report: 'Ver informe'
      }
    },
    atp_log: {
      title: 'Registro ATP',
      infected_attachments: 'Archivos adjuntos infectados',
      suspicious_attachments: 'Archivos adjuntos sospechosos',
      last_30_days: 'En los últimos 30 días',
      status: 'Estado',
      time: 'Tiempo',
      file_type: 'Tipo de archivo',
      export_button: 'Exportar lista como CSV',
      search_placeholder: 'Buscar desde, hasta, asunto o nombre de archivo',
      all_domains: 'Todos los dominios',
      file_types: {
        all: 'Todos los tipos de archivos',
        ms_word: 'MS Word (doc, docx)',
        docx: 'MS Word (doc, docx)',
        ms_excel: 'MS Excel (xls, xlsx)',
        ms_ppt: 'MS Powerpoint (ppt, pptx)',
        win_exe: 'Windows Executable (exe)',
        win_package: 'Windows Package (msi)',
        android_package: 'Android Package (apk)',
        pdf: 'Adobe PDF (pdf)',
        archives: 'Archives (zip, rar)'
      },
      filters: {
        all: 'Todos',
        clean: 'Limpiar',
        suspicious: 'Sospechoso',
        infected: 'Infectado',
        30: 'Últimos 30 días',
        7: 'Últimos 7 días',
        1: 'Últimas 24 horas'
      },
      logStatuses: {
        all: 'Todos',
        infected: 'Infectado',
        clean: 'Limpiar',
        error: 'Error',
        suspicious: 'Sospechoso',
        future: 'Escaneado...'
      },
      export: {
        status: 'Estado',
        origin: 'Origen',
        fileName: 'Nombre del archivo',
        fileType: 'Tipo de archivo',
        messageId: 'Mensaje ID',
        from: 'Desde',
        to: 'Para',
        time: 'Tiempo'
      }
    },
    audit_log: {
      title: 'Registro de auditoría',
      status: 'Estado',
      time: 'Tiempo',
      file_type: 'Tipo de archivo',
      exportButton: 'Exportar CSV',
      search_placeholder: 'Buscar en',
      start_date: 'Fecha de inicio',
      end_date: 'Fecha final',
      filter: {
        title: 'Filtro',
        filter: 'Filtro',
        event_type: 'Tipo de acontecimiento',
        apply: 'Solicitar',
        clear: 'Claro'
      },
      export: {
        to: 'Para',
        time: 'TIEMPO',
        ipAddress: 'Dirección IP',
        userName: 'Usuario',
        description: 'Descripción',
        eventType: 'Tipo de acontecimiento',
        formattedDate: 'Fecha'
      },
      action: {
        select_all: 'Seleccionar todo',
        move: 'Mover',
        delete: 'Borrar',
        change: 'Clasificado',
        login: 'Iniciar sesión',
        logout: 'Cerrar sesión',
        log_action: 'Acción de registro',
        create: 'Cree',
        delete_all: 'Borrar todo',
        reset: 'Restablecer',
        enable: 'Activar',
        disable: 'Desactivar',
        suspend: 'Suspender',
        notify: 'Notificar a',
        migrate_cpl: 'Migrar CPL',
        restore: 'Restaurar',
        login_failure: 'Firmar en caso de fallo'
      },
      affected: {
        unavailable: 'No disponible',
        details_unavailable: 'Detalles no disponibles',
        require_password_for_quarantine_links: 'Contraseña necesaria para los enlaces de cuarentena',
        allow_quarantine_notification_interval_change: 'Permitir a los usuarios especificar el intervalo',
        enable_quarantine: 'Mensajes de cuarentena',
        sender_policies: 'Políticas del remitente',
        quarantine_notification_interval: 'Intervalo de notificación de cuarentena',
        created: 'Hora de creación del usuario',
        sub_expires: 'Expiración de la suscripción',
        sub_start_date: 'Fecha de inicio de la suscripción',
        visited: 'Visitado',
        password: 'Contraseña',
        smtp_servers: 'Servidores de correo',
        message_content_filters: 'Filtro de contenido de mensajes',
        ip_policies: 'Políticas de direcciones IP',
        geoip_policies: 'Políticas de GeoIP',
        spooling: 'Carrete',
        spf_hardfail: 'Fallo grave del Marco de directivas de remitente (SPF)',
        spf_softfail: 'Fallo suave del Marco de directivas de remitente (SPF)',
        cname_token: 'CNAME token',
        directory_services: 'Servicios de directorio',
        dkim: 'Acción DomainKeys Identified Mail (DKIM)',
        azure_ad: 'Azure AD',
        sender_spoof: 'Protección contra la suplantación de identidad del remitente',
        user_auto_add: 'Añadir usuarios automáticamente',
        fastspam_scores: 'Puntuación de Cloudscan',
        encryption_passtoken: 'Cifrado Validación Passtoken',
        encryption_vtype: 'Tipo de validación de cifrado',
        ldap_auth: 'Configuración de la autenticación LDAP',
        ldap_sync: 'LDAP sync config',
        force_tls: 'SMTP sobre TLS',
        srs: 'Esquema de reescritura del remitente (SRS)',
        ptr: 'Bloqueo por ausencia de registros PTR',
        bafi: 'Inteligencia antifraude',
        redirector_content_action: 'Intención de contenido',
        atd: 'Protección avanzada contra amenazas',
        intent: 'Análisis de intenciones',
        brts: 'Sistema en tiempo real Barracuda (BRTS)',
        brbl: 'Lista de bloqueos de reputación de Barracuda',
        fastspam_enabled: 'Cloudscan',
        encryption_verification: 'Verificación del cifrado',
        vscan: 'Análisis de virus',
        ext_sender_warning: 'Aviso de remitente externo',
        linkprotect: 'Protección de enlaces',
        default_user_exempt_enabled: 'Permitir a los usuarios eximir a los remitentes',
        status: 'Estado de la suscripción',
        typosquat: 'Protección contra la typosquatting',
        outbound_message_content_filters: 'Filtro de contenido de mensajes salientes',
        linked_domain_policy: 'Políticas de dominio de intención',
        dmarc: 'Autenticación de mensajes basada en dominios (DMARC)',
        dkim_exempt: 'DKIM Exento',
        atd_notify: 'ATP - Notificar a Admin',
        user_policies: 'Políticas de los beneficiarios',
        ldap: 'Ajustes de sincronización LDAP',
        encryption_confirmed: 'Validación de cifrado confirmada',
        outbound_predefined_message_content_filters: 'Filtros predefinidos de salida',
        spf_exempt: 'Exenciones del SPF',
        language_policies: 'Políticas lingüísticas',
        atd_exempt_email: 'ATP - Exenciones por dirección de correo electrónico / dominios',
        default_timezone: 'Zona horaria por defecto',
        bulk_email_detection: 'Detección de correo masivo',
        attachment_filter_policy: 'Filtro de fijación',
        default_unmanaged_scan_policy: 'Política por defecto para usuarios no gestionados',
        emailcat_marketing: 'Material de marketing y boletines',
        default_user_deliver_enabled: 'Permitir a los usuarios finales ver y entregar mensajes bloqueados',
        default_user_quar_deliver_enabled: 'Permitir a los usuarios finales ver y entregar mensajes en cuarentena',
        emailcat_listserver: 'Categorización de las listas de correo',
        emailcat_socialmedia: 'Categorización de las redes sociales',
        emailcat_corporate: 'Categorización del correo electrónico corporativo',
        emailcat_transactional: 'Categorización del correo electrónico transaccional',
        emergency_inbox_autoenabled: 'Continuidad del correo electrónico',
        outbound_tls_domains: 'Dominios TLS salientes',
        default_managed_scan_policy: 'Política por defecto para usuarios gestionados',
        trusted_forwarders: 'Transitarios de confianza',
        managed_domains: 'Dominios gestionados por usuario',
        default_user_block_enabled: 'Permitir a los usuarios bloquear remitentes',
        deleted: 'Dominio eliminado',
        rate_control_exemptions: 'Exenciones del control de tasas',
        saved_searches: 'Búsquedas guardadas',
        rbls: 'Listas negras de reputación (RBLs)',
        atd_exempt_ip: 'ATP - Exenciones por dirección IP del remitente',
        dmarc_exempt: 'Exento de DMARC',
        ptr_exempt: 'Exenciones PTR que faltan',
        outbound_spam_notification_address: 'Dirección de notificación de spam saliente',
        reject_notification_address: 'Dirección de notificación de rechazo',
        rate_control: 'Control de tarifas',
        alias_of: 'Alias de dominio',
        helpdesk_domains: 'Dominios del servicio de asistencia al usuario',
        outbound_tagline: 'Texto saliente Tagline/Footer',
        outbound_tagline_enabled: 'Añadir eslogan',
        outbound_attachment_filter_policy: 'Filtro de archivos adjuntos salientes',
        atd_admin_emails: 'Correo electrónico de notificación ATP',
        disable_account_rate_control: 'Control interno de tarifas',
        sender_notification_address: 'Dirección de notificación de cuarentena',
        sender_notification_enable: 'Notificación de remitente en cuarentena',
        reject_notification_subject: 'Asunto de la notificación de rechazo',
        reject_notification_template: 'Plantilla de notificación de rechazo',
        password_msoffice_filtering: 'Documentos de Microsoft Office protegidos con contraseña',
        password_archive_filtering: 'Filtrado de archivos protegido por contraseña',
        outbound_password_archive_filtering: 'Filtrado de archivos salientes protegido por contraseña',
        password_pdf_filtering: 'PDF protegido con contraseña',
        outbound_password_pdf_filtering: 'PDF saliente protegido con contraseña',
        outbound_predefined_message_content_filter_exceptions: 'Excepciones del filtro predefinido de salida',
        outbound_password_msoffice_filtering: 'Documentos de Microsoft Office salientes protegidos con contraseña',
        sender_notification_subject: 'Asunto de la notificación de cuarentena',
        sender_notification_template: 'Plantilla de notificación de cuarentena',
        support_access_enabled: 'Apoyo al acceso',
        default_scan_policy: 'Política de destinatarios por defecto',
        message_body_access_enabled: 'Acceso de los socios a los mensajes',
        dismissed_notifications: 'Notificación Desestimada',
        sub_override: 'Suscripción anulada por Barracuda Networks',
        brts_send_evidence: 'Enviar contenido de mensajes sospechosos a Barracuda Central para su análisis',
        outbound_quarantine_start: 'Hora de inicio de la notificación',
        outbound_quarantine_email: 'Dirección de notificación',
        outbound_quarantine_interval: 'Intervalo de notificación de cuarentena saliente',
        custom_interval: 'Intervalo de notificación de cuarentena saliente',
        search_logs_all_domains:
          '(Configuración interna) Activar registros de mensajes de búsqueda en todos los dominios',
        azure_ad_ui: '(Configuración interna) Activar la configuración de Azure AD',
        domain_move: '(Configuración interna) Activar el traslado de dominio de autoservicio',
        trial_expiration_banner: '(Configuración interna) Activar banner de caducidad de prueba',
        greylist_exempt: '(Configuración interna) Desactivar los aplazamientos greylist (sospechosos) para la cuenta',
        use_primary_smarthost: '(Configuración interna) Utilizar Smarthost primario',
        auditlog: '(Configuración interna) Activar registro de auditoría',
        click_protection: '(Configuración interna) Activar la configuración de protección contra clics',
        config_copy: '(Configuración interna) Activar copia de seguridad de la configuración de la cuenta',
        dmarc_ui: '(Configuración interna) Activar la configuración DMARC',
        inbound_tagline: '(Configuración interna) Eslogan del correo electrónico entrante',
        noblock_sender_response: '(Configuración interna) Activar bloqueo silencioso de mensajes de correo electrónico',
        retention: '(Configuración interna) Periodo de conservación del correo electrónico',
        outbound_use_email_domain:
          '(Configuración interna) Utilizar el dominio de correo electrónico para los mensajes salientes',
        outbound_ndr_policy: '(Configuración interna) Política NDR de salida',
        spoofing_enabled: '(Configuración interna) Spoofing activado',
        max_message_size: '(Configuración interna) Tamaño máximo de mensaje',
        athena_stats: '(Configuración interna) Activar recuento de destinatarios de athena',
        disable_shared_ip_ranges: '(Configuración interna) Retransmisión saliente alojada - EG. O365, Google Apps,...',
        outbound_ip_partition_id: '(Configuración interna) Tipo de partición de salida',
        skip_subdomains: '(Configuración interna) Rechazar correo a dominios secundarios',
        outbound_rate_control_block: '(Configuración interna) Bloqueo de control de tarifa de usuario de salida',
        outbound_rate_control: '(Configuración interna) Control de velocidad de usuario no gestionado por dominio',
        outbound_per_domain_rate_limit: '(Configuración interna) Control de velocidad por usuario gestionado',
        admins_msg_body_access:
          '(Configuración interna) Permitir a los administradores ver el contenido del correo electrónico y los archivos adjuntos',
        disable_outbound_multilevel_intent_content_check:
          '(Configuración interna) Permitir a los administradores desactivar la comprobación saliente de intención múltiple del contenido de las páginas web.',
        outbound_per_user_rate_limit: '(Configuración interna) Exenciones de control de tarifas de usuarios salientes',
        type: '(Configuración interna) Tipo de dominio (ESS/Híbrido)',
        end_user_react_ui: '(Configuración interna) Usuario final React UI',
        react_end_users: '(Configuración interna) Usuario final React UI',

        // NOTE: These Quarantine Notifier Settings need to be in camel-case
        allowOverride: 'Permitir a los usuarios especificar el intervalo',
        inboundSchedule: 'Intervalo de notificación de cuarentena',
        customInboundSchedule: 'Programar intervalos de notificación',
        customOutboundSchedule: 'Programar intervalos de notificación',
        outboundEmailIds: 'Dirección de notificación',
        outboundSchedule: 'Intervalo de notificación de cuarentena saliente',
        allowWhitelist: 'Permitir a los usuarios eximir a los remitentes',

        // Strings with params
        fmt_domain_moved: 'Dominio %s movido a %s',
        fmt_domain_from: 'Dominio %s movido a esta cuenta desde %s',
        fmt_changed: '%s cambiado',
        fmt_changed_for_domain: '%s cambiado para el dominio %s',
        fmt_user_deleted: 'Usuario %s eliminado',
        fmt_outbound_ip_deleted: 'IP de salida %s eliminada',
        fmt_deleted_for_domain: '%s borrado para el dominio %s',
        fmt_domain_deleted: 'Dominio %s eliminado',
        fmt_deleted: '%s borrado',
        fmt_user_logged_in: 'Usuario conectado correctamente',
        fmt_logged_in_domain: 'El usuario se ha conectado correctamente a la gestión del dominio %s',
        fmt_support_logged_in: 'Barracuda Support ha iniciado sesión correctamente',
        fmt_user_logged_out: 'Usuario desconectado correctamente',
        fmt_user_logged_out_domain: 'El usuario se ha desconectado correctamente de la gestión del dominio %s',
        fmt_support_logged_out: 'Barracuda Support ha cerrado la sesión correctamente',
        fmt_atd_exported: 'Líneas de registro ATD exportadas',
        fmt_message_log_exported: 'Líneas del registro de mensajes exportadas',
        fmt_message_deleted: 'Mensaje %s borrado',
        fmt_message_sent_to_emailreg: 'Mensaje %s enviado a EmailReg',
        fmt_message_blocked: 'Mensaje %s bloqueado',
        fmt_message_whitelisted: 'Mensaje %s en la lista blanca',
        fmt_message_recategorized: 'Mensaje %s recategorizado',
        fmt_message_delivered: 'Mensaje %s entregado',
        fmt_message_viewed: 'Mensaje %s visto',
        fmt_message_marked_spam: 'Mensaje %s marcado como spam',
        fmt_message_marked_not_spam: 'Mensaje %s marcado como no spam',
        fmt_message_rejected: 'Mensaje %s rechazado',
        fmt_setting_created: '%s creados',
        fmt_user_created: 'Usuario %s creado',
        fmt_outbound_ip_created: 'IP de salida %s creada',
        fmt_domain_created: 'Dominio %s creado',
        fmt_user_settings_deleted: 'Todos los ajustes del usuario %s borrados',
        fmt_domain_users_deleted: 'Todos los usuarios del dominio %s eliminados',
        fmt_account_outbound_ips_deleted: 'Todas las IPs salientes para el dominio %s en la cuenta %s eliminadas',
        fmt_list_outbound_ips_deleted: 'Todas las IPs salientes de la lista [%s] eliminadas',
        fmt_domain_settings_deleted: 'Todos los ajustes del dominio %s eliminados',
        fmt_account_settings_deleted: 'Todos los ajustes de la cuenta %s eliminados',
        fmt_domain_settings_reset_account_level:
          'Ajustes de Dominio para el Dominio %s Restablecidos a Equivalentes de Nivel de Cuenta',
        fmt_login_info_sent: 'La Información de Inicio de Sesión se ha Enviado a <strong>%s</strong>.',
        fmt_account_ended: 'Cuenta Cerrada',
        fmt_account_disabled: 'Cuenta Desactivada',
        fmt_account_suspended: 'Cuenta Suspendida',
        fmt_account_migrated_to_cpl: 'Cuenta Migrada a CPL',
        fmt_user_notified_via_email: 'Usuario(s) %s ha(n) Sido Notificado(s) por Correo Electrónico',
        fmt_domain_settings_restored: 'Configuración de Dominio [%s] Restaurada para el Dominio %s',
        fmt_login_failure: 'Fallo de Inicio de Sesión'
      }
    }
  }
}
/* eslint-enable quotes */
