import { makeStyles } from '@cuda-networks/bds-core'

import PreviousIcon from 'assets/images/icons/icon_previous_arrow.svg'
import NextIcon from 'assets/images/icons/icon_next_arrow.svg'

export default makeStyles(theme => ({
  toolbarWrapper: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    height: `${theme.layout.messageLogToolbarHeight}`,
    '& .k-grid-toolbar': {
      alignItems: 'center',
      display: 'flex'
    }
  },
  toolbarIconButton: {
    margin: theme.spacing(0, 0.25),
    padding: theme.spacing(0.75)
  },
  toolbarCheckbox: {
    margin: 0,
    padding: theme.spacing(0.75)
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  splitterMenu: {
    lineHeight: 'normal',
    '& div::before': {
      borderBottom: 'none'
    },
    '& div > div > div': {
      display: 'flex',
      width: '20px !important'
    }
  },
  pager: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    display: 'inline-flex',
    margin: theme.spacing(0.75, 1),
    padding: 0,
    '&.disabled': {
      pointerEvents: 'none',
      '& .k-link': {
        opacity: 0.6
      }
    },
    '& .k-i-arrow-60-left::before': {
      background: `url(${PreviousIcon}) no-repeat center center`,
      backgroundSize: '24px 24px',
      content: '"" !important',
      marginBottom: '2px',
      minHeight: '24px',
      minWidth: '24px'
    },
    '& .k-i-arrow-60-right::before': {
      background: `url(${NextIcon}) no-repeat center center`,
      backgroundSize: '24px 24px',
      content: '"" !important',
      marginBottom: '2px',
      minHeight: '24px',
      minWidth: '24px'
    },
    '& .k-pager-info': {
      display: 'inline-block !important',
      float: 'left',
      margin: 0,
      minWidth: '80px',
      order: -1,
      textAlign: 'center'
    },
    '& .k-pager-numbers-wrap': {
      display: 'none !important'
    },
    '& .k-dropdown, .k-pager-first, .k-pager-last': {
      display: 'none !important'
    }
  },
  spacer: {
    flexGrow: 1
  },
  toolbarIcon: {
    width: 24,
    height: 24
  }
}))
