import { useState, useMemo, useCallback, useEffect } from 'react'

import { SavedSearch } from 'types/Messages'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { openSearchForm, removeSavedSearchForm } from 'redux/features/mstore/mstoreSlice'

export interface UseOpenSearchDialogLogic {
  savedSearchQuery: string
  savedSearches: SavedSearch[]
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDeleteSearch: (searchName: string) => void
  handleSelectSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedSearch: string
  openSearch: () => void
}

export const useOpenSearchDialogLogic = (onClose: () => void): UseOpenSearchDialogLogic => {
  const dispatch = useAppDispatch()

  const { savedSearchForms } = useAppSelector(_store => ({
    savedSearchForms: _store.mstore.savedSearchForms
  }))

  const [savedSearchQuery, setSavedSearchQuery] = useState('')
  const [savedSearches, setSavedSearches] = useState(savedSearchForms)
  const [selectedSearch, setSelectedSearch] = useState<string>('')

  useEffect(() => {
    setSavedSearches(savedSearchForms)
  }, [savedSearchForms])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setSavedSearchQuery(value)
      const filteredSavedSearches = savedSearchForms.filter(
        searchForm => searchForm.name.search(value) >= 0 || searchForm.searchTerm.search_str.search(value) >= 0
      )

      if (value.length >= 1) {
        setSavedSearches(filteredSavedSearches)
      } else {
        setSavedSearches(savedSearchForms)
      }
    },
    [savedSearchForms]
  )

  const handleDeleteSearch = useCallback(
    (searchName: string) => {
      dispatch(removeSavedSearchForm(searchName))
    },
    [dispatch]
  )

  const handleSelectSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSearch(e.target.value)
  }, [])

  const openSearch = useCallback(() => {
    dispatch(openSearchForm(selectedSearch))
    onClose()
  }, [dispatch, onClose, selectedSearch])

  return useMemo(
    () => ({
      savedSearchQuery,
      savedSearches,
      handleInputChange,
      handleDeleteSearch,
      selectedSearch,
      handleSelectSearch,
      openSearch
    }),
    [
      savedSearchQuery,
      savedSearches,
      handleInputChange,
      handleDeleteSearch,
      handleSelectSearch,
      selectedSearch,
      openSearch
    ]
  )
}
