/* eslint-disable jsx-a11y/anchor-is-valid */
import util from 'util'
import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Typography,
  Link,
  LinearProgress,
  AppBar,
  Snackbar,
  Alert,
  Grid
} from '@cuda-networks/bds-core'
import * as bdsTheme from '@cuda-networks/bds-core/dist/styles/themes'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isPending } from 'redux/toolkit/api'
import LogoURL from 'assets/images/barracuda/EP-EmailGatewayDefense_rev.svg'
import { isEmailValid } from 'lib/validation'
import { login, resetLogin } from 'redux/features/auth/authSlice'
import { postBlockSender, postAllowSender, postRedeliverMessage } from 'redux/features/mstore/mstoreSlice'
import routesConfig, { appEntryPath } from 'lib/routesConfig'
import restClient from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import Footer from 'components/libs/layout/footer/Footer'
import styles from './loginStyles'

const actionsMap: { [index: string]: any } = {
  deliver: postRedeliverMessage,
  block: postBlockSender,
  whitelist: postAllowSender
}

function Login() {
  const dispatch = useAppDispatch()
  const { loginError, isLoggingIn, accessToken } = useAppSelector(_store => ({
    loginError: getErrorMessage(_store.auth.loginApiStatus),
    isLoggingIn: isPending(_store.auth.loginApiStatus),
    accessToken: _store.auth.accessToken
  }))
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const actionType = params.get('actionType')
  const messageId = params.get('messageId')
  const [cardType, setCardType] = useState('Login')
  const resetPassword = params.get('reset-password')
  const forgotPassword = params.get('forgot-password')
  const [formValues, setFormValues] = useState({ user_id: '', password: '' })
  const [emailError, setEmailError] = useState(false)
  const [message, setMessage] = useState('')
  const classes = styles(bdsTheme)
  const intl = useIntl()
  const nextButton = useRef<HTMLButtonElement>()
  const loginButton = useRef<HTMLButtonElement>()

  const text = (id: string) => intl.formatMessage({ id: `ess.login.${id}` })

  useEffect(() => {
    setMessage('')

    if (resetPassword === '1') {
      setMessage(text('reset_password_message'))
      routesConfig.LOGIN.goto()
    } else if (forgotPassword && forgotPassword.length !== 0) {
      const msg = text('message.sent_message')
      setMessage(util.format(msg, forgotPassword))
      routesConfig.LOGIN.goto()
    }

    const listener = (e: any) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault()
        e.stopPropagation()
        if (loginButton.current) {
          loginButton.current.click()
        } else if (nextButton.current) {
          nextButton.current.click()
        }
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
    // eslint-disable-next-line
  }, [])

  const adminLoginUrl = `https://login.barracudanetworks.com/auth/login?service=${encodeURI(window.location.href)}`

  const getAzureLoginUrl = async () => {
    try {
      const response = await restClient(apiRoutes.AZURE_LOGIN_URL, {
        data: {
          ...formValues
        }
      })
      return response.data
    } catch (e) {
      // FIXME: improve error handling
      // eslint-disable-next-line no-console
      console.log('Failed to get Azure login URL: ', e)
      return ''
    }
  }

  const requestLoginInfo = async () => {
    try {
      const response = await restClient(apiRoutes.LOGIN_INFO, {
        data: {
          ...formValues
        }
      })
      return response.data
    } catch (e) {
      // FIXME: improve error handling
      // eslint-disable-next-line no-console
      console.log('Failed to request login info: ', e)
      return ''
    }
  }

  const onInputChange = (e: any) => {
    setEmailError(false)
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const onNext = () => {
    const valid = isEmailValid(formValues.user_id)
    setEmailError(!valid)
    if (valid) {
      getAzureLoginUrl().then(azureUrl => {
        if (azureUrl) {
          window.location.href = azureUrl
        } else {
          setCardType('Password')
        }
      })
    }
  }

  const onLogin = () => {
    dispatch(login(formValues))
  }

  const onLoginDifferentUser = () => {
    dispatch(resetLogin())
    setFormValues({ user_id: '', password: '' })
    setCardType('Login')
  }

  const onForgotPassword = () => {
    requestLoginInfo().then(() => {
      setCardType('Message')
    })
  }

  const onResendLink = () => {
    onForgotPassword()
    setMessage(text('new_link_sent'))
  }

  const onClickLogo = () => {
    window.location.href = 'https://www.barracuda.com/products/essentials'
  }

  useEffect(() => {
    if (accessToken) {
      // TODO: using mixpanel without user
      // trackMixpanelEvent(TRACKING_EVENTS.WEBUI.LOGIN_PAGE_VIEW)
      if (actionType === 'quarantineNotification') {
        routesConfig.SETTINGS_QUARANTINE_NOTIFICATION.goto()
      } else if (actionType && messageId) {
        dispatch(
          actionsMap[actionType]?.([
            {
              messageId
            }
          ])
        )

        appEntryPath.goto()
      } else {
        appEntryPath.goto()
      }
    }
    // eslint-disable-next-line
  }, [accessToken])

  const validatedLoginError = useMemo(() => cardType === 'Password' && !!loginError, [cardType, loginError])

  const validatedLoggingIn = useMemo(() => cardType === 'Password' && isLoggingIn, [cardType, isLoggingIn])

  const onCloseSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setMessage('')
  }

  const Progress = (
    <div className={classes.progress}>{validatedLoggingIn && <LinearProgress data-testid="linear-progress" />}</div>
  )

  const LoginCard = (
    <Card elevation={3} className={classes.passwordCard} data-testid="login-card">
      <CardHeader title={<Typography className={classes.title}>{text('title')}</Typography>} data-testid="title" />
      <CardContent className={classes.cardContent}>
        <div className={classes.loginCardTitle}>
          <Typography variant="body1" data-testid="signin-message">
            {text('signin_message')}
          </Typography>
        </div>
        <div className={classes.loginCardForm}>
          <Typography variant="subtitle2" data-testid="email">
            {text('email')}
          </Typography>
          <TextField
            error={emailError}
            fullWidth
            autoFocus
            name="user_id"
            value={formValues.user_id}
            onChange={onInputChange}
            size="medium"
            placeholder={text('email')}
            helperText={emailError ? text('invalid_email') : ''}
            data-testid="user-id-input"
            inputProps={{ style: { padding: 8 } }}
          />
        </div>
        <CardActions disableSpacing className={classes.cardActions}>
          <Grid container>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.loginCardActionGridContainer}
            >
              <Grid item>
                <Link href={adminLoginUrl} data-testid="admin-signin-link">
                  <Typography variant="body1">{text('admin_signin')}</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={onNext}
                  innerRef={nextButton}
                  data-testid="next-button"
                >
                  {text('next')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
        {Progress}
      </CardContent>
    </Card>
  )

  const PasswordCard = (
    <Card elevation={3} className={classes.loginCard} data-testid="password-card">
      <CardHeader title={<Typography className={classes.title}>{text('title')}</Typography>} data-testid="title" />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item className={classes.passwordCardContentTitle}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: util.format(text('signin_message_email'), formValues.user_id)
              }}
              data-testid="signin-message-email"
            />
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="subtitle2" data-testid="password">
                {text('password')}
              </Typography>
            </Grid>
            <Grid item className={classes.passwordCardInput}>
              <TextField
                error={validatedLoginError}
                fullWidth
                autoFocus
                name="password"
                value={formValues.password}
                onChange={onInputChange}
                size="medium"
                placeholder="Password"
                type="password"
                helperText={validatedLoginError ? text('invalid_email') : ''}
                data-testid="password-input"
                inputProps={{ style: { padding: 8 } }}
              />
            </Grid>
          </Grid>
        </Grid>
        <CardActions disableSpacing className={classes.cardActions}>
          <Grid container>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              className={classes.passwordCardActionGridContainer}
            >
              <Grid item className={classes.forgotPasswordLink}>
                <Link
                  href="#"
                  className={classes.flexLink}
                  onClick={onForgotPassword}
                  data-testid="forgot-password-link"
                >
                  <Typography variant="body1">{text('forgot_password')}</Typography>
                </Link>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.passwordCardActionLinks}
              >
                <Grid item>
                  <Link
                    href="#"
                    className={classes.flexLink}
                    onClick={onLoginDifferentUser}
                    data-testid="different-user-link"
                  >
                    <Typography variant="body1">{text('different_account_message')}</Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={onLogin}
                    innerRef={loginButton}
                    data-testid="login-button"
                  >
                    {text('signin')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
        {Progress}
      </CardContent>
    </Card>
  )

  const MessageCard = (
    <Card elevation={3} className={classes.messageCard} data-testid="message-card">
      <CardHeader title={text('message.title')} />
      <CardContent>
        <div className={classes.messageCardContent}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: util.format(text('message.sent_message'), formValues.user_id)
            }}
          />
        </div>
        <div className={classes.messageCardSubtitle}>
          <Typography variant="subtitle2">{text('message.expiry_message')}</Typography>
        </div>
        <div className={classes.resendLinkBox}>
          <Link href="#" className={classes.flexLink} onClick={onResendLink} data-testid="resend-link">
            {text('message.resend')}
          </Link>
        </div>
        <div className={classes.differentUserLoginBox}>
          <Link href="#" className={classes.flexLink} onClick={onLoginDifferentUser}>
            {text('different_account_message')}
          </Link>
        </div>
      </CardContent>
    </Card>
  )

  const CardMap: { [key: string]: any } = {
    Login: LoginCard,
    Password: PasswordCard,
    Message: MessageCard
  }

  return (
    <AppBar position="fixed" elevation={1} className={classes.login}>
      <div className={classes.logoBanner}>
        <img
          role="presentation"
          alt="Barracuda Email Gateway Defense"
          className={classes.logo}
          src={LogoURL}
          onClick={onClickLogo}
          data-testid="logo"
        />
      </div>
      <Snackbar
        className={classes.snackBar}
        open={message !== ''}
        transitionDuration={0}
        autoHideDuration={6000}
        onClose={onCloseSnackBar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        TransitionProps={{
          appear: false
        }}
        data-testid="snack-bar"
      >
        <Alert severity="info" onClose={onCloseSnackBar} data-testid="alert">
          {message}
        </Alert>
      </Snackbar>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.container}>{CardMap[cardType]}</div>
        </Grid>
      </Grid>
      <Footer />
    </AppBar>
  )
}

export default Login
/* eslint-enable jsx-a11y/anchor-is-valid */
