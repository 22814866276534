import React from 'react'

import { Grid, Typography } from '@cuda-networks/bds-core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import styles from 'components/libs/errorPage/errorPageStyles'

export interface ErrorPageProps {
  title: string
  subtitle?: string
}

function ErrorPage({ title, subtitle }: ErrorPageProps) {
  const classes = styles()

  return (
    <div data-testid="error-page" className={classes.fullSizeContainer}>
      <Grid className={classes.container} container justifyContent="center" alignContent="center" direction="column">
        <ErrorOutlineIcon className={classes.icon} />
        <Typography className={classes.text} variant="h2" align="center">
          {title}
        </Typography>
        {subtitle && (
          <Typography className={classes.text} variant="subtitle1" align="center">
            {subtitle}
          </Typography>
        )}
      </Grid>
    </div>
  )
}

export default ErrorPage
