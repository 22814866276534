import {
  initMixpanel,
  setMixpanelUser,
  setMixpanelUserFlags,
  trackMixpanelEvent,
  trackPageLeaveMixpanelEvent
} from 'lib/monitoring/mixpanel/mixpanelService'
import { initIntercom, setIntercomUser, trackIntercomEvent } from 'lib/monitoring/intercom/intercomService'
import { LoginResponse, User } from 'types/auth'
import appConfig from 'config/appConfig'

export { TRACKING_EVENTS } from 'config/trackingEvents/trackingEvents'

export function initMonitoringServices(user: User, accessToken: LoginResponse['accessToken']) {
  initMixpanel(user, accessToken)
  initIntercom(user, accessToken)
}

export function trackEventInAllServices(
  eventName: string,
  metadata?: Record<string, unknown>,
  timerEventName?: string
) {
  const updatedMetadata = {
    ...(metadata || {}),
    ...(!appConfig.ENVIRONMENT.IS_PRODUCTION && { productVersion: 'v1' })
  }

  trackMixpanelEvent(eventName, updatedMetadata, timerEventName)
  trackIntercomEvent(eventName, updatedMetadata)
}

export {
  trackMixpanelEvent,
  trackIntercomEvent,
  trackPageLeaveMixpanelEvent,
  setIntercomUser,
  setMixpanelUser,
  setMixpanelUserFlags
}
