import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles({
  card: {
    width: '40%',
    '& .k-animation-container': {
      zIndex: 10003
    }
  },
  loadingCard: {
    width: '40%',
    height: '225px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .k-animation-container': {
      zIndex: 10003
    }
  },
  loadingDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reportingMessage: {
    marginTop: '10px'
  },
  actionContent: {
    padding: '15px 0'
  },
  cardActions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  }
})
