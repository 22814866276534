import appConfig, { AppNames } from 'config/appConfig'

const { IS_DEV, IS_GT3, IS_PRODUCTION, IS_TEST, IS_STAGING } = appConfig.ENVIRONMENT

export type FeatureConfig = [AppNames[], boolean[]]

export enum AppFeature {
  // Wizard
  EnabledWizardBccLogin = 'EnabledWizardBccLogin',
  DisabledWizardAdminCheck = 'DisabledWizardAdminCheck',
  DisabledWizardRegionSelectedCheck = 'DisabledWizardRegionSelectedCheck',
  EnabledRegionChange = 'EnabledRegionChange',

  // Admin
  EnabledNewAdminApp = 'EnabledNewAdminApp',
  EnabledLicenseCompliance = 'EnabledLicenseCompliance',

  // Enduser
  DisabledLanguageSelector = 'DisabledLanguageSelector'
}

export const APP_FEATURES: { [key in AppFeature]: FeatureConfig } = {
  // Wizard
  [AppFeature.EnabledWizardBccLogin]: [[AppNames.wizard], [IS_STAGING, IS_PRODUCTION]],
  [AppFeature.DisabledWizardAdminCheck]: [[AppNames.wizard], [IS_DEV, IS_GT3]],
  [AppFeature.DisabledWizardRegionSelectedCheck]: [[AppNames.wizard], []],
  [AppFeature.EnabledRegionChange]: [[AppNames.wizard], [IS_PRODUCTION, IS_TEST]],

  // Admin
  [AppFeature.EnabledNewAdminApp]: [
    [AppNames.wizard, AppNames.admin],
    [IS_STAGING, IS_DEV, IS_GT3]
  ],
  [AppFeature.EnabledLicenseCompliance]: [[AppNames.admin], [IS_DEV, IS_STAGING, IS_GT3]],

  // Enduser
  [AppFeature.DisabledLanguageSelector]: [[AppNames.enduser], [IS_PRODUCTION]]
}

export function isFeatureEnabled(featureName: AppFeature): boolean {
  const [appName, enabledStates] = APP_FEATURES[featureName]
  const isValidApp = appName.includes(appConfig.APP.NAME) || IS_TEST
  const isEnabled = enabledStates.some(state => state)

  return isValidApp && isEnabled
}

export default (Object.keys(AppFeature) as Array<AppFeature>).reduce(
  (all, appFeature) => ({ ...all, [appFeature]: isFeatureEnabled(appFeature) }),
  {}
) as { [key in AppFeature]: boolean }
