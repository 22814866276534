import React, { useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import initLocalization, { Localization } from 'lib/localization'
import { useAppSelector } from 'redux/toolkit/hooks'

export const IntlWrapper = ({ children }: React.PropsWithChildren) => {
  const { language } = useAppSelector(_store => ({
    language: _store.app.language
  }))
  const localization: Localization = useMemo(() => initLocalization(language), [language])

  return (
    <IntlProvider locale={localization.locale} messages={localization.messages}>
      {children}
    </IntlProvider>
  )
}
