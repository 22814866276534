import React, { Children, cloneElement } from 'react'

import Navbar from 'components/libs/layout/navbar/Navbar'

import Footer from 'components/libs/layout/footer/Footer'
import styles from 'components/libs/layout/layoutStyles'

export default function Layout({ children }: { children: React.ReactNode }) {
  const classes = styles()

  return (
    <div>
      <div className="App">
        <Navbar />
        <div className={classes.wrapper}>
          {Children.map(children, child => cloneElement(child as React.ReactElement<any>, {}))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
