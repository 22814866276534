import {
  useMemo,
  DragEventHandler,
  DragEvent,
  MouseEventHandler,
  MouseEvent,
  useRef,
  MutableRefObject,
  useCallback
} from 'react'
import { cloneDeep } from 'lodash'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { DAYS, HOURS, Day, Hour, InboundScheduleUI } from 'types/Settings'
import { updateSchedule } from 'redux/features/settings/settingsSlice'

interface ScheduleMatrixContainer {
  draggable: boolean
  onDragStart: DragEventHandler<HTMLTableCellElement>
  onClick: MouseEventHandler<HTMLTableCellElement>
  onDragLeave: DragEventHandler<HTMLTableCellElement>
  onDragEnd: DragEventHandler<HTMLTableCellElement>
  onDrag: DragEventHandler<HTMLTableCellElement>
  onDragOver: DragEventHandler<HTMLTableCellElement>
}

export interface ScheduleMatrixLogic {
  allowdQNInterval: boolean
  container: ScheduleMatrixContainer
  days: typeof DAYS
  hours: typeof HOURS
  scheduleRef: MutableRefObject<InboundScheduleUI | undefined>
}

const useScheduleMatrixLogic = (): ScheduleMatrixLogic => {
  const dispatch = useAppDispatch()
  const { quarantineNotification, accountPermissions } = useAppSelector(_store => ({
    quarantineNotification: _store.settings.quarantineNotification,
    accountPermissions: _store.settings.accountPermissions
  }))

  const scheduleRef = useRef(quarantineNotification?.customInboundSchedule)

  const allowdQNInterval: boolean = useMemo(
    () => accountPermissions?.allowQuarantineNotificationIntervalChange === 1,
    [accountPermissions]
  )

  const selectTime = useCallback(
    (e: MouseEvent | DragEvent, toggle = false) => {
      const { classList, dataset } = e.target as HTMLElement
      if (!dataset.time) {
        return
      }
      const [day, hour] = dataset.time.split('-') as [Day, Hour]

      // update UI
      if (toggle) {
        classList.toggle('selected-cell')
      } else {
        // eslint-disable-next-line no-unused-expressions
        e.shiftKey ? classList.remove('selected-cell') : classList.add('selected-cell')
      }

      // update dataset
      const updatedCurrent = cloneDeep(scheduleRef.current)
      if (updatedCurrent) {
        if (classList.contains('selected-cell')) {
          updatedCurrent[day][hour] = true
        } else {
          delete updatedCurrent[day][hour]
        }
        dispatch(updateSchedule(updatedCurrent))
      }
      scheduleRef.current = updatedCurrent
    },
    [dispatch]
  )

  const handleDragStart = useCallback(
    (e: DragEvent) => {
      selectTime(e)
      e.stopPropagation()
    },
    [selectTime]
  )

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault()
  }

  const handleDragOver = useCallback(
    (e: DragEvent) => {
      selectTime(e)
      e.preventDefault()
    },
    [selectTime]
  )

  const handleDragEnd = (e: DragEvent) => {
    e.preventDefault()
  }

  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
  }

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (allowdQNInterval) {
        selectTime(e, true)
        e.preventDefault()
      }
    },
    [allowdQNInterval, selectTime]
  )

  const container: ScheduleMatrixContainer = useMemo(
    () => ({
      draggable: true,
      onDragStart: handleDragStart,
      onClick: handleClick,
      onDragLeave: handleDragLeave,
      onDragEnd: handleDragEnd,
      onDrag: handleDrag,
      onDragOver: handleDragOver
    }),
    [handleClick, handleDragOver, handleDragStart]
  )

  return useMemo(
    () => ({
      allowdQNInterval,
      container,
      days: DAYS,
      hours: HOURS,
      scheduleRef
    }),
    [allowdQNInterval, container]
  )
}

export default useScheduleMatrixLogic
