import { useCallback, useMemo } from 'react'

import * as lib from 'lib/mstore'
import { AccountPermission } from 'types/Settings'
import { Message } from 'types/Messages'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

export const useMstore = () => {
  const { userHasRight } = useUserRights()

  /* eslint-disable prettier/prettier */
  const contentViewFlags = useCallback(
    (accountPermissions: AccountPermission, fields: Message['fields']) => {
      if (userHasRight(UserRights.VIEW_BLOCKED_AND_QUARANTINED_MESSAGES)) {
        return {
          cantViewBlocked: false,
          cantViewQuarantined: false
        }
      }
      return lib.contentViewFlags(accountPermissions, fields)
    },
    [userHasRight]
  )
  /* eslint-enable prettier/prettier */

  return useMemo(
    () => ({
      ...lib,
      contentViewFlags
    }),
    [contentViewFlags]
  )
}
