import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundIpAddressPolicies(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      <h3>IP Blocking / Exemption</h3>
      <p>
        Add any IP addresses or networks to always block or always exempt (allow) here. Use a netmask of 255.255.255.255
        to add an individual IP address. Allowed IP addresses/networks bypass spam scoring (see <b>Enable Cloudscan</b>{' '}
        on the <b>Inbound Settings &gt; Anti-spam/Antivirus</b> page) as well as all other block lists. Virus scanning
        still applies.
        <br />
        <br />
        <b>Important</b>: When entering an IP range in {props.productName}, it is <i>critical</i> that the starting IP
        address is valid. For more information, see{' '}
        <a href="https://campus.barracuda.com/doc/96023004/" target="_blank" rel="noreferrer">
          {' '}
          Barracuda Campus.
        </a>
        <br />
        <br />
        This list of IP addresses that you choose to block takes precedence over the Barracuda Reputation Block List and
        Custom RBL entries.
        <br />
        <br />
        Click on one of the following column headings to sort the <b>IP Blocking / Exemption</b> table:
        <ul>
          <li>IP Address</li>
          <li>Netmask</li>
          <li>Policy</li>
          <li>Comment</li>
        </ul>
      </p>
      <h3>Trusted Forwarders</h3>
      Add IP addresses of machines that you have set up to forward email (that is, Trusted Forwarders) to{' '}
      {props.productName} from outside sources. {props.productName} exempts any IP address in this list from Rate
      Control, Sender Policy Framework (SPF) checks, and IP Reputation (see the{' '}
      <b>Inbound Settings &gt; Anti-Spam/Antivirus</b> page). In the Received headers, {props.productName} continues
      looking beyond a Trusted Forwarder IP address until it encounters the first non-trusted IP address. At this point,
      Rate Control, SPF checks, and IP Reputation checks are applied.
      <br />
      <br />
      Click on one of the following column headings to sort the <b>Trusted Forwarder</b> table:
      <ul>
        <li>IP Address</li>
        <li>Netmask</li>
        <li>Comment</li>
      </ul>
    </div>
  )
}

export default InboundIpAddressPolicies
