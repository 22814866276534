import React, { useMemo, useState } from 'react'

import ReactCopyToClipboard from 'react-copy-to-clipboard'
import { Grid, Typography, Tooltip, Snackbar } from '@cuda-networks/bds-core'
import { Copy } from '@cuda-networks/bds-core/dist/Icons/Core'

import { useFormatMessage } from 'lib/localization'
import StatusCell from 'components/pages/onboardWizard/serverAndMxSetup/sections/components/StatusCell'
import { MxRecord } from 'types/emailServer'

import styles from 'components/pages/onboardWizard/serverAndMxSetup/sections/sectionsStyles'

const BASE_I18N_KEY = 'ess_wizard.server_and_mx_setup'

export interface MxRecordsRowProps extends Omit<MxRecord, 'priority'> {
  priority?: number
  record?: string
  disabledCopy?: boolean
  isMissedToVerify?: boolean
}

const COPIED_TEXT_LIFETIME = 2000

const MxRecordRow = ({ record, priority, domain, verified, disabledCopy, isMissedToVerify }: MxRecordsRowProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [isCopied, setIsCopied] = useState<boolean>(false)

  return useMemo(
    () => (
      <Grid data-testid="mx-record-row" container direction="row">
        {record && (
          <Grid container data-testid="record-column" item xs={3} md={2} alignItems="center" justifyContent="center">
            <Typography variant="body1">{record}</Typography>
          </Grid>
        )}
        <Grid container data-testid="priority-column" item xs={2} md={1} alignItems="center" justifyContent="center">
          <Typography variant="body1" align="center">
            {priority}
          </Typography>
        </Grid>
        <Grid data-testid="domain-column" className={classes.domainColumn} item xs={record ? 4 : 7} md={record ? 7 : 9}>
          <Grid container direction="row" className={classes.domainColumContainer}>
            <Grid item>
              <Tooltip title={domain} placement="top-start">
                <Typography variant="body1">{domain}</Typography>
              </Tooltip>
            </Grid>
            {!disabledCopy && (
              <ReactCopyToClipboard text={domain} onCopy={() => setIsCopied(true)}>
                <Tooltip title={formatMessage('copy_to_clipboard')} placement="top-start">
                  <data className={classes.iconWrapper}>
                    <Copy className={classes.copyIcon} />
                  </data>
                </Tooltip>
              </ReactCopyToClipboard>
            )}
          </Grid>
        </Grid>
        <Grid container data-testid="verified-column" item xs={3} md={2} alignItems="center">
          <StatusCell isVerified={verified} isMissedToVerify={!!isMissedToVerify} />
        </Grid>

        <Snackbar
          className={classes.copiedMessage}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={isCopied}
          onClose={() => {
            setIsCopied(false)
          }}
          autoHideDuration={COPIED_TEXT_LIFETIME}
          message={formatMessage('copied_to_clipboard')}
        />
      </Grid>
    ),
    [classes, formatMessage, record, domain, priority, verified, isMissedToVerify, isCopied, disabledCopy]
  )
}

export default MxRecordRow
