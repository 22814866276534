import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function AtpLog(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        The ATP Log displays inbound email messages that are scanned by the Advanced Threat Protection (ATP) service.
        The following sections describe the different aspects of the ATP Log page:
      </p>
      <ul>
        <li>
          <a href="#help-domain">Filter Messages by Domain</a>
        </li>
        <li>
          <a href="#help-filter">Filter Messages</a>
        </li>
        <li>
          <a href="#help-search">Search Messages</a>
        </li>
        <li>
          <a href="#help-scroll">Scroll through and View Messages</a>
        </li>
        <li>
          <a href="#help-actions">Export Messages</a>
        </li>
        <li>
          <a href="#help-column">ATP Log Columns</a>
        </li>
        <li>
          <a href="#help-deliver">Determine Whether to Deliver a Message</a>
        </li>
      </ul>
      <br />
      <h3 id="help-domain">Filter Messages by Domain</h3>
      Use the drop-down menu to filter message on <b>All domains</b>, or select a specific domain on which to filter.
      Once you select a domain, the table automatically updates based on your selection.
      <br />
      <h3 id="help-filter">Filter Messages</h3>
      Use the filter options to display messages in the ATP Log table.
      <ul>
        <li>
          <b>Status</b>
          <br />
          Click on a filter to display messages based on the message attachment status. View based on <b>All</b>,{' '}
          <b>Clean</b>, <b>Suspicious</b>, or <b>Infected</b> status.
        </li>
        <li>
          <b>Time</b>
          <br />
          Click on a filter to view messages for the <b>Last 30 Days</b>, <b>Last 7 Days</b>, or <b>Last 24 Hours</b>.
        </li>
        <li>
          <b>File Type</b>
          <br />
          Use the drop-down menu to filter messages by <b>All File Types</b>, or select a specific file type.
        </li>
      </ul>
      <h3 id="help-search">Search Messages</h3>
      Use the search field to filter messages that display in the ATP Log table. Enter all or part of the search
      criteria, and then press <b>Return</b>.
      <ul>
        <li>
          <b>From</b>
          <br />
          Sender email address
        </li>
        <li>
          <b>To</b>
          <br />
          Recipient email address
        </li>
        <li>
          <b>Subject</b>
          <br />
          Email subject line
        </li>
        <li>
          <b>File Name</b>
          <br />
          Attachment file name or file type
        </li>
      </ul>
      <h3 id="help-scroll">Scroll through and View Messages</h3>
      Use the vertical scroll bar to move up and down the page. Click <b>Previous</b> and <b>Next</b> or click on an
      entry number at the bottom of the table to move through the list of messages.
      <br />
      Click on a message in the table to view the message content in the <b>Message View</b> page.
      <br />
      <h3 id="help-actions">Export Messages</h3>
      <p>
        Click <b>Export List as CSV</b> to export the messages that currently display in the table to your local system
        as a CSV file.
      </p>
      <br />
      <h3 id="help-column">ATP Log Columns</h3>
      <ul>
        <li>
          <b>Status</b>
          <br />
          Displays the message attachment status as determined by ATP.
        </li>
        <ul>
          <li>
            <b>Clean</b>
            <br />
            Message attachment was scanned for viruses and none were found.
          </li>
          <li>
            <b>Suspicious</b>
            <br />
            Message attachment was determined by ATP to be suspicious.
          </li>
          <li>
            <b>Infected</b>
            <br />
            Message attachment was scanned for viruses and one or more infections were found.
          </li>
          <li>
            <b>Scanning</b>
            <br />
            If you selected <b>Deliver First, Then Scan</b> for <b>Enable Advanced Threat Protection</b> on the{' '}
            <b>ATP Settings</b> page, and the email was delivered first, this status indicates that the attachment is
            being scanned. Once the ATP service determines whether the attachment is infected or suspicious, the status
            updates to the appropriate value in this list.
          </li>
          <li>
            <b>Error</b>
            <br />
            The ATP service returned an error and the attachment health was not determined. The message should not be
            delivered.
          </li>
        </ul>
        <li>
          <b>Time</b>
          <br />
          Date and time message was processed.
        </li>
        <li>
          <b>From</b>
          <br />
          Sender email address (this may not match the address in the headers that mail clients display to an end-user).
        </li>
        <li>
          <b>To</b>
          <br />
          Recipient email address(es).
        </li>
        <li>
          <b>Subject</b>
          <br />
          Message subject.
        </li>
        <li>
          <b>File Name</b>
          <br />
          Attachment name.
        </li>
        <li>
          <b>File Type</b>
          <br />
          Attachment file type.
        </li>
        <li>
          <b>Report</b>
          <br />
          Click to view the ATP report for the selected message.
        </li>
      </ul>
      <h3 id="help-deliver">Determine Whether to Deliver Message</h3>
      <b>Important:</b> When ATP determines an attachment contains a threat and blocks the message, Barracuda Networks
      highly recommends that you review each infected ATP Report before determining whether to deliver the message. Note
      that you must log in as the admin to deliver a message blocked by ATP. For more information on ATP Reports, see{' '}
      <a href="https://campus.barracuda.com/doc/96023051/" target="_blank" rel="noreferrer">
        {' '}
        Barracuda Campus.
      </a>
    </div>
  )
}

export default AtpLog
