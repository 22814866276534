import { Dispatch, SetStateAction, useMemo, useState, useCallback, useEffect } from 'react'

import { trim } from 'lodash'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess, isFailed } from 'redux/toolkit/api'
import {
  detectEmailServer,
  verifyEmailServer,
  resetEmailServer,
  resetVerifyEmailServer,
  setEmailAddress,
  setUpdatedEmailServer
} from 'redux/features/emailServer/emailServerSlice'
import { EmailServerConfig } from 'types/emailServer'
import usePreviousValue from 'lib/usePreviousValue'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { SectionsProps } from 'components/pages/onboardWizard/onboardWizardTypes'
import { isEmailValid } from 'lib/validation'

export interface State {
  emailAddress: string
  isEmailInputError: boolean
  isMissedServerDetection: boolean
  isMissedServerVerify: boolean
  isDetectEmailServerInProgress: boolean
  defaultEmailServerConfig: EmailServerConfig | undefined
  emailServerConfig: EmailServerConfig | undefined
  isVerifiedServer: boolean
  isVerifyEmailServerInProgress: boolean
  isVerifyEmailServerFailed: boolean
  isEmailServerConfigError: boolean
  isEditEmailServer: boolean
  isEditButtonDisabled: boolean
}

export interface EventHandlers {
  onChangeEmailAddress: (e: React.ChangeEvent<HTMLInputElement>) => void
  onEdit: () => void
  onChangeEmailServer: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeServerPort: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFinishEdit: () => void
  onDetectEmailServer: () => void
  onVerifyServer: () => void
}

export interface TestHelpers {
  [key: string]: Dispatch<SetStateAction<any>>
}

export type SpecifyEmailServerLogic = [State, EventHandlers, TestHelpers]

export const useSpecifyEmailServerLogic = ({
  setSpecifyEmailServerSectionErrorState,
  isRequestedToCompleteSetup,
  setSectionToActive,
  sectionsVerifiedStates
}: SectionsProps): SpecifyEmailServerLogic => {
  const dispatch = useAppDispatch()

  const {
    emailAddress,
    isDetectEmailServerInProgress,
    defaultEmailServerConfig,
    emailServerConfig,
    isVerifyEmailServerInProgress,
    isVerifyEmailServerSuccess,
    isVerifyEmailServerFailed
  } = useAppSelector(_store => ({
    emailAddress: _store.emailServer.emailAddress,
    isDetectEmailServerInProgress: isPending(_store.emailServer.detectEmailServerApiStatus),
    // use the first email server config as default
    defaultEmailServerConfig: _store.emailServer.emailServer,
    emailServerConfig: _store.emailServer.updatedEmailServer,
    isVerifyEmailServerInProgress: isPending(_store.emailServer.verifyEmailServerApiStatus),
    isVerifyEmailServerSuccess: isSuccess(_store.emailServer.verifyEmailServerApiStatus),
    isVerifyEmailServerFailed: isFailed(_store.emailServer.verifyEmailServerApiStatus)
  }))
  const [isEditEmailServer, setIsEditEmailServer] = useState<boolean>(false)
  const [isEmailInputError, setIsEmailInputError] = useState<boolean>(false)
  const [isMissedServerDetection, setIsMissedServerDetection] = useState<boolean>(false)
  const [isMissedServerVerify, setIsMissedServerVerify] = useState<boolean>(false)
  const [isEmailServerConfigError, setIsEmailServerConfigError] = useState<boolean>(false)

  const prevDefaultEmailServerConfig: EmailServerConfig | undefined = usePreviousValue(defaultEmailServerConfig)

  const isValidEmail = useMemo(() => isEmailValid(trim(emailAddress)), [emailAddress])
  const isValidEmailServerConfig = useMemo(
    () => !!emailServerConfig?.server.length && !!String(emailServerConfig?.port).length,
    [emailServerConfig]
  )

  // init
  useEffect(
    () => () => {
      dispatch(resetEmailServer())
      dispatch(resetVerifyEmailServer())
    },
    // eslint-disable-next-line
    []
  )

  // sections verified state is changed
  useEffect(() => {
    if (!sectionsVerifiedStates.specifyEmailServer) {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_1_START, {}, TRACKING_EVENTS.WIZARD.STEP_1_COMPLETE)
    }
  }, [sectionsVerifiedStates.specifyEmailServer])

  // server is verified successfully
  useEffect(() => {
    if (isVerifyEmailServerSuccess) {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_1_COMPLETE, { emailServerConfig })
      setIsMissedServerVerify(false)
    }
  }, [isVerifyEmailServerSuccess, emailServerConfig])

  // user is reuqested to complete the setup
  useEffect(() => {
    if (isRequestedToCompleteSetup) {
      setIsEmailInputError(false)
      setIsMissedServerDetection(false)
      setIsMissedServerVerify(false)

      switch (true) {
        case !isValidEmail:
          setIsEmailInputError(true)
          break
        case isValidEmail && !defaultEmailServerConfig:
          setIsMissedServerDetection(true)
          break
        case !defaultEmailServerConfig?.verified:
          setIsMissedServerVerify(true)
          break
        default:
      }
    }
  }, [
    isRequestedToCompleteSetup,
    isValidEmail,
    defaultEmailServerConfig,
    emailServerConfig,
    isVerifyEmailServerInProgress
  ])

  // delegate the section's error state
  useEffect(() => {
    const isSectionHasError =
      [isEmailInputError, isMissedServerDetection, isMissedServerVerify, isEmailServerConfigError].some(
        errorState => errorState
      ) ||
      (!!emailServerConfig && !defaultEmailServerConfig?.server?.length)

    setSpecifyEmailServerSectionErrorState?.(isSectionHasError)
  }, [
    setSpecifyEmailServerSectionErrorState,
    isEmailInputError,
    isMissedServerDetection,
    isMissedServerVerify,
    isEmailServerConfigError,
    defaultEmailServerConfig,
    emailServerConfig,
    isVerifyEmailServerSuccess
  ])

  // new email server config is arrived
  useEffect(() => {
    if (
      defaultEmailServerConfig &&
      (defaultEmailServerConfig.server !== prevDefaultEmailServerConfig?.server ||
        defaultEmailServerConfig.port !== prevDefaultEmailServerConfig?.port)
    ) {
      dispatch(resetVerifyEmailServer())

      dispatch(
        setUpdatedEmailServer({
          server: defaultEmailServerConfig.server,
          port: defaultEmailServerConfig.port
        })
      )
      setIsEditEmailServer(!defaultEmailServerConfig.server.length)
      setIsMissedServerVerify(false)
    }
  }, [defaultEmailServerConfig, emailServerConfig, prevDefaultEmailServerConfig, dispatch])

  const onChangeEmailAddress: EventHandlers['onChangeEmailAddress'] = useCallback(
    e => {
      setSectionToActive()

      if (isEmailInputError) {
        setIsEmailInputError(false)
      }

      dispatch(setEmailAddress(e.target.value.replace(/\s/g, '')))
    },
    [dispatch, isEmailInputError, setSectionToActive]
  )

  const onDetectEmailServer = useCallback(() => {
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_1_DETECT_EMAIL_SERVER, { email: trim(emailAddress || '') })
    setSectionToActive()

    if (!isValidEmail) {
      setIsEmailInputError(true)
    } else {
      const fixedEmail = trim(emailAddress)

      dispatch(setEmailAddress(fixedEmail))
      dispatch(setUpdatedEmailServer())
      setIsEmailServerConfigError(false)
      setIsMissedServerDetection(false)
      dispatch(detectEmailServer(fixedEmail))
      dispatch(resetVerifyEmailServer())
    }
  }, [dispatch, isValidEmail, emailAddress, setSectionToActive])

  const onEdit = useCallback(() => {
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_1_EDIT_EMAIL_SERVER, { emailServerConfig })
    dispatch(resetVerifyEmailServer(true))
    setIsEditEmailServer(true)
  }, [dispatch, emailServerConfig])

  const onFinishEdit = useCallback(() => {
    setSectionToActive()

    if (isValidEmailServerConfig) {
      setIsEditEmailServer(false)
    } else {
      setIsEmailServerConfigError(true)
    }
  }, [isValidEmailServerConfig, setSectionToActive])

  const onChangeEmailServer: EventHandlers['onChangeEmailServer'] = useCallback(
    e => {
      setSectionToActive()
      setIsEmailServerConfigError(false)

      const newConfig = {
        server: e.target.value.replace(/\s/g, ''),
        port: emailServerConfig?.port || ''
      }
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_1_CHANGE_EMAIL_SERVER, { emailServerConfig: newConfig })
      dispatch(setUpdatedEmailServer(newConfig))
    },
    [dispatch, emailServerConfig, setSectionToActive]
  )

  const onChangeServerPort: EventHandlers['onChangeServerPort'] = useCallback(
    e => {
      setSectionToActive()
      setIsEmailServerConfigError(false)

      const newConfig = {
        server: emailServerConfig?.server || '',
        port: e.target.value.length ? Number(e.target.value.replace(/\D/g, '')) : ''
      }
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_1_CHANGE_EMAIL_SERVER, { emailServerConfig: newConfig })
      dispatch(setUpdatedEmailServer(newConfig))
    },
    [dispatch, emailServerConfig, setSectionToActive]
  )

  const onVerifyServer = useCallback(() => {
    setSectionToActive()
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_1_VERIFY_SERVER, {
      email: trim(emailAddress),
      emailServerConfig
    })

    if (emailServerConfig && isValidEmailServerConfig) {
      setIsEditEmailServer(false)
      dispatch(
        verifyEmailServer({
          email: trim(emailAddress),
          server: emailServerConfig.server,
          port: Number(emailServerConfig.port)
        })
      )
    } else {
      setIsEmailServerConfigError(true)
    }
  }, [dispatch, emailAddress, emailServerConfig, isValidEmailServerConfig, setSectionToActive])

  return useMemo(
    () => [
      {
        emailAddress,
        isMissedServerDetection,
        isMissedServerVerify,
        isEmailInputError,
        isDetectEmailServerInProgress,
        defaultEmailServerConfig,
        emailServerConfig,
        isVerifiedServer: !!defaultEmailServerConfig?.verified,
        isVerifyEmailServerInProgress,
        isVerifyEmailServerFailed,
        isEditEmailServer,
        isEmailServerConfigError,
        isEditButtonDisabled: isVerifyEmailServerInProgress
      },
      {
        onChangeEmailAddress,
        onDetectEmailServer,
        onVerifyServer,
        onEdit,
        onFinishEdit,
        onChangeEmailServer,
        onChangeServerPort
      },
      {
        setIsEditEmailServer,
        setIsEmailInputError,
        setIsMissedServerDetection,
        setIsMissedServerVerify,
        setIsEmailServerConfigError
      }
    ],
    [
      emailAddress,
      isMissedServerDetection,
      isMissedServerVerify,
      isEmailInputError,
      isDetectEmailServerInProgress,
      defaultEmailServerConfig,
      emailServerConfig,
      isVerifyEmailServerInProgress,
      isVerifyEmailServerFailed,
      isEmailServerConfigError,
      isEditEmailServer,
      onChangeEmailAddress,
      onEdit,
      onDetectEmailServer,
      onVerifyServer,
      onFinishEdit,
      setIsEditEmailServer,
      onChangeEmailServer,
      onChangeServerPort,
      setIsEmailInputError,
      setIsMissedServerDetection,
      setIsMissedServerVerify,
      setIsEmailServerConfigError
    ]
  )
}
