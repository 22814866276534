import { useEffect } from 'react'

import { appEntryPath } from 'lib/routesConfig'

function UnregisteredPage() {
  useEffect(() => {
    appEntryPath.goto()
  }, [])

  return null
}

export default UnregisteredPage
