import util from 'util'
import { useCallback, useMemo } from 'react'
import { startCase } from 'lodash'
import { Message, RecipientInfo } from 'types/Messages'
import { formatDate } from 'lib/datetime'
import config from 'config/appConfig'
import IconAlertSuccess from 'assets/images/icons/icon_alert_success.svg'
import IconAlertInfo from 'assets/images/icons/icon_alert_info.svg'
import IconAlertWarning from 'assets/images/icons/icon_alert_warning.svg'
import { useMstore } from 'lib/useMstore'
import { useAppSelector } from 'redux/toolkit/hooks'
import { useRemediated } from 'components/libs/message/useRemediated'

interface Config {
  message: Message | undefined
  text: (id: string) => string
}

export interface State {
  reason: string
  determinationColor: string
  determinationIcon: typeof IconAlertSuccess | typeof IconAlertInfo | typeof IconAlertWarning
  subject: string
  from: string
  to: string
  date: string
  id: string
  ip: string
  recipientTable: RecipientTable
  cantViewBlocked: boolean
  cantViewQuarantined: boolean
  isDownloadAttachmentEnabled: boolean | undefined
}

export interface Methods {
  getRecipientDeliveryStatus: (recipient: RecipientInfo) => string
  getRecipientReasons: (recipient: RecipientInfo) => string
  getHeader: (
    headerName: keyof Message['headers'],
    fieldName: keyof Message['fields'] | undefined,
    isDate: boolean
  ) => string
}

interface RecipientTableRow {
  recipients: string
  action: string
  reason: string
  deliveryStatus: string
}

export interface RecipientTable {
  header: RecipientTableRow
  data: RecipientTableRow[]
}

export type MessageLogic = [State, Methods]

export const useMessage = ({ message, text }: Config): MessageLogic => {
  const { contentViewFlags, isAtpAffected, mapDeliveryColor } = useMstore()
  const remediated = useRemediated()

  const { accountPermissions } = useAppSelector(_store => ({
    accountPermissions: _store.settings.accountPermissions
  }))
  const { cantViewBlocked, cantViewQuarantined } = useMemo(() => {
    if (!accountPermissions || !message) {
      return { cantViewBlocked: false, cantViewQuarantined: false }
    }
    return contentViewFlags(accountPermissions, message.fields)
  }, [accountPermissions, message, contentViewFlags])

  const getRecipientDeliveryStatus = useCallback(
    (recipient: RecipientInfo) => {
      if (!recipient.deliveryStatus) {
        return ''
      }
      const detail = recipient.deliveryDetail?.length ? ` (${decodeURIComponent(recipient.deliveryDetail)})` : ''
      const status = text(`status.${recipient.deliveryStatus}`)
      return `${status}${detail}`
    },
    [text]
  )

  const getRecipientReasons = useCallback(
    (recipient: RecipientInfo) => {
      if (!recipient.reason) {
        return ''
      }
      const extra = recipient.reasonExtra?.length ? ` (${decodeURIComponent(recipient.reasonExtra)})` : ''
      const reason = text(`reason.${recipient.reason}`)
      return `${reason}${extra}`
    },
    [text]
  )

  const getHeader = useCallback(
    (headerName: keyof Message['headers'], fieldName: keyof Message['fields'] | undefined, isDate = false) => {
      if (!message) {
        return ''
      }
      if (message.headers[headerName]) {
        if (headerName === 'Date') {
          return formatDate(
            new Date(Number(message.fields.timestamp) * 1000),
            config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT
          )
        }

        return message.headers[headerName]
      }
      if (isDate) {
        return formatDate(
          new Date(Number(message.fields.timestamp) * 1000),
          config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT
        )
      }
      return !fieldName || !message.fields[fieldName] ? '-' : decodeURIComponent(`${message.fields[fieldName]}`)
    },
    [message]
  )

  const reason = useMemo(() => {
    if (!message || typeof remediated === 'undefined') {
      return ''
    }
    if (!message.fields.recipientInfo.length) {
      return ''
    }
    const recipient = message.fields.recipientInfo?.[0]
    if (remediated) {
      return util.format(text('header_detail.remediated'), getHeader('Date', undefined, true))
    }
    if (recipient.reason) {
      return util.format(text('header_detail.long_reason'), recipient.action, getRecipientReasons(recipient))
    }
    return util.format(text('header_detail.short_reason'), recipient.action)
  }, [getHeader, getRecipientReasons, message, remediated, text])

  const determinationColor = useMemo(() => {
    if (!message || !message.fields.recipientInfo[0] || typeof remediated === 'undefined') {
      return ''
    }
    return mapDeliveryColor(message.fields.recipientInfo[0].action, remediated)
  }, [message, remediated, mapDeliveryColor])

  const determinationIcon = useMemo(() => {
    if (!message || !message.fields.recipientInfo.length || typeof remediated === 'undefined') {
      return IconAlertInfo
    }
    const status = message.fields.recipientInfo[0].action
    if (status.startsWith('allowed') || remediated) {
      return IconAlertSuccess
    }
    if (status.startsWith('blocked')) {
      return IconAlertInfo
    }
    if (status.startsWith('quarantined')) {
      return IconAlertWarning
    }
    if (status.startsWith('encrypted')) {
      return IconAlertInfo
    }
    if (status.startsWith('deferred')) {
      return IconAlertInfo
    }
    return IconAlertInfo
  }, [message, remediated])

  const subject = useMemo(() => getHeader('Subject', 'subject'), [getHeader])

  const from = useMemo(() => getHeader('From', 'headerFrom'), [getHeader])

  const to = useMemo(() => getHeader('To', 'headerTo'), [getHeader])

  const date = useMemo(() => getHeader('Date', 'timestamp', true), [getHeader])

  const id = useMemo(() => getHeader('X-BESS-ID', 'mid'), [getHeader])

  const ip = useMemo(() => {
    if (!message) {
      return ''
    }
    const { ptr } = message.fields
    const headerIp = message.headers['X-BESS-Apparent-Source-IP'] || message.headers['x-BESS-Apparent-Source-IP']

    return ptr ? util.format('%s (%s)', headerIp, ptr) : headerIp || message.fields.ip || '-'
  }, [message])

  const recipientTable: RecipientTable = useMemo(() => {
    const header = {
      recipients: 'recipients',
      action: 'action',
      reason: 'reason',
      deliveryStatus: 'delivery_status'
    }
    if (!message) {
      return { header, data: [] }
    }
    return {
      header,
      data: message.fields.recipientInfo.map(recipient => ({
        recipients: recipient.envelopeTo,
        action: startCase(recipient.action),
        reason: getRecipientReasons(recipient),
        deliveryStatus: getRecipientDeliveryStatus(recipient)
      }))
    }
  }, [getRecipientDeliveryStatus, getRecipientReasons, message])

  const isDownloadAttachmentEnabled = useMemo(() => {
    if (!message) {
      return undefined
    }
    return !isAtpAffected(message)
  }, [message, isAtpAffected])

  return useMemo(
    () => [
      {
        reason,
        determinationColor,
        determinationIcon,
        subject,
        from,
        to,
        date,
        id,
        ip,
        recipientTable,
        cantViewBlocked,
        cantViewQuarantined,
        isDownloadAttachmentEnabled
      },
      {
        getRecipientDeliveryStatus,
        getRecipientReasons,
        getHeader
      }
    ],
    [
      cantViewBlocked,
      cantViewQuarantined,
      date,
      determinationColor,
      determinationIcon,
      from,
      getHeader,
      getRecipientDeliveryStatus,
      getRecipientReasons,
      id,
      ip,
      isDownloadAttachmentEnabled,
      reason,
      recipientTable,
      subject,
      to
    ]
  )
}
