import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { Params } from 'react-router-dom'
import { postMixpanelEvent } from 'redux/features/app/appApiThunks'
import { SplitterOrientation } from 'components/libs/splitter/splitterTypes'
import { LocalStorageKeys } from 'lib/types/localStorageTypes'
import { getLanguageCookie, setLanguageCookie } from 'lib/cookies'
import { Language } from 'types/Language'
import { getSenderPolicies } from '../settings/settingsApiThunks'
import { getMessage, getSearch } from '../mstore/mstoreSlice'
import { getAtpSearch } from '../atp/atpApiThunks'

export interface ActivePath {
  id?: string
  url: string
  isNavbarVisible: boolean
  params: Readonly<Params<string>>
  isPublicRoute: boolean
  metadata?: Record<string, unknown> | undefined
}

export enum SnackbarSeveriy {
  none = '',
  error = 'error',
  warning = 'warning',
  success = 'success'
}

export interface Snackbar {
  severity: SnackbarSeveriy
  open: boolean
  autoHideDuration: number
  message: string
  params: any[]
}

export interface Splitter {
  orientation: SplitterOrientation
}

export interface AppState {
  activePath: ActivePath
  snackBar: Snackbar
  splitter: Splitter
  timezone: string
  language: string
}

// initialState
export const INITIAL_STATE: AppState = {
  activePath: {
    // using explicit type check so the jest node environment can be used
    // without a window mock for testing the slice
    url: typeof window === 'undefined' ? '' : window.location.pathname,
    isNavbarVisible: false,
    params: {},
    metadata: undefined,
    isPublicRoute: true
  },
  snackBar: {
    severity: SnackbarSeveriy.none,
    open: false,
    autoHideDuration: 6000,
    message: '',
    params: []
  },
  splitter: {
    orientation:
      (localStorage.getItem(LocalStorageKeys.splitterOrientation) as SplitterOrientation) ||
      SplitterOrientation.vertical
  },
  timezone: '',
  language: getLanguageCookie() || Language.english
}

export type SetActivePathPayload = ActivePath

export interface SetSnackbarPayload {
  message: Snackbar['message']
  severity?: Snackbar['severity']
  open?: Snackbar['open']
  autoHideDuration?: Snackbar['autoHideDuration']
  params?: Snackbar['params']
}

/* eslint-disable no-param-reassign */
export const appSlice = createSlice({
  name: 'APP',
  initialState: INITIAL_STATE,
  reducers: {
    setActivePath: (state: AppState, action: PayloadAction<SetActivePathPayload>) => {
      state.activePath = {
        ...INITIAL_STATE.activePath,
        ...action.payload
      }
    },
    setSuccessSnackBar: (state: AppState, action: PayloadAction<SetSnackbarPayload>) => {
      state.snackBar = {
        ...INITIAL_STATE.snackBar,
        severity: SnackbarSeveriy.success,
        open: true,
        ...action.payload
      }
    },
    setWarningSnackBar: (state: AppState, action: PayloadAction<SetSnackbarPayload>) => {
      state.snackBar = {
        ...INITIAL_STATE.snackBar,
        severity: SnackbarSeveriy.warning,
        open: true,
        ...action.payload
      }
    },
    setErrorSnackBar: (state: AppState, action: PayloadAction<SetSnackbarPayload>) => {
      state.snackBar = {
        ...INITIAL_STATE.snackBar,
        severity: SnackbarSeveriy.error,
        open: true,
        ...action.payload
      }
    },
    closeSnackBar: state => {
      state.snackBar = { ...INITIAL_STATE.snackBar }
    },
    setSplitterOrientation: (state: AppState, action: PayloadAction<SplitterOrientation>) => {
      state.splitter = {
        ...INITIAL_STATE.splitter,
        orientation: action.payload
      }
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
      setLanguageCookie(action.payload)
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(getSenderPolicies.fulfilled, getMessage.fulfilled, getSearch.fulfilled, getAtpSearch.fulfilled),
      (state, action) => {
        state.timezone = action.payload.metadata.userTimezone
      }
    )
  }
})
/* eslint-enable no-param-reassign */

export const {
  setActivePath,
  setSuccessSnackBar,
  setWarningSnackBar,
  setErrorSnackBar,
  closeSnackBar,
  setSplitterOrientation,
  setLanguage,
  reset
} = appSlice.actions

export { postMixpanelEvent }

export default appSlice.reducer
