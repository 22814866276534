import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles({
  card: {
    minWidth: 400,
    maxWidth: 600,
    margin: 'auto',
    flexDirection: 'column'
  },
  cardContent: {
    margin: 'auto'
  },
  textField: {
    marginTop: 15
  },
  progress: {
    height: 3,
    marginTop: 0
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  },
  actions: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16
  }
})
