import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { UiRoute } from 'lib/routes'
import { useAppDispatch } from 'redux/toolkit/hooks'
import { setActivePath } from 'redux/features/app/appSlice'

export interface Props {
  route: UiRoute
  Component: any
}

function PublicRoute({ route, Component }: Props) {
  const dispatch = useAppDispatch()

  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    dispatch(
      // TODO: align type with redux state depends on (BNESS-22692)
      setActivePath({
        id: route.id,
        url: location.pathname,
        params,
        isNavbarVisible: false,
        isPublicRoute: true
      } as any)
    )
  }, [dispatch, location, route, params])

  return <Component route={route} />
}

export default PublicRoute
