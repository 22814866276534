import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundSenderAuthentication(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
        unless you change these settings for a specific domain. In that case, the domain-level settings override the
        global system settings.
        <br />
        <br />
        Sender Authentication mechanisms enable {props.productName} to protect your network and users from spammers who
        might &quot;spoof&quot; a domain or otherwise hide the identity of the true sender. For more information, see{' '}
        <a href="https://campus.barracuda.com/doc/96022989/" target="_new">
          Sender Authentication
        </a>{' '}
        in Barracuda Campus.
        <br />
        <br />
        The following techniques are used to verify the message &quot;from&quot; address:
        <br />
        <a href="#dmarc">Use Domain Based Message Authentication (DMARC)</a>
        <br />
        <a href="#dkim">DomainKeys Identified Mail (DKIM) Action</a>
        <br />
        <a href="#spf">Enable Sender Policy Framework (SPF) Checking</a>
        <br />
        <a href="#block_no_spf">Block on No SPF Records</a>
        <br />
        <a href="#block_no_ptr">Block on No PTR Records</a>
        <br />
        <br />
        Click on a column heading to sort the tables.
        <br />
        <br />
        <h3 id="dmarc">Use Domain Based Message Authentication (DMARC)</h3>
        When set to <b>Yes</b>, DMARC enables a sending domain to specify policy for messages that fail DomainKeys
        Identified Mail (DKIM) and Sender Policy Framework (SPF). This is the default setting.
        <br />
        <br />
        When set to <b>No</b>, {props.productName} does <i>not</i> run DMARC checks for inbound messages.
        <br />
        <br />
        <b>Note</b>: DMARC overrides settings for DKIM and SPF if the following conditions are true:
        <ul>
          <li>DMARC is enabled</li>
          <li>The sender&apos;s domain is not exempted from DMARC</li>
          <li>
            The sender&apos;s domain has a valid DMARC DNS TXT record (_dmarc.<i>example.com</i>)
          </li>
          <li>
            The policy specified by the sender&apos;s DMARC record indicates <b>block</b> or <b>quarantine</b>
          </li>
        </ul>
        <a href="#block_no_spf">
          <b>Block on No SPF Records</b>
        </a>{' '}
        set to <b>Block</b> takes precedence over DMARC.
        <br />
        <br />
        <b>DMARC Exempt</b>
        <br />
        You can select to exempt specific domains from DMARC verification. Enter the <b>Envelope From Domain</b> to
        exempt, and, optionally, a <b>Comment</b> to identify the domain exemption. Click <b>Add</b> in the{' '}
        <b>Actions</b> column to add the Envelope From Domain to the <b>DMARC Exempt</b> table.
        <br />
        <br />
        <h3 id="dkim">DomainKeys Identified Mail (DKIM) Action</h3>
        DKIM enables a sending domain to cryptographically sign outgoing messages, allowing the sending domain to assert
        responsibility for a message. When receiving a message from a domain, the recipient can check the signature of
        the message to verify that the message is, indeed, from the sending domain and that the message has not been
        tampered with. This feature applies to inbound mail only. For messages that fail DKIM verification, select{' '}
        <b>Block</b>
        {!props.hasCpl && (
          <>
            , <b>Quarantine</b>,
          </>
        )}{' '}
        or <b>Off</b>.The default setting is <b>Block</b>.
        <br />
        <br />
        <b>DKIM Exempt</b>
        <br />
        List of domains from which messages that fail DKIM verification have no action taken. Any message that arrives
        from a domain listed here that fails DKIM inspection is not subject to the action specified above. Enter the{' '}
        <b>Envelope From Domain</b> to exempt, and, optionally, a <b>Comment</b> to identify the domain exemption. Click{' '}
        <b>Add</b> in the <b>Actions</b> column to add the Envelope From Domain to the <b>DKIM Exempt</b> table.
        <br />
        <br />
        <h3 id="spf">Enable Sender Policy Framework (SPF) Checking</h3>
        Sender Policy Framework (SPF) is a sender authentication tool that works by having domains publish reverse MX
        records displaying which machines are designated as mail sending machines for that domain. When receiving a
        message from a domain, {props.productName} can check those records to make sure mail is coming from a designated
        sending machine. This setting applies to inbound mail only.
        <ul>
          <li>
            <b>Hard Fail</b> - Response indicates that the message sender&apos;s IP address does not match the IP
            address or range of IP addresses specified in the sending domain name&apos;s SPF record, and that the real
            owner of the domain has specifically indicated that such messages should be rejected (blocked) as spoofed.
          </li>
          <ul>
            <li>
              <b>Block</b> - Messages from a domain that fails SPF checking are blocked.
            </li>
            {!props.hasCpl && (
              <li>
                <b>Quarantine</b> - Messages from a domain that fails SPF checking are quarantined.
              </li>
            )}
            <li>
              {' '}
              <b>Off</b> - When set to Off, {props.productName} does not query DNS for an SPF record for the sending
              domain to verify whether the sender is the true owner of that domain. This is the default setting.
            </li>
          </ul>
          <li>
            <b>Soft Fail</b> - Response indicates that the message sender&apos;s IP address does not match the IP
            address or range of IP addresses specified in the sending domain name&apos;s SPF record, and that the domain
            owner did not specify how such messages should be handled.
          </li>
          <ul>
            <li>
              <b>Block</b> - Messages from a domain that fails SPF checking are blocked.
            </li>
            {!props.hasCpl && (
              <li>
                <b>Quarantine</b> - Messages from a domain that fails SPF checking are quarantined.
              </li>
            )}
            <li>
              {' '}
              <b>Off</b> - When set to Off, {props.productName} does not query DNS for an SPF record for the sending
              domain to verify whether the sender is the true owner of that domain. This is the default setting.
            </li>
          </ul>
          <br />
          <b>Note:</b> When Hard Fail is set to <b>Off</b>, Soft Fail options are disabled.
        </ul>
        <b>SPF Exemptions</b>
        <br />
        List of IP addresses of any machines set up specifically to forward mail to {props.productName} from outside
        sources. Enter the <b>IP Address</b>, <b>Netmask</b>, and, optionally, a <b>Comment</b> to identify the
        exemption. Click <b>Add</b> in the
        <b>Actions</b> column to add the IP address to the <b>SPF Exemptions</b> table. Any IP address in this list is
        ignored when performing SPF checks. Mail from these IP addresses is still scanned for spam.
        <br />
        <br />
        <h3 id="block_no_spf">Block on No SPF Records</h3>
        <ul>
          <li>
            <b>Block</b> - If a sending domain does not have an SPF record, the mail server is blocked and messages are
            not delivered to the user.
          </li>
          <li>
            <b>Quarantine</b> - If a sending domain does not have an SPF record, messages are quarantined.
          </li>
          <li>
            <b>Off</b> - When set to Off, there is no query for any senders.
          </li>
        </ul>
        <b>Missing SPF Exemptions</b>
        <br />
        If you have known/trusted contacts that send email from or through mail servers whose domains lack reverse MX
        records (that is, have no SPF records), you can create exemptions for these senders to allow their mail through
        while still blocking mail from other mail servers that do not have SPF records. A missing SPF record often
        indicates that the mail server is not legitimate for the supposed sending domain, thereby spoofing that domain.
        Enter the <b>IP Address</b>,<b>Netmask</b>, and, optionally, a <b>Comment</b> to identify the exemption. Click{' '}
        <b>Add</b> in the <b>Actions</b> column to add the IP address to the <b>Missing SPF Exemptions</b> table.
        <br />
        <br />
        <b>Note: Block on No SPF Records</b> set to <b>Block</b> takes precedence over DMARC.
        <br />
        <br />
        <h3 id="block_no_ptr">Block on No PTR Records</h3>
        While the A record for a domain points to an IP address, a PTR record resolves an IP address to a
        domain/hostname and is used for reverse DNS lookup.
        <br />
        <br />
        When <b>Block on No PTR Records</b> is set to <b>Yes</b>, and a sending domain does not have a PTR record, the
        mail server is blocked and the mail is not delivered to the user.
        <br />
        <br />
        When set to <b>No</b>, there is no query for any senders.
        <br />
        <br />
        <b>Missing PTR Exemptions</b>
        <br />
        If you have known/trusted contacts that send email from or through mail servers whose IP addresses lack reverse
        DNS (that is, have no PTR records), you can create exemptions for these senders to allow their mail through
        while still blocking mail from other mail servers that do not have PTR records. A missing PTR record often
        indicates that the mail server is not legitimate for the supposed sending domain, thereby spoofing that domain.
        Enter the <b>IP Address</b>, <b>Netmask</b>, and, optionally, a <b>Comment</b> to identify the exemption. Click{' '}
        <b>Add</b> in the
        <b>Actions</b> column to add the IP address to the <b>Missing PTR Exemptions</b> table.
      </p>
    </div>
  )
}

export default InboundSenderAuthentication
