import React from 'react'

import { Tab, Tabs, TabsProps } from '@cuda-networks/bds-core'
import styles from './tabsStyles'

export interface TabsComponentProps {
  className?: string
  activeTab: any
  tabs: TabType[]
  variant?: TabsProps['variant']
}

export interface TabType {
  id: string | number
  label: any
  onClick: () => void
}

function TabsComponent({ activeTab, tabs, variant, className }: TabsComponentProps) {
  const classes = styles()

  return (
    <Tabs
      className={className}
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      variant={variant}
      data-testid="tabs"
    >
      {tabs.map((tab: TabType) => (
        <Tab
          className={classes.styledTabs}
          data-field={tab.id}
          key={tab.id}
          onClick={tab.onClick}
          label={tab.label}
          value={tab.id}
          data-testid={tab.id}
        />
      ))}
    </Tabs>
  )
}

export default TabsComponent
