import React, { useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import config from 'config/appConfig'
import { useAppDispatch } from 'redux/toolkit/hooks'
import validateSession from 'lib/validateSession'
import { setActivePath } from 'redux/features/app/appSlice'
import { UiRoute } from 'lib/routes'
import validateSplitioKeys from 'components/libs/validateSplitioKeys'

export interface Props {
  route?: UiRoute
  Component: any
}

function PrivateRoute({ route, Component }: Props) {
  const dispatch = useAppDispatch()

  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    if (route) {
      dispatch(
        // TODO: align type with redux state depends on (BNESS-22692)
        setActivePath({
          id: route.id || '',
          url: location.pathname,
          params,
          metadata: route.metadata,
          isNavbarVisible: true,
          isPublicRoute: false
        } as any)
      )
    }
  }, [dispatch, location, params, route])

  const RenderComponent = useCallback(
    (childProps: any) => {
      const WrappedComponent =
        config.APP.IS_ADMIN || config.APP.IS_ENDUSER
          ? validateSession(validateSplitioKeys(Component))
          : validateSession(Component)
      return <WrappedComponent {...childProps} />
    },
    [Component]
  )

  return <RenderComponent route={route} />
}

export default PrivateRoute
