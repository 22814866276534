import React, { useMemo } from 'react'

import { Grid } from '@cuda-networks/bds-core'

import { useFormatMessage } from 'lib/localization'
import { useOnboardWizardLogic } from 'components/pages/onboardWizard/useOnboardWizardLogic'
import { WizardSteps } from 'components/pages/onboardWizard/onboardWizardTypes'
import RegionSelection from 'components/pages/onboardWizard/regionSelection/RegionSelection'
import ServerAndMxSetup from 'components/pages/onboardWizard/serverAndMxSetup/ServerAndMxSetup'
import ErrorPage from 'components/libs/errorPage/ErrorPage'
import Loading from 'components/libs/loading/Loading'

const BASE_I18N_KEY = 'ess_wizard.error_page'

const OnboardWizard: React.FC = () => {
  const [state, eventHandlers] = useOnboardWizardLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Grid container>
        {state.isGetEmailServerSettingsInProgress && <Loading />}

        {state.isGetEmailServerSettingsIsSuccess && (
          <Grid data-testid="content-view" container>
            {state.currentStep === WizardSteps.regionSelection && (
              <RegionSelection gotoNextStep={eventHandlers.gotoNextStep} />
            )}
            {state.currentStep === WizardSteps.serverAndMxSetup && <ServerAndMxSetup />}
          </Grid>
        )}

        {state.isGetEmailServerSettingsFailed && (
          <ErrorPage title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        )}
      </Grid>
    ),
    [state, eventHandlers, formatMessage]
  )
}

export default OnboardWizard
