import React, { useMemo } from 'react'

import { Alert, Box, Card, CardContent, Grid, LinearProgress, Typography } from '@cuda-networks/bds-core'

import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'

import MessageLogList from 'components/pages/messageLog/messageLogList/MessageLogList'
import MessageLogDetails from 'components/pages/messageLog/messageLogDetails/MessageLogDetails'
import { Split } from 'components/libs/splitter/Split/Split'
import InterfaceExpBanner from 'components/libs/banner/InterfaceExpBanner'

import styles from 'components/pages/messageLog/messageLogStyles'
import { useMessageLogLogic } from 'components/pages/messageLog/useMessageLogLogic'
import MessageLogSearchbar from 'components/pages/messageLog/messageLogSearchBar/MessageLogSearchBar'
import { UiState } from 'components/pages/messageLog/useMessageLogPreloadLogic'

const BASE_I18N_KEY = 'ess.message_log'

const MessageLog = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const { inProgress, showSplitter, isHorizontal, uiState, preloadErrors, viewConfig, isAdmin } = useMessageLogLogic()
  const classes = styles({ isAdmin })
  return useMemo(
    () => (
      <Grid className={classes.logTableContainer}>
        <Card className={classes.logTableCard} elevation={1}>
          <CardContent className={classes.logTableCardContent}>
            <InterfaceExpBanner />
            <Typography className={classes.logTableTitle} variant="body1">
              {formatMessage(viewConfig.title)}
            </Typography>
            {uiState === UiState.Loading && <LinearProgress data-testid="preload-progress" />}
            {uiState === UiState.Error && preloadErrors.getAccountPermissionsError && (
              <Alert className={classes.alert} severity="error" data-testid="alert-account-permissions">
                {errorFormatMessage(preloadErrors.getAccountPermissionsError)}
              </Alert>
            )}
            {uiState === UiState.Error && preloadErrors.getAvailableDomainsError && (
              <Alert className={classes.alert} severity="error" data-testid="alert-domains">
                {errorFormatMessage(preloadErrors.getAvailableDomainsError)}
              </Alert>
            )}
            {uiState === UiState.Error &&
              !preloadErrors.getAvailableDomainsError &&
              preloadErrors.noEmailDomainError && (
                <Alert className={classes.alert} severity="error" data-testid="alert-no-email-domain">
                  {formatMessage('error.no_email_domain')}
                </Alert>
              )}
            {uiState === UiState.Ready && (
              <>
                <MessageLogSearchbar />
                <Box className={classes.logTableProgress}>
                  {inProgress && <LinearProgress data-testid="table-progress" />}
                </Box>
                <Grid className={classes.logTableContent} data-testid="message-log-content">
                  <Split initialPrimarySize="100%" horizontal={isHorizontal} showSplitter={showSplitter}>
                    <Box className={classes.tableBox}>
                      <MessageLogList />
                    </Box>
                    <Box className={classes.drawerBox} flexDirection="column">
                      <MessageLogDetails />
                    </Box>
                  </Split>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    ),
    [
      classes.logTableContainer,
      classes.logTableCard,
      classes.logTableCardContent,
      classes.logTableTitle,
      classes.alert,
      classes.logTableProgress,
      classes.logTableContent,
      classes.tableBox,
      classes.drawerBox,
      formatMessage,
      uiState,
      preloadErrors.getAccountPermissionsError,
      preloadErrors.getAvailableDomainsError,
      preloadErrors.noEmailDomainError,
      errorFormatMessage,
      inProgress,
      isHorizontal,
      showSplitter,
      viewConfig.title
    ]
  )
}

export default MessageLog
