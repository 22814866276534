import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CircularProgress, Grid } from '@cuda-networks/bds-core'

import ContentLoaderErrorPage from 'components/libs/contentLoader/ContentLoaderErrorPage'
import styles from 'components/libs/contentLoader/contentLoaderStyles'

export interface ContentLoaderProps {
  isLoading: boolean
  isFailed: boolean
  errorMessage?: string
  children: any
}

function ContentLoader({ isLoading, isFailed, errorMessage, children }: ContentLoaderProps) {
  const classes = styles()
  const [contentHeight, setContentHeight] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current?.clientHeight)
    }
  }, [])

  return useMemo(
    () => (
      <div className={classes.wrapper}>
        <Grid data-testid="rendered-content" ref={contentRef} container className={classes.wrapper}>
          {children}
        </Grid>
        {(isLoading || isFailed) && (
          <div
            data-testid="content-statuses-section"
            className={classes.fullSizeContainer}
            style={{ height: contentHeight, zIndex: 9999 }}
          >
            <Grid className={classes.fullSizeOpacityContainer} />
            <Grid container justifyContent="center" alignContent="center" direction="column">
              {isLoading && <CircularProgress data-testid="loading-state" className={classes.loadingIcon} />}
              {isFailed && <ContentLoaderErrorPage errorMessage={errorMessage} />}
            </Grid>
          </div>
        )}
      </div>
    ),
    [classes, isLoading, isFailed, errorMessage, children, contentHeight]
  )
}

export default ContentLoader
