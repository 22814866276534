/* eslint-disable no-underscore-dangle */
import mixpanelBrowser from 'mixpanel-browser'

import appConfig from 'config/appConfig'
import { LoginResponse, User } from 'types/auth'

let mixpanel: typeof mixpanelBrowser | undefined

export { TRACKING_EVENTS } from 'config/trackingEvents/trackingEvents'

export interface Flags {
  [key: string]: string
}

const mixpanelConfig = appConfig.MIXPANEL
const isMixpanelEnabled = mixpanelConfig.IS_ENABLED && mixpanelConfig.TOKEN

export function setMixpanelUser(user: User, accessToken: LoginResponse['accessToken']) {
  if (mixpanel && isMixpanelEnabled && user) {
    const data = {
      id: user.userId,
      $email: user.userId,
      accessToken
      // TODO add admin flag
    }

    mixpanel.identify(user.userId)
    mixpanel.register({
      $email: user.userId,
      bos_serial: accessToken,
      bcc_account_id: user.bccAccountId,
      bcc_user_id: user.userInfo?.userId || '',
      productVersion: 'v1'
    })

    mixpanel.people.set(data)
  }
}

export function setMixpanelUserFlags(user: User, flags: any) {
  if (mixpanel && isMixpanelEnabled && user?.userId) {
    const data: Flags = Object.keys(flags).reduce(
      (all: Flags, flagName: string) => ({
        ...all,
        [`__${flagName}`]: flags[flagName]
      }),
      { $email: user.userId }
    )

    // if mixpanel_distinct_id is passed in the url params from barracuda.com
    if (data.__mixpanel_distinct_id) {
      mixpanel.identify(data.__mixpanel_distinct_id)
    }

    mixpanel.register({ $email: user.userId })
    mixpanel.people.set(data)
  }
}

export function initMixpanel(user: User, accessToken: LoginResponse['accessToken']) {
  if (mixpanelConfig.TOKEN) {
    mixpanelBrowser.init(mixpanelConfig.TOKEN, {
      debug: appConfig.ENVIRONMENT.IS_DEV,
      loaded: () => {
        setMixpanelUser(user, accessToken)
      }
    })
    mixpanel = mixpanelBrowser
  }
}

export function trackMixpanelEvent(eventName: string, metadata?: Record<string, unknown>, timerEventName?: string) {
  if (mixpanel && isMixpanelEnabled) {
    mixpanel.track(eventName, metadata)

    if (timerEventName) {
      mixpanel.time_event(timerEventName)
    }
  } else if (appConfig.ENVIRONMENT.IS_DEV) {
    // eslint-disable-next-line
    console.log('Mixpanel is not defined, blocked event:', eventName, metadata)
  }
}

export function trackPageLeaveMixpanelEvent(eventName: string, metadata?: Record<string, unknown>) {
  window.addEventListener('beforeunload', () => {
    trackMixpanelEvent(eventName, metadata)
  })
}
