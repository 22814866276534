import React from 'react'
import { useIntl } from 'react-intl'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@cuda-networks/bds-core'

import styles from 'components/libs/dialog/infoDialog/infoDialogStyles'
import { useInfoDialogLogic } from 'components/libs/dialog/infoDialog/useInfoDialogLogic'

const BASE_I18N_KEY = 'ess.dialog'

export interface InfoDialogProps {
  title?: string
  text: string
  buttonText?: string
  open?: boolean
  onClose: () => void
}

function InfoDialog({ title, text, buttonText, onClose, open = true, ...rest }: InfoDialogProps) {
  const intl = useIntl()
  const classes = styles()
  const [dialog] = useInfoDialogLogic({ onClose, open })

  return (
    <Dialog maxWidth="sm" open={dialog.isDialogOpened} onClose={dialog.onHandleClose} {...rest}>
      <DialogTitle disableTypography id="form-dialog-title">
        <Typography variant="h6">{title || intl.formatMessage({ id: `${BASE_I18N_KEY}.info_title` })}</Typography>
      </DialogTitle>
      <DialogContent className={classes.infoDialog}>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={dialog.onHandleClose} color="primary">
          {buttonText || intl.formatMessage({ id: `${BASE_I18N_KEY}.ok` })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoDialog
