import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { Card, CardContent, Typography, CircularProgress, Grid } from '@cuda-networks/bds-core'
import styles from 'components/pages/actions/verifyLinkAccount/verifyLinkAccountStyles'
import { useVerifyLinkAccountLogic } from 'components/pages/actions/verifyLinkAccount/useVerifyLinkAccountLogic'
import InfoDialog from 'components/libs/dialog/infoDialog/InfoDialog'
import ErrorDialog from 'components/libs/dialog/errorDialog/ErrorDialog'

const BASE_I18N_KEY = 'ess.settings.linked_accounts'

function VerifyLinkAccount() {
  const classes = styles()
  const intl = useIntl()
  const [state, eventHandlers] = useVerifyLinkAccountLogic()
  const {
    isVerifyLinkedAccountApiSuccess,
    isVerifyLinkedAccountApiFailed,
    isVerifyLinkedAccountApiPending,
    userId,
    email,
    errorMessageId,
    allParamsValid
  } = state
  const { onCloseErrorDialog, onCloseInfoDialog } = eventHandlers

  const text = useCallback(
    (id: string, values?: Record<string, string>) => {
      if (!id) {
        return ''
      }
      return intl.formatMessage({ id: `${BASE_I18N_KEY}.${id}` }, values)
    },
    [intl]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        {isVerifyLinkedAccountApiSuccess && (
          <InfoDialog text={text('email_sent', { userId, email })} onClose={onCloseInfoDialog} />
        )}
        {isVerifyLinkedAccountApiPending && (
          <Card elevation={1} className={classes.card}>
            <CardContent>
              <CircularProgress size={32} className={classes.circularProgress} />
              <Typography variant="body1">{text('verifying')}</Typography>
            </CardContent>
          </Card>
        )}
        {isVerifyLinkedAccountApiFailed && <ErrorDialog text={text(errorMessageId)} onClose={onCloseErrorDialog} />}
        {!allParamsValid && <ErrorDialog text={text('invalid_params')} onClose={onCloseErrorDialog} />}
      </Grid>
    </Grid>
  )
}

export default VerifyLinkAccount
