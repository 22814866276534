import Cookies from 'universal-cookie'
import config from 'config/appConfig'
import { LocalStorageKeys } from 'lib/types/localStorageTypes'

const cookies = new Cookies()
const COOKIE_NAME = 'ess_session_id'
const EXP_BANNER_HIDDEN = 'isExpBannerHidden'
const LANGUAGE_PREFERENCE = 'languagePreference'

const getCookieDomain = () => {
  const cookieDomain = config.DOMAIN
  return cookieDomain
}
const removeSessionCookie = () => {
  const cookieDomain = getCookieDomain()
  if (cookieDomain === 'localhost') {
    return
  }
  cookies.remove(COOKIE_NAME, {
    domain: cookieDomain,
    path: '/'
  })
  cookies.remove(COOKIE_NAME, {
    domain: `.${cookieDomain}`,
    path: '/'
  })
}
const persistAccessToken = (value: any) => {
  localStorage.setItem(LocalStorageKeys.accessToken, JSON.stringify(value))
}

const loadAccessToken = () => {
  const localAccesstoken = localStorage.getItem(LocalStorageKeys.accessToken) || '""'
  return JSON.parse(localAccesstoken)
}

const removeAccessToken = () => {
  localStorage.removeItem(LocalStorageKeys.accessToken)
}

const setSessionCookie = (value: string) => {
  removeSessionCookie()
  const cookieDomain = config.DOMAIN
  if (cookieDomain === 'localhost') {
    return
  }
  cookies.set(COOKIE_NAME, value, {
    expires: new Date(Date.now() + 8 * 60 * 60 * 1000),
    domain: getCookieDomain(),
    path: '/'
  })
}
const getSessionCookie = (): string => {
  const cookieDomain = config.DOMAIN
  if (cookieDomain === 'localhost') {
    return 'localhost'
  }
  return cookies.get(COOKIE_NAME)
}
const getExpBannerCookie = () => typeof cookies.get(EXP_BANNER_HIDDEN, { doNotParse: true }) !== 'undefined'
const setExpBannerCookie = (value: boolean) => {
  cookies.set(EXP_BANNER_HIDDEN, value, {
    path: '/'
  })
}
const getLanguageCookie = (): string | undefined => cookies.get(LANGUAGE_PREFERENCE)
const setLanguageCookie = (value: string) => {
  cookies.set(LANGUAGE_PREFERENCE, value, {
    path: '/'
  })
}
export {
  setSessionCookie,
  removeSessionCookie,
  getSessionCookie,
  persistAccessToken,
  loadAccessToken,
  removeAccessToken,
  getExpBannerCookie,
  setExpBannerCookie,
  getLanguageCookie,
  setLanguageCookie
}
