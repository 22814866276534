import { useCallback, useEffect, useMemo, useState } from 'react'
import { addHook, removeAllHooks, sanitize } from 'dompurify'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getMessage, setShowImages } from 'redux/features/mstore/mstoreSlice'
import { StyleProps } from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessagePreview/messageDetailsContentTabMessagePreviewStyles'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'
import { useMessageDetailsContentLogic } from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/useMessageDetailsContentLogic'

export interface State {
  styleProps: StyleProps
  cantViewBlocked: boolean | undefined
  isShowImagesBannerVisible: boolean
  isPreviewDisabled: boolean | undefined
  previewDisabledReason: string | undefined
}

export interface EventHandlers {
  onClickShowImages: () => void
}

export type MessageDetailsContentTabMessagePreviewLogic = [State, EventHandlers]

export const useMessageDetailsContentTabMessagePreviewLogic = (): MessageDetailsContentTabMessagePreviewLogic => {
  const [{ hasContent, hasVirus, hasImages, cantViewQuarantined, cantViewBlocked, isLargeMessage, isAtpAffected }] =
    useMessageDetailsContentLogic()
  const dispatch = useAppDispatch()
  const { message, showImages, userId } = useAppSelector(_store => ({
    message: _store.mstore.message,
    showImages: _store.mstore.showImages,
    userId: _store.auth.accessTokenObject?.userId
  }))
  const { userHasRight } = useUserRights()
  const [iframeWindow, setIframeWindow] = useState<Window>()
  const [previewHtml, setPreviewHtml] = useState<string>()
  const [iframeHeight, setIframeHeight] = useState<number>()

  const isShowImagesBannerVisible = useMemo(() => !!(hasImages && !showImages), [hasImages, showImages])

  const isPreviewDisabled = useMemo(
    () => isAtpAffected || hasVirus || isLargeMessage || cantViewBlocked || cantViewQuarantined || !hasContent,
    [cantViewBlocked, cantViewQuarantined, hasVirus, isAtpAffected, isLargeMessage, hasContent]
  )

  const previewDisabledReason = useMemo(() => {
    if (!message) {
      return undefined
    }
    switch (true) {
      case isAtpAffected: {
        return 'atd_detected'
      }
      case hasVirus: {
        return 'message_has_virus'
      }
      case isLargeMessage: {
        return 'message_too_large'
      }
      case cantViewBlocked: {
        return 'cant_view_blocked'
      }
      case cantViewQuarantined: {
        return 'cant_view_quarantined'
      }
      case !hasContent: {
        return 'no_content'
      }
      default:
        return ''
    }
  }, [cantViewBlocked, cantViewQuarantined, hasVirus, isAtpAffected, isLargeMessage, message, hasContent])

  const onIframeMessage = useCallback((evt: MessageEvent) => {
    if (!evt.source || !(evt.source as any).document) {
      return
    }
    try {
      const data = JSON.parse(evt.data)
      if (data.type === 'preview-iframe-loaded') {
        setIframeWindow(evt.source as any)
      }
      if (data.type === 'preview-content-changed') {
        setIframeHeight(data.height)
      }
    } catch (e) {
      // data may not be a JSON string, irrelevant in the context of this component (e.g. dev server messages during local development)
    }
  }, [])

  const onClickShowImages = useCallback(() => {
    if (!message) {
      return
    }
    dispatch(setShowImages(true))
    dispatch(
      getMessage({
        headersOnly: 0,
        messageId: message.mid,
        showImages: 1,
        domainId: message.did,
        userId: userHasRight(UserRights.SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS) ? undefined : userId
      })
    )
  }, [dispatch, message, userHasRight, userId])

  useEffect(() => {
    if (!message) {
      return
    }
    addHook('afterSanitizeAttributes', (node: any) => {
      // Set all elements owning target to target=_blank
      if ('target' in node) {
        node.setAttribute('target', '_blank')
        node.setAttribute('rel', 'noopener')
      }
    })
    const html = sanitize(message.body, {
      FORCE_BODY: true,
      WHOLE_DOCUMENT: true,
      RETURN_DOM: true
    })
    const style = document.createElement('style')
    style.innerHTML = 'body, html { padding: 0; margin: 0; overflow-y: hidden; }'
    html.style.margin = '0'
    html.style.padding = '0'
    html.style.overflowY = 'hidden'
    const body = html.getElementsByTagName('body')[0]
    body.style.margin = '0'
    body.style.padding = '0'
    body.style.overflowY = 'hidden'
    const script = document.createElement('script')
    script.innerHTML = 'window.parent.postCurrentHeight()'
    body.appendChild(script)
    setPreviewHtml(html.outerHTML)
    removeAllHooks()
  }, [message])

  // Iframe posts a message when its body is ready
  // see iframe source in preview-loader.html.ts
  useEffect(() => {
    window.addEventListener('message', onIframeMessage, false)
    return () => window.removeEventListener('message', onIframeMessage)
  }, [onIframeMessage])

  // Set preview HTML when all dependencies are ready
  useEffect(() => {
    if (!iframeWindow || !previewHtml || !message) {
      return
    }
    const previewContentIframe = iframeWindow.parent.document.getElementById('preview-content') as HTMLIFrameElement
    if (!previewContentIframe) {
      return
    }
    if (message.headers['Content-Type']?.split(';').find((s: string) => s.trim().toLowerCase() === 'format="flowed"')) {
      previewContentIframe.style.whiteSpace = 'pre'
    }
    previewContentIframe.srcdoc = previewHtml
  }, [previewHtml, iframeWindow, message])

  return useMemo(
    () => [
      {
        styleProps: {
          iframeHeight
        },
        cantViewBlocked,
        isShowImagesBannerVisible,
        isPreviewDisabled,
        previewDisabledReason
      },
      {
        onClickShowImages
      }
    ],
    [
      cantViewBlocked,
      iframeHeight,
      isPreviewDisabled,
      isShowImagesBannerVisible,
      onClickShowImages,
      previewDisabledReason
    ]
  )
}
