import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { ActionStatus, ActionTaken, MessageDirection, Reason, Search } from 'types/Messages'
import { getEndDate, getStartDate } from 'lib/mstore'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getSearch,
  MlogViewConfig,
  resetSearch,
  setCustomDate,
  setSearchDate,
  setSearchDomainId,
  setSearchFilter,
  setSearchMessageDirection,
  setSearchTerm
} from 'redux/features/mstore/mstoreSlice'
import { reset as resetMessagesTable } from 'redux/features/dataTables/messages/messagesSlice'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'

export interface UseMessageLogSearchBarLogic {
  searchTerm: Search
  handleFilterChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  handleSearch: () => void
  searchField: {
    handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleClearTerm: () => void
    inputElement: MutableRefObject<null>
  }
  advancedSearch: {
    openAdvancedSearch: boolean
    setOpenAdvancedSearch: Dispatch<SetStateAction<boolean>>
  }
  dateField: {
    selectedDate: number
    handleDateChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  }
  actionTakenField: {
    menuItems: string[]
    selectedItem: string
  }
  statusField: {
    menuItems: string[]
    selectedItem: string
  }
  reasonField: {
    menuItems: string[]
    selectedItem: string
  }
  disableSearch: boolean
  domainsField: {
    menuItems: DomainsInDB[]
    selectedItem: string
    handleDomainChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
    searchAllEnabled: boolean
  }
  isFilterAboutDomainsEnabled: boolean
  messageDirectionField: {
    selectedItem: MessageDirection
    handleMessageDirectionChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  }
  viewConfig: MlogViewConfig
}

const emergencyEnabledActions = Object.values(ActionTaken)
const { emailContinuity, ...noAccessActions } = ActionTaken
const emergencyDisabledActions = Object.values(noAccessActions)

export const useMessageLogSearchBarLogic = (): UseMessageLogSearchBarLogic => {
  const dispatch = useAppDispatch()
  const {
    emergencyInboxEnabled,
    isCustomDate,
    searchFilter,
    searchTerm,
    isHistoryOpen,
    availableDomains,
    searchLogsAllDomainsLimit,
    viewConfig
  } = useAppSelector(_store => ({
    emergencyInboxEnabled: _store.settings.accountPermissions?.emergencyInboxEnabled,
    isCustomDate: _store.mstore.isCustomDate,
    searchFilter: _store.mstore.searchFilter,
    searchTerm: _store.mstore.searchTerm,
    isHistoryOpen: _store.mstore.isMessageHistoryOpen,
    availableDomains: _store.user.availableDomains || [],
    searchLogsAllDomainsLimit: _store.settings.accountPermissions?.searchLogsAllDomainsLimit || 0,
    viewConfig: _store.mstore.viewConfig
  }))
  const { userHasRight } = useUserRights()

  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false)
  const [selectedDate, setSelectedDate] = useState<number>(2)
  const actionTakenMenuItems = useMemo(
    () => (emergencyInboxEnabled === 1 ? emergencyEnabledActions : emergencyDisabledActions),
    [emergencyInboxEnabled]
  )

  const inputElement = useRef(null)

  // Keep an eye if date is changed from the advanced search (custom range)
  useEffect(() => {
    if (isCustomDate) {
      setSelectedDate(0)
    }
  }, [isCustomDate])

  const handleClearBasicSearchTerm = useCallback(() => {
    dispatch(setSearchTerm({ ...searchTerm, search_str: '' }))
    dispatch(resetSearch({ hardReset: true }))
    dispatch(resetMessagesTable())

    dispatch(getSearch())
  }, [dispatch, searchTerm])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setSearchTerm({ ...searchTerm, search_str: e.target.value }))
    },
    [dispatch, searchTerm]
  )

  const handleSearch = useCallback(() => {
    dispatch(resetSearch({ hardReset: true }))
    dispatch(resetMessagesTable())

    dispatch(getSearch())
  }, [dispatch])

  const handleDateChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const days = event.target.value as number
      setSelectedDate(days)

      dispatch(setSearchDate({ start: getStartDate(days), end: getEndDate() }))
      dispatch(setCustomDate(false))

      handleSearch()
    },
    [dispatch, handleSearch]
  )

  const setFilterToEmailContinuity = useCallback(() => {
    dispatch(
      setSearchFilter({
        action: ActionTaken.emailContinuity,
        delivery_status: ActionStatus.spooled,
        reason: Reason.any
      })
    )
  }, [dispatch])

  const handleFilterChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = event.target
      if (name === 'action' && value === ActionTaken.emailContinuity) {
        setFilterToEmailContinuity()
      } else if (name) {
        dispatch(setSearchFilter({ [name as string]: value }))
      }

      handleSearch()
    },
    [dispatch, handleSearch, setFilterToEmailContinuity]
  )

  const handleDomainChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      dispatch(setSearchDomainId(event.target.value === '' ? undefined : (event.target.value as string)))
      handleSearch()
    },
    [dispatch, handleSearch]
  )

  const handleMessageDirectionChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      dispatch(setSearchMessageDirection(event.target.value as MessageDirection))
      handleSearch()
    },
    [dispatch, handleSearch]
  )

  return useMemo(
    () => ({
      searchTerm,
      handleSearch,
      handleFilterChange,
      searchField: {
        handleInputChange,
        handleClearTerm: handleClearBasicSearchTerm,
        inputElement
      },
      advancedSearch: {
        openAdvancedSearch,
        setOpenAdvancedSearch
      },
      dateField: {
        selectedDate,
        handleDateChange
      },
      actionTakenField: {
        menuItems: actionTakenMenuItems,
        selectedItem: searchFilter.action
      },
      statusField: {
        menuItems: Object.values(ActionStatus),
        selectedItem: searchFilter.delivery_status
      },
      reasonField: {
        menuItems: Object.values(Reason),
        selectedItem: searchFilter.reason
      },
      disableSearch: isHistoryOpen,
      domainsField: {
        menuItems: availableDomains,
        selectedItem: searchTerm.domain_id || '',
        handleDomainChange,
        searchAllEnabled:
          userHasRight(UserRights.SEARCH_IN_ALL_DOMAINS) && availableDomains.length <= searchLogsAllDomainsLimit
      },
      messageDirectionField: {
        selectedItem: searchTerm.outbound,
        handleMessageDirectionChange
      },
      isFilterAboutDomainsEnabled: userHasRight(UserRights.FILTER_ABOUT_DOMAINS),
      viewConfig
    }),
    [
      searchTerm,
      handleSearch,
      handleFilterChange,
      handleInputChange,
      handleClearBasicSearchTerm,
      openAdvancedSearch,
      selectedDate,
      handleDateChange,
      actionTakenMenuItems,
      searchFilter,
      isHistoryOpen,
      availableDomains,
      handleDomainChange,
      userHasRight,
      searchLogsAllDomainsLimit,
      handleMessageDirectionChange,
      viewConfig
    ]
  )
}
