import React, { useCallback, useMemo } from 'react'
import { Info } from '@cuda-networks/bds-core/dist/Icons/Feedback'
import {
  LinearProgress,
  TextField,
  Select,
  MenuItem,
  Button,
  DataTable,
  DataTableColumn,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Tooltip
} from '@cuda-networks/bds-core'
import { useFormatMessage } from 'lib/localization'

import { Cell } from 'components/libs/grid/cell'
import YesNoDialog from 'components/libs/dialog/yesNoDialog/YesNoDialog'
import ErrorDialog from 'components/libs/dialog/errorDialog/ErrorDialog'
import IconPolicyWarning from 'assets/images/icons/icon_policy_warning.svg'
import IconPolicyExempt from 'assets/images/icons/icon_policy_exempt.svg'
import IconPolicyBlock from 'assets/images/icons/icon_policy_block.svg'
import { PolicyStatus } from 'types/Settings'
import styles from './senderPoliciesStyles'
import { ModifiedPolicy, useSenderPoliciesLogic } from './useSenderPoliciesLogic'

const BASE_I18N_KEY = 'ess.settings.sender_policies'

function SenderPolicies() {
  const {
    policiesAreLoading,
    accountPermissionsLoading,
    openInvalidDomainBox,
    shouldShowBanner,
    onClickBulkEdit,
    sort,
    handleSortChange,
    sortedData,
    dataStateChange,
    gridState,
    defaultPolicyFormValues,
    nameInput,
    senderPolicyDropdownValues,
    commentInput,
    onSubmit,
    onDelete,
    isOpenConfirmBox,
    closeConfirmBox,
    handleDeletePolicy,
    closeInvalidDomainBox,
    policyInput,
    handleCommentInputChange,
    handleNameInputChange,
    handlePolicyInputChange
  } = useSenderPoliciesLogic()

  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const nameCell = useCallback(
    ({ dataItem }: { dataItem: ModifiedPolicy }) =>
      dataItem.inEdit ? (
        <Cell data-testid="edit-name-cell">
          <TextField
            fullWidth
            name="name"
            defaultValue={defaultPolicyFormValues.current.name}
            inputRef={nameInput}
            inputProps={{ style: { padding: 8 }, onChange: handleNameInputChange }}
          />
        </Cell>
      ) : (
        <Cell data-testid="name-cell">
          {dataItem.isConflict === 1 || dataItem.isDisabled ? (
            <span>
              <Tooltip title={formatMessage('warningHoverMessage')} placement="top">
                <img alt="warning" src={IconPolicyWarning} className={classes.iconWarning} />
              </Tooltip>
              {dataItem.name}
            </span>
          ) : (
            <span className={classes.itemName}>{dataItem.name}</span>
          )}
        </Cell>
      ),
    [defaultPolicyFormValues, nameInput, handleNameInputChange, formatMessage, classes.iconWarning, classes.itemName]
  )

  const policyCell = useCallback(
    ({ dataItem }: { dataItem: ModifiedPolicy }) => (
      <Cell
        className={
          (dataItem.isConflict === 1 || dataItem.isDisabled) && !dataItem.inEdit ? `${classes.disableCss}` : ''
        }
        data-testid="policy-cell"
      >
        {dataItem.inEdit ? (
          <Select
            fullWidth
            variant="outlined"
            className={classes.dropDownList}
            name="policy"
            defaultValue={defaultPolicyFormValues.current.policy}
            SelectDisplayProps={{ style: { padding: 8 } }}
            inputProps={{
              ref: policyInput,
              onChange: handlePolicyInputChange
            }}
          >
            {senderPolicyDropdownValues.map(policy => (
              <MenuItem key={policy} value={policy}>
                {policy}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <span>
            {dataItem.policy === PolicyStatus.exempt && (
              <img alt="exempt" src={IconPolicyExempt} className={classes.iconWarning} />
            )}
            {dataItem.policy === PolicyStatus.block && (
              <img alt="block" src={IconPolicyBlock} className={classes.iconWarning} />
            )}
            {dataItem.policy !== PolicyStatus.exempt && dataItem.policy !== PolicyStatus.block && (
              <img alt="warning" src={IconPolicyWarning} className={classes.iconWarning} />
            )}
            {formatMessage(dataItem.policy)}
          </span>
        )}
      </Cell>
    ),
    [
      classes.disableCss,
      classes.dropDownList,
      classes.iconWarning,
      defaultPolicyFormValues,
      policyInput,
      handlePolicyInputChange,
      senderPolicyDropdownValues,
      formatMessage
    ]
  )

  const commentCell = useCallback(
    ({ dataItem }: { dataItem: ModifiedPolicy }) => (
      <Cell data-testid="comment-cell">
        {dataItem.inEdit ? (
          <TextField
            fullWidth
            name="comment"
            defaultValue={defaultPolicyFormValues.current.comment}
            inputRef={commentInput}
            inputProps={{ style: { padding: 8 }, onChange: handleCommentInputChange }}
          />
        ) : (
          dataItem.comment
        )}
      </Cell>
    ),
    [commentInput, defaultPolicyFormValues, handleCommentInputChange]
  )

  const modifiedCell = useCallback(
    ({ dataItem }: { dataItem: ModifiedPolicy }) => (
      <Cell data-testid="modified-cell">{dataItem.inEdit ? null : dataItem.formattedModified}</Cell>
    ),
    []
  )

  const actionCell = useCallback(
    ({ dataItem }: { dataItem: ModifiedPolicy }) => (
      <Cell data-testid="action-cell">
        {dataItem.inEdit ? (
          <Button
            className={classes.actionButton}
            color="primary"
            onClick={onSubmit}
            variant="contained"
            data-testid="submit-button"
          >
            {formatMessage('buttons.add')}
          </Button>
        ) : (
          <Button
            className={classes.actionButton}
            color="secondary"
            onClick={() => {
              onDelete(dataItem)
            }}
            variant="contained"
            data-testid="delete-button"
          >
            {formatMessage('buttons.remove')}
          </Button>
        )}
      </Cell>
    ),
    [classes, formatMessage, onDelete, onSubmit]
  )

  return useMemo(
    () => (
      <>
        <YesNoDialog
          title={formatMessage('confirm_removal_title')}
          text={formatMessage('confirm_removal')}
          open={isOpenConfirmBox}
          onClose={closeConfirmBox}
          onConfirm={handleDeletePolicy}
        />
        {openInvalidDomainBox && (
          <ErrorDialog
            title={formatMessage('invalid_domain_title')}
            text={formatMessage('invalid_domain')}
            onClose={closeInvalidDomainBox}
          />
        )}
        {shouldShowBanner && !accountPermissionsLoading && (
          <div className={classes.whitelistNotification} data-testid="banner">
            <Info />
            <span className={classes.whitelistNotificationMessage}>
              {formatMessage('sender_policy_whitelist_notification_message')}
            </span>
          </div>
        )}
        <Card elevation={1} data-testid="card">
          <CardHeader
            title={
              <Typography className={classes.title} data-testid="title">
                {formatMessage('title')}
              </Typography>
            }
            subheader={
              <Typography className={classes.subTitle} variant="body2" data-testid="subtitle">
                {formatMessage('sub_title')}
              </Typography>
            }
          />
          <CardActions className={classes.actions} data-testid="card-actions">
            <Button
              disabled={policiesAreLoading}
              color="secondary"
              onClick={onClickBulkEdit}
              variant="contained"
              data-testid="bulk-button"
            >
              {formatMessage('buttons.bulk_edit')}
            </Button>
          </CardActions>
          <CardContent data-testid="card-content">
            <div className={classes.progress}>
              {(policiesAreLoading || accountPermissionsLoading) && <LinearProgress data-testid="linear-progress" />}
            </div>
            {!policiesAreLoading && !accountPermissionsLoading && (
              <DataTable
                data-testid="data-table"
                className={classes.table}
                sortable={{
                  allowUnsort: false,
                  mode: 'single'
                }}
                sort={sort}
                onSortChange={handleSortChange}
                data={sortedData}
                groupConfig={{
                  onDataStateChange: dataStateChange
                }}
                pageConfig={{
                  pageable: { pageSizes: [10, 25, 50] },
                  skip: gridState.dataState.skip,
                  take: gridState.dataState.take,
                  total: gridState.dataResult.total
                }}
                {...(gridState.dataState as any)}
                editField="inEdit"
              >
                <DataTableColumn
                  className={classes.editCell}
                  field="name"
                  title={formatMessage('tableHeadings.sender')}
                  cell={nameCell}
                />
                <DataTableColumn field="policy" title={formatMessage('tableHeadings.policy')} cell={policyCell} />
                <DataTableColumn
                  className={classes.editCell}
                  field="comment"
                  title={formatMessage('tableHeadings.comment')}
                  cell={commentCell}
                />
                <DataTableColumn
                  field="formattedModified"
                  title={formatMessage('tableHeadings.modified')}
                  editable={false}
                  cell={modifiedCell}
                />
                <DataTableColumn
                  title={formatMessage('tableHeadings.actions')}
                  sortable={false}
                  editable={false}
                  cell={actionCell}
                />
              </DataTable>
            )}
          </CardContent>
        </Card>
      </>
    ),
    [
      accountPermissionsLoading,
      actionCell,
      classes.actions,
      classes.editCell,
      classes.progress,
      classes.subTitle,
      classes.table,
      classes.title,
      classes.whitelistNotification,
      classes.whitelistNotificationMessage,
      closeConfirmBox,
      commentCell,
      dataStateChange,
      formatMessage,
      sortedData,
      gridState.dataResult.total,
      gridState.dataState,
      handleDeletePolicy,
      handleSortChange,
      isOpenConfirmBox,
      modifiedCell,
      nameCell,
      onClickBulkEdit,
      openInvalidDomainBox,
      policiesAreLoading,
      policyCell,
      closeInvalidDomainBox,
      shouldShowBanner,
      sort
    ]
  )
}

export default SenderPolicies
