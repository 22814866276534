import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getMxRecords, getProvider, resetMxRecords, resetProvider } from 'redux/features/emailServer/emailServerSlice'
import { MxRecords, Provider } from 'types/emailServer'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { SectionsProps } from 'components/pages/onboardWizard/onboardWizardTypes'
import { useDetectProviderLinksLogic } from 'components/pages/onboardWizard/serverAndMxSetup/sections/providers/useDetectProviderLinksLogic'
import { ProviderWithMxLinks } from 'components/pages/onboardWizard/serverAndMxSetup/sections/providers/providerTypes'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export interface State {
  provider: Provider[] | undefined
  getProviderInProgress: boolean
  mxRecords: MxRecords | undefined
  getMxRecordsInProgress: boolean
  verifyRecordButtonStatuses: { disabled: boolean; inProgress: boolean }
  isShowMissedToVerifyMxRecordsError: boolean
  providerWithMxLinks: ProviderWithMxLinks | undefined
  isMissedToVerify: { primary: boolean; backup: boolean }
  isMxRecordsAreVerified: boolean
}

export interface EventHandlers {
  onVerifyRecords: () => void
}

export interface TestHelpers {
  [key: string]: Dispatch<SetStateAction<any>>
}

export type AddNewMxRecordsLogic = [State, EventHandlers, TestHelpers]

export const useAddNewMxRecordsLogic = ({
  setSectionToActive,
  isRequestedToCompleteSetup,
  setIsAddMxRecordsSectionHasError,
  sectionsVerifiedStates
}: SectionsProps): AddNewMxRecordsLogic => {
  const dispatch = useAppDispatch()
  const {
    detectedDomain,
    isDetectEmailServerInProgress,
    isDetectEmailServerSuccess,
    getProviderInProgress,
    provider,
    getMxRecordsInProgress,
    getMxRecordsSuccess,
    getMxRecordsFailed,
    mxRecords
  } = useAppSelector(_store => ({
    detectedDomain: _store.emailServer.emailServer?.domainName,
    isDetectEmailServerInProgress: isPending(_store.emailServer.detectEmailServerApiStatus),
    isDetectEmailServerSuccess: isSuccess(_store.emailServer.detectEmailServerApiStatus),
    getProviderInProgress: isPending(_store.emailServer.getMxRecordsApiStatus),
    provider: _store.emailServer.provider,
    getMxRecordsInProgress: isPending(_store.emailServer.getMxRecordsApiStatus),
    getMxRecordsSuccess: isSuccess(_store.emailServer.getMxRecordsApiStatus),
    getMxRecordsFailed: isFailed(_store.emailServer.getMxRecordsApiStatus),
    mxRecords: _store.emailServer.mxRecords
  }))
  const [isShowMissedToVerifyMxRecordsError, setIsShowMissedToVerifyMxRecordsError] = useState<boolean>(false)
  const [isMxRecordsAreVerified, setIsMxRecordsAreVerified] = useState<boolean>(false)
  const [providerWithMxLinks] = useDetectProviderLinksLogic()

  // init
  useEffect(
    () => () => {
      dispatch(resetMxRecords())
      dispatch(resetProvider())
    },
    // eslint-disable-next-line
    []
  )

  // previous section is completed
  useEffect(() => {
    if (sectionsVerifiedStates.specifyEmailServer) {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_2_START, {}, TRACKING_EVENTS.WIZARD.STEP_2_COMPLETE)
    }
  }, [sectionsVerifiedStates.specifyEmailServer])

  // section verified state is changed
  useEffect(() => {
    if (sectionsVerifiedStates.addNewMxRecords) {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_2_COMPLETE)
    }
  }, [sectionsVerifiedStates.addNewMxRecords])

  // domain is changed
  useEffect(
    () => {
      if (detectedDomain && isDetectEmailServerSuccess) {
        setIsMxRecordsAreVerified(false)
        setIsShowMissedToVerifyMxRecordsError(false)
        dispatch(getMxRecords(detectedDomain))
        dispatch(getProvider(detectedDomain))
      }
    },
    // eslint-disable-next-line
    [detectedDomain, isDetectEmailServerSuccess]
  )

  // new server detection is started
  useEffect(() => {
    if (isDetectEmailServerInProgress) {
      setIsMxRecordsAreVerified(false)
      setIsShowMissedToVerifyMxRecordsError(false)
      dispatch(resetProvider())
      dispatch(resetMxRecords())
    }
  }, [dispatch, isDetectEmailServerInProgress])

  // delegate the section's error state
  useEffect(() => {
    setIsAddMxRecordsSectionHasError?.(isShowMissedToVerifyMxRecordsError)
  }, [isShowMissedToVerifyMxRecordsError, setIsAddMxRecordsSectionHasError])

  // user is reuqested to complete the setup
  useEffect(() => {
    if (isRequestedToCompleteSetup) {
      const isMissedToVerifyMxRecords = !mxRecords || Object.values(mxRecords).some(record => !record.verified)
      setIsShowMissedToVerifyMxRecordsError(isMissedToVerifyMxRecords)
    }
  }, [isRequestedToCompleteSetup, mxRecords])

  const isMissedToVerify = useMemo(() => {
    const isAlreadyHitVerifyButton: boolean = isMxRecordsAreVerified && (getMxRecordsSuccess || getMxRecordsFailed)

    return {
      primary: !mxRecords?.primary.verified && isAlreadyHitVerifyButton,
      backup: !mxRecords?.backup.verified && isAlreadyHitVerifyButton
    }
  }, [isMxRecordsAreVerified, getMxRecordsSuccess, getMxRecordsFailed, mxRecords])

  const onVerifyRecords = useCallback(() => {
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_2_VERIFIY_RECORDS, { mxRecords })

    if (detectedDomain) {
      setIsMxRecordsAreVerified(true)
      setSectionToActive()
      setIsShowMissedToVerifyMxRecordsError(false)
      dispatch(getMxRecords(detectedDomain))
    }
  }, [dispatch, detectedDomain, setSectionToActive, mxRecords])

  const verifyRecordButtonStatuses = useMemo(
    () => ({
      disabled: !mxRecords,
      inProgress: getMxRecordsInProgress
    }),
    [mxRecords, getMxRecordsInProgress]
  )

  return useMemo(
    () => [
      {
        provider,
        getProviderInProgress,
        mxRecords,
        getMxRecordsInProgress,
        verifyRecordButtonStatuses,
        isShowMissedToVerifyMxRecordsError,
        providerWithMxLinks,
        isMissedToVerify,
        isMxRecordsAreVerified
      },
      {
        onVerifyRecords
      },
      {
        setIsShowMissedToVerifyMxRecordsError,
        setIsMxRecordsAreVerified
      }
    ],
    [
      getProviderInProgress,
      provider,
      getMxRecordsInProgress,
      mxRecords,
      verifyRecordButtonStatuses,
      isShowMissedToVerifyMxRecordsError,
      providerWithMxLinks,
      isMissedToVerify,
      isMxRecordsAreVerified,
      onVerifyRecords,
      setIsShowMissedToVerifyMxRecordsError,
      setIsMxRecordsAreVerified
    ]
  )
}
