import React, { useMemo } from 'react'
import { Close } from '@cuda-networks/bds-core/dist/Icons/Core'
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Modal
} from '@cuda-networks/bds-core'

import { useFormatMessage } from 'lib/localization'

import styles from 'components/libs/dialog/saveSearch/saveSearchDialogStyles'
import { useSaveSearchDialogLogic } from 'components/libs/dialog/saveSearch/useSaveSearchDialogLogic'

interface SaveSearchFormProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.save_search'
const BASE_I18N_DIALOG_KEY = 'ess.dialog'

const SaveSearchDialog: React.FC<SaveSearchFormProps> = (props: SaveSearchFormProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dialogFormatMessage = useFormatMessage(BASE_I18N_DIALOG_KEY)

  const { onClose } = props
  const { saveName, error, handleInputChange, saveSearch } = useSaveSearchDialogLogic(onClose)

  return useMemo(
    () => (
      <Modal open onClose={onClose} className={classes.modal} data-testid="root-dialog">
        <Card elevation={1} className={`keep-open ${classes.container}`}>
          <CardHeader
            title={formatMessage('title')}
            action={
              <IconButton onClick={onClose} data-testid="close-icon">
                <Close />
              </IconButton>
            }
            data-testid="title"
          />
          <CardContent>
            <TextField
              variant="outlined"
              fullWidth
              name="name"
              placeholder={formatMessage('name_your_search')}
              error={!!error}
              helperText={error && formatMessage(error)}
              margin="none"
              inputProps={{ maxLength: 32 }}
              value={saveName}
              onChange={handleInputChange}
              data-testid="input-field"
            />
          </CardContent>
          <CardActions className={classes.buttonsWrapper}>
            <Button variant="text" color="secondary" onClick={onClose} data-testid="cancel-button">
              {dialogFormatMessage('cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={saveSearch} data-testid="save-button">
              {dialogFormatMessage('save')}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    ),
    [classes, formatMessage, dialogFormatMessage, onClose, saveName, error, handleInputChange, saveSearch]
  )
}

export default SaveSearchDialog
