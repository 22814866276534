import React, { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import Loading from 'components/libs/loading/Loading'
import { useBccLoginLogic } from 'components/pages/bccLogin/useBccLoginLogic'
import ErrorPage from 'components/libs/errorPage/ErrorPage'

const BASE_I18N_KEY = 'ess.bcc_login.error_page'

function BccLogin() {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [isFailedToLogin] = useBccLoginLogic()

  return useMemo(() => {
    if (!isFailedToLogin) {
      return <Loading />
    }

    // TODO: validate the error screen with UX team
    return <ErrorPage title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
  }, [isFailedToLogin, formatMessage])
}

export default BccLogin
