import { createAsyncThunk } from '@reduxjs/toolkit'

import { objectToCamel, objectToSnake } from 'ts-case-convert'
import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import config from 'config/appConfig'

import { AtpLog, AtpSearch, FileTypes } from 'types/Atp'
import { RootState } from 'redux/toolkit/store'
import { DEFAULT_TIMEZONE, getPastDate } from 'lib/datetime'
import { MetadataResponse } from 'types/general'

export interface AtpLogPayload {
  domainId?: string
}
export interface AtpLogResult {
  count: number
  result: AtpLog[]
  metadata: MetadataResponse
}

export interface AtpOverviewResult {
  infectedCount: number
  suspiciousCount: number
}

export interface AtpLogDeletePayload {
  idList: string[]
}

export interface AtpLogReportPayload {
  cuid: string
}

export const getAtpSearch = createAsyncThunk<AtpLogResult, AtpLogPayload | undefined, ApiRejectResponse>(
  'ATP/atpSearch',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const queryState: AtpSearch = (getState() as RootState).atp.searchTerm
      const { take, skip } = (getState() as RootState).dataTables.atp

      const query = {} as any
      if (queryState.searchStr) {
        query.searchStr = queryState.searchStr
      }
      if (queryState.status !== 'all') {
        query.status = queryState.status
      }
      if (queryState.time !== 30) {
        query.time = getPastDate(query.time, config.DATETIME.ATP_API_DATE_WITH_TIME_FORMAT)
      }
      if (queryState.filetype !== 'all') {
        query.filetype = queryState.filetype
      }
      if (queryState.messageId) {
        query.messageId = queryState.messageId
      }
      const resp = await restClient(apiRoutes.ATP_LOG, {
        params: {
          ...objectToSnake(query),
          page: skip + 1,
          limit: take,
          domain_id: payload?.domainId !== 'all' ? payload?.domainId : undefined
        }
      })

      return {
        count: resp.data.result.data.count,
        result: resp.data.result.data.result.map((r: any) => objectToCamel<AtpLog>(r)),
        metadata: resp.data.result.metadata
      }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAtpOverview = createAsyncThunk<AtpOverviewResult, AtpLogPayload | undefined, ApiRejectResponse>(
  'ATP/atpOverview',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.ATP_OVERVIEW, { params: { domain_id: payload?.domainId } })
      return resp.data.result
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAtpFiletypes = createAsyncThunk<FileTypes[], undefined, ApiRejectResponse>(
  'ATP/atpFiletypes',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.ATP_FILETYPES)

      return resp.data.result.result
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteAtp = createAsyncThunk<string, AtpLogDeletePayload, ApiRejectResponse>(
  'ATP/atpLogDelete',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.ATP_LOG_DELETE, {
        data: objectToSnake(payload)
      })

      return resp.data.result.result
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAtpReport = createAsyncThunk<string, AtpLogReportPayload, ApiRejectResponse>(
  'ATP/atpLogReport',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const timezone: string = (getState() as RootState).app.timezone || DEFAULT_TIMEZONE
      const resp = await restClient(apiRoutes.ATP_LOG_REPORT, {
        params: {
          cuid: payload.cuid,
          timezone: encodeURI(timezone)
        },
        responseType: 'arraybuffer'
      } as any)
      const file = new Blob([resp.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank')
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
