import { DateTime } from 'luxon'
import { isDate, isString } from 'lodash'
import config from 'config/appConfig'
import reduxStore from 'lib/reduxStore'
import { RootState } from 'redux/toolkit/store'

export const DEFAULT_TIMEZONE = 'America/Los_Angeles'

export type DateFormat = Date | string | undefined

// luxon methods
export function luxonDate(date?: DateFormat) {
  switch (true) {
    case isDate(date):
      return DateTime.fromJSDate(date as Date)
    case isString(date):
      return DateTime.fromISO(date as string)
    default:
      return DateTime.now()
  }
}

export function formatDate(date: DateFormat, format?: string, timezone?: string) {
  const userTimezone = (reduxStore.getState() as RootState).app.timezone
  return luxonDate(date)
    .setZone(timezone || userTimezone || DEFAULT_TIMEZONE)
    .toFormat(format || config.DATETIME.DEFAULT_DATE_FORMAT)
}

export function getHours(dateRange: any) {
  return Math.round((dateRange.end - dateRange.start) / 36e5)
}

export function removeHours(date: Date, hours: number) {
  date.setTime(date.getTime() - hours * 36e5)

  return date
}

export function calculateTimeframe(dateRange: any) {
  const timeframe = getHours(dateRange)

  return timeframe > 24 ? Math.ceil(timeframe / 24) * 24 : timeframe
}

export function calculateTokenExpiration(tokenExpirationTime: number) {
  return new Date(now() + tokenExpirationTime * 1000)
}

export function now() {
  return Date.now()
}

export function hasSameDay(date: DateFormat) {
  return DateTime.now().hasSame(luxonDate(date), 'day')
}

export function getPastDate(days: number, format: string = config.DATETIME.DEFAULT_DATE_FORMAT) {
  return DateTime.now()
    .minus({
      days
    })
    .toFormat(format)
}
