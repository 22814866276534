import React, { useMemo } from 'react'
import { Typography, Link } from '@cuda-networks/bds-core'
import config from 'config/appConfig'
import styles from 'components/libs/layout/footer/footerStyles'
import inIframe from 'lib/inIframe'

function Footer() {
  const classes = styles()

  return useMemo(() => {
    const fullYear = new Date().getFullYear()
    const copyrightYears = fullYear === 2021 ? '2021' : `2021-${fullYear}`

    if (inIframe()) {
      return null
    }

    return (
      <div className={classes.container} id="footer">
        <div className={classes.components}>
          <Typography variant="body1" className={classes.copyright}>
            © {copyrightYears} Barracuda Networks, Inc. All rights reserved. &nbsp;|
          </Typography>
          <Link
            href="https://www.barracuda.com/company/legal/privacy"
            className={classes.privacy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Typography variant="body1" className={classes.copyright}>
            &nbsp;|
          </Typography>
          <Link
            href="https://www.barracuda.com/legal/terms"
            className={classes.terms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
          <Typography variant="body1" className={classes.copyright}>
            &nbsp;|
          </Typography>
          {config.ONETRUST_ID && (
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Cookie Preferences
            </button>
          )}
        </div>
      </div>
    )
  }, [classes])
}

export default Footer
