import { ChangeEvent, useCallback, useMemo, useState } from 'react'

export type TabName = 'preview' | 'source'

export interface State {
  selectedTabName: TabName
}

export interface EventHandlers {
  onTabChange: (_: ChangeEvent<Record<string, never>>, value: TabName) => void
}

export type MessageDetailsContentTabsLogic = [State, EventHandlers]

export const useMessageDetailsContentTabsLogic = (): MessageDetailsContentTabsLogic => {
  const [selectedTabName, setSelectedTabName] = useState<TabName>('preview')

  const onTabChange = useCallback(
    (_: ChangeEvent<Record<string, never>>, value: TabName) => setSelectedTabName(value),
    []
  )

  return useMemo(
    () => [
      {
        selectedTabName
      },
      {
        onTabChange
      }
    ],
    [onTabChange, selectedTabName]
  )
}
