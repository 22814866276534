import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  dialogContent: {
    minHeight: 80,
    width: 500
  },
  dialogTitle: {
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}))
