import React, { useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isFailed } from 'redux/toolkit/api'
import { getSessionCookie, loadAccessToken } from 'lib/cookies'
import { logout, setAccessToken, validateAccessToken, validateSessionId } from 'redux/features/auth/authSlice'
import Loading from 'components/libs/loading/Loading'
import { initMonitoringServices } from 'lib/monitoring/monitoringService'

export default function validateSession<P>(WrappedComponent: React.ComponentType<P>) {
  const ValidatedSession: React.FC<any> = props => {
    const { accessToken, accessTokenObject, isLoggingOut, logoutIsFailed, validateAccessTokenIsFailed } =
      useAppSelector(_store => ({
        accessToken: _store.auth.accessToken,
        accessTokenObject: _store.auth.accessTokenObject,
        logoutIsFailed: isFailed(_store.auth.logoutApiStatus),
        isLoggingOut: isPending(_store.auth.logoutApiStatus),
        validateAccessTokenIsFailed: isFailed(_store.auth.validateAccessTokenApiStatus)
      }))

    const dispatch = useAppDispatch()
    const sessionId = getSessionCookie()
    const localAccessToken = loadAccessToken()

    // init
    useEffect(() => {
      if (localAccessToken) {
        dispatch(setAccessToken(localAccessToken))
      }
      // eslint-disable-next-line
    }, [sessionId])

    // Logout the user if sessionId is not set
    useEffect(() => {
      if ((!isLoggingOut && logoutIsFailed) || !sessionId || validateAccessTokenIsFailed) {
        dispatch(logout())
      }
    }, [dispatch, isLoggingOut, logoutIsFailed, sessionId, validateAccessTokenIsFailed])

    // Trying to validate session if sessionId is set but no accessToken
    useEffect(() => {
      if (sessionId && !localAccessToken) {
        dispatch(validateSessionId({ session_id: sessionId }))
      }
    }, [dispatch, localAccessToken, sessionId])

    // Validate the accessToken if set
    useEffect(() => {
      if (accessToken && !accessTokenObject) {
        dispatch(validateAccessToken(accessToken))
      }
    }, [accessToken, accessTokenObject, dispatch])

    // init monitoring services
    useEffect(() => {
      if (accessToken && accessTokenObject) {
        initMonitoringServices(accessTokenObject, accessToken)
      }
    }, [accessToken, accessTokenObject])

    return useMemo(() => {
      if (!accessTokenObject) {
        return <Loading />
      }

      return <WrappedComponent {...props} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessTokenObject])
  }

  return ValidatedSession
}
