import React from 'react'

import { Drawer, List, ListItem, ListItemText } from '@cuda-networks/bds-core'

import hamburgerMenuIcon from 'assets/images/hamburgerMenuIcon.svg'
import { useSideMenuLogic } from 'components/libs/layout/navbar/useSideMenuLogic'

import styles from './navbarStyles'

function SideMenu() {
  const classes = styles()
  const { sideMenuItems, selectedSideMenuItem, toggleSideMenu, isSideMenuOpen, closeSideMenu } = useSideMenuLogic()

  const list = (
    <div className={classes.list} role="presentation">
      <List>
        {sideMenuItems.map(item => (
          <ListItem
            button
            key={item.id}
            selected={item.id === selectedSideMenuItem.id}
            classes={{ selected: classes.activeListItem }}
            onClick={item.onClick}
          >
            <ListItemText primary={<span className={classes.sideMenuText}>{item.text}</span>} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <div id="ess-menu-sidenav">
      <div role="presentation" onClick={toggleSideMenu} className={classes.hamburgerWrapper}>
        <img className={classes.hamburger} alt="MENU" src={hamburgerMenuIcon} />
      </div>
      <Drawer className={classes.drawer} anchor="left" open={isSideMenuOpen} onClose={closeSideMenu}>
        {list}
      </Drawer>
    </div>
  )
}

export default SideMenu
