// TODO: we need to revisit all of the stored values to check their necessity
export enum LocalStorageKeys {
  // validated storage keys
  'version' = 'version',
  'accessToken' = 'accessToken',

  // need to validate keys
  'splitterOrientation' = 'splitterOrientation',
  'columnWidths' = 'columnWidths',
  'essFromColumnDisplay' = 'ess_from_column_display',
  'settingsStore' = 'settingsStore',
  'searchForm' = 'searchForm',
  'outboundQuarantineSearchForm' = 'outboundQuarantineSearchForm'
}
