import React, { useMemo } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Typography,
  LinearProgress
} from '@cuda-networks/bds-core'
import { useFormatMessage } from 'lib/localization'

import YesNoDialog from 'components/libs/dialog/yesNoDialog/YesNoDialog'
import styles from 'components/pages/settings/changePassword/changePasswordStyles'
import { useChangePasswordLogic } from './useChangePasswordLogic'

const BASE_I18N_KEY = 'ess.change_password_page'

const ChangePassword: React.FC = () => {
  const {
    changePasswordFormValues,
    handleInputChange,
    changingPassword,
    submitIsDisabled,
    onSubmit,
    isOpenConfirmDialog,
    closeConfirmDialog,
    onChangePassword
  } = useChangePasswordLogic()

  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <>
        <Card elevation={1} className={classes.card} data-testid="root-card">
          <CardHeader
            title={
              <Typography className={classes.title} data-testid="title">
                {formatMessage('change_password')}
              </Typography>
            }
          />
          <CardContent className={classes.cardContent}>
            <TextField
              className={classes.textField}
              fullWidth
              name="oldPassword"
              value={changePasswordFormValues.oldPassword}
              onChange={handleInputChange}
              size="medium"
              placeholder={formatMessage('old_password')}
              type="password"
              inputProps={{ style: { padding: 8 } }}
              data-testid="old-password"
            />
            <TextField
              className={classes.textField}
              fullWidth
              name="newPassword"
              value={changePasswordFormValues.newPassword}
              onChange={handleInputChange}
              size="medium"
              placeholder={formatMessage('new_password')}
              type="password"
              inputProps={{ style: { padding: 8 } }}
              data-testid="new-password"
            />
            <TextField
              className={classes.textField}
              fullWidth
              name="confirmNewPassword"
              value={changePasswordFormValues.confirmNewPassword}
              onChange={handleInputChange}
              size="medium"
              placeholder={formatMessage('confirm_new_password')}
              type="password"
              inputProps={{ style: { padding: 8 } }}
              data-testid="confirm-password"
            />
          </CardContent>
          <div className={classes.progress}>{changingPassword && <LinearProgress data-testid="loader" />}</div>
          <CardActions className={classes.actions}>
            <Button
              disabled={submitIsDisabled}
              color="primary"
              size="medium"
              onClick={onSubmit}
              variant="contained"
              data-testid="submit-button"
            >
              Submit
            </Button>
          </CardActions>
        </Card>
        <YesNoDialog
          title={formatMessage('change_password_title')}
          text={formatMessage('change_password_confirmation')}
          open={isOpenConfirmDialog}
          onClose={closeConfirmDialog}
          onConfirm={onChangePassword}
        />
      </>
    ),
    [
      classes,
      formatMessage,
      changePasswordFormValues,
      handleInputChange,
      changingPassword,
      submitIsDisabled,
      onSubmit,
      isOpenConfirmDialog,
      closeConfirmDialog,
      onChangePassword
    ]
  )
}

export default ChangePassword
