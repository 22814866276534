import React from 'react'
import PropTypes from 'prop-types'

import { Account as AccountIcon } from '@cuda-networks/bds-core/dist/Icons/Core'
import styles from './navbarStyles'
import NavbarLogic from './NavbarLogic'
import { NavbarProps } from './navbarProps'

type Props = NavbarProps

function UserInfoWidget(props: Props) {
  const classes = styles()

  return (
    <div className={classes.noticeableWrapper}>
      <span className={classes.accountIcon}>
        <AccountIcon />
      </span>
      <span className={classes.accountEmail}>{props.userEmail}</span>
    </div>
  )
}

UserInfoWidget.propTypes = {
  userEmail: PropTypes.string.isRequired
}

export default NavbarLogic(UserInfoWidget)
