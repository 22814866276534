import React, { useMemo } from 'react'
import { Alert, LinearProgress, Grid, Typography } from '@cuda-networks/bds-core'

import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'
import { useMessageDetailsContentTabMessageSourceLogic } from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessageSource/useMessageDetailsContentTabMessageSourceLogic'
import styles from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessageSource/messageDetailsContentTabMessageSourceStyles'

const BASE_I18N_KEY = 'ess.message_log.message_preview'

const MessageDetailsContentTabMessageSource = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [
    {
      isGetMessageSourcePending,
      isGetMessageSourceFailed,
      getMessageSourceError,
      isGetMessageSourceSuccess,
      isShowSourceDisabled,
      showSourceDisabledReason,
      messageSource
    }
  ] = useMessageDetailsContentTabMessageSourceLogic()
  const classes = styles()

  return useMemo(
    () => (
      <>
        {isGetMessageSourcePending && <LinearProgress data-testid="linear-progress" />}
        {isGetMessageSourceFailed && getMessageSourceError && (
          <Alert severity="error">{errorFormatMessage(getMessageSourceError)}</Alert>
        )}
        {isShowSourceDisabled && !!showSourceDisabledReason && formatMessage(showSourceDisabledReason)}
        {isGetMessageSourceSuccess && !isShowSourceDisabled && messageSource && (
          <Grid container data-testid="message-source">
            <Grid item xs={12} className={classes.sourceWrapper}>
              <Typography className={classes.sourceText}>{messageSource.message}</Typography>
            </Grid>
          </Grid>
        )}
      </>
    ),
    [
      classes.sourceText,
      classes.sourceWrapper,
      errorFormatMessage,
      formatMessage,
      getMessageSourceError,
      isGetMessageSourceFailed,
      isGetMessageSourcePending,
      isGetMessageSourceSuccess,
      isShowSourceDisabled,
      messageSource,
      showSourceDisabledReason
    ]
  )
}

export default MessageDetailsContentTabMessageSource
