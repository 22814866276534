import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@cuda-networks/bds-core'
import { useYesNoDialogLogic } from 'components/libs/dialog/yesNoDialog/useYesNoDialogLogic'
import styles from './yesNoDialogStyles'

const BASE_I18N_KEY = 'ess.dialog'

export interface YesNoDialogProps {
  title: string
  text: string
  open: boolean
  onCancel?: () => void
  onClose: () => void
  onConfirm: () => void
}

const YesNoDialog: React.FC<YesNoDialogProps> = ({ title, text, open, onClose, onConfirm, onCancel }) => {
  const intl = useIntl()
  const classes = styles()
  const [dialog] = useYesNoDialogLogic({ onCancel, onClose, onConfirm, open })

  return useMemo(
    () => (
      <Dialog maxWidth="sm" open={dialog.isDialogOpened} onClose={dialog.onHandleClose}>
        <DialogTitle disableTypography id="form-dialog-title">
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
        <DialogContent className={classes.yesNoDialog}>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={dialog.onClickNo} color="primary">
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.no` })}
          </Button>
          <Button variant="contained" onClick={dialog.onClickYes} color="primary">
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.yes` })}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      classes.yesNoDialog,
      dialog.isDialogOpened,
      dialog.onClickNo,
      dialog.onClickYes,
      dialog.onHandleClose,
      intl,
      text,
      title
    ]
  )
}

export default YesNoDialog
