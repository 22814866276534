import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import {
  changePassword,
  deleteLinkedAccount,
  deletePolicy,
  getAccountPermissions,
  getLinkedAccounts,
  getQuarantineNotification,
  getSenderPolicies,
  postLinkedAccount,
  postPolicy,
  putBulkEditPolicies,
  putQuarantineNotification,
  verifyLinkedAccount
} from 'redux/features/settings/settingsApiThunks'
import {
  AccountPermission,
  InboundScheduleUI,
  LinkedAccount,
  QuarantineNotificationUI,
  SenderPoliciesReport
} from 'types/Settings'
import { convertScheduleToObj } from 'lib/convertSchedule'

export interface SettingsState {
  getSenderPoliciesApiStatus: ApiStatus
  postPolicyApiStatus: ApiStatus
  deletePolicyApiStatus: ApiStatus
  putBulkEditPoliciesApiStatus: ApiStatus
  getQuarantineNotificationApiStatus: ApiStatus
  putQuarantineNotificationApiStatus: ApiStatus
  changePasswordApiStatus: ApiStatus
  getLinkedAccountsApiStatus: ApiStatus
  postLinkedAccountApiStatus: ApiStatus
  verifyLinkedAccountApiStatus: ApiStatus
  deleteLinkedAccountApiStatus: ApiStatus
  getAccountPermissionsApiStatus: ApiStatus

  senderPolicies: SenderPoliciesReport | undefined
  quarantineNotification: QuarantineNotificationUI | undefined
  updatedSchedule: InboundScheduleUI | undefined
  changePasswordMessage: string | undefined
  linkedAccountAdded: LinkedAccount | undefined
  linkedAccounts: LinkedAccount[] | undefined
  accountPermissions: AccountPermission | undefined
}

// initialState
export const INITIAL_STATE: SettingsState = {
  getSenderPoliciesApiStatus: inIdle,
  postPolicyApiStatus: inIdle,
  deletePolicyApiStatus: inIdle,
  putBulkEditPoliciesApiStatus: inIdle,
  getQuarantineNotificationApiStatus: inIdle,
  putQuarantineNotificationApiStatus: inIdle,
  changePasswordApiStatus: inIdle,
  getLinkedAccountsApiStatus: inIdle,
  postLinkedAccountApiStatus: inIdle,
  verifyLinkedAccountApiStatus: inIdle,
  deleteLinkedAccountApiStatus: inIdle,
  getAccountPermissionsApiStatus: inIdle,

  senderPolicies: undefined,
  quarantineNotification: undefined,
  updatedSchedule: undefined,
  changePasswordMessage: undefined,
  linkedAccountAdded: undefined,
  linkedAccounts: undefined,
  accountPermissions: undefined
}

/* eslint-disable no-param-reassign */
export const settingsSlice = createSlice({
  name: 'SETTINGS',
  initialState: INITIAL_STATE,
  reducers: {
    updateSchedule: (state: SettingsState, action: PayloadAction<InboundScheduleUI>) => {
      state.updatedSchedule = action.payload
    },
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetAccountPermissions: (state: SettingsState) => {
      state.getAccountPermissionsApiStatus = INITIAL_STATE.getAccountPermissionsApiStatus
      state.accountPermissions = INITIAL_STATE.accountPermissions
    }
  },
  extraReducers: builder => {
    builder
      // getSenderPolicies
      .addCase(getSenderPolicies.pending, state => {
        state.getSenderPoliciesApiStatus = inProgress
      })
      .addCase(getSenderPolicies.fulfilled, (state, action) => {
        state.getSenderPoliciesApiStatus = successResponse
        state.senderPolicies = action.payload.data
      })
      .addCase(getSenderPolicies.rejected, (state, action) => {
        state.getSenderPoliciesApiStatus = failedResponse(action.payload)
      })

      // postPolicy
      .addCase(postPolicy.pending, state => {
        state.postPolicyApiStatus = inProgress
      })
      .addCase(postPolicy.fulfilled, (state, action) => {
        state.postPolicyApiStatus = successResponse
        if (state.senderPolicies) {
          state.senderPolicies.results = [...state.senderPolicies.results, action.payload]
        }
      })
      .addCase(postPolicy.rejected, (state, action) => {
        state.postPolicyApiStatus = failedResponse(action.payload)
      })

      // deletePolicy
      .addCase(deletePolicy.pending, state => {
        state.deletePolicyApiStatus = inProgress
      })
      .addCase(deletePolicy.fulfilled, state => {
        state.deletePolicyApiStatus = successResponse
      })
      .addCase(deletePolicy.rejected, (state, action) => {
        state.deletePolicyApiStatus = failedResponse(action.payload)
      })

      // putBulkEditPolicies
      .addCase(putBulkEditPolicies.pending, state => {
        state.putBulkEditPoliciesApiStatus = inProgress
      })
      .addCase(putBulkEditPolicies.fulfilled, state => {
        state.putBulkEditPoliciesApiStatus = successResponse
      })
      .addCase(putBulkEditPolicies.rejected, (state, action) => {
        state.putBulkEditPoliciesApiStatus = failedResponse(action.payload)
      })

      // getQuarantineNotification
      .addCase(getQuarantineNotification.pending, state => {
        state.getQuarantineNotificationApiStatus = inProgress
        state.updatedSchedule = INITIAL_STATE.updatedSchedule
      })
      .addCase(getQuarantineNotification.fulfilled, (state, action) => {
        state.getQuarantineNotificationApiStatus = successResponse

        const quarantineNotificationResult = action.payload.results?.[0]
        const customInboundSchedule = convertScheduleToObj(quarantineNotificationResult.customInboundSchedule)
        state.quarantineNotification = {
          inboundSchedule: quarantineNotificationResult.inboundSchedule,
          customInboundSchedule
        }

        state.updatedSchedule = customInboundSchedule
      })
      .addCase(getQuarantineNotification.rejected, (state, action) => {
        state.getQuarantineNotificationApiStatus = failedResponse(action.payload)
      })

      // putQuarantineNotification
      .addCase(putQuarantineNotification.pending, state => {
        state.putQuarantineNotificationApiStatus = inProgress
        state.updatedSchedule = INITIAL_STATE.updatedSchedule
      })
      .addCase(putQuarantineNotification.fulfilled, (state, action) => {
        state.putQuarantineNotificationApiStatus = successResponse

        const quarantineNotificationResult = action.payload.results?.[0]
        const customInboundSchedule = convertScheduleToObj(quarantineNotificationResult.customInboundSchedule)
        state.quarantineNotification = {
          inboundSchedule: quarantineNotificationResult.inboundSchedule,
          customInboundSchedule
        }

        state.updatedSchedule = customInboundSchedule
      })
      .addCase(putQuarantineNotification.rejected, (state, action) => {
        state.putQuarantineNotificationApiStatus = failedResponse(action.payload)
      })

      // changePassword
      .addCase(changePassword.pending, state => {
        state.changePasswordApiStatus = inProgress
      })
      .addCase(changePassword.fulfilled, state => {
        state.changePasswordApiStatus = successResponse
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordApiStatus = failedResponse(action.payload)
      })

      // getLinkedAccounts
      .addCase(getLinkedAccounts.pending, state => {
        state.getLinkedAccountsApiStatus = inProgress
      })
      .addCase(getLinkedAccounts.fulfilled, (state, action) => {
        state.getLinkedAccountsApiStatus = successResponse
        state.linkedAccounts = action.payload.results
      })
      .addCase(getLinkedAccounts.rejected, (state, action) => {
        state.getLinkedAccountsApiStatus = failedResponse(action.payload)
      })

      // postLinkedAccount
      .addCase(postLinkedAccount.pending, state => {
        state.postLinkedAccountApiStatus = inProgress
      })
      .addCase(postLinkedAccount.fulfilled, (state, action) => {
        state.postLinkedAccountApiStatus = successResponse

        const account = action.payload.results[0]
        if (account.verificationPending) {
          state.linkedAccountAdded = account
        } else if (state.linkedAccounts) {
          state.linkedAccounts = action.payload.results
        }
      })
      .addCase(postLinkedAccount.rejected, (state, action) => {
        state.postLinkedAccountApiStatus = failedResponse(action.payload)
      })

      // verifyLinkedAccount
      .addCase(verifyLinkedAccount.pending, state => {
        state.verifyLinkedAccountApiStatus = inProgress
      })
      .addCase(verifyLinkedAccount.fulfilled, state => {
        state.verifyLinkedAccountApiStatus = successResponse
      })
      .addCase(verifyLinkedAccount.rejected, (state, action) => {
        state.verifyLinkedAccountApiStatus = failedResponse(action.payload)
      })

      // deleteLinkedAccount
      .addCase(deleteLinkedAccount.pending, state => {
        state.deleteLinkedAccountApiStatus = inProgress
      })
      .addCase(deleteLinkedAccount.fulfilled, (state, action) => {
        state.deleteLinkedAccountApiStatus = successResponse

        if (state.linkedAccounts) {
          state.linkedAccounts = state.linkedAccounts.filter(value => value.id !== action.payload[0].id)
        }
      })
      .addCase(deleteLinkedAccount.rejected, (state, action) => {
        state.deleteLinkedAccountApiStatus = failedResponse(action.payload)
      })

      // getAccountPermissions
      .addCase(getAccountPermissions.pending, state => {
        state.getAccountPermissionsApiStatus = inProgress
      })
      .addCase(getAccountPermissions.fulfilled, (state, action) => {
        state.getAccountPermissionsApiStatus = successResponse
        // eslint-disable-next-line prefer-destructuring
        state.accountPermissions = action.payload.results[0]
      })
      .addCase(getAccountPermissions.rejected, (state, action) => {
        state.getAccountPermissionsApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { updateSchedule, reset, resetAccountPermissions } = settingsSlice.actions

export {
  getSenderPolicies,
  postPolicy,
  deletePolicy,
  putBulkEditPolicies,
  getQuarantineNotification,
  putQuarantineNotification,
  changePassword,
  getLinkedAccounts,
  postLinkedAccount,
  verifyLinkedAccount,
  deleteLinkedAccount,
  getAccountPermissions
}

export default settingsSlice.reducer
