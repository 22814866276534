import { createStyles, colors } from '@cuda-networks/bds-core'

export const commonStyles = createStyles({
  '@global': {
    body: {
      backgroundColor: colors.white
    }
  },
  title: {
    color: colors.scaleGray900
  },
  subtitle: {
    color: colors.scaleGray600
  }
})
