import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes, { ApiRoute } from 'lib/api/apiRoutes'
import { persistAccessToken, removeAccessToken, removeSessionCookie, setSessionCookie } from 'lib/cookies'
import { calculateTokenExpiration } from 'lib/datetime'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import camelizeKeys from 'lib/camelizeKeys'
import { LoginResponse, User, UserType } from 'types/auth'
import { RootState } from 'redux/toolkit/store'
import { appLoginEntryPath, gotoLegacyLogin } from 'lib/routesConfig'

export const resetAll = createAction('ALL/reset')
export const ENFORCE_MANUAL_LOGIN = 'ENFORCE_MANUAL_LOGIN'

export interface LoginPayload {
  user_id: string
  password: string
}

export interface AutoLoginPayload {
  user_id: string
  expiration: string
  hash: string
  session_id?: string
  source?: string
}

export interface AzureSSOLoginPayload {
  state: string
  code: string
  session_state: string
}

export type BCCLoginPayload = string

export interface BCCLoginUpdatedPayload {
  cloud_at?: string
  portal_id?: string
  migrate_token?: string
}

export interface ValidateSessionIdPayload {
  session_id: string
}

export interface Login {
  session: LoginResponse
  accessToken: LoginResponse['accessToken']
  accessTokenObject: User
  accessTokenExpires: number
}

export interface ResetPasswordPayload {
  userId: string
  password: string
  hash: string
  expiration: string
}

export type PostMixpanelPayload = string

export interface RequestLoginInfoPayload {
  userId: string
  origin: 'LinkExpired' | 'CheckEmail'
}

export type ValidateAccessTokenPayload = string

export type LogoutPayload = boolean

// helpers
async function buildAccessTokenObject(accessToken: string): Promise<User> {
  // get additional user information from jwt token
  const userInfoFromAccessToken = JSON.parse(atob(accessToken.split('.')[1]))

  // Get user object
  const userInfoResponse: { data: User } = await restClient(apiRoutes.USERINFO, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  return {
    ...userInfoResponse.data,
    portalId: Number(userInfoFromAccessToken.portal_id),
    scope: userInfoFromAccessToken.scope,
    isAzureAdAccount: userInfoResponse.data.type === UserType.azure,
    companyId: userInfoFromAccessToken.company_id,
    companyName: userInfoFromAccessToken.company_name,
    ...(!!userInfoResponse.data.userInfo && { userInfo: camelizeKeys(userInfoResponse.data.userInfo) })
  }
}

async function createNewSession(
  payload: LoginPayload | AutoLoginPayload | AzureSSOLoginPayload | BCCLoginUpdatedPayload | ValidateSessionIdPayload,
  apiRoute?: ApiRoute
): Promise<Login> {
  // validate the session
  const loginResp: { data: LoginResponse } = await restClient(apiRoute || apiRoutes.LOGIN, {
    data: { ...payload },
    headers: {
      isToolkitCompatible: true
    }
  })
  setSessionCookie(loginResp.data.sessionId)

  const accessTokenObject = await buildAccessTokenObject(loginResp.data.accessToken)
  const accessTokenExpires = calculateTokenExpiration(loginResp.data.accessTokenExpires)

  // Build store values
  const sessionPayload = {
    session: loginResp.data,
    accessToken: loginResp.data.accessToken,
    accessTokenObject,
    accessTokenExpires: accessTokenExpires.getTime()
  }
  persistAccessToken(loginResp.data.accessToken)

  return sessionPayload
}

function handleSessionError(e: any, rejectWithValue: any) {
  if (e?.status === 403) {
    rejectWithValue(ENFORCE_MANUAL_LOGIN)
  }

  return rejectWithValue(validateApiError(e))
}

// apiThunks
export const validateAccessToken = createAsyncThunk<User, ValidateAccessTokenPayload, ApiRejectResponse>(
  'AUTH/validateAccessToken',
  async (payload, { rejectWithValue }) => {
    try {
      return await buildAccessTokenObject(payload)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const login = createAsyncThunk<Login, LoginPayload, ApiRejectResponse>(
  'AUTH/login',
  async (payload, { rejectWithValue }) => {
    try {
      return await createNewSession(payload)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const autoLogin = createAsyncThunk<Login, AutoLoginPayload, ApiRejectResponse>(
  'AUTH/autoLogin',
  async (payload, { rejectWithValue }) => {
    try {
      return await createNewSession(payload)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const azureSSOLogin = createAsyncThunk<Login, AzureSSOLoginPayload, ApiRejectResponse>(
  'AUTH/azureSSOLogin',
  async (payload, { rejectWithValue }) => {
    try {
      return await createNewSession(payload)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const bccLogin = createAsyncThunk<Login, BCCLoginPayload | undefined, ApiRejectResponse>(
  'AUTH/bccLogin',
  async (payload, { rejectWithValue }) => {
    try {
      return await createNewSession(
        {
          cloud_at: undefined, // hardcode the cloud_at token cookie value for BCC login
          portal_id: undefined, // hardcode the current_account cookie value for BCC login
          migrate_token: payload
        },
        apiRoutes.BCC_LOGIN
      )
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const validateSessionId = createAsyncThunk<Login, ValidateSessionIdPayload, ApiRejectResponse>(
  'AUTH/validateSessionId',
  async (payload, { rejectWithValue }) => {
    try {
      return await createNewSession(payload)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const logout = createAsyncThunk<undefined, undefined, ApiRejectResponse>(
  'AUTH/logout',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { accessToken, accessTokenObject: user } = (getState() as RootState).auth
    const isAzureAdUser = !!user?.isAzureAdAccount

    const handleAzureAdUserLogout = async () => {
      if (isAzureAdUser) {
        removeAccessToken()
        removeSessionCookie()
        gotoLegacyLogin()
        // stop the app
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await new Promise(() => {})
      }
    }

    try {
      // invalidate the session data and reset the entire redux store
      dispatch(resetAll())

      // Forward the non AuzerAd users to login page
      if (!isAzureAdUser) {
        appLoginEntryPath.goto()
        removeAccessToken()
        removeSessionCookie()
      }

      // invalidate the session on BE side if accessToken is set
      if (accessToken) {
        await restClient(apiRoutes.LOGOUT, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      }

      // We should forward the user to TDF after the session is invalidated on BE side
      await handleAzureAdUserLogout()

      return undefined
    } catch (e) {
      // We should forward the user to TDF after the session is invalidated on BE side
      await handleAzureAdUserLogout()

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const resetPassword = createAsyncThunk<undefined, ResetPasswordPayload, ApiRejectResponse>(
  'AUTH/resetPassword',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.RESET_PASSWORD, {
        data: { ...payload }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'reset_password_success'
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'reset_password_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const requestLoginInfo = createAsyncThunk<undefined, RequestLoginInfoPayload, ApiRejectResponse>(
  'AUTH/requestLoginInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.LOGIN_INFO, {
        data: {
          user_id: payload.userId
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
