import { makeStyles, colors } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  login: {
    backgroundColor: theme.palette.info.main,
    height: '100%'
  },
  logoBanner: {
    backgroundColor: theme.palette.text.primary,
    height: 60,
    width: '100%'
  },
  logo: {
    height: 40,
    margin: 10,
    cursor: 'pointer'
  },
  resetPasswordCard: {
    width: 600,
    height: 362
  },
  checkEmailCard: {
    width: 600,
    height: 294
  },
  linkExpiredCard: {
    width: 600,
    height: 200
  },
  successfulResetCard: {
    width: 600,
    height: 134
  },
  linkExpiredContent: {
    height: 70
  },
  linkExpiredMessage: {
    marginTop: 16,
    height: 40
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: `url("/login_background.jpg") center center no-repeat, linear-gradient(${theme.palette.primary.dark}, ${colors.skyBlue500}), ${colors.chartBlueDark}`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardActions: {
    padding: '0'
  },
  snackBar: {
    marginBottom: 32,
    borderRadius: 32,
    boxShadow: `5px 10px 18px ${colors.scaleGray200}`
  },
  passwordWrapper: {
    marginTop: 20,
    height: 80
  },
  password: {
    '& fieldset': {
      borderColor: `${colors.green600} !important`
    },
    message: {
      color: theme.palette.error.main,
      verticalAlign: 'middle'
    }
  },
  passwordHelpText: {
    color: colors.green600
  },
  cardContentMessageBox: {
    marginBottom: 10
  },
  cardActionGridContainer: {
    marginTop: 45
  },
  cardProgressOuter: {
    marginTop: 10,
    marginBottom: 5
  },
  cardProgressInner: {
    marginTop: 0
  },
  linkedAccountsLink: {
    marginTop: 60
  }
}))
