import { makeStyles } from '@cuda-networks/bds-core'

export default makeStyles(theme => ({
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    padding: theme.spacing(2),
    width: 600
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))
